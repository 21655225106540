import { Remove as FalseIcon, Check as TrueIcon } from "@material-ui/icons";
import MaterialTable, { Column } from "material-table";
import React from "react";

import {
  ModelSurvey,
  ModelSurveyStressEnum,
  ModelUserRoleEnum,
  getSurveysAPI,
} from "../api_client";
import { AppDatabase, useAppContext } from "../helpers";

interface SurveysTableProps {
  isLoading: boolean;
  data: ModelSurvey[];
  setData: React.Dispatch<React.SetStateAction<ModelSurvey[]>>;
  refreshData: () => void;
  onRowClick: (survey?: ModelSurvey) => void;
}

export const SurveysTable: React.FC<SurveysTableProps> = ({
  isLoading,
  data,
  setData,
  refreshData,
  onRowClick,
}) => {
  const { handleAPIError, showSnackbar, user } = useAppContext();
  const isAdmin = user?.role === ModelUserRoleEnum.Administrator;

  const updateStats = async (carerId: number | undefined) => {
    if (carerId === undefined) {
      return;
    }

    const db = await AppDatabase.getInstance();
    if (db !== undefined) {
      const carer = await db.users.get(carerId);
      await db.updateUnitStats(carer?.careUnitID);
    }
  };

  const columns: Column<ModelSurvey>[] = [
    { title: "Date", field: "createdAt", type: "date" },
    {
      title: "Name",
      field: "userFullName",
      hidden: data.length === 0 || data[0].userFullName === undefined,
    },
    { title: "Is Safe?", field: "isSafe", type: "boolean" },
  ];

  // Admins also see all the nitty gritty details.
  if (isAdmin) {
    const extraCols: Column<ModelSurvey>[] = [
      {
        title: "Graph 1 DISC",
        render: rowData => {
          const disc = rowData.graph1DISC;
          return `${disc?.rawDominance}, ${disc?.rawInfluence}, ${disc?.rawSteadiness}, ${disc?.rawConscientiousness}`;
        },
      },
      {
        title: "Graph 2 DISC",
        render: rowData => {
          const disc = rowData.graph2DISC;
          return `${disc?.rawDominance}, ${disc?.rawInfluence}, ${disc?.rawSteadiness}, ${disc?.rawConscientiousness}`;
        },
      },
      {
        title: "Graph 3 DISC",
        render: rowData => {
          const disc = rowData.graph3DISC;
          return `${disc?.rawDominance}, ${disc?.rawInfluence}, ${disc?.rawSteadiness}, ${disc?.rawConscientiousness}`;
        },
      },
      {
        title: "Graph 1 Pattern",
        render: rowData => rowData.graph1DISC?.finePattern,
      },
      {
        title: "Graph 2 Pattern",
        render: rowData => rowData.graph2DISC?.finePattern,
      },
      {
        title: "Graph 3 Pattern",
        render: rowData => rowData.graph3DISC?.finePattern,
      },
      {
        title: "Is Graph 1 Tight?",
        render: rowData => {
          const isTight = rowData.graph1DISC?.isTight;
          const icon = isTight ? <TrueIcon /> : <FalseIcon />;
          return icon;
        },
      },
      {
        title: "Is Graph 2 Tight?",
        render: rowData => {
          const isTight = rowData.graph2DISC?.isTight;
          const icon = isTight ? <TrueIcon /> : <FalseIcon />;
          return icon;
        },
      },
      {
        title: "Is Graph 3 Tight?",
        render: rowData => {
          const isTight = rowData.graph3DISC?.isTight;
          const icon = isTight ? <TrueIcon /> : <FalseIcon />;
          return icon;
        },
      },
      {
        title: "Graph 1 Has Overshift?",
        field: "graph1HasOvershift",
        type: "boolean",
      },
      {
        title: "Stress",
        field: "stress",
        lookup: {
          [ModelSurveyStressEnum.None]: "None",
          [ModelSurveyStressEnum.Personal]: "Personal",
          [ModelSurveyStressEnum.WorkRelated]: "Work-Related",
        },
      },
      {
        title: "Stress Amount",
        field: "stressAmount",
      },
      {
        title: "Net Customer Advocacy Score",
        field: "netCustomerAdvocacyScore",
      },
      {
        title: "Happiness Score",
        field: "happinessScore",
      },
      {
        title: "Has Volatile Low Dominance?",
        field: "hasVolatileLowDominance",
        type: "boolean",
      },
      { title: "Dominance Shift", field: "dominanceShift" },
      { title: "Graph 3 Coarse Pattern", field: "graph3CoarsePattern" },
    ];

    columns.push(...extraCols);
  }

  const deleteSurvey = async (surveyToDelete: ModelSurvey) => {
    if (surveyToDelete.id === undefined) {
      return;
    }

    try {
      await getSurveysAPI().deleteSurvey({ id: surveyToDelete.id });

      const db = await AppDatabase.getInstance();
      if (db !== undefined) {
        db.surveys.delete(surveyToDelete.id);
      }
      setData(current => current.filter(s => s.id !== surveyToDelete.id));

      await updateStats(surveyToDelete.carerID);

      showSnackbar("Successfully deleted survey.", "success");
    } catch (e) {
      handleAPIError(e, "removing survey");
    }
  };

  return (
    <MaterialTable
      isLoading={isLoading}
      title="Surveys"
      columns={columns}
      data={data}
      onRowClick={(_event, rowData) => onRowClick(rowData)}
      options={{
        exportButton: false,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50, 100],
        actionsColumnIndex: -1,
      }}
      actions={[
        {
          icon: "refresh",
          isFreeAction: true,
          onClick: refreshData,
          tooltip: "Refresh data",
        },
      ]}
      editable={{
        onRowDelete: isAdmin ? deleteSurvey : undefined,
      }}
    />
  );
};
