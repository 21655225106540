import {
  Button,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { PageContainer } from "../components";
import { Constants } from "../helpers";
import { Breadcrumb, useBreadcrumbs } from "../hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      marginTop: theme.spacing(4),
    },
  }),
);

export const PageNotFound: React.FC = () => {
  const classes = useStyles();

  useBreadcrumbs(Breadcrumb.PageNotFound);

  return (
    <PageContainer>
      <Typography variant="h4" component="h4">
        Page Not Found
      </Typography>

      <div className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          component={RouterLink}
          to={Constants.paths.home}
        >
          Back to home
        </Button>
      </div>
    </PageContainer>
  );
};
