/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ModelUser,
  ModelUserFromJSON,
  ModelUserFromJSONTyped,
  ModelUserToJSON,
} from "./";

/**
 *
 * @export
 * @interface HandlersRecordsPageModelUser
 */
export interface HandlersRecordsPageModelUser {
  /**
   *
   * @type {number}
   * @memberof HandlersRecordsPageModelUser
   */
  nextPage?: number;
  /**
   *
   * @type {number}
   * @memberof HandlersRecordsPageModelUser
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof HandlersRecordsPageModelUser
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof HandlersRecordsPageModelUser
   */
  previousPage?: number;
  /**
   *
   * @type {Array<ModelUser>}
   * @memberof HandlersRecordsPageModelUser
   */
  records?: Array<ModelUser>;
}

export function HandlersRecordsPageModelUserFromJSON(
  json: any,
): HandlersRecordsPageModelUser {
  return HandlersRecordsPageModelUserFromJSONTyped(json, false);
}

export function HandlersRecordsPageModelUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HandlersRecordsPageModelUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    nextPage: !exists(json, "nextPage") ? undefined : json["nextPage"],
    pageNumber: !exists(json, "pageNumber") ? undefined : json["pageNumber"],
    pageSize: !exists(json, "pageSize") ? undefined : json["pageSize"],
    previousPage: !exists(json, "previousPage")
      ? undefined
      : json["previousPage"],
    records: !exists(json, "records")
      ? undefined
      : (json["records"] as Array<any>).map(ModelUserFromJSON),
  };
}

export function HandlersRecordsPageModelUserToJSON(
  value?: HandlersRecordsPageModelUser | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    nextPage: value.nextPage,
    pageNumber: value.pageNumber,
    pageSize: value.pageSize,
    previousPage: value.previousPage,
    records:
      value.records === undefined
        ? undefined
        : (value.records as Array<any>).map(ModelUserToJSON),
  };
}
