/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EchoHTTPError,
  EchoHTTPErrorFromJSON,
  EchoHTTPErrorToJSON,
  HandlersRecordsPageModelCareUnit,
  HandlersRecordsPageModelCareUnitFromJSON,
  HandlersRecordsPageModelCareUnitToJSON,
  HandlersUploadError,
  HandlersUploadErrorFromJSON,
  HandlersUploadErrorToJSON,
  ModelCareUnit,
  ModelCareUnitFromJSON,
  ModelCareUnitToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface AddCareUnitRequest {
  careUnit: ModelCareUnit;
}

export interface DeleteCareUnitRequest {
  id: number;
}

export interface DownloadCareHomeSurveyCsvRequest {
  id: number;
  fromDate?: Date;
  toDate?: Date;
}

export interface GetAllCareUnitsRequest {
  includeStats?: boolean;
  forOrganisation?: number;
  pageNumber?: number;
  pageSize?: number;
  order?: string;
  disablePagination?: boolean;
}

export interface GetCareUnitRequest {
  id: number;
}

export interface UpdateCareUnitRequest {
  id: number;
  careUnit: ModelCareUnit;
}

export interface UploadCareHomeSurveyCsvRequest {
  id: number;
  csvFile: Blob;
}

/**
 *
 */
export class CareUnitsApi extends runtime.BaseAPI {
  /**
   * Add care home unit to CQE systems.
   * Add care home unit.
   */
  async addCareUnitRaw(
    requestParameters: AddCareUnitRequest,
  ): Promise<runtime.ApiResponse<ModelCareUnit>> {
    if (
      requestParameters.careUnit === null ||
      requestParameters.careUnit === undefined
    ) {
      throw new runtime.RequiredError(
        "careUnit",
        "Required parameter requestParameters.careUnit was null or undefined when calling addCareUnit.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/care-units`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelCareUnitToJSON(requestParameters.careUnit),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelCareUnitFromJSON(jsonValue),
    );
  }

  /**
   * Add care home unit to CQE systems.
   * Add care home unit.
   */
  async addCareUnit(
    requestParameters: AddCareUnitRequest,
  ): Promise<ModelCareUnit> {
    const response = await this.addCareUnitRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete care units from CQE systems.
   * Delete care unit.
   */
  async deleteCareUnitRaw(
    requestParameters: DeleteCareUnitRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteCareUnit.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/care-units/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete care units from CQE systems.
   * Delete care unit.
   */
  async deleteCareUnit(
    requestParameters: DeleteCareUnitRequest,
  ): Promise<void> {
    await this.deleteCareUnitRaw(requestParameters);
  }

  /**
   * Download CSV containing survey data on survey analysis. The format of this CSV closely matches the format of the uploaded CSV which a few columns removed and the \"E#\" column replaced with the ID of the user in the API database.
   * Download survey data for care home as CSV.
   */
  async downloadCareHomeSurveyCsvRaw(
    requestParameters: DownloadCareHomeSurveyCsvRequest,
  ): Promise<runtime.ApiResponse<string>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling downloadCareHomeSurveyCsv.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.fromDate !== undefined) {
      queryParameters[
        "fromDate"
      ] = (requestParameters.fromDate as any).toISOString();
    }

    if (requestParameters.toDate !== undefined) {
      queryParameters[
        "toDate"
      ] = (requestParameters.toDate as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/care-units/{id}/surveys`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Download CSV containing survey data on survey analysis. The format of this CSV closely matches the format of the uploaded CSV which a few columns removed and the \"E#\" column replaced with the ID of the user in the API database.
   * Download survey data for care home as CSV.
   */
  async downloadCareHomeSurveyCsv(
    requestParameters: DownloadCareHomeSurveyCsvRequest,
  ): Promise<string> {
    const response = await this.downloadCareHomeSurveyCsvRaw(requestParameters);
    return await response.value();
  }

  /**
   * Retrieve all care units that exist within the CQE systems. The results are paginated by default. See arguments for pagination options.
   * Get all care units in the CQE system.
   */
  async getAllCareUnitsRaw(
    requestParameters: GetAllCareUnitsRequest,
  ): Promise<runtime.ApiResponse<HandlersRecordsPageModelCareUnit>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.includeStats !== undefined) {
      queryParameters["includeStats"] = requestParameters.includeStats;
    }

    if (requestParameters.forOrganisation !== undefined) {
      queryParameters["forOrganisation"] = requestParameters.forOrganisation;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["pageNumber"] = requestParameters.pageNumber;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["pageSize"] = requestParameters.pageSize;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.disablePagination !== undefined) {
      queryParameters["disablePagination"] =
        requestParameters.disablePagination;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/care-units`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      HandlersRecordsPageModelCareUnitFromJSON(jsonValue),
    );
  }

  /**
   * Retrieve all care units that exist within the CQE systems. The results are paginated by default. See arguments for pagination options.
   * Get all care units in the CQE system.
   */
  async getAllCareUnits(
    requestParameters: GetAllCareUnitsRequest,
  ): Promise<HandlersRecordsPageModelCareUnit> {
    const response = await this.getAllCareUnitsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Retrieve care unit from within the CQE systems with specified ID.
   * Get care home with specified ID.
   */
  async getCareUnitRaw(
    requestParameters: GetCareUnitRequest,
  ): Promise<runtime.ApiResponse<ModelCareUnit>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getCareUnit.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/care-units/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelCareUnitFromJSON(jsonValue),
    );
  }

  /**
   * Retrieve care unit from within the CQE systems with specified ID.
   * Get care home with specified ID.
   */
  async getCareUnit(
    requestParameters: GetCareUnitRequest,
  ): Promise<ModelCareUnit> {
    const response = await this.getCareUnitRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update care unit within CQE systems.
   * Update care unit.
   */
  async updateCareUnitRaw(
    requestParameters: UpdateCareUnitRequest,
  ): Promise<runtime.ApiResponse<ModelCareUnit>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateCareUnit.",
      );
    }

    if (
      requestParameters.careUnit === null ||
      requestParameters.careUnit === undefined
    ) {
      throw new runtime.RequiredError(
        "careUnit",
        "Required parameter requestParameters.careUnit was null or undefined when calling updateCareUnit.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/care-units/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ModelCareUnitToJSON(requestParameters.careUnit),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelCareUnitFromJSON(jsonValue),
    );
  }

  /**
   * Update care unit within CQE systems.
   * Update care unit.
   */
  async updateCareUnit(
    requestParameters: UpdateCareUnitRequest,
  ): Promise<ModelCareUnit> {
    const response = await this.updateCareUnitRaw(requestParameters);
    return await response.value();
  }

  /**
   * Upload CSV containing survey data on pre-generated survey analysis, as per CQE-specified format.
   * Upload survey data for care home as CSV.
   */
  async uploadCareHomeSurveyCsvRaw(
    requestParameters: UploadCareHomeSurveyCsvRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling uploadCareHomeSurveyCsv.",
      );
    }

    if (
      requestParameters.csvFile === null ||
      requestParameters.csvFile === undefined
    ) {
      throw new runtime.RequiredError(
        "csvFile",
        "Required parameter requestParameters.csvFile was null or undefined when calling uploadCareHomeSurveyCsv.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.csvFile !== undefined) {
      formParams.append("csvFile", requestParameters.csvFile as any);
    }

    const response = await this.request({
      path: `/care-units/{id}/surveys`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Upload CSV containing survey data on pre-generated survey analysis, as per CQE-specified format.
   * Upload survey data for care home as CSV.
   */
  async uploadCareHomeSurveyCsv(
    requestParameters: UploadCareHomeSurveyCsvRequest,
  ): Promise<void> {
    await this.uploadCareHomeSurveyCsvRaw(requestParameters);
  }
}
