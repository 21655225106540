/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ModelQuestion,
  ModelQuestionFromJSON,
  ModelQuestionFromJSONTyped,
  ModelQuestionToJSON,
} from "./";

/**
 *
 * @export
 * @interface ModelQuestionGroup
 */
export interface ModelQuestionGroup {
  /**
   *
   * @type {Array<ModelQuestion>}
   * @memberof ModelQuestionGroup
   */
  questions?: Array<ModelQuestion>;
  /**
   *
   * @type {boolean}
   * @memberof ModelQuestionGroup
   */
  randomise?: boolean;
}

export function ModelQuestionGroupFromJSON(json: any): ModelQuestionGroup {
  return ModelQuestionGroupFromJSONTyped(json, false);
}

export function ModelQuestionGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ModelQuestionGroup {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    questions: !exists(json, "questions")
      ? undefined
      : (json["questions"] as Array<any>).map(ModelQuestionFromJSON),
    randomise: !exists(json, "randomise") ? undefined : json["randomise"],
  };
}

export function ModelQuestionGroupToJSON(
  value?: ModelQuestionGroup | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    questions:
      value.questions === undefined
        ? undefined
        : (value.questions as Array<any>).map(ModelQuestionToJSON),
    randomise: value.randomise,
  };
}
