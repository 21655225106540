import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  createStyles,
  fade,
  makeStyles,
} from "@material-ui/core";
import { Delete as DeleteIcon, Save as SaveIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import humanInterval from "human-interval";
import React, { useEffect, useState } from "react";

import { ModelCareUnit, ModelCareUnitShiftSafetyModeEnum } from "../api_client";
import { toTitleCase } from "../helpers";
import { CareUnitConfig } from "../pages";
import { ConfirmationDialog } from ".";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //
    },
    form: {
      marginTop: theme.spacing(2),
    },
    formSection: {
      marginBottom: theme.spacing(2),

      "&:not(:first-child)": {
        borderTop: "1px solid",
        borderColor: fade(theme.palette.text.secondary, 0.2),
        paddingTop: theme.spacing(2),
      },

      "& .MuiFormControl-root": {
        marginBottom: theme.spacing(2),
      },
    },
    formSectionTitle: {
      marginBottom: theme.spacing(1),
    },
    formFieldDescription: {
      marginBottom: theme.spacing(2),
    },
    formFieldControl: {
      width: 300,
    },
    dangerZone: {
      padding: theme.spacing(2),
      border: "1px solid",
      borderColor: theme.palette.error.main,
      borderRadius: 6,
    },
    dangerRow: {
      display: "flex",
      "&:not(:first-child)": {
        borderTop: "1px solid",
        borderColor: fade(theme.palette.text.secondary, 0.2),
      },
    },
    dangerInfo: {
      flexGrow: 1,
    },
    dangerDescription: {
      //
    },
    dangerButtonWrapper: {
      position: "relative",
      margin: theme.spacing(1),
    },
    dangerButton: {
      height: 38,
      position: "relative",
      top: "50%",
      transform: "translateY(-50%)",
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      transitionProperty: "background-color, color",

      "&:hover, &:focus": {
        backgroundColor: theme.palette.error.main,
        color: "white",
      },
    },
    actions: {
      display: "flex",
      marginTop: theme.spacing(4),
    },
    saveButtonWrapper: {
      marginLeft: "auto",
    },
    saveButton: {
      //
    },
    progressButtonWrapper: {
      position: "relative",
    },
    progressButtonSpinner: {
      color: theme.palette.success.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

// For display purposes, use 1 month = 30 days. This is in line with human-interval
// package, which is used to convert the other way from human readable to interval.
const msInSec = 1000;
const msInMinute = msInSec * 60;
const msInHour = msInMinute * 60;
const msInDay = msInHour * 24;
const msInMonth = msInDay * 30;

const intervalToHuman = (ms: number | undefined) => {
  if (ms === undefined || isNaN(ms)) {
    return "N/A";
  }

  if (ms === 0) {
    return "0 seconds";
  }

  const months = Math.floor(ms / msInMonth);
  const days = Math.floor((ms % msInMonth) / msInDay);
  const hours = Math.floor(((ms % msInMonth) % msInDay) / msInHour);
  const mins = Math.floor(
    (((ms % msInMonth) % msInDay) % msInHour) / msInMinute,
  );
  const secs =
    ((((ms % msInMonth) % msInDay) % msInHour) % msInMinute) / msInSec;

  const monthsText =
    months !== 0 ? `${months} month${months > 1 ? "s" : ""} ` : "";
  const daysText = days !== 0 ? `${days} day${days > 1 ? "s" : ""} ` : "";
  const hoursText = hours !== 0 ? `${hours} hour${hours > 1 ? "s" : ""} ` : "";
  const minsText = mins !== 0 ? `${mins} minute${mins > 1 ? "s" : ""} ` : "";
  const secsText = secs !== 0 ? `${secs} second${secs > 1 ? "s" : ""}` : "";

  return monthsText + daysText + hoursText + minsText + secsText;
};

/**
 * Convert millisecond interval to days, rounding to the nearest whole number.
 * @param ms Interval, in milliseconds, to convert to whole days.
 */
const intervalToDays = (ms: number | undefined) => {
  if (ms === undefined || isNaN(ms)) {
    return undefined;
  }

  return Math.round(ms / msInDay);
};

interface CareUnitSettingsProps {
  careUnit: ModelCareUnit | undefined;
  onUpdate: (updated: CareUnitConfig) => void;
  onDelete: () => void;
}

export const CareUnitSettings: React.FC<CareUnitSettingsProps> = ({
  careUnit,
  onUpdate,
  onDelete,
}) => {
  const classes = useStyles();

  const [deleting, setDeleting] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [saving, setSaving] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const currentName = careUnit?.name ?? "";
  const [name, setName] = useState(currentName);
  useEffect(() => setName(currentName), [currentName]);

  const currentCarerSurveyFrequency =
    careUnit?.carerSurveyFrequencyDays !== undefined
      ? careUnit.carerSurveyFrequencyDays * msInDay
      : undefined;
  const [carerSurveyFrequencyHuman, setCarerSurveyFrequencyHuman] = useState(
    intervalToHuman(currentCarerSurveyFrequency),
  );
  const [carerSurveyFrequency, setCarerSurveyFrequency] = useState<
    number | null
  >(currentCarerSurveyFrequency ?? null);

  useEffect(() => {
    setCarerSurveyFrequencyHuman(intervalToHuman(currentCarerSurveyFrequency));
    setCarerSurveyFrequency(currentCarerSurveyFrequency ?? null);
  }, [currentCarerSurveyFrequency]);

  const currentManagerSurveyFrequency =
    careUnit?.managerSurveyFrequencyDays !== undefined
      ? careUnit.managerSurveyFrequencyDays * msInDay
      : undefined;
  const [
    managerSurveyFrequencyHuman,
    setManagerSurveyFrequencyHuman,
  ] = useState(intervalToHuman(currentManagerSurveyFrequency));
  const [managerSurveyFrequency, setManagerSurveyFrequency] = useState<
    number | null
  >(currentManagerSurveyFrequency ?? null);

  useEffect(() => {
    setManagerSurveyFrequencyHuman(
      intervalToHuman(currentManagerSurveyFrequency),
    );
    setManagerSurveyFrequency(currentManagerSurveyFrequency ?? null);
  }, [currentManagerSurveyFrequency]);

  const currentShiftMode =
    careUnit?.shiftSafetyMode ?? ModelCareUnitShiftSafetyModeEnum.Standard;
  const [shiftSafetyMode, setShiftSafetyMode] = useState(currentShiftMode);

  useEffect(() => setShiftSafetyMode(currentShiftMode), [currentShiftMode]);

  const hasChanged =
    name !== currentName ||
    carerSurveyFrequency !== currentCarerSurveyFrequency ||
    managerSurveyFrequency !== currentManagerSurveyFrequency ||
    shiftSafetyMode !== currentShiftMode;

  const isValid =
    name !== "" &&
    carerSurveyFrequency !== null &&
    !isNaN(carerSurveyFrequency) &&
    carerSurveyFrequency > 0 &&
    managerSurveyFrequency !== null &&
    !isNaN(managerSurveyFrequency) &&
    managerSurveyFrequency > 0 &&
    shiftSafetyMode !== undefined;

  const updateCareUnit = async () => {
    const carerSurveyFrequencyDays = intervalToDays(
      carerSurveyFrequency ?? undefined,
    );
    const managerSurveyFrequencyDays = intervalToDays(
      managerSurveyFrequency ?? undefined,
    );

    if (
      carerSurveyFrequencyDays === undefined ||
      managerSurveyFrequencyDays === undefined
    ) {
      return;
    }

    setSaving(true);

    try {
      await onUpdate({
        name,
        carerSurveyFrequencyDays,
        managerSurveyFrequencyDays,
        shiftSafetyMode,
      });
    } finally {
      setSaving(false);
    }
  };

  const deleteCareUnit = async () => {
    setDeleting(true);

    try {
      await onDelete();
      setDeleteSuccess(true);
    } finally {
      setDeleting(false);
    }
  };

  const surveyFrequencyHelper =
    "Enter as human-readable time interval, e.g. '2 weeks', '30 days', '2 months'. Rounded to whole number of days. 1 month = 30 days.";

  return (
    <div className={classes.root}>
      <Typography variant="h3">Care Unit Settings</Typography>
      <div className={classes.form}>
        <div className={classes.formSection}>
          <Typography variant="h6" className={classes.formSectionTitle}>
            {careUnit !== undefined ? "General" : <Skeleton width="20%" />}
          </Typography>

          {careUnit !== undefined ? (
            <TextField
              label="Name"
              variant="outlined"
              value={name}
              onChange={event => setName(event.target.value)}
            />
          ) : (
            <Skeleton width="50%" />
          )}
        </div>

        <div className={classes.formSection}>
          <Typography variant="h6" className={classes.formSectionTitle}>
            {careUnit !== undefined ? "Surveys" : <Skeleton width="20%" />}
          </Typography>

          <Typography variant="body1" className={classes.formFieldDescription}>
            {careUnit !== undefined ? (
              <>
                Carers and managers should complete surveys regularly to keep
                the system up-to-date. It&apos;s recommended to set the survey
                frequency to ~1 to 2 months.
              </>
            ) : (
              <Skeleton width="75%" />
            )}
          </Typography>

          {careUnit !== undefined ? (
            <TextField
              label="Carer survey frequency"
              variant="outlined"
              helperText={surveyFrequencyHelper}
              error={
                carerSurveyFrequency !== null && isNaN(carerSurveyFrequency)
              }
              value={carerSurveyFrequencyHuman}
              onChange={event => {
                const value = event.target.value;
                setCarerSurveyFrequencyHuman(value);

                const interval = humanInterval(value);
                setCarerSurveyFrequency(interval ?? null);
              }}
            />
          ) : (
            <Skeleton width="50%" />
          )}

          {careUnit !== undefined ? (
            <TextField
              label="Manager survey frequency"
              variant="outlined"
              helperText={surveyFrequencyHelper}
              error={
                managerSurveyFrequency !== null && isNaN(managerSurveyFrequency)
              }
              value={managerSurveyFrequencyHuman}
              onChange={event => {
                const value = event.target.value;
                setManagerSurveyFrequencyHuman(value);

                const interval = humanInterval(value);
                setManagerSurveyFrequency(interval ?? null);
              }}
            />
          ) : (
            <Skeleton width="50%" />
          )}
        </div>

        <div className={classes.formSection}>
          <Typography variant="h6" className={classes.formSectionTitle}>
            {careUnit !== undefined ? "Rotas" : <Skeleton width="20%" />}
          </Typography>

          <Typography variant="body1" className={classes.formFieldDescription}>
            {careUnit !== undefined ? (
              <>
                When first starting out with the CQE rota management system, it
                can be useful to set the rota shift safety mode to
                &quot;Beginner&quot; to make the safety calculations slightly
                more lenient. Once the care unit is more well established, you
                can then update the shift safety mode to &quot;Standard&quot;.
              </>
            ) : (
              <Skeleton width="75%" />
            )}
          </Typography>

          {careUnit !== undefined ? (
            <FormControl className={classes.formFieldControl}>
              <InputLabel id="rota-shift-safety-mode-label">
                Shift safety mode
              </InputLabel>
              <Select
                labelId="rota-shift-safety-mode-label"
                id="shift-safety-mode-select"
                value={shiftSafetyMode}
                onChange={event => {
                  const value = event.target
                    .value as ModelCareUnitShiftSafetyModeEnum;
                  setShiftSafetyMode(value);
                }}
              >
                {Object.values(ModelCareUnitShiftSafetyModeEnum).map(
                  (mode, i) => (
                    <MenuItem key={i} value={mode}>
                      {toTitleCase(mode)}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          ) : (
            <Skeleton width="50%" />
          )}
        </div>

        <div className={classes.formSection}>
          <Typography variant="h6" className={classes.formSectionTitle}>
            {careUnit !== undefined ? "Danger Zone" : <Skeleton width="20%" />}
          </Typography>

          <div className={classes.dangerZone}>
            <div className={classes.dangerRow}>
              <div className={classes.dangerInfo}>
                <Typography variant="h6" component="p">
                  {careUnit !== undefined ? (
                    "Delete this care unit"
                  ) : (
                    <Skeleton width="30%" />
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.dangerDescription}
                >
                  {careUnit !== undefined ? (
                    <>
                      Once you delete this care unit, it and all the associated
                      users, surveys and rotas will all be deleted. This
                      operation cannot be undone.
                    </>
                  ) : (
                    <Skeleton width="75%" />
                  )}
                </Typography>
              </div>

              <div
                className={clsx(
                  classes.dangerButtonWrapper,
                  classes.progressButtonWrapper,
                )}
              >
                <Button
                  className={classes.dangerButton}
                  variant="outlined"
                  disabled={careUnit === undefined || deleting || deleteSuccess}
                  onClick={() => setConfirmDialogOpen(true)}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
                {deleting && (
                  <CircularProgress
                    size={24}
                    className={classes.progressButtonSpinner}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.actions}>
        <div
          className={clsx(
            classes.saveButtonWrapper,
            classes.progressButtonWrapper,
          )}
        >
          <Button
            className={classes.saveButton}
            color="secondary"
            variant="contained"
            disabled={
              careUnit === undefined ||
              !hasChanged ||
              !isValid ||
              saving ||
              deleting ||
              deleteSuccess
            }
            onClick={updateCareUnit}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
          {saving && (
            <CircularProgress
              size={24}
              className={classes.progressButtonSpinner}
            />
          )}
        </div>
      </div>

      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        message={
          <>
            This action <strong>cannot</strong> be undone. This will{" "}
            <strong>permanently delete</strong> all carers, managers, rotas,
            shifts, surveys and anything else associated with this care unit.
          </>
        }
        onConfirm={deleteCareUnit}
        confirmationText={careUnit?.name}
      />
    </div>
  );
};
