/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ModelQuestionGroup,
  ModelQuestionGroupFromJSON,
  ModelQuestionGroupFromJSONTyped,
  ModelQuestionGroupToJSON,
} from "./";

/**
 *
 * @export
 * @interface ViewmodelAddSurvey
 */
export interface ViewmodelAddSurvey {
  /**
   *
   * @type {Array<ModelQuestionGroup>}
   * @memberof ViewmodelAddSurvey
   */
  surveyContents?: Array<ModelQuestionGroup>;
  /**
   *
   * @type {number}
   * @memberof ViewmodelAddSurvey
   */
  userID?: number;
}

export function ViewmodelAddSurveyFromJSON(json: any): ViewmodelAddSurvey {
  return ViewmodelAddSurveyFromJSONTyped(json, false);
}

export function ViewmodelAddSurveyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ViewmodelAddSurvey {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    surveyContents: !exists(json, "surveyContents")
      ? undefined
      : (json["surveyContents"] as Array<any>).map(ModelQuestionGroupFromJSON),
    userID: !exists(json, "userID") ? undefined : json["userID"],
  };
}

export function ViewmodelAddSurveyToJSON(
  value?: ViewmodelAddSurvey | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    surveyContents:
      value.surveyContents === undefined
        ? undefined
        : (value.surveyContents as Array<any>).map(ModelQuestionGroupToJSON),
    userID: value.userID,
  };
}
