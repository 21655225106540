import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";

import { ModelAdjective, ModelAdjectiveQuestion } from "../api_client";
import { shuffle } from "../helpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //
    },
    questionNumber: {
      fontWeight: "bold",
    },
    textEmphasis: {
      fontStyle: "oblique",
    },
    adjectiveSkeleton: {
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: "1rem",
    },
    questionPart: {
      textAlign: "center",
    },
    card: {
      textAlign: "left",
      marginTop: theme.spacing(2),
      display: "inline-block",
    },
    options: {
      marginLeft: theme.spacing(4),
      fontSize: "1.2rem",
    },
  }),
);

interface AdjectiveQuestionProps {
  question: ModelAdjectiveQuestion | undefined;
  questionNumber?: number;
  onUpdate?: (completed: ModelAdjectiveQuestion) => void;
  onAdvance?: () => void;
}

export const AdjectiveQuestion: React.FC<AdjectiveQuestionProps> = ({
  question,
  questionNumber,
  onUpdate,
  onAdvance,
}) => {
  const classes = useStyles();

  const currentChoiceMost =
    question?.userChoiceMost !== undefined
      ? question.adjectives?.[question.userChoiceMost]?.text ?? null
      : null;
  const currentChoiceLeast =
    question?.userChoiceLeast !== undefined
      ? question.adjectives?.[question.userChoiceLeast]?.text ?? null
      : null;

  const [adjectives, setAdjectives] = useState<ModelAdjective[]>([]);
  const [choiceMost, setChoiceMost] = useState<string | null>(
    currentChoiceMost,
  );
  const [choiceLeast, setChoiceLeast] = useState<string | null>(
    currentChoiceLeast,
  );

  const questionAdjectives = question?.adjectives;

  useEffect(() => {
    setAdjectives(shuffle(questionAdjectives ?? []));
  }, [questionAdjectives]);

  useEffect(() => setChoiceMost(currentChoiceMost), [currentChoiceMost]);
  useEffect(() => setChoiceLeast(currentChoiceLeast), [currentChoiceLeast]);

  useEffect(() => {
    if (
      question === undefined ||
      question.adjectives === undefined ||
      choiceMost === null ||
      choiceLeast === null ||
      (choiceMost === currentChoiceMost && choiceLeast === currentChoiceLeast)
    ) {
      return;
    }

    const mostIndex = question.adjectives?.map(a => a.text).indexOf(choiceMost);
    const leastIndex = question.adjectives
      ?.map(a => a.text)
      .indexOf(choiceLeast);

    if (mostIndex === -1 || leastIndex === -1) {
      console.error(
        `Unable to find index for adjective '${choiceMost}' or '${choiceLeast}'; choices are: ${question?.adjectives
          .map(q => q.text)
          .join(", ")}`,
      );
      return;
    }

    question.userChoiceMost = mostIndex;
    question.userChoiceLeast = leastIndex;

    const nextQuestionDelay = 1;
    setTimeout(() => {
      onUpdate?.(question);
      onAdvance?.();
    }, nextQuestionDelay);
  }, [
    choiceMost,
    choiceLeast,
    currentChoiceMost,
    currentChoiceLeast,
    question,
    onUpdate,
    onAdvance,
  ]);

  return (
    <div className={classes.root}>
      <div className={classes.questionPart}>
        <Card className={classes.card}>
          <CardHeader
            title={
              question !== undefined ? (
                <>
                  <span className={classes.questionNumber}>
                    {questionNumber}a)
                  </span>{" "}
                  Which <span className={classes.textEmphasis}>most</span>{" "}
                  describes you at work?
                </>
              ) : (
                <Skeleton />
              )
            }
          />

          <CardContent>
            {question !== undefined ? (
              <FormControl component="fieldset" className={classes.options}>
                <RadioGroup
                  aria-label="most choice"
                  name="most-choice"
                  value={choiceMost}
                  onChange={event => setChoiceMost(event.target.value)}
                >
                  {adjectives.map((a, i) => (
                    <FormControlLabel
                      key={i}
                      value={a.text}
                      control={<Radio />}
                      label={a.text}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            ) : (
              <>
                {[1, 2, 3, 4].map(v => (
                  <Skeleton
                    key={v}
                    variant="text"
                    width="40%"
                    className={classes.adjectiveSkeleton}
                  />
                ))}
              </>
            )}
          </CardContent>
        </Card>
      </div>

      <div className={classes.questionPart}>
        <Card className={classes.card}>
          <CardHeader
            title={
              question !== undefined ? (
                <>
                  <span className={classes.questionNumber}>
                    {questionNumber}b)
                  </span>{" "}
                  At work, which{" "}
                  <span className={classes.textEmphasis}>least</span> describes
                  you?
                </>
              ) : (
                <Skeleton />
              )
            }
          />

          <CardContent>
            {question !== undefined ? (
              <FormControl component="fieldset" className={classes.options}>
                <RadioGroup
                  aria-label="least choice"
                  name="least-choice"
                  value={choiceLeast}
                  onChange={event => setChoiceLeast(event.target.value)}
                >
                  {adjectives.map((a, i) => (
                    <FormControlLabel
                      key={i}
                      value={a.text}
                      control={<Radio />}
                      label={a.text}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            ) : (
              <>
                {[1, 2, 3, 4].map(v => (
                  <Skeleton
                    key={v}
                    variant="text"
                    width="40%"
                    className={classes.adjectiveSkeleton}
                  />
                ))}
              </>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
