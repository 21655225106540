import { Theme, createStyles, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";

import logo from "../static/cqe-logo-circle.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
      opacity: 1,
      transition: "opacity ease-in-out 1000ms",
      zIndex: 9999,
    },
    invisible: {
      opacity: 0,
    },
    hide: {
      display: "none",
    },
  }),
);

interface SplashScreenProps {
  isVisible: boolean;
}

export const SplashScreen: React.FC<SplashScreenProps> = ({ isVisible }) => {
  const classes = useStyles();

  const [hasFadedOut, setHasFadedOut] = useState(false);

  return (
    <div
      className={clsx(classes.root, {
        [classes.invisible]: !isVisible,
        [classes.hide]: hasFadedOut,
      })}
      onTransitionEnd={() => setHasFadedOut(true)}
      onAnimationEnd={() => setHasFadedOut(true)}
    >
      <img src={logo} alt="CQE splash logo" />
    </div>
  );
};
