import { useCallback, useEffect, useState } from "react";

import { ModelCareOrganisation } from "../api_client";
import { getCareOrganisationsAPI } from "../api_client";
import { AppDatabase, HandleAPIErrorFun, useAppContext } from "../helpers";
import { useAPIUpdatePeriod } from ".";

// Get org info directly from API.
const getLiveOrg = async (
  orgId: number,
  setOrgInfo: (newOrgInfo: ModelCareOrganisation) => void,
  handleAPIError: HandleAPIErrorFun,
) => {
  try {
    const orgInfo = await getCareOrganisationsAPI().getCareOrganisation({
      id: orgId,
    });

    const db = await AppDatabase.getInstance();
    if (db !== undefined) {
      await db.organisations.put(orgInfo, orgInfo.id);
    }

    setOrgInfo(orgInfo);
  } catch (e) {
    handleAPIError(e, "retrieving care organisation");
  }
};

export const useOrgInfo = (orgId: number | undefined) => {
  const { handleAPIError } = useAppContext();

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [orgInfo, setOrgInfo] = useState<ModelCareOrganisation | undefined>(
    undefined,
  );

  const [setUpdatedFromAPI, isAPIUpdateDue] = useAPIUpdatePeriod(
    `organisation-${orgId}`,
  );

  useEffect(() => {
    if (initialLoadComplete || orgId === undefined) {
      return;
    }

    const getCachedOrg = async () => {
      const db = await AppDatabase.getInstance();
      if (db === undefined) {
        return;
      }

      const cachedOrg = await db.organisations.get(orgId);
      setOrgInfo(cachedOrg);
      return cachedOrg !== undefined;
    };

    getCachedOrg().then(async cachePresent => {
      if (!cachePresent || isAPIUpdateDue()) {
        await getLiveOrg(orgId, setOrgInfo, handleAPIError);
      }

      setInitialLoadComplete(true);
      setIsLoading(false);
      setUpdatedFromAPI();
    });
  }, [
    orgId,
    handleAPIError,
    initialLoadComplete,
    isAPIUpdateDue,
    setUpdatedFromAPI,
  ]);

  const refreshOrgInfo = useCallback(async () => {
    if (orgId === undefined) {
      return;
    }

    setIsLoading(true);
    await getLiveOrg(orgId, setOrgInfo, handleAPIError);
    setIsLoading(false);
    setUpdatedFromAPI();
  }, [orgId, handleAPIError, setUpdatedFromAPI]);

  return [orgInfo, setOrgInfo, isLoading, refreshOrgInfo] as const;
};
