import DateFnsUtils from "@date-io/date-fns";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  createStyles,
  darken,
  fade,
  lighten,
  makeStyles,
} from "@material-ui/core";
import * as is from "is_js";
import React, { useEffect, useState } from "react";

import {
  ModelCareUnitShiftSafetyModeEnum,
  ModelRotaUser,
  ModelShift,
  ModelShiftPreset,
  ModelShiftPresetPresetTimeEnum,
  ModelUser,
  getRotasAPI,
  getShiftsAPI,
} from "../../api_client";
import { ModelRota } from "../../api_client";
import { AppDatabase, shiftsOverlap, useAppContext } from "../../helpers";
import { UserShiftMap, useShiftPresets } from "../../hooks";
import { UserSafetyScores } from "../../pages";
import { ShiftSafetyRow } from "./ShiftSafetyRow";
import { UserRow } from "./UserRow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginLeft: 12,
      display: "flex",
      justifyContent: "space-between",
    },
    titleIcon: {
      verticalAlign: "sub",
      marginRight: 8,
    },
    titleActions: {},
    rotaTableContainer: {
      overflowX: "visible",
    },
    rotaTable: {
      "& .MuiTableCell-root": {
        borderColor:
          theme.palette.type === "light"
            ? lighten(fade(theme.palette.divider, 1), 0.68)
            : darken(fade(theme.palette.divider, 1), 0.72),
      },
      "& .MuiTableCell-stickyHeader": {
        backgroundColor: darken(
          theme.palette.background.paper,
          theme.palette.type === "light" ? 0.15 : 0.5,
        ),
      },
    },
    actionsCell: {
      width: 48,
    },
    totalHoursCell: {
      minWidth: 48,
    },
    shiftSafetyCell: {
      minHeight: 65,
    },
    reorderButtonsCell: {
      width: 50,
      padding: 2,
    },
    userInfoCell: {
      minWidth: 225,
      paddingLeft: 0,
    },
    footer: {
      "& .MuiTableCell-stickyHeader": {
        bottom: 0,
        borderBottom: "none",
      },
    },
    // Safari doesn't listen to `bottom: 0` for the `<th>` elements but works for the
    // `<thead>` element. Chrome doesn't allow `position: sticky` for `thead` but works
    // with the `<th>`. Firefox works either way.
    footerSafari: {
      position: "sticky",
      bottom: 0,
      "& .MuiTableCell-stickyHeader": {
        borderBottom: "none",
        position: "initial",
      },
    },
  }),
);

export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export class ShiftSafety {
  numEarlyCarers: number;
  numLateCarers: number;
  numNightCarers: number;

  earlyScore: number | undefined;
  lateScore: number | undefined;
  nightScore: number | undefined;

  numCarers: { [time: string]: number };
  isShiftSafe: { [time: string]: boolean };
  isShiftUnsafe: { [time: string]: boolean };

  isAnyTimeSafe: boolean;
  isAnyTimeUnsafe: boolean;

  isSafe: boolean;
  isUnsafe: boolean;

  public constructor(
    early: readonly [number, number | undefined],
    late: readonly [number, number | undefined],
    night: readonly [number, number | undefined],
  ) {
    [this.numEarlyCarers, this.earlyScore] = early;
    [this.numLateCarers, this.lateScore] = late;
    [this.numNightCarers, this.nightScore] = night;

    this.numCarers = {
      [ModelShiftPresetPresetTimeEnum.Early]: this.numEarlyCarers,
      [ModelShiftPresetPresetTimeEnum.Late]: this.numLateCarers,
      [ModelShiftPresetPresetTimeEnum.Night]: this.numNightCarers,
    };

    this.isShiftSafe = {
      [ModelShiftPresetPresetTimeEnum.Early]:
        this.earlyScore !== undefined ? this.earlyScore >= 1 : false,
      [ModelShiftPresetPresetTimeEnum.Late]:
        this.lateScore !== undefined ? this.lateScore >= 1 : false,
      [ModelShiftPresetPresetTimeEnum.Night]:
        this.nightScore !== undefined ? this.nightScore >= 1 : false,
    };

    this.isShiftUnsafe = {
      [ModelShiftPresetPresetTimeEnum.Early]:
        this.earlyScore !== undefined ? this.earlyScore < 1 : false,
      [ModelShiftPresetPresetTimeEnum.Late]:
        this.lateScore !== undefined ? this.lateScore < 1 : false,
      [ModelShiftPresetPresetTimeEnum.Night]:
        this.nightScore !== undefined ? this.nightScore < 1 : false,
    };

    this.isAnyTimeSafe = Object.values(this.isShiftSafe).some(v => v);
    this.isAnyTimeUnsafe = Object.values(this.isShiftUnsafe).some(v => v);

    this.isSafe = this.isAnyTimeSafe && !this.isAnyTimeUnsafe;
    this.isUnsafe = this.isAnyTimeUnsafe;
  }
}

const numSkeletonRows = 3;
const dateUtils = new DateFnsUtils();

interface RotaTableProps {
  orgId: number | undefined;
  onNavigateToConfigureShifts: () => void;

  rota: ModelRota | undefined;
  setRota: React.Dispatch<React.SetStateAction<ModelRota | undefined>>;
  isRotaLoading: boolean;
  rotaDate: Date;
  isNightStaff: boolean;

  users: ModelUser[];
  areUsersLoading: boolean;

  userShifts: UserShiftMap;
  setUserShifts: React.Dispatch<React.SetStateAction<UserShiftMap>>;
  areShiftsLoading: boolean;

  userSafetyScores: UserSafetyScores;
  shiftSafetyMode: ModelCareUnitShiftSafetyModeEnum | undefined;
  isSafetyLoading: boolean;
}

export const RotaTable: React.FC<RotaTableProps> = ({
  orgId,
  onNavigateToConfigureShifts,

  rota,
  setRota,
  isRotaLoading,
  rotaDate,
  isNightStaff,

  users,

  userShifts,
  setUserShifts,
  areShiftsLoading,

  userSafetyScores,
  shiftSafetyMode,
  isSafetyLoading,
}) => {
  const classes = useStyles();
  const { handleAPIError } = useAppContext();

  const [shiftPresets] = useShiftPresets(orgId);

  const currentRotaUsers =
    rota?.users?.sort((l, r) => l.index! - r.index!) ?? [];
  const [rotaUsers, setRotaUsers] = useState(currentRotaUsers);

  useEffect(() => {
    if (JSON.stringify(rotaUsers) !== JSON.stringify(currentRotaUsers)) {
      setRotaUsers(currentRotaUsers);
    }
  }, [rotaUsers, currentRotaUsers]);

  // Get IDs of all users working a particular shift. This takes into account shift
  // overlap.
  const getUserIdsWorkingShift = (
    dayOfWeek: DayOfWeek,
    preset: ModelShiftPreset | undefined,
  ) => {
    if (preset === undefined) {
      return undefined;
    }

    return Object.keys(userShifts)
      .map(userId => Number(userId))
      .filter(userId => {
        // Supernumerary users don't work carer shifts (e.g. training, supervisors,
        // management, etc.) so shouldn't be counted as "working" any particular shift.
        const user = users.find(u => u.id === userId);
        return user?.isSupernumerary === false;
      })
      .filter(userId => shiftsOverlap(userShifts[userId], dayOfWeek, preset));
  };

  const getShiftSafety = (
    dayOfWeek: DayOfWeek,
    preset: ModelShiftPreset | undefined,
  ) => {
    const shiftCarerIds = getUserIdsWorkingShift(dayOfWeek, preset);
    if (shiftCarerIds === undefined) {
      return [0, undefined] as const;
    }

    // Rated here just means they are classified either as "safe" or "unsafe", not just
    // as "needs more info".
    const ratedUserIds = shiftCarerIds.filter(
      userId => userSafetyScores[userId] !== undefined,
    );

    const numTotalCarers = shiftCarerIds.length;

    if (ratedUserIds.length === 0) {
      return [numTotalCarers, undefined] as const;
    }

    const numSafeCarers = shiftCarerIds.filter(
      userId => userSafetyScores[userId] === 1,
    ).length;

    const numUnsafeCarers = shiftCarerIds.filter(
      userId => userSafetyScores[userId] === 0,
    ).length;

    // Risky carers are not just simply counted but weighted but how risky they are.
    const numRiskyCarers = shiftCarerIds
      .filter(userId => {
        const safetyScore = userSafetyScores[userId];
        return safetyScore !== undefined && safetyScore > 0 && safetyScore < 1;
      })
      .map(userId => {
        const safetyScore = userSafetyScores[userId]!;
        return shiftSafetyMode === ModelCareUnitShiftSafetyModeEnum.Beginner
          ? safetyScore
          : 1 - safetyScore;
      })
      .reduce((accumulator, current) => accumulator + current, 0);

    const numerator =
      shiftSafetyMode === ModelCareUnitShiftSafetyModeEnum.Beginner
        ? numSafeCarers + numRiskyCarers
        : numSafeCarers;

    const denominator =
      shiftSafetyMode === ModelCareUnitShiftSafetyModeEnum.Beginner
        ? numUnsafeCarers
        : numRiskyCarers + numUnsafeCarers;

    // If we don't have any unsafe or risky carers, we're safe. Early return here to prevent divide by zero.
    if (denominator === 0) {
      return [numTotalCarers, numSafeCarers] as const;
    }

    return [numTotalCarers, numerator / denominator] as const;
  };

  const shiftSafetyScores = Array.from(Array(7).keys()).map(
    i =>
      new ShiftSafety(
        getShiftSafety(
          i as DayOfWeek,
          shiftPresets.find(
            p => p.presetTime === ModelShiftPresetPresetTimeEnum.Early,
          ),
        ),
        getShiftSafety(
          i as DayOfWeek,
          shiftPresets.find(
            p => p.presetTime === ModelShiftPresetPresetTimeEnum.Late,
          ),
        ),
        getShiftSafety(
          i as DayOfWeek,
          shiftPresets.find(
            p => p.presetTime === ModelShiftPresetPresetTimeEnum.Night,
          ),
        ),
      ),
  );

  const userNameSafetyScores = { ...userSafetyScores } as any;
  for (const userId of Object.keys(userNameSafetyScores)) {
    const user = users.find(u => u.id === Number(userId));
    if (user !== undefined) {
      userNameSafetyScores[user.firstName + " " + user.lastName] =
        userNameSafetyScores[userId];
    }
    delete userNameSafetyScores[userId];
  }

  const getShiftsForUser = (userId: number | undefined) => {
    if (userId === undefined) {
      return [];
    }

    const allShifts = userShifts[userId];

    if (allShifts === undefined) {
      return [];
    }

    return allShifts.filter(s => s !== undefined) as ModelShift[];
  };

  const addOrUpdateShift = async (shift: ModelShift, dayOfWeek: DayOfWeek) => {
    const userId = shift.userID;
    if (rota === undefined || userId === undefined) {
      return;
    }

    try {
      const newShift =
        shift.id !== undefined
          ? await getShiftsAPI().updateShift({ id: shift.id, shift })
          : await getShiftsAPI().addShift({ shift });

      const db = await AppDatabase.getInstance();
      if (db !== undefined) {
        await db.shifts.put(newShift, newShift.id);
      }

      setUserShifts(currentShifts => {
        const newShifts = { ...currentShifts };

        if (newShifts[userId] === undefined) {
          newShifts[userId] = [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
          ];
        }

        newShifts[userId][dayOfWeek] = newShift;

        return newShifts;
      });
    } catch (e) {
      handleAPIError(e, "adding shift to rota");
    }
  };

  const removeShift = async (shift: ModelShift, dayOfWeek: DayOfWeek) => {
    const shiftId = shift.id;
    const userId = shift.userID;

    if (shiftId === undefined || userId === undefined) {
      return;
    }

    try {
      await getShiftsAPI().deleteShift({ id: shiftId });

      const db = await AppDatabase.getInstance();
      if (db !== undefined) {
        await db.shifts.delete(shiftId);
      }

      setUserShifts(currentUserShifts => {
        const newUserShifts = { ...currentUserShifts };
        if (newUserShifts[userId] !== undefined) {
          newUserShifts[userId][dayOfWeek] = undefined;
        }
        return newUserShifts;
      });
    } catch (e) {
      handleAPIError(e, "deleting shift");
    }
  };

  const removeUserFromRota = async (userId: number | undefined) => {
    if (userId === undefined || rota === undefined) {
      return;
    }

    const shiftsToDelete = getShiftsForUser(userId);

    try {
      // Todo: If the API exposed a bulk-delete functionality, this would be more efficient.
      const shiftsAPI = getShiftsAPI();
      for (const shiftToDelete of shiftsToDelete.filter(
        s => s !== undefined,
      ) as ModelShift[]) {
        await shiftsAPI.deleteShift({
          id: shiftToDelete.id!,
        });
      }

      const db = await AppDatabase.getInstance();
      if (db !== undefined) {
        await db.shifts.bulkDelete(shiftsToDelete.map(s => s.id!));
      }

      setUserShifts(currentUserShifts => {
        const newUserShifts = { ...currentUserShifts };
        delete newUserShifts[userId];
        return newUserShifts;
      });
    } catch (e) {
      handleAPIError(e, "deleting user shifts");
    }

    // As we're removing a user we need to reset the indices according to the new order.
    const newUsers = rota.users
      ?.filter(u => u.userID !== userId)
      .sort((l, r) => l.index! - r.index!)
      .map((u, i) => ({ ...u, index: i } as ModelRotaUser));

    try {
      const newRota = {
        ...rota,
        users: newUsers,
      };
      await getRotasAPI().updateRota({
        id: rota.id!,
        rota: newRota,
      });

      const db = await AppDatabase.getInstance();
      if (db !== undefined) {
        await db.rotas.update(rota.id!, newRota);
      }

      setRota(newRota);
    } catch (e) {
      handleAPIError(e, "removing user from rota");
    }
  };

  const moveUserUp = async (userId: number | undefined) => {
    if (userId === undefined || rota?.id === undefined) {
      return;
    }

    const userIndex = rotaUsers.map(u => u.userID).indexOf(userId);
    if (userIndex <= 0) {
      return;
    }

    let newRotaUsers = [...rotaUsers];
    newRotaUsers[userIndex].index!--;
    newRotaUsers[userIndex - 1].index!++;
    newRotaUsers = newRotaUsers.sort((l, r) => l.index! - r.index!);

    setRotaUsers(newRotaUsers);

    try {
      const newRota = {
        ...rota,
        users: newRotaUsers,
      };
      await getRotasAPI().updateRota({
        id: rota.id,
        rota: newRota,
      });

      const db = await AppDatabase.getInstance();
      if (db !== undefined) {
        await db.rotas.update(rota.id!, newRota);
      }

      setRota(newRota);
    } catch (e) {
      handleAPIError(e, "re-ordering user in rota");
    }
  };

  const moveUserDown = async (userId: number | undefined) => {
    if (userId === undefined || rota?.id === undefined) {
      return;
    }

    const userIndex = rotaUsers.map(u => u.userID).indexOf(userId);
    if (userIndex === -1 || userIndex === rotaUsers.length - 1) {
      return;
    }

    let newRotaUsers = [...rotaUsers];
    newRotaUsers[userIndex].index!++;
    newRotaUsers[userIndex + 1].index!--;
    newRotaUsers = newRotaUsers.sort((l, r) => l.index! - r.index!);

    setRotaUsers(newRotaUsers);

    try {
      const newRota = {
        ...rota,
        users: newRotaUsers,
      };
      await getRotasAPI().updateRota({
        id: rota.id,
        rota: newRota,
      });

      const db = await AppDatabase.getInstance();
      if (db !== undefined) {
        await db.rotas.update(rota.id!, newRota);
      }

      setRota(newRota);
    } catch (e) {
      handleAPIError(e, "re-ordering user in rota");
    }
  };

  return (
    <TableContainer className={classes.rotaTableContainer}>
      <Table stickyHeader className={classes.rotaTable} aria-label="rota table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.reorderButtonsCell}></TableCell>
            <TableCell className={classes.userInfoCell}>Name</TableCell>
            <TableCell align="center">Risk</TableCell>
            {Array.from(Array(7).keys()).map(i => {
              const day = dateUtils.addDays(rotaDate, i);
              return (
                <TableCell key={i} align="center">
                  {dateUtils.format(day, "EEEEEE")}
                  <br />({dateUtils.format(day, "do")})
                </TableCell>
              );
            })}
            <TableCell className={classes.totalHoursCell} align="right">
              Total Hours
            </TableCell>
            <TableCell className={classes.actionsCell}></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isRotaLoading &&
            Array.from(Array(numSkeletonRows).keys()).map(i => (
              <UserRow key={i} />
            ))}

          {rotaUsers.map((rotaUser, i) => (
            <UserRow
              key={rotaUser.userID}
              isLoading={isRotaLoading || areShiftsLoading}
              user={users.find(u => u.id === rotaUser.userID)}
              shiftsForUser={
                rotaUser.userID !== undefined
                  ? userShifts[rotaUser.userID]
                  : undefined
              }
              rota={rota}
              addOrUpdateShift={addOrUpdateShift}
              removeShift={removeShift}
              removeUserFromRota={removeUserFromRota}
              userCanMoveUp={i !== 0}
              userCanMoveDown={i !== rotaUsers.length - 1}
              moveUserUp={() => moveUserUp(rotaUser.userID)}
              moveUserDown={() => moveUserDown(rotaUser.userID)}
              userSafetyScores={userSafetyScores}
              isSafetyLoading={isSafetyLoading}
              shiftPresets={shiftPresets}
              onNavigateToConfigureShifts={onNavigateToConfigureShifts}
            />
          ))}

          {!isRotaLoading &&
            (rota?.users === undefined || rota.users.length === 0) && (
              <TableRow>
                <TableCell component="th" scope="row" colSpan={11}>
                  <Typography variant="h6">
                    There aren&apos;t any users in this rota yet. Why don&apos;t
                    you try adding one?
                  </Typography>
                </TableCell>
              </TableRow>
            )}
        </TableBody>

        <TableHead
          className={is.safari() ? classes.footerSafari : classes.footer}
        >
          <ShiftSafetyRow
            isNightStaff={isNightStaff}
            isLoading={isSafetyLoading || areShiftsLoading}
            shiftSafetyScores={shiftSafetyScores}
            classes={{ cell: classes.shiftSafetyCell }}
          />
        </TableHead>
      </Table>
    </TableContainer>
  );
};
