import { useCallback, useEffect, useState } from "react";

import { ModelRota, getRotasAPI } from "../api_client";
import {
  AppDatabase,
  HandleAPIErrorFun,
  localToUTC,
  useAppContext,
} from "../helpers";
import { useAPIUpdatePeriod } from "./useAPIUpdatePeriod";
import { InfoRef, useInfoRef } from "./useInfoRef";

const getRotas = async (
  careUnitId: number,
  dateMs: number,
  setDaysRota: React.Dispatch<React.SetStateAction<ModelRota | undefined>>,
  setNightsRota: React.Dispatch<React.SetStateAction<ModelRota | undefined>>,
  infoRef: InfoRef<[number | undefined, number]>,
  handleAPIError: HandleAPIErrorFun,
) => {
  try {
    const response = await getRotasAPI().getAllRotas({
      careUnitID: careUnitId,
      pageSize: 2,
      startDate: new Date(dateMs),
    });

    const daysRota = response.records?.find(r => r.isNightStaff === false);
    const nightsRota = response.records?.find(r => r.isNightStaff === true);

    const db = await AppDatabase.getInstance();
    if (db !== undefined) {
      const clearRemoved = async () => {
        const itemsToRemove = await db.rotas
          .filter(
            rota =>
              rota.careUnitID === careUnitId &&
              rota.startDate?.getTime() === dateMs &&
              rota.id !== daysRota?.id &&
              rota.id !== nightsRota?.id,
          )
          .toArray();

        await db.rotas.bulkDelete(itemsToRemove.map(r => r.id!));
      };

      await Promise.all([
        clearRemoved(),
        daysRota !== undefined ? db.rotas.put(daysRota) : undefined,
        nightsRota !== undefined ? db.rotas.put(nightsRota) : undefined,
      ]);
    }

    if (infoRef.current.hasChanged(careUnitId, dateMs)) {
      // The identifying details for the rota has changed since then so don't set the
      // state. This can happen if you move between rotas faster than they can be loaded
      // from the API.
      return;
    }

    setDaysRota(daysRota);
    setNightsRota(nightsRota);
  } catch (e) {
    handleAPIError(e, "retrieving rota");
  }
};

export const useRotas = (careUnitId: number | undefined, dateLocal: Date) => {
  const { handleAPIError } = useAppContext();

  const dateUTC = localToUTC(dateLocal);

  // Date objects aren't equal to each other, so reduce to number then get date back
  // inside the effect function.
  const dateMs = dateUTC.getTime();

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [daysRota, setDaysRota] = useState<ModelRota | undefined>(undefined);
  const [nightsRota, setNightsRota] = useState<ModelRota | undefined>(
    undefined,
  );

  // Rotas are always being updated at the same time, so don't persist the timestamp in
  // localStorage across page refresh and only cache for 1 minute instead of 10 minutes.
  const [setUpdatedFromAPI, isAPIUpdateDue] = useAPIUpdatePeriod(
    `rota-${careUnitId}-${dateUTC.toISOString()}`,
    60 * 1000,
    false,
  );
  const infoRef = useInfoRef(careUnitId, dateMs);

  // If these two defining variables change, we need to reload the whole rota because
  // we're talking about a different rota now.
  useEffect(() => {
    setInitialLoadComplete(false);
    setIsLoading(true);
    setDaysRota(undefined);
    setNightsRota(undefined);
  }, [careUnitId, dateMs]);

  useEffect(() => {
    if (initialLoadComplete || careUnitId === undefined) {
      return;
    }

    const getCachedRotas = async () => {
      const db = await AppDatabase.getInstance();
      if (db === undefined) {
        return;
      }

      const cachedRotas = await db.rotas
        .filter(
          r => r.careUnitID === careUnitId && r.startDate?.getTime() === dateMs,
        )
        .toArray();

      if (infoRef.current.hasChanged(careUnitId, dateMs)) {
        return false;
      }

      const cachedDays = cachedRotas.find(r => r.isNightStaff === false);
      const cachedNights = cachedRotas.find(r => r.isNightStaff === true);

      setDaysRota(cachedDays);
      setNightsRota(cachedNights);

      // If either the days rotas or the nights rotas doesn't exist we need to be
      // conservative and assume that they're missing from the cache. Rotas are
      // frequently updated in parallel so it's important to err on the side of
      // retrieving the live data.
      return cachedDays !== undefined && cachedNights !== undefined;
    };

    getCachedRotas().then(async cachePresent => {
      if (infoRef.current.hasChanged(careUnitId, dateMs)) {
        return;
      }

      if (!cachePresent || isAPIUpdateDue()) {
        await getRotas(
          careUnitId,
          dateMs,
          setDaysRota,
          setNightsRota,
          infoRef,
          handleAPIError,
        );
      }

      setInitialLoadComplete(true);
      setIsLoading(false);
      setUpdatedFromAPI();
    });
  }, [
    careUnitId,
    dateMs,
    initialLoadComplete,
    isAPIUpdateDue,
    setUpdatedFromAPI,
    handleAPIError,
    infoRef,
  ]);

  const refreshData = useCallback(async () => {
    if (careUnitId === undefined) {
      return;
    }

    setIsLoading(true);
    await getRotas(
      careUnitId,
      dateMs,
      setDaysRota,
      setNightsRota,
      infoRef,
      handleAPIError,
    );
    setIsLoading(false);
    setUpdatedFromAPI();
  }, [careUnitId, dateMs, infoRef, handleAPIError, setUpdatedFromAPI]);

  return [
    daysRota,
    setDaysRota,
    nightsRota,
    setNightsRota,
    isLoading,
    refreshData,
  ] as const;
};
