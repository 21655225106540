/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ModelCareUnit,
  ModelCareUnitFromJSON,
  ModelCareUnitFromJSONTyped,
  ModelCareUnitToJSON,
  ModelRotaUser,
  ModelRotaUserFromJSON,
  ModelRotaUserFromJSONTyped,
  ModelRotaUserToJSON,
} from "./";

/**
 *
 * @export
 * @interface ModelRota
 */
export interface ModelRota {
  /**
   *
   * @type {ModelCareUnit}
   * @memberof ModelRota
   */
  careUnit?: ModelCareUnit;
  /**
   *
   * @type {number}
   * @memberof ModelRota
   */
  careUnitID?: number;
  /**
   *
   * @type {number}
   * @memberof ModelRota
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof ModelRota
   */
  isNightStaff?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ModelRota
   */
  isPublished?: boolean;
  /**
   *
   * @type {Date}
   * @memberof ModelRota
   */
  startDate?: Date;
  /**
   *
   * @type {Array<ModelRotaUser>}
   * @memberof ModelRota
   */
  users?: Array<ModelRotaUser>;
}

export function ModelRotaFromJSON(json: any): ModelRota {
  return ModelRotaFromJSONTyped(json, false);
}

export function ModelRotaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ModelRota {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    careUnit: !exists(json, "careUnit")
      ? undefined
      : ModelCareUnitFromJSON(json["careUnit"]),
    careUnitID: !exists(json, "careUnitID") ? undefined : json["careUnitID"],
    id: !exists(json, "id") ? undefined : json["id"],
    isNightStaff: !exists(json, "isNightStaff")
      ? undefined
      : json["isNightStaff"],
    isPublished: !exists(json, "isPublished") ? undefined : json["isPublished"],
    startDate: !exists(json, "startDate")
      ? undefined
      : new Date(json["startDate"]),
    users: !exists(json, "users")
      ? undefined
      : (json["users"] as Array<any>).map(ModelRotaUserFromJSON),
  };
}

export function ModelRotaToJSON(value?: ModelRota | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    careUnit: ModelCareUnitToJSON(value.careUnit),
    careUnitID: value.careUnitID,
    id: value.id,
    isNightStaff: value.isNightStaff,
    isPublished: value.isPublished,
    startDate:
      value.startDate === undefined ? undefined : value.startDate.toISOString(),
    users:
      value.users === undefined
        ? undefined
        : (value.users as Array<any>).map(ModelRotaUserToJSON),
  };
}
