/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ModelDISCScore,
  ModelDISCScoreFromJSON,
  ModelDISCScoreFromJSONTyped,
  ModelDISCScoreToJSON,
  ModelQuestionGroup,
  ModelQuestionGroupFromJSON,
  ModelQuestionGroupFromJSONTyped,
  ModelQuestionGroupToJSON,
} from "./";

/**
 *
 * @export
 * @interface ModelSurvey
 */
export interface ModelSurvey {
  /**
   * Not stored in database but returned only if you ask for surveys for a particular care home. This is included
   * to make client-side filtering based on care unit ID possible.
   * @type {number}
   * @memberof ModelSurvey
   */
  careUnitID?: number;
  /**
   *
   * @type {number}
   * @memberof ModelSurvey
   */
  carerID?: number;
  /**
   *
   * @type {Date}
   * @memberof ModelSurvey
   */
  createdAt?: Date;
  /**
   * Variation between the D field for this survey and the previous survey.
   * @type {number}
   * @memberof ModelSurvey
   */
  dominanceShift?: number;
  /**
   *
   * @type {ModelDISCScore}
   * @memberof ModelSurvey
   */
  graph1DISC?: ModelDISCScore;
  /**
   *
   * @type {boolean}
   * @memberof ModelSurvey
   */
  graph1HasOvershift?: boolean;
  /**
   *
   * @type {ModelDISCScore}
   * @memberof ModelSurvey
   */
  graph2DISC?: ModelDISCScore;
  /**
   * High-level overview of the pattern of the third graph.
   * @type {string}
   * @memberof ModelSurvey
   */
  graph3CoarsePattern?: string;
  /**
   *
   * @type {ModelDISCScore}
   * @memberof ModelSurvey
   */
  graph3DISC?: ModelDISCScore;
  /**
   *
   * @type {number}
   * @memberof ModelSurvey
   */
  happinessScore?: number;
  /**
   * This is a modified dominance value indicating that score looks low but could become very
   * high very soon, i.e. it is volatile.
   * @type {boolean}
   * @memberof ModelSurvey
   */
  hasVolatileLowDominance?: boolean;
  /**
   *
   * @type {number}
   * @memberof ModelSurvey
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof ModelSurvey
   */
  isSafe?: boolean;
  /**
   * NCAS is measure of customer delight, used here as proxy for carer morale.
   * @type {number}
   * @memberof ModelSurvey
   */
  netCustomerAdvocacyScore?: number;
  /**
   *
   * @type {string}
   * @memberof ModelSurvey
   */
  stress?: ModelSurveyStressEnum;
  /**
   *
   * @type {number}
   * @memberof ModelSurvey
   */
  stressAmount?: number;
  /**
   * The original survey input, if available. Useful if you want to re-analyse based on a modified
   * analysis pipeline. Contains all information needed to analyse the survey input.
   * @type {Array<ModelQuestionGroup>}
   * @memberof ModelSurvey
   */
  surveyInput?: Array<ModelQuestionGroup>;
  /**
   * Not stored in database but included if requested based on calculating user ID.
   * @type {string}
   * @memberof ModelSurvey
   */
  userFullName?: string;
}

export function ModelSurveyFromJSON(json: any): ModelSurvey {
  return ModelSurveyFromJSONTyped(json, false);
}

export function ModelSurveyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ModelSurvey {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    careUnitID: !exists(json, "careUnitID") ? undefined : json["careUnitID"],
    carerID: !exists(json, "carerID") ? undefined : json["carerID"],
    createdAt: !exists(json, "createdAt")
      ? undefined
      : new Date(json["createdAt"]),
    dominanceShift: !exists(json, "dominanceShift")
      ? undefined
      : json["dominanceShift"],
    graph1DISC: !exists(json, "graph1DISC")
      ? undefined
      : ModelDISCScoreFromJSON(json["graph1DISC"]),
    graph1HasOvershift: !exists(json, "graph1HasOvershift")
      ? undefined
      : json["graph1HasOvershift"],
    graph2DISC: !exists(json, "graph2DISC")
      ? undefined
      : ModelDISCScoreFromJSON(json["graph2DISC"]),
    graph3CoarsePattern: !exists(json, "graph3CoarsePattern")
      ? undefined
      : json["graph3CoarsePattern"],
    graph3DISC: !exists(json, "graph3DISC")
      ? undefined
      : ModelDISCScoreFromJSON(json["graph3DISC"]),
    happinessScore: !exists(json, "happinessScore")
      ? undefined
      : json["happinessScore"],
    hasVolatileLowDominance: !exists(json, "hasVolatileLowDominance")
      ? undefined
      : json["hasVolatileLowDominance"],
    id: !exists(json, "id") ? undefined : json["id"],
    isSafe: !exists(json, "isSafe") ? undefined : json["isSafe"],
    netCustomerAdvocacyScore: !exists(json, "netCustomerAdvocacyScore")
      ? undefined
      : json["netCustomerAdvocacyScore"],
    stress: !exists(json, "stress") ? undefined : json["stress"],
    stressAmount: !exists(json, "stressAmount")
      ? undefined
      : json["stressAmount"],
    surveyInput: !exists(json, "surveyInput")
      ? undefined
      : (json["surveyInput"] as Array<any>).map(ModelQuestionGroupFromJSON),
    userFullName: !exists(json, "userFullName")
      ? undefined
      : json["userFullName"],
  };
}

export function ModelSurveyToJSON(value?: ModelSurvey | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    careUnitID: value.careUnitID,
    carerID: value.carerID,
    createdAt:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    dominanceShift: value.dominanceShift,
    graph1DISC: ModelDISCScoreToJSON(value.graph1DISC),
    graph1HasOvershift: value.graph1HasOvershift,
    graph2DISC: ModelDISCScoreToJSON(value.graph2DISC),
    graph3CoarsePattern: value.graph3CoarsePattern,
    graph3DISC: ModelDISCScoreToJSON(value.graph3DISC),
    happinessScore: value.happinessScore,
    hasVolatileLowDominance: value.hasVolatileLowDominance,
    id: value.id,
    isSafe: value.isSafe,
    netCustomerAdvocacyScore: value.netCustomerAdvocacyScore,
    stress: value.stress,
    stressAmount: value.stressAmount,
    surveyInput:
      value.surveyInput === undefined
        ? undefined
        : (value.surveyInput as Array<any>).map(ModelQuestionGroupToJSON),
    userFullName: value.userFullName,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ModelSurveyStressEnum {
  None = "none",
  Personal = "personal",
  WorkRelated = "work_related",
}
