const paths = {
  home: "/",

  // Technically any other URL will resolve to this, but it's good to have a canonical
  // address for 404.
  notFound: "/not-found",

  permissionDenied: "/permission-denied",

  userSettings: "/user-settings",

  organisations: "/organisations",
  newOrganisation: "/organisations/new",
  organisation: "/organisations/:orgInfo",

  newOrganisationCareUnit: "/organisations/:orgInfo/units/new",
  organisationUnit: "/organisations/:orgInfo/units/:unitInfo",
  organisationUnitUser:
    "/organisations/:orgInfo/units/:unitInfo/users/:userInfo",

  completeSurvey:
    "/organisations/:orgInfo/units/:unitInfo/users/:userInfo/complete-survey",

  configureShifts: "/organisations/:orgInfo/units/:unitInfo/configure-shifts",

  manageAdmins: "/admins",
};

const api = {
  // If this is nullish, API client will take value from generated runtime.ts BASE_PATH.
  baseUrl: process.env.REACT_APP_API_URL,
};

const auth = {
  stsAuthority:
    // "https://auth-dev.cq-eco.co.uk/auth/realms/cqe",
    process.env.REACT_APP_IAM_URL ??
    "https://auth.cq-eco.co.uk/auth/realms/cqe/",
  clientId: "cqe-frontend",
  clientRoot: process.env.REACT_APP_BASE_URL ?? "http://localhost:3000",
  // Offline access is needed for the refresh token to work, other you can 'invalid_grant'.
  clientScope: "openid email profile offline_access",
};

const palette = {
  blue: "#47a5a4",
  orange: "#eab255",
  green: "#99c353",
  red: "#b73a38",
};

const contactDetails = {
  adminEmail: "mrcharold@me.com",
  adminTelephone: "01925 751521",
};

const app = {
  isDevelopment: process.env.NODE_ENV === "development",
  version: process.env.REACT_APP_VERSION ?? "dev",
  environment: process.env.REACT_APP_ENVIRONMENT ?? "dev",
  buildTime: process.env.REACT_APP_BUILD_TIME ?? "unknown",
};

const logging = {
  sentryEnabled: process.env.REACT_APP_SENTRY_DSN !== undefined,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,

  logRocketEnabled:
    process.env.REACT_APP_LOGROCKET_APP_ID !== undefined &&
    app.isDevelopment === false,
  logRocketAppId: process.env.REACT_APP_LOGROCKET_APP_ID,
};

export const Constants = {
  paths,
  api,
  auth,
  palette,
  contactDetails,
  app,
  logging,
};
