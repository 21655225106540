import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

import { NotEnoughDataIcon, UserSafetyIcon } from "..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    safeIcon: {
      color: theme.palette.success.main,
    },
    riskyIcon: {
      color: theme.palette.warning.main,
    },
    unsafeIcon: {
      color: theme.palette.error.main,
    },
    skeleton: {
      display: "inline-block",
      height: 27,
      verticalAlign: "sub",
    },
  }),
);

interface UserSafetyTooltipIconProps {
  userSafetyScore?: number;
  isLoading?: boolean;
}

export const UserSafetyTooltipIcon: React.FC<UserSafetyTooltipIconProps> = ({
  userSafetyScore,
  isLoading,
}) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <Skeleton variant="circle" className={classes.skeleton}>
        <UserSafetyIcon />
      </Skeleton>
    );
  }

  if (userSafetyScore === 1) {
    return (
      <span className={classes.safeIcon}>
        <UserSafetyIcon />
      </span>
    );
  }

  if (userSafetyScore === 0) {
    return (
      <span className={classes.unsafeIcon}>
        <UserSafetyIcon />
      </span>
    );
  }

  if (
    userSafetyScore !== undefined &&
    userSafetyScore > 0 &&
    userSafetyScore < 1
  ) {
    return (
      <span className={classes.riskyIcon}>
        <UserSafetyIcon />
      </span>
    );
  }

  if (isLoading === false && userSafetyScore === undefined) {
    return <NotEnoughDataIcon />;
  }

  return null;
};
