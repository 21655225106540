/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ModelQuestionGroup,
  ModelQuestionGroupFromJSON,
  ModelQuestionGroupFromJSONTyped,
  ModelQuestionGroupToJSON,
} from "./";

/**
 *
 * @export
 * @interface ModelSurveyTemplate
 */
export interface ModelSurveyTemplate {
  /**
   *
   * @type {Array<ModelQuestionGroup>}
   * @memberof ModelSurveyTemplate
   */
  contents?: Array<ModelQuestionGroup>;
  /**
   *
   * @type {Date}
   * @memberof ModelSurveyTemplate
   */
  createdAt?: Date;
  /**
   *
   * @type {number}
   * @memberof ModelSurveyTemplate
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof ModelSurveyTemplate
   */
  isActive?: boolean;
}

export function ModelSurveyTemplateFromJSON(json: any): ModelSurveyTemplate {
  return ModelSurveyTemplateFromJSONTyped(json, false);
}

export function ModelSurveyTemplateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ModelSurveyTemplate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    contents: !exists(json, "contents")
      ? undefined
      : (json["contents"] as Array<any>).map(ModelQuestionGroupFromJSON),
    createdAt: !exists(json, "createdAt")
      ? undefined
      : new Date(json["createdAt"]),
    id: !exists(json, "id") ? undefined : json["id"],
    isActive: !exists(json, "isActive") ? undefined : json["isActive"],
  };
}

export function ModelSurveyTemplateToJSON(
  value?: ModelSurveyTemplate | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    contents:
      value.contents === undefined
        ? undefined
        : (value.contents as Array<any>).map(ModelQuestionGroupToJSON),
    createdAt:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    id: value.id,
    isActive: value.isActive,
  };
}
