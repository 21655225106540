import DateFnsUtils from "@date-io/date-fns";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert as MenuIcon } from "@material-ui/icons";
import { createArrayCsvStringifier } from "csv-writer";
import React, { useState } from "react";

import { ModelRota, ModelShift } from "../../api_client";
import { countHoursForUser, userFullName } from "../../helpers";
import { UserShiftMap } from "../../hooks";

const dateUtils = new DateFnsUtils();

interface RotaMenuProps {
  rota: ModelRota | undefined;
  rotaDate: Date;
  isLoading: boolean;
  isNightStaff: boolean;
  userShifts: UserShiftMap;
  onNavigateToConfigureShifts: () => void;
}

export const RotaMenu: React.FC<RotaMenuProps> = ({
  rota,
  rotaDate,
  isLoading,
  isNightStaff,
  userShifts,
  onNavigateToConfigureShifts,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const downloadRotaCsv = () => {
    if (isLoading || rota?.id === undefined) {
      return;
    }

    const daysHeaders = Array.from(Array(7).keys()).map(i => {
      const day = dateUtils.addDays(rotaDate, i);
      const dayOfWeekName = dateUtils.format(day, "EEEEEE");
      const dayOfMonthNum = dateUtils.format(day, "do");
      const monthName = dateUtils.format(day, "LLL");
      return `${dayOfWeekName} (${dayOfMonthNum} ${monthName})`;
    });

    const weekNum = dateUtils.format(rotaDate, "w");

    const csvStringifier = createArrayCsvStringifier({
      header: [`Week ${weekNum}`, ...daysHeaders, "Total Hours"],
    });

    const rows =
      rota.users?.map(u => {
        const shifts =
          u.userID !== undefined && userShifts[u.userID] !== undefined
            ? userShifts[u.userID]
            : Array.from(Array(7).keys()).map(
                _ => undefined as ModelShift | undefined,
              );

        const totalHours = countHoursForUser(u.user, shifts);

        const shiftsStr = shifts.map(s => {
          if (s === undefined) {
            return "-";
          }

          const startTime = s.startTime ?? new Date(0);
          const endTime = new Date(startTime);
          endTime.setMinutes(startTime.getMinutes() + (s.durationMinutes ?? 0));

          const startText = dateUtils.format(startTime, "h:mm aaaa");
          const endText = dateUtils.format(endTime, "h:mm aaaa");

          const breakTime = s.breakDurationMinutes;

          const breakText =
            breakTime !== undefined && breakTime > 0
              ? ` (-${Math.floor(breakTime / 60)}:${breakTime % 60} break)`
              : "";

          return `${startText} - ${endText}${breakText}`;
        });

        return [userFullName(u.user), ...shiftsStr, totalHours];
      }) ?? [];

    const rotaCsv =
      csvStringifier.getHeaderString() + csvStringifier.stringifyRecords(rows);

    const csvUrl = URL.createObjectURL(
      new Blob([rotaCsv], { type: "text/csv" }),
    );

    const rotaWeek = dateUtils.format(
      rota.startDate ?? new Date(),
      "yyyy-MM-dd",
    );

    // The 'download' attribute isn't supported on IE. More testing
    // needed to verify what IE behaviour is here and how to work around
    // this.
    const a = document.createElement("a");
    a.href = csvUrl;
    a.download = `rota-${isNightStaff ? "nights" : "days"}-${rotaWeek}.csv`;
    a.click();
  };

  return (
    <>
      <IconButton
        aria-controls="rota-additional-actions-menu"
        aria-haspopup="true"
        onClick={event => setMenuAnchorEl(event.currentTarget)}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="rota-additional-actions-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        <MenuItem
          disabled={isLoading}
          onClick={() => {
            setMenuAnchorEl(null);
            downloadRotaCsv();
          }}
        >
          Export as CSV
        </MenuItem>

        <MenuItem onClick={onNavigateToConfigureShifts}>
          Configure shift presets
        </MenuItem>
      </Menu>
    </>
  );
};
