/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ViewmodelAddAdmin
 */
export interface ViewmodelAddAdmin {
  /**
   *
   * @type {string}
   * @memberof ViewmodelAddAdmin
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ViewmodelAddAdmin
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ViewmodelAddAdmin
   */
  lastName?: string;
}

export function ViewmodelAddAdminFromJSON(json: any): ViewmodelAddAdmin {
  return ViewmodelAddAdminFromJSONTyped(json, false);
}

export function ViewmodelAddAdminFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ViewmodelAddAdmin {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, "email") ? undefined : json["email"],
    firstName: !exists(json, "firstName") ? undefined : json["firstName"],
    lastName: !exists(json, "lastName") ? undefined : json["lastName"],
  };
}

export function ViewmodelAddAdminToJSON(value?: ViewmodelAddAdmin | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    firstName: value.firstName,
    lastName: value.lastName,
  };
}
