import DateFnsUtils from "@date-io/date-fns";
import {
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React from "react";
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { ModelSurvey, ModelSurveyStressEnum } from "../../api_client";
import { Constants } from "../../helpers";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    noDataLabel: {
      width: "100%",
      textAlign: "center",
      display: "block",
      position: "relative",
      top: "-59%",
    },
  }),
);

interface StressGraphProps {
  data: ModelSurvey[];
}

const dateUtils = new DateFnsUtils();

const formatInt = (n: any) => {
  switch (n) {
    case 1:
      return "Yes";
    case 0:
      return "No";
    default:
      return "";
  }
};

export const StressGraph: React.FC<StressGraphProps> = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();

  const tickStyle = {
    stroke: theme.palette.text.primary,
    fill: theme.palette.text.primary,
  };

  return (
    <>
      <ResponsiveContainer>
        <ComposedChart
          data={data.map(survey => ({
            date: new Date(survey.createdAt ?? 0).getTime(),
            isPersonal:
              survey.stress === ModelSurveyStressEnum.Personal ? 1 : 0,
            isWorkRelated:
              survey.stress === ModelSurveyStressEnum.WorkRelated ? 1 : 0,
          }))}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            name="Date"
            domain={["auto", "auto"]}
            dataKey="date"
            interval={0}
            tick={{ ...tickStyle, fontSize: theme.typography.body2.fontSize }}
            tickFormatter={date => {
              if (!dateUtils.isValid(date)) {
                return "";
              }

              return dateUtils.format(date, "MM-yyyy");
            }}
            type="number"
          />
          <YAxis
            domain={[-0.1, 1.1]}
            ticks={[0, 1]}
            tick={{ ...tickStyle, fontSize: theme.typography.h6.fontSize }}
            tickFormatter={formatInt}
            type="number"
          />
          <Tooltip
            labelStyle={{ color: "black" }}
            formatter={value => formatInt(value)}
            labelFormatter={label => {
              const date = new Date(label);
              return dateUtils.isValid(date)
                ? dateUtils.format(date, "MMMM yyyy")
                : label;
            }}
          />
          <Legend />

          <Scatter
            name="Is stressed - personal"
            dataKey="isPersonal"
            fill={Constants.palette.blue}
            line={false}
            strokeWidth={5}
          />
          <Scatter
            name="Is stressed - work related"
            dataKey="isWorkRelated"
            fill={Constants.palette.orange}
            line={false}
            strokeWidth={5}
          />
        </ComposedChart>
      </ResponsiveContainer>

      {data.length === 0 && (
        <Typography variant="h6" component="p" className={classes.noDataLabel}>
          No data to display
        </Typography>
      )}
    </>
  );
};
