import { useCallback, useEffect, useState } from "react";

import { ModelCareOrganisation } from "../api_client";
import { getCareOrganisationsAPI } from "../api_client";
import { AppDatabase, HandleAPIErrorFun, useAppContext } from "../helpers";
import { useAPIUpdatePeriod } from ".";

const getAllOrgs = async (
  setOrgs: (newOrgs: ModelCareOrganisation[]) => void,
  handleAPIError: HandleAPIErrorFun,
) => {
  try {
    const api = getCareOrganisationsAPI();
    const orgsResponse = await api.getAllCareOrganisations({
      includeStats: true,
      disablePagination: true,
    });

    const records = orgsResponse.records;
    if (records === undefined) {
      return;
    }

    const db = await AppDatabase.getInstance();

    if (db !== undefined) {
      const clearRemoved = async () => {
        const orgsToRemove = await db.organisations
          .filter(org => !records.map(o => o.id).includes(org.id))
          .toArray();
        await db.organisations.bulkDelete(orgsToRemove.map(o => o.id!));
      };

      await Promise.all([clearRemoved(), db.organisations.bulkPut(records)]);
    }

    setOrgs(records);
  } catch (e) {
    handleAPIError(e, "retrieving organisations");
  }
};

export const useOrganisations = () => {
  const { handleAPIError } = useAppContext();

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [orgs, setOrgs] = useState<ModelCareOrganisation[]>([]);

  const [setUpdatedFromAPI, isAPIUpdateDue] = useAPIUpdatePeriod(
    "organisations",
  );

  useEffect(() => {
    if (initialLoadComplete) {
      return;
    }

    const getCachedOrgs = async () => {
      const db = await AppDatabase.getInstance();
      if (db === undefined) {
        return;
      }

      const cachedOrgs = await db.organisations.toArray();
      setOrgs(cachedOrgs);
      return cachedOrgs.length > 0;
    };

    getCachedOrgs().then(async cachePresent => {
      if (!cachePresent || isAPIUpdateDue()) {
        await getAllOrgs(setOrgs, handleAPIError);
      }

      setInitialLoadComplete(true);
      setIsLoading(false);
      setUpdatedFromAPI();
    });
  }, [handleAPIError, initialLoadComplete, setUpdatedFromAPI, isAPIUpdateDue]);

  const refreshOrgs = useCallback(async () => {
    setIsLoading(true);
    setOrgs([]);
    await getAllOrgs(setOrgs, handleAPIError);
    setIsLoading(false);
    setUpdatedFromAPI();
  }, [handleAPIError, setUpdatedFromAPI]);

  return [orgs, isLoading, refreshOrgs] as const;
};
