import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { ModelUserRoleEnum } from "../api_client";
import { Constants, modelToURLParams, useAppContext } from "../helpers";

export const Home: React.FC = () => {
  const { user } = useAppContext();
  const history = useHistory();

  useEffect(() => {
    switch (user?.role) {
      case ModelUserRoleEnum.Administrator:
        history.push(Constants.paths.organisations);
        break;
      case ModelUserRoleEnum.Manager: {
        history.push(
          Constants.paths.organisationUnit
            .replace(
              ":orgInfo",
              modelToURLParams([
                user.careOrganisationID,
                user.careOrganisationName,
              ]),
            )
            .replace(
              ":unitInfo",
              modelToURLParams([user.careUnitID, user.careUnitName]),
            ),
        );

        break;
      }
      case ModelUserRoleEnum.Carer: {
        history.push(Constants.paths.permissionDenied);
        break;
      }
      default:
        break;
    }
  }, [user, history]);

  return null;
};
