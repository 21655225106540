import {
  Button,
  CircularProgress,
  TextField,
  Theme,
  Typography,
  createStyles,
  darken,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ModelUserRoleEnum, getCareUnitsAPI } from "../api_client";
import { PageContainer } from "../components";
import {
  AppDatabase,
  Constants,
  urlParamToModel,
  useAppContext,
} from "../helpers";
import { Breadcrumb, useBreadcrumbs } from "../hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //
    },
    form: {
      marginTop: theme.spacing(2),
    },
    actions: {
      marginTop: theme.spacing(2),
      textAlign: "right",
    },
    submitButtonWrapper: {
      margin: theme.spacing(1),
      position: "relative",
      display: "inline-block",
    },
    submitButton: {
      //
    },
    submitSuccess: {
      backgroundColor: theme.palette.success.main,
      "&:hover": {
        backgroundColour: darken(theme.palette.success.main, 0.2),
      },
    },
    submitProgress: {
      color: theme.palette.success.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

interface NewCareUnitParams {
  orgInfo: string;
}

export const NewCareUnit: React.FC = () => {
  const classes = useStyles();
  const { handleAPIError, showSnackbar, user } = useAppContext();
  const history = useHistory();
  const params = useParams<NewCareUnitParams>();

  const [orgId] = urlParamToModel(params.orgInfo);

  const [name, setName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  useBreadcrumbs(
    Breadcrumb.Organisations,
    Breadcrumb.Organisation,
    Breadcrumb.NewCareUnit,
  );

  if (user?.role !== ModelUserRoleEnum.Administrator) {
    history.push(Constants.paths.permissionDenied);
    return null;
  }

  const createOrg = async () => {
    if (orgId === undefined) {
      return;
    }

    setSubmitting(true);
    setSubmitSuccess(false);

    try {
      const newCareUnit = await getCareUnitsAPI().addCareUnit({
        careUnit: { name, careOrganisationID: orgId },
      });

      newCareUnit.numCarers = 0;
      newCareUnit.numManagers = 0;
      newCareUnit.numSurveys = 0;

      const db = await AppDatabase.getInstance();
      if (db !== undefined) {
        await db.careUnits.add(newCareUnit, newCareUnit.id);
        await db.updateOrgStats(orgId);
      }

      setSubmitSuccess(true);

      showSnackbar("Successfully created care unit.", "success");

      window.setTimeout(
        () =>
          history.push(
            Constants.paths.organisation.replace(
              ":orgInfo",
              params.orgInfo ?? "",
            ),
          ),
        500,
      );
    } catch (e) {
      handleAPIError(e, "creating care organisation");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <PageContainer>
        <Typography variant="h4">New Care Unit</Typography>

        <div className={classes.form}>
          <TextField
            label="Name"
            variant="outlined"
            value={name}
            onChange={event => setName(event.target.value)}
          />
        </div>

        <div className={classes.actions}>
          <div className={classes.submitButtonWrapper}>
            <Button
              className={clsx(classes.submitButton, {
                [classes.submitSuccess]: submitSuccess,
              })}
              color="secondary"
              variant="contained"
              disabled={name === "" || submitting || submitSuccess}
              onClick={createOrg}
              startIcon
            >
              Submit
            </Button>
            {submitting && (
              <CircularProgress size={24} className={classes.submitProgress} />
            )}
          </div>
        </div>
      </PageContainer>
    </div>
  );
};
