import {
  CssBaseline,
  Snackbar,
  SnackbarCloseReason,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import React, { useCallback, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

import { NotLoggedInError, getUsersAPI } from "./api_client";
import { AppTopBar, SessionExpiredDialog, SplashScreen } from "./components";
import { APIError, Constants, useAppContext, userFullName } from "./helpers";
import {
  CareHomeUnit,
  CompleteSurvey,
  ConfigureShifts,
  Home,
  NewCareUnit,
  NewOrganisation,
  Organisation,
  Organisations,
  PageNotFound,
  PermissionDenied,
  UnitUser,
  UserSettings,
} from "./pages";
import { Admins } from "./pages/Admins";
import { AuthService } from "./services";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    content: {
      flexGrow: 1,
    },
  }),
);

const responseToMessage = async (response: Response) => {
  try {
    const body = await response.json();
    if (body.message !== undefined) {
      return body.message as string;
    }
  } catch {}

  return response.statusText;
};

const responseToPlainObject = async (response: Response) => {
  const serialised = {
    status: response.status,
    statusText: response.statusText,
    ok: response.ok,
    redirected: response.redirected,
    type: response.type,
    url: response.url,
    headers: Object.fromEntries(response.headers.entries()),
    body: undefined as string | undefined,
  };

  try {
    serialised.body = await response.text();
  } catch {}

  return serialised;
};

export const App = () => {
  const classes = useStyles();
  const appContext = useAppContext();
  const { jwt, isLoggedIn, user, setUser } = appContext;

  const jwtUserIamId = jwt?.profile.sub;

  const [isAppLoading, setIsAppLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<Color>("info");

  const showSnackbar = useCallback((message: string, severity: Color) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  }, []);

  const handleAPIError = useCallback(
    async (err: any, description: string) => {
      console.error(`Encountered error when ${description}:`, err);

      if (err instanceof Response) {
        const message = await responseToMessage(err.clone());
        showSnackbar(`Error when ${description}: ${message}`, "error");

        const responseObj = await responseToPlainObject(err.clone());

        Sentry.captureException(
          new APIError(`Encountered API error when ${description}: ${message}`),
          scope =>
            scope
              .setExtra("apiResponse", responseObj)
              .setExtra("internal", err),
        );
      } else if (err instanceof TypeError) {
        showSnackbar("Unable to connect to the server.", "error");
        Sentry.captureException(
          new APIError(`Unable to connect to server when ${description}.`),
          scope => scope.setExtra("internal", err),
        );
      } else if (err instanceof NotLoggedInError) {
        // This is actually expected, so don't capture it in Sentry.
      } else {
        Sentry.captureException(
          new APIError(`Encountered unknown API error when ${description}.`),
          scope => scope.setExtra("internal", err),
        );
      }

      // Could also catch the "NotLoggedInError" here and act accordingly.
    },
    [showSnackbar],
  );

  appContext.showSnackbar = showSnackbar;
  appContext.handleAPIError = handleAPIError;

  useEffect(() => {
    if (!isLoggedIn) {
      // We can't retrieve the user info because they're not logged in yet.
      return;
    }

    if (user !== undefined && user.iamID === jwtUserIamId) {
      // We've already got the user info for this user, so don't need to get it again.
      return;
    }

    const getUserInfo = async () => {
      const usersApi = getUsersAPI();

      try {
        const userInfo = await usersApi.getCurrentUser();
        setUser(userInfo);

        // Prevent stale state from overpopulating which can cause browser to refuse
        // localStorage access.
        await AuthService.getInstance().userManager.clearStaleState();

        if (Constants.logging.logRocketEnabled) {
          LogRocket.identify(userInfo.id?.toString() ?? "", {
            fullName: userFullName(userInfo),
            email: userInfo.email ?? "",
            iamID: userInfo.iamID ?? "",
          });
        }
      } catch (e) {
        handleAPIError(e, "retrieving user info");
      }

      setIsAppLoading(false);
    };

    getUserInfo();
  }, [isLoggedIn, jwtUserIamId, user, setUser, handleAPIError]);

  const handleSnackbarClosed = (
    _event?: React.SyntheticEvent,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <>
      {!isAppLoading && (
        <div className={classes.root}>
          <CssBaseline />
          <AppTopBar />

          {isLoggedIn ? (
            <div className={classes.content}>
              <Switch>
                <Route exact path={Constants.paths.home} component={Home} />
                <Route
                  exact
                  path={Constants.paths.organisations}
                  component={Organisations}
                />
                <Route
                  exact
                  path={Constants.paths.newOrganisation}
                  component={NewOrganisation}
                />
                <Route
                  exact
                  path={Constants.paths.organisation}
                  component={Organisation}
                />
                <Route
                  exact
                  path={Constants.paths.newOrganisationCareUnit}
                  component={NewCareUnit}
                />
                <Route
                  exact
                  path={Constants.paths.organisationUnit}
                  component={CareHomeUnit}
                />
                <Route
                  exact
                  path={Constants.paths.organisationUnitUser}
                  component={UnitUser}
                />
                <Route
                  exact
                  path={Constants.paths.completeSurvey}
                  component={CompleteSurvey}
                />
                <Route
                  exact
                  path={Constants.paths.configureShifts}
                  component={ConfigureShifts}
                />
                <Route
                  exact
                  path={Constants.paths.userSettings}
                  component={UserSettings}
                />
                <Route
                  exact
                  path={Constants.paths.manageAdmins}
                  component={Admins}
                />
                <Route
                  exact
                  path={Constants.paths.permissionDenied}
                  component={PermissionDenied}
                />
                <Route component={PageNotFound} />
              </Switch>
            </div>
          ) : (
            <SessionExpiredDialog />
          )}

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={5000}
            onClose={handleSnackbarClosed}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          >
            <Alert severity={snackbarSeverity} onClose={handleSnackbarClosed}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      )}

      <SplashScreen isVisible={isLoggedIn && isAppLoading} />
    </>
  );
};
