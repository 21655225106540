import DateFnsUtils from "@date-io/date-fns";
import {
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React from "react";
import {
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";

import { ModelSurvey } from "../../api_client";
import { Constants } from "../../helpers";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    noDataLabel: {
      width: "100%",
      textAlign: "center",
      display: "block",
      position: "relative",
      top: "-59%",
    },
  }),
);

interface UserNCASGraphProps {
  data: ModelSurvey[];
}

const dateUtils = new DateFnsUtils();

export const UserNCASGraph: React.FC<UserNCASGraphProps> = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();

  const tickStyle = {
    stroke: theme.palette.text.primary,
    fill: theme.palette.text.primary,
  };

  const colour = Constants.palette.red;

  return (
    <>
      <ResponsiveContainer>
        <ComposedChart
          data={data.map(survey => ({
            date: new Date(survey.createdAt ?? 0).getTime(),
            ncas: survey.netCustomerAdvocacyScore,
          }))}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            name="Date"
            domain={["auto", "auto"]}
            dataKey="date"
            interval={0}
            tick={{ ...tickStyle, fontSize: theme.typography.body2.fontSize }}
            tickFormatter={date => {
              if (!dateUtils.isValid(date)) {
                return "";
              }

              return dateUtils.format(date, "MM-yyyy");
            }}
            type="number"
          />
          <YAxis
            domain={[0, 10]}
            interval={0}
            ticks={[0, 2, 4, 6, 8, 10]}
            tick={{ ...tickStyle, fontSize: theme.typography.h6.fontSize }}
          />
          <ZAxis range={[200, 200]} />
          <Tooltip labelStyle={{ color: "black" }} />

          <Scatter
            name="Net Customer Advocacy Score"
            dataKey="ncas"
            fill={colour}
            line
            strokeWidth={5}
          />
        </ComposedChart>
      </ResponsiveContainer>

      {data.length === 0 && (
        <Typography variant="h6" component="p" className={classes.noDataLabel}>
          No data to display
        </Typography>
      )}
    </>
  );
};
