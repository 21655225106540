import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
} from "@material-ui/core";
import {
  LocationCity as CareUnitIcon,
  Person as CarerIcon,
  SupervisedUserCircle as ManagerIcon,
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useHistory } from "react-router-dom";

import { ModelCareOrganisation } from "../api_client";
import { Colour, Constants, modelToURLParams } from "../helpers";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  avatar: {},
  actionButton: {
    marginLeft: "auto",
  },
  infoKey: {
    fontWeight: "bold",
  },
  infoValue: {
    marginLeft: "auto",
  },
});

interface OrganisationCardProps {
  org?: ModelCareOrganisation;
}

export const OrganisationCard: React.FC<OrganisationCardProps> = ({ org }) => {
  const classes = useStyles();
  const history = useHistory();

  const avatarColour = Colour.generateForString(org?.name);

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          org !== undefined ? (
            <Avatar
              aria-label="organistion"
              className={classes.avatar}
              style={{
                backgroundColor: avatarColour.toHex(),
                color: avatarColour.fgColour(),
              }}
            >
              {org?.name && org.name[0]}
            </Avatar>
          ) : (
            <Skeleton variant="circle" width={40} height={40} />
          )
        }
        title={
          org !== undefined ? org.name : <Skeleton width="80%" height={56} />
        }
      />

      <CardContent>
        <List>
          <ListItem>
            {org !== undefined ? (
              <>
                <ListItemIcon>
                  <CareUnitIcon />
                </ListItemIcon>
                <span className={classes.infoKey}>N# care units:</span>{" "}
                <span className={classes.infoValue}>{org.numUnits}</span>
              </>
            ) : (
              <Skeleton width="100%" />
            )}
          </ListItem>

          <ListItem>
            {org !== undefined ? (
              <>
                <ListItemIcon>
                  <ManagerIcon />
                </ListItemIcon>
                <span className={classes.infoKey}>N# managers:</span>{" "}
                <span className={classes.infoValue}>{org.numManagers}</span>
              </>
            ) : (
              <Skeleton width="100%" />
            )}
          </ListItem>

          <ListItem>
            {org !== undefined ? (
              <>
                <ListItemIcon>
                  <CarerIcon />
                </ListItemIcon>
                <span className={classes.infoKey}>N# carers:</span>{" "}
                <span className={classes.infoValue}>{org.numCarers}</span>
              </>
            ) : (
              <Skeleton width="100%" />
            )}
          </ListItem>
        </List>
      </CardContent>

      <CardActions disableSpacing>
        <Button
          className={classes.actionButton}
          variant="contained"
          color="secondary"
          size="large"
          disabled={org === undefined}
          onClick={() => {
            history.push(
              Constants.paths.organisation.replace(
                ":orgInfo",
                modelToURLParams([org?.id, org?.name]),
              ),
            );
          }}
        >
          View
        </Button>
      </CardActions>
    </Card>
  );
};
