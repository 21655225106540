import {
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Add as AddIcon, Refresh as RefreshIcon } from "@material-ui/icons";
import {
  Pagination,
  Skeleton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ModelUserRoleEnum } from "../api_client";
import { OrganisationCard, PageContainer } from "../components";
import { Constants, useAppContext } from "../helpers";
import {
  Breadcrumb,
  useBreadcrumbs,
  useOrganisations,
  usePagination,
} from "../hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    grid: {
      flex: "1 0 auto",
    },
    paginationContainer: {
      paddingTop: theme.spacing(2),
      textAlign: "center",
      flexShrink: 0,
    },
    pagination: {
      display: "inline-block",
    },
    speedDialWrapper: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    speedDial: {},
  }),
);

export const Organisations: React.FC = () => {
  const { user } = useAppContext();
  const classes = useStyles();
  const history = useHistory();

  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [orgs, isLoading, refreshOrgs] = useOrganisations();

  const {
    pageNumber,
    setPageNumber,
    offset,
    pageSize,
    pageCount,
    setPageCount,
  } = usePagination(8);

  const orgsPage = orgs.slice(offset, offset + pageSize);

  useBreadcrumbs(Breadcrumb.Organisations);

  useEffect(() => setPageCount(Math.ceil(orgs.length / pageSize)), [
    setPageCount,
    orgs,
    pageSize,
  ]);

  if (user?.role !== ModelUserRoleEnum.Administrator) {
    history.push(Constants.paths.permissionDenied);
    return null;
  }

  return (
    <PageContainer>
      <div className={classes.root}>
        {orgsPage.length === 0 && !isLoading && (
          <Typography variant="h4" component="h4">
            No organisations to display.
          </Typography>
        )}

        <Grid className={classes.grid} container spacing={3}>
          {isLoading &&
            Array.from(Array(3).keys()).map(i => (
              <Grid key={i} item xs={3}>
                <OrganisationCard />
              </Grid>
            ))}

          {!isLoading &&
            orgsPage.map((org, index) => (
              <Grid key={index} item xs={3}>
                <OrganisationCard org={org} />
              </Grid>
            ))}
        </Grid>

        <div className={classes.paginationContainer}>
          {isLoading ? (
            <Skeleton width={72} height={38} className={classes.pagination} />
          ) : (
            <Pagination
              disabled={isLoading}
              className={classes.pagination}
              page={pageNumber}
              count={pageCount}
              onChange={(_event, value) => setPageNumber(value)}
            />
          )}
        </div>

        <div className={classes.speedDialWrapper}>
          <SpeedDial
            ariaLabel="Further actions"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onOpen={() => setSpeedDialOpen(true)}
            onClose={() => setSpeedDialOpen(false)}
            open={speedDialOpen}
            direction="up"
            FabProps={{ size: "large" }}
          >
            <SpeedDialAction
              icon={<AddIcon />}
              tooltipTitle="Add organisation"
              onClick={() => history.push(Constants.paths.newOrganisation)}
            />
            <SpeedDialAction
              icon={<RefreshIcon />}
              tooltipTitle="Refresh"
              onClick={refreshOrgs}
            />
          </SpeedDial>
        </div>
      </div>
    </PageContainer>
  );
};
