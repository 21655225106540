import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  createStyles,
  fade,
  makeStyles,
} from "@material-ui/core";
import { Delete as DeleteIcon, Save as SaveIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import humanInterval from "human-interval";
import React, { useEffect, useState } from "react";

import {
  ModelCareOrganisation,
  ModelCareOrganisationDefaultShiftSafetyModeEnum,
} from "../api_client";
import {
  intervalToDays,
  intervalToHuman,
  msInDay,
  toTitleCase,
} from "../helpers";
import { OrgConfig } from "../pages";
import { ConfirmationDialog } from ".";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //
    },
    form: {
      marginTop: theme.spacing(2),
    },
    formSection: {
      marginBottom: theme.spacing(2),

      "&:not(:first-child)": {
        borderTop: "1px solid",
        borderColor: fade(theme.palette.text.secondary, 0.2),
        paddingTop: theme.spacing(2),
      },

      "& .MuiFormControl-root": {
        marginBottom: theme.spacing(2),
      },
    },
    formSectionTitle: {
      marginBottom: theme.spacing(1),
    },
    formFieldDescription: {
      marginBottom: theme.spacing(2),
    },
    formFieldControl: {
      width: 300,
    },
    dangerZone: {
      padding: theme.spacing(2),
      border: "1px solid",
      borderColor: theme.palette.error.main,
      borderRadius: 6,
    },
    dangerRow: {
      display: "flex",
      "&:not(:first-child)": {
        borderTop: "1px solid",
        borderColor: fade(theme.palette.text.secondary, 0.2),
      },
    },
    dangerInfo: {
      flexGrow: 1,
    },
    dangerDescription: {
      //
    },
    dangerButtonWrapper: {
      position: "relative",
      margin: theme.spacing(1),
    },
    dangerButton: {
      height: 38,
      position: "relative",
      top: "50%",
      transform: "translateY(-50%)",
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      transitionProperty: "background-color, color",

      "&:hover, &:focus": {
        backgroundColor: theme.palette.error.main,
        color: "white",
      },
    },
    actions: {
      display: "flex",
      marginTop: theme.spacing(4),
    },
    saveButtonWrapper: {
      marginLeft: "auto",
    },
    saveButton: {
      //
    },
    progressButtonWrapper: {
      position: "relative",
    },
    progressButtonSpinner: {
      color: theme.palette.success.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

interface OrganisationSettingsProps {
  org: ModelCareOrganisation | undefined;
  onUpdate: (updated: OrgConfig) => void;
  onDelete: () => Promise<boolean>;
}

export const OrganisationSettings: React.FC<OrganisationSettingsProps> = ({
  org,
  onUpdate,
  onDelete,
}) => {
  const classes = useStyles();

  const [deleting, setDeleting] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [saving, setSaving] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const currentName = org?.name ?? "";
  const [name, setName] = useState(currentName);
  useEffect(() => setName(currentName), [currentName]);

  const currentDefaultCarerSurveyFrequency =
    org?.defaultCarerSurveyFrequencyDays !== undefined
      ? org.defaultCarerSurveyFrequencyDays * msInDay
      : undefined;
  const [
    defaultCarerSurveyFrequencyHuman,
    setDefaultCarerSurveyFrequencyHuman,
  ] = useState(intervalToHuman(currentDefaultCarerSurveyFrequency));
  const [
    defaultCarerSurveyFrequency,
    setDefaultCarerSurveyFrequency,
  ] = useState<number | null>(currentDefaultCarerSurveyFrequency ?? null);

  useEffect(() => {
    setDefaultCarerSurveyFrequencyHuman(
      intervalToHuman(currentDefaultCarerSurveyFrequency),
    );
    setDefaultCarerSurveyFrequency(currentDefaultCarerSurveyFrequency ?? null);
  }, [currentDefaultCarerSurveyFrequency]);

  const currentDefaultManagerSurveyFrequency =
    org?.defaultManagerSurveyFrequencyDays !== undefined
      ? org.defaultManagerSurveyFrequencyDays * msInDay
      : undefined;
  const [
    defaultManagerSurveyFrequencyHuman,
    setDefaultManagerSurveyFrequencyHuman,
  ] = useState(intervalToHuman(currentDefaultManagerSurveyFrequency));
  const [
    defaultManagerSurveyFrequency,
    setDefaultManagerSurveyFrequency,
  ] = useState<number | null>(currentDefaultManagerSurveyFrequency ?? null);

  useEffect(() => {
    setDefaultManagerSurveyFrequencyHuman(
      intervalToHuman(currentDefaultManagerSurveyFrequency),
    );
    setDefaultManagerSurveyFrequency(
      currentDefaultManagerSurveyFrequency ?? null,
    );
  }, [currentDefaultManagerSurveyFrequency]);

  const currentDefaultShiftMode =
    org?.defaultShiftSafetyMode ??
    ModelCareOrganisationDefaultShiftSafetyModeEnum.Standard;
  const [defaultShiftSafetyMode, setDefaultShiftSafetyMode] = useState(
    currentDefaultShiftMode,
  );

  useEffect(() => setDefaultShiftSafetyMode(currentDefaultShiftMode), [
    currentDefaultShiftMode,
  ]);

  const hasChanged =
    name !== currentName ||
    defaultCarerSurveyFrequency !== currentDefaultCarerSurveyFrequency ||
    defaultManagerSurveyFrequency !== currentDefaultManagerSurveyFrequency ||
    defaultShiftSafetyMode !== currentDefaultShiftMode;

  const isValid =
    name !== "" &&
    defaultCarerSurveyFrequency !== null &&
    !isNaN(defaultCarerSurveyFrequency) &&
    defaultCarerSurveyFrequency > 0 &&
    defaultManagerSurveyFrequency !== null &&
    !isNaN(defaultManagerSurveyFrequency) &&
    defaultManagerSurveyFrequency > 0 &&
    defaultShiftSafetyMode !== undefined;

  const updateOrg = async () => {
    const defaultCarerSurveyFrequencyDays = intervalToDays(
      defaultCarerSurveyFrequency ?? undefined,
    );
    const defaultManagerSurveyFrequencyDays = intervalToDays(
      defaultManagerSurveyFrequency ?? undefined,
    );

    if (
      !isValid ||
      defaultCarerSurveyFrequencyDays === undefined ||
      defaultManagerSurveyFrequencyDays === undefined
    ) {
      return;
    }

    setSaving(true);

    try {
      await onUpdate({
        name,
        defaultCarerSurveyFrequencyDays,
        defaultManagerSurveyFrequencyDays,
        defaultShiftSafetyMode,
      });
    } finally {
      // Error is already being handled in parent.
      setSaving(false);
    }
  };

  const deleteOrg = async () => {
    setDeleting(true);

    try {
      const success = await onDelete();
      setDeleteSuccess(success);
    } finally {
      setDeleting(false);
    }
  };

  const surveyFrequencyHelper =
    "Enter as human-readable time interval, e.g. '2 weeks', '30 days', '2 months'. Rounded to whole number of days. 1 month = 30 days.";

  return (
    <div className={classes.root}>
      <Typography variant="h3">Organisation Settings</Typography>
      <div className={classes.form}>
        <div className={classes.formSection}>
          <Typography variant="h6" className={classes.formSectionTitle}>
            {org !== undefined ? "General" : <Skeleton width="20%" />}
          </Typography>

          {org !== undefined ? (
            <TextField
              label="Name"
              variant="outlined"
              value={name}
              onChange={event => setName(event.target.value)}
            />
          ) : (
            <Skeleton width="50%" />
          )}
        </div>

        <div className={classes.formSection}>
          <Typography variant="h6" className={classes.formSectionTitle}>
            {org !== undefined ? "Surveys" : <Skeleton width="20%" />}
          </Typography>

          <Typography variant="body1" className={classes.formFieldDescription}>
            {org !== undefined ? (
              <>
                Carers and managers should complete surveys regularly to keep
                the system up-to-date. It&apos;s recommended to set the survey
                frequency to ~1 to 2 months. These are the default values used
                for all new care units in this organisation.
              </>
            ) : (
              <Skeleton width="75%" />
            )}
          </Typography>

          {org !== undefined ? (
            <TextField
              label="Default carer survey frequency"
              variant="outlined"
              helperText={surveyFrequencyHelper}
              error={
                defaultCarerSurveyFrequency !== null &&
                isNaN(defaultCarerSurveyFrequency)
              }
              value={defaultCarerSurveyFrequencyHuman}
              onChange={event => {
                const value = event.target.value;
                setDefaultCarerSurveyFrequencyHuman(value);

                const interval = humanInterval(value);
                setDefaultCarerSurveyFrequency(interval ?? null);
              }}
            />
          ) : (
            <Skeleton width="50%" />
          )}

          {org !== undefined ? (
            <TextField
              label="Default manager survey frequency"
              variant="outlined"
              helperText={surveyFrequencyHelper}
              error={
                defaultManagerSurveyFrequency !== null &&
                isNaN(defaultManagerSurveyFrequency)
              }
              value={defaultManagerSurveyFrequencyHuman}
              onChange={event => {
                const value = event.target.value;
                setDefaultManagerSurveyFrequencyHuman(value);

                const interval = humanInterval(value);
                setDefaultManagerSurveyFrequency(interval ?? null);
              }}
            />
          ) : (
            <Skeleton width="50%" />
          )}
        </div>

        <div className={classes.formSection}>
          <Typography variant="h6" className={classes.formSectionTitle}>
            {org !== undefined ? "Rotas" : <Skeleton width="20%" />}
          </Typography>

          <Typography variant="body1" className={classes.formFieldDescription}>
            {org !== undefined ? (
              <>
                When first starting out with the CQE rota management system, it
                can be useful to set the rota shift safety mode to
                &quot;Beginner&quot; to make the safety calculations slightly
                more lenient. Once the care unit is more well established, you
                can then update the shift safety mode to &quot;Standard&quot;.
                This is the default value used for all new care units in this
                organisation.
              </>
            ) : (
              <Skeleton width="75%" />
            )}
          </Typography>

          {org !== undefined ? (
            <FormControl className={classes.formFieldControl}>
              <InputLabel id="rota-shift-safety-mode-label">
                Default shift safety mode
              </InputLabel>
              <Select
                labelId="rota-shift-safety-mode-label"
                id="shift-safety-mode-select"
                value={defaultShiftSafetyMode}
                onChange={event => {
                  const value = event.target
                    .value as ModelCareOrganisationDefaultShiftSafetyModeEnum;
                  setDefaultShiftSafetyMode(value);
                }}
              >
                {Object.values(
                  ModelCareOrganisationDefaultShiftSafetyModeEnum,
                ).map((mode, i) => (
                  <MenuItem key={i} value={mode}>
                    {toTitleCase(mode)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Skeleton width="50%" />
          )}
        </div>

        <div className={classes.formSection}>
          <Typography variant="h6" className={classes.formSectionTitle}>
            {org !== undefined ? "Danger Zone" : <Skeleton width="20%" />}
          </Typography>

          <div className={classes.dangerZone}>
            <div className={classes.dangerRow}>
              <div className={classes.dangerInfo}>
                <Typography variant="h6" component="p">
                  {org !== undefined ? (
                    "Delete this organisation"
                  ) : (
                    <Skeleton width="30%" />
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.dangerDescription}
                >
                  {org !== undefined ? (
                    <>
                      Once you delete this organisation, it and all the
                      associated care units, users, surveys and rotas will all
                      be deleted. This operation cannot be undone.
                    </>
                  ) : (
                    <Skeleton width="75%" />
                  )}
                </Typography>
              </div>

              <div
                className={clsx(
                  classes.dangerButtonWrapper,
                  classes.progressButtonWrapper,
                )}
              >
                <Button
                  className={classes.dangerButton}
                  variant="outlined"
                  disabled={org === undefined || deleting || deleteSuccess}
                  onClick={() => setConfirmDialogOpen(true)}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
                {deleting && (
                  <CircularProgress
                    size={24}
                    className={classes.progressButtonSpinner}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.actions}>
        <div
          className={clsx(
            classes.saveButtonWrapper,
            classes.progressButtonWrapper,
          )}
        >
          <Button
            className={classes.saveButton}
            color="secondary"
            variant="contained"
            disabled={
              org === undefined ||
              !hasChanged ||
              !isValid ||
              saving ||
              deleting ||
              deleteSuccess
            }
            onClick={updateOrg}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
          {saving && (
            <CircularProgress
              size={24}
              className={classes.progressButtonSpinner}
            />
          )}
        </div>
      </div>

      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        message={
          <>
            This action <strong>cannot</strong> be undone. This will{" "}
            <strong>permanently delete</strong> all care units, carers,
            managers, rotas, shifts, surveys and anything else associated with
            this organisation.
          </>
        }
        onConfirm={deleteOrg}
        confirmationText={org?.name}
      />
    </div>
  );
};
