import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  message?: string | React.ReactNode;
  onConfirm: () => void;

  /**
   * If specified, user needs to type in particular message to confirm choice.
   */
  confirmationText?: string;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  title,
  message,
  onConfirm,
  confirmationText,
}) => {
  const [inputText, setInputText] = useState("");

  const enableOK =
    confirmationText === undefined ||
    inputText.toLowerCase() === confirmationText.toLowerCase();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="cqe-confirmation-dialog-title"
      aria-describedby="cqe-confirmation-dialog-description"
    >
      <DialogTitle id="cqe-confirmation-dialog-title">
        {title ?? "Are you sure?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="cqe-confirmation-dialog-description">
          {message ?? (
            <>
              This action <strong>cannot</strong> be undone.
            </>
          )}
        </DialogContentText>

        {confirmationText && (
          <>
            <DialogContentText>
              Please type in <strong>{confirmationText}</strong> to confirm.
            </DialogContentText>

            <TextField
              autoFocus
              margin="dense"
              fullWidth
              value={inputText}
              onChange={event => setInputText(event.target.value)}
              variant="outlined"
            />
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>

        <Button
          onClick={() => {
            onConfirm();
            onClose();
          }}
          color="primary"
          disabled={!enableOK}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
