/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EchoHTTPError,
  EchoHTTPErrorFromJSON,
  EchoHTTPErrorToJSON,
  HandlersRecordsPageModelCareOrganisation,
  HandlersRecordsPageModelCareOrganisationFromJSON,
  HandlersRecordsPageModelCareOrganisationToJSON,
  ModelCareOrganisation,
  ModelCareOrganisationFromJSON,
  ModelCareOrganisationToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface AddCareOrganisationRequest {
  careOrganisation: ModelCareOrganisation;
}

export interface DeleteCareOrganisationRequest {
  id: number;
}

export interface GetAllCareOrganisationsRequest {
  includeStats?: boolean;
  pageNumber?: number;
  pageSize?: number;
  order?: string;
  disablePagination?: boolean;
}

export interface GetCareOrganisationRequest {
  id: number;
}

export interface UpdateCareOrganisationRequest {
  id: number;
  careOrganisation: ModelCareOrganisation;
}

/**
 *
 */
export class CareOrganisationsApi extends runtime.BaseAPI {
  /**
   * Add care organisation to CQE systems.
   * Add care organisation.
   */
  async addCareOrganisationRaw(
    requestParameters: AddCareOrganisationRequest,
  ): Promise<runtime.ApiResponse<ModelCareOrganisation>> {
    if (
      requestParameters.careOrganisation === null ||
      requestParameters.careOrganisation === undefined
    ) {
      throw new runtime.RequiredError(
        "careOrganisation",
        "Required parameter requestParameters.careOrganisation was null or undefined when calling addCareOrganisation.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/care-organisations`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelCareOrganisationToJSON(requestParameters.careOrganisation),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelCareOrganisationFromJSON(jsonValue),
    );
  }

  /**
   * Add care organisation to CQE systems.
   * Add care organisation.
   */
  async addCareOrganisation(
    requestParameters: AddCareOrganisationRequest,
  ): Promise<ModelCareOrganisation> {
    const response = await this.addCareOrganisationRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete care organisation from CQE systems.
   * Delete care organisation.
   */
  async deleteCareOrganisationRaw(
    requestParameters: DeleteCareOrganisationRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteCareOrganisation.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/care-organisations/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete care organisation from CQE systems.
   * Delete care organisation.
   */
  async deleteCareOrganisation(
    requestParameters: DeleteCareOrganisationRequest,
  ): Promise<void> {
    await this.deleteCareOrganisationRaw(requestParameters);
  }

  /**
   * Retrieve all care organisations that exist within the CQE systems. The results are paginated by default. See arguments for pagination options.
   * Get all care organisations in the CQE system.
   */
  async getAllCareOrganisationsRaw(
    requestParameters: GetAllCareOrganisationsRequest,
  ): Promise<runtime.ApiResponse<HandlersRecordsPageModelCareOrganisation>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.includeStats !== undefined) {
      queryParameters["includeStats"] = requestParameters.includeStats;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["pageNumber"] = requestParameters.pageNumber;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["pageSize"] = requestParameters.pageSize;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.disablePagination !== undefined) {
      queryParameters["disablePagination"] =
        requestParameters.disablePagination;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/care-organisations`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      HandlersRecordsPageModelCareOrganisationFromJSON(jsonValue),
    );
  }

  /**
   * Retrieve all care organisations that exist within the CQE systems. The results are paginated by default. See arguments for pagination options.
   * Get all care organisations in the CQE system.
   */
  async getAllCareOrganisations(
    requestParameters: GetAllCareOrganisationsRequest,
  ): Promise<HandlersRecordsPageModelCareOrganisation> {
    const response = await this.getAllCareOrganisationsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Retrieve care organisation from within the CQE systems with specified ID.
   * Get care organisations with specified ID.
   */
  async getCareOrganisationRaw(
    requestParameters: GetCareOrganisationRequest,
  ): Promise<runtime.ApiResponse<ModelCareOrganisation>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getCareOrganisation.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/care-organisations/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelCareOrganisationFromJSON(jsonValue),
    );
  }

  /**
   * Retrieve care organisation from within the CQE systems with specified ID.
   * Get care organisations with specified ID.
   */
  async getCareOrganisation(
    requestParameters: GetCareOrganisationRequest,
  ): Promise<ModelCareOrganisation> {
    const response = await this.getCareOrganisationRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update care organisation within CQE systems.
   * Update care organisation.
   */
  async updateCareOrganisationRaw(
    requestParameters: UpdateCareOrganisationRequest,
  ): Promise<runtime.ApiResponse<ModelCareOrganisation>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateCareOrganisation.",
      );
    }

    if (
      requestParameters.careOrganisation === null ||
      requestParameters.careOrganisation === undefined
    ) {
      throw new runtime.RequiredError(
        "careOrganisation",
        "Required parameter requestParameters.careOrganisation was null or undefined when calling updateCareOrganisation.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/care-organisations/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ModelCareOrganisationToJSON(requestParameters.careOrganisation),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelCareOrganisationFromJSON(jsonValue),
    );
  }

  /**
   * Update care organisation within CQE systems.
   * Update care organisation.
   */
  async updateCareOrganisation(
    requestParameters: UpdateCareOrganisationRequest,
  ): Promise<ModelCareOrganisation> {
    const response = await this.updateCareOrganisationRaw(requestParameters);
    return await response.value();
  }
}
