import { useCallback, useRef, useState } from "react";

import { Session } from "../helpers";

export const useAPIUpdatePeriod = (
  key: string,
  optionalPeriodMs?: number,
  optionalPersistTimestamp?: boolean,
) => {
  const periodMs = optionalPeriodMs ?? 10 * 60 * 1000;
  const persistTimestamp = optionalPersistTimestamp ?? true;

  // If we're persisting in local storage we don't actually need the state but if we're
  // not, we do need it. We still need the ref either way because we don't want the
  // callbacks to change every time `timestampState` changes.
  const initialTimestamp = persistTimestamp
    ? Session.getInstance().apiRefreshTimestamp(key)
    : undefined;
  const [timestampState, setTimestampState] = useState<Date | undefined>(
    initialTimestamp,
  );
  const timestampRef = useRef(timestampState);

  const setUpdatedFromAPI = useCallback(() => {
    const now = new Date();
    setTimestampState(now);
    timestampRef.current = now;
    if (persistTimestamp) {
      Session.getInstance().setAPIRefreshTimestamp(key, now);
    }
  }, [key, persistTimestamp]);

  const isAPIUpdateDue = useCallback(() => {
    const timestamp = timestampRef.current;
    return (
      timestamp === undefined ||
      new Date().getTime() - timestamp.getTime() > periodMs
    );
  }, [periodMs]);

  return [setUpdatedFromAPI, isAPIUpdateDue] as const;
};
