/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ModelCareOrganisation,
  ModelCareOrganisationFromJSON,
  ModelCareOrganisationFromJSONTyped,
  ModelCareOrganisationToJSON,
} from "./";

/**
 *
 * @export
 * @interface ModelCareUnit
 */
export interface ModelCareUnit {
  /**
   *
   * @type {ModelCareOrganisation}
   * @memberof ModelCareUnit
   */
  careOrganisation?: ModelCareOrganisation;
  /**
   *
   * @type {number}
   * @memberof ModelCareUnit
   */
  careOrganisationID?: number;
  /**
   *
   * @type {number}
   * @memberof ModelCareUnit
   */
  carerSurveyFrequencyDays?: number;
  /**
   *
   * @type {number}
   * @memberof ModelCareUnit
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ModelCareUnit
   */
  managerSurveyFrequencyDays?: number;
  /**
   *
   * @type {string}
   * @memberof ModelCareUnit
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ModelCareUnit
   */
  numCarers?: number;
  /**
   *
   * @type {number}
   * @memberof ModelCareUnit
   */
  numManagers?: number;
  /**
   *
   * @type {number}
   * @memberof ModelCareUnit
   */
  numSurveys?: number;
  /**
   *
   * @type {string}
   * @memberof ModelCareUnit
   */
  shiftSafetyMode?: ModelCareUnitShiftSafetyModeEnum;
}

export function ModelCareUnitFromJSON(json: any): ModelCareUnit {
  return ModelCareUnitFromJSONTyped(json, false);
}

export function ModelCareUnitFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ModelCareUnit {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    careOrganisation: !exists(json, "careOrganisation")
      ? undefined
      : ModelCareOrganisationFromJSON(json["careOrganisation"]),
    careOrganisationID: !exists(json, "careOrganisationID")
      ? undefined
      : json["careOrganisationID"],
    carerSurveyFrequencyDays: !exists(json, "carerSurveyFrequencyDays")
      ? undefined
      : json["carerSurveyFrequencyDays"],
    id: !exists(json, "id") ? undefined : json["id"],
    managerSurveyFrequencyDays: !exists(json, "managerSurveyFrequencyDays")
      ? undefined
      : json["managerSurveyFrequencyDays"],
    name: !exists(json, "name") ? undefined : json["name"],
    numCarers: !exists(json, "numCarers") ? undefined : json["numCarers"],
    numManagers: !exists(json, "numManagers") ? undefined : json["numManagers"],
    numSurveys: !exists(json, "numSurveys") ? undefined : json["numSurveys"],
    shiftSafetyMode: !exists(json, "shiftSafetyMode")
      ? undefined
      : json["shiftSafetyMode"],
  };
}

export function ModelCareUnitToJSON(value?: ModelCareUnit | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    careOrganisation: ModelCareOrganisationToJSON(value.careOrganisation),
    careOrganisationID: value.careOrganisationID,
    carerSurveyFrequencyDays: value.carerSurveyFrequencyDays,
    id: value.id,
    managerSurveyFrequencyDays: value.managerSurveyFrequencyDays,
    name: value.name,
    numCarers: value.numCarers,
    numManagers: value.numManagers,
    numSurveys: value.numSurveys,
    shiftSafetyMode: value.shiftSafetyMode,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ModelCareUnitShiftSafetyModeEnum {
  Beginner = "beginner",
  Standard = "standard",
}
