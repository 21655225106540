import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { ModelFreeTextQuestion } from "../api_client";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
    },
    questionNumber: {
      fontWeight: "bold",
    },
    card: {
      textAlign: "left",
      marginTop: theme.spacing(2),
      display: "inline-block",
      maxWidth: 650,
      width: "100%",
    },
    content: {
      textAlign: "center",
      padding: theme.spacing(3),
      height: 300,
    },
    textField: {
      height: "100%",
      width: "100%",
    },
    okButton: {
      marginLeft: "auto",
    },
  }),
);

interface FreeTextQuestionProps {
  question: ModelFreeTextQuestion | undefined;
  questionNumber: number;
  onUpdate: (completed: ModelFreeTextQuestion) => void;
  onAdvance: () => void;
  orgName?: string;
}

export const FreeTextQuestion: React.FC<FreeTextQuestionProps> = ({
  question,
  questionNumber,
  onUpdate,
  onAdvance,
  orgName,
}) => {
  const classes = useStyles();

  const currentUserInput = question?.userResponse ?? "";
  const [userInput, setUserInput] = useState(currentUserInput);

  useEffect(() => setUserInput(currentUserInput), [currentUserInput]);

  useEffect(() => {
    if (question === undefined) {
      return;
    }

    question.userResponse = userInput;
    onUpdate(question);
  }, [question, onUpdate, userInput]);

  const text = question?.text?.replace(
    "{{ care_organisation_name }}",
    orgName ?? "",
  );

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          title={
            <>
              <span className={classes.questionNumber}>{questionNumber})</span>{" "}
              {text}
            </>
          }
        />
        <CardContent className={classes.content}>
          <TextField
            className={classes.textField}
            label="Type your answer here"
            multiline
            variant="outlined"
            rows={11}
            onChange={event => {
              const value = event.target.value;
              setUserInput(value);
            }}
          />
        </CardContent>

        <CardActions>
          <Button
            className={classes.okButton}
            onClick={() => {
              if (question === undefined) {
                return;
              }

              question.userResponse = userInput;
              onUpdate(question);
              onAdvance();
            }}
          >
            OK
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};
