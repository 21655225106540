/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ModelCareUnit,
  ModelCareUnitFromJSON,
  ModelCareUnitFromJSONTyped,
  ModelCareUnitToJSON,
} from "./";

/**
 *
 * @export
 * @interface HandlersRecordsPageModelCareUnit
 */
export interface HandlersRecordsPageModelCareUnit {
  /**
   *
   * @type {number}
   * @memberof HandlersRecordsPageModelCareUnit
   */
  nextPage?: number;
  /**
   *
   * @type {number}
   * @memberof HandlersRecordsPageModelCareUnit
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof HandlersRecordsPageModelCareUnit
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof HandlersRecordsPageModelCareUnit
   */
  previousPage?: number;
  /**
   *
   * @type {Array<ModelCareUnit>}
   * @memberof HandlersRecordsPageModelCareUnit
   */
  records?: Array<ModelCareUnit>;
}

export function HandlersRecordsPageModelCareUnitFromJSON(
  json: any,
): HandlersRecordsPageModelCareUnit {
  return HandlersRecordsPageModelCareUnitFromJSONTyped(json, false);
}

export function HandlersRecordsPageModelCareUnitFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HandlersRecordsPageModelCareUnit {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    nextPage: !exists(json, "nextPage") ? undefined : json["nextPage"],
    pageNumber: !exists(json, "pageNumber") ? undefined : json["pageNumber"],
    pageSize: !exists(json, "pageSize") ? undefined : json["pageSize"],
    previousPage: !exists(json, "previousPage")
      ? undefined
      : json["previousPage"],
    records: !exists(json, "records")
      ? undefined
      : (json["records"] as Array<any>).map(ModelCareUnitFromJSON),
  };
}

export function HandlersRecordsPageModelCareUnitToJSON(
  value?: HandlersRecordsPageModelCareUnit | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    nextPage: value.nextPage,
    pageNumber: value.pageNumber,
    pageSize: value.pageSize,
    previousPage: value.previousPage,
    records:
      value.records === undefined
        ? undefined
        : (value.records as Array<any>).map(ModelCareUnitToJSON),
  };
}
