import React, { useEffect, useRef } from "react";

class CurrentInfo<T extends any[]> {
  values: T;

  public constructor(...values: T) {
    this.values = values;
  }

  public set(...newValues: T) {
    this.values = newValues;
  }

  public hasChanged(...newValues: T) {
    return JSON.stringify(this.values) !== JSON.stringify(newValues);
  }
}

export type InfoRef<T extends any[]> = React.MutableRefObject<CurrentInfo<T>>;

export const useInfoRef = <T extends any[]>(...info: T) => {
  const infoRef = useRef(new CurrentInfo(...info)) as InfoRef<T>;

  // Note: this only updates when the array instance itself has changed, not when the
  // contents change. This is okay because we change the current info values using
  // `info.set(a, b, c)` which creates a new array owing to the rest parameters. This is
  // something to be aware of, though.
  useEffect(() => {
    infoRef.current.set(...info);
  }, [infoRef, info]);

  return infoRef;
};
