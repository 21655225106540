import { Paper, Tab, Theme, createStyles, makeStyles } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  ModelCareUnit,
  ModelCareUnitShiftSafetyModeEnum,
  ModelUserRoleEnum,
  getCareUnitsAPI,
} from "../api_client";
import {
  CareUnitOverview,
  CareUnitSettings,
  CareUnitSurveys,
  PageContainer,
  RotaBuilder,
  UsersTable,
} from "../components";
import {
  AppDatabase,
  Constants,
  modelToURLParams,
  urlParamToModel,
  useAppContext,
  userSafety,
} from "../helpers";
import {
  Breadcrumb,
  useBreadcrumbs,
  useCareUnit,
  useEmployees,
  useSurveys,
} from "../hooks";
import { useQueryParams } from "../hooks/useQueryParams";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      "& .MuiTabPanel-root": {
        padding: 0,
      },
    },
    tabBar: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    table: {
      flex: "1 0 auto",
    },
  }),
);

export interface UserSafetyScores {
  [userId: number]: number | undefined;
}

interface CareHomeUnitTab {
  label: string;
  wideMode: boolean;
  index: number;
  enabled?: boolean; // Undefined is taken to mean 'enabled'.
}

export interface CareUnitConfig {
  name: string;
  carerSurveyFrequencyDays: number;
  managerSurveyFrequencyDays: number;
  shiftSafetyMode: ModelCareUnitShiftSafetyModeEnum;
}

interface CareHomeUnitParams {
  // If come through listing care units in organisation, this will be present.
  orgInfo?: string;

  // If no ID is specified, we just get the care home that the user is in.
  unitInfo?: string;
}

export const CareHomeUnit: React.FC = () => {
  const { handleAPIError, showSnackbar, user } = useAppContext();
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<CareHomeUnitParams>();
  const query = useQueryParams();
  const location = useLocation();

  const tabs = {
    overview: {
      label: "Overview",
      wideMode: false,
      index: 0,
      enabled: false, // Until this is implemented, just disable it.
    },
    users: {
      label: "Carers",
      wideMode: true,
      index: 1,
    },
    rotas: {
      label: "Rotas",
      wideMode: true,
      index: 2,
    },
    surveys: {
      label: "Surveys",
      wideMode: true,
      index: 3,
      enabled: true,
    },
    settings: {
      label: "Settings",
      wideMode: false,
      index: 4,
      enabled: user?.role === ModelUserRoleEnum.Administrator,
    },
  } as { [key: string]: CareHomeUnitTab };

  const tabIndices = Object.values(tabs)
    .filter(t => t.enabled !== false)
    .map(t => t.index.toString())
    .sort((a, b) => Number(a) - Number(b));
  const queryTab = query.get("tab") ?? "0";
  const defaultTab = tabIndices.includes(queryTab) ? queryTab : tabIndices[0];
  const [currentTab, setCurrentTab] = useState(defaultTab);
  useEffect(() => setCurrentTab(defaultTab), [defaultTab]);

  const [paramOrgId, paramOrgName] = urlParamToModel(params.orgInfo);
  const [paramUnitId, paramUnitName] = urlParamToModel(params.unitInfo);

  const careUnitId = paramUnitId ?? user?.careUnitID;

  const [careUnit, setCareUnit] = useCareUnit(careUnitId);
  const [users, setUsers, areUsersLoading, refreshUsers] = useEmployees(
    careUnitId,
  );

  const [surveys, setSurveys, areSurveysLoading, refreshSurveys] = useSurveys(
    undefined,
    careUnitId,
  );

  const userSafetyScores = users.reduce((scores, u) => {
    const userId = u.id;
    if (userId !== undefined) {
      scores[userId] = userSafety(u, surveys);
    }
    return scores;
  }, {} as UserSafetyScores);

  useBreadcrumbs(
    Breadcrumb.Organisations,
    Breadcrumb.Organisation,
    Breadcrumb.OrganisationUnit,
  );

  const hasPermission =
    user?.role === ModelUserRoleEnum.Administrator ||
    (user?.role === ModelUserRoleEnum.Manager &&
      user.careUnitID === careUnitId);
  if (!hasPermission) {
    history.push(Constants.paths.permissionDenied);
    return null;
  }

  const navigateToConfigureShifts = () => {
    history.push(
      Constants.paths.configureShifts
        .replace(":orgInfo", params.orgInfo ?? "")
        .replace(":unitInfo", params.unitInfo ?? ""),
    );
  };

  const navigateToCompleteSurvey = (userId: number, userFullName: string) => {
    history.push(
      Constants.paths.completeSurvey
        .replace(":orgInfo", params.orgInfo ?? "")
        .replace(":unitInfo", params.unitInfo ?? "")
        .replace(":userInfo", modelToURLParams([userId, userFullName])),
    );
  };

  const currentWideMode = Object.values(tabs).find(
    tab => tab.index.toString() === currentTab,
  )?.wideMode;
  const isWide = currentWideMode === true;

  const updateUnitConfig = async (config: CareUnitConfig) => {
    if (careUnitId === undefined) {
      return;
    }

    const db = await AppDatabase.getInstance();

    const oldName = careUnit?.name;

    const updatedCareUnit: ModelCareUnit = {
      ...careUnit,
      name: config.name,
      shiftSafetyMode: config.shiftSafetyMode,
      carerSurveyFrequencyDays: config.carerSurveyFrequencyDays,
      managerSurveyFrequencyDays: config.managerSurveyFrequencyDays,
    };

    try {
      await getCareUnitsAPI().updateCareUnit({
        id: careUnitId,
        careUnit: updatedCareUnit,
      });

      if (db !== undefined) {
        await db.careUnits.update(careUnitId, updatedCareUnit);
      }

      setCareUnit(current => {
        if (current === undefined) {
          return undefined;
        }

        return {
          ...current,
          name: config.name,
          carerSurveyFrequencyDays: config.carerSurveyFrequencyDays,
          managerSurveyFrequencyDays: config.managerSurveyFrequencyDays,
          shiftSafetyMode: config.shiftSafetyMode,
        };
      });

      showSnackbar("Successfully updated care unit settings.", "success");

      if (
        oldName !== config.name &&
        paramOrgId !== undefined &&
        paramOrgName !== undefined
      ) {
        history.push({
          pathname: Constants.paths.organisationUnit
            .replace(":orgInfo", params.orgInfo ?? "")
            .replace(":unitInfo", modelToURLParams([careUnitId, config.name])),
          search: `?${query.toString()}`,
        });
      }
    } catch (e) {
      handleAPIError(e, "updating care unit settings");
    }
  };

  const deleteCareUnit = async () => {
    if (careUnitId === undefined) {
      return;
    }

    const db = await AppDatabase.getInstance();

    try {
      await getCareUnitsAPI().deleteCareUnit({ id: careUnitId });

      if (db !== undefined) {
        const rotasToDelete = await db.rotas
          .filter(r => r.careUnitID === careUnitId)
          .toArray();
        const rotaIdsToDelete = rotasToDelete
          .map(r => r.id)
          .filter(id => id !== undefined) as number[];

        const usersToDelete = await db.users
          .filter(u => u.careUnitID === careUnitId)
          .toArray();
        const userIdsToDelete = usersToDelete
          .map(u => u.id)
          .filter(id => id !== undefined) as number[];

        const shiftsToDelete = await db.shifts
          .filter(
            s =>
              (s.rotaID !== undefined && rotaIdsToDelete.includes(s.rotaID)) ||
              (s.userID !== undefined && userIdsToDelete.includes(s.userID)),
          )
          .toArray();
        const shiftIdsToDelete = shiftsToDelete
          .map(s => s.id)
          .filter(id => id !== undefined) as number[];

        const surveysToDelete = await db.surveys
          .filter(
            s => s.carerID !== undefined && userIdsToDelete.includes(s.carerID),
          )
          .toArray();
        const surveyIdsToDelete = surveysToDelete
          .map(s => s.id)
          .filter(id => id !== undefined) as number[];

        await db.careUnits.delete(careUnitId);
        await db.rotas.bulkDelete(rotaIdsToDelete);
        await db.users.bulkDelete(userIdsToDelete);
        await db.shifts.bulkDelete(shiftIdsToDelete);
        await db.surveys.bulkDelete(surveyIdsToDelete);
      }

      showSnackbar("Successfully delete care unit.", "success");

      window.setTimeout(() => {
        if (paramOrgId !== undefined && paramOrgName !== undefined) {
          history.push(
            Constants.paths.organisation.replace(
              ":orgInfo",
              params.orgInfo ?? "",
            ),
          );
        } else {
          history.push(Constants.paths.home);
        }
      }, 500);
    } catch (e) {
      handleAPIError(e, "deleting care unit");
    }
  };

  return (
    <div className={classes.root}>
      <TabContext value={currentTab}>
        <Paper className={classes.tabBar}>
          <TabList
            onChange={(_event, value) => {
              setCurrentTab(value);
              query.set("tab", value);
              location.search = query.toString();
              history.push(location);
            }}
            indicatorColor="primary"
            textColor="primary"
            centered
            aria-label="main navigation tabs"
          >
            {Object.values(tabs)
              .filter(tab => tab.enabled !== false)
              .sort((left, right) => left.index - right.index)
              .map((tab, i) => (
                <Tab key={i} label={tab.label} value={tab.index.toString()} />
              ))}
          </TabList>
        </Paper>

        <PageContainer isWide={isWide}>
          <TabPanel value={tabs.overview.index.toString()}>
            <CareUnitOverview careUnitId={careUnitId} surveys={surveys} />
          </TabPanel>

          <TabPanel value={tabs.users.index.toString()}>
            <UsersTable
              title="Carers"
              isLoading={areUsersLoading}
              users={users}
              setUsers={setUsers}
              refreshData={refreshUsers}
              userRoles="employees"
              careUnitId={paramUnitId}
              careUnit={careUnit}
              surveys={surveys}
              onRowClick={rowData => {
                if (rowData === undefined) {
                  return;
                }

                if (
                  paramOrgId === undefined ||
                  paramOrgName === undefined ||
                  paramUnitId === undefined ||
                  paramUnitName === undefined
                ) {
                  return;
                }

                history.push(
                  Constants.paths.organisationUnitUser
                    .replace(":orgInfo", params.orgInfo ?? "")
                    .replace(":unitInfo", params.unitInfo ?? "")
                    .replace(
                      ":userInfo",
                      modelToURLParams([
                        rowData.id,
                        `${rowData.firstName} ${rowData.lastName}`,
                      ]),
                    ),
                );
              }}
              navigateToCompleteSurvey={navigateToCompleteSurvey}
            />
          </TabPanel>

          <TabPanel value={tabs.surveys.index.toString()}>
            <CareUnitSurveys
              careUnitId={careUnitId}
              surveys={surveys}
              setSurveys={setSurveys}
              isLoading={areSurveysLoading}
              refreshData={refreshSurveys}
            />
          </TabPanel>

          <TabPanel value={tabs.rotas.index.toString()}>
            <RotaBuilder
              orgId={paramOrgId}
              careUnitId={careUnitId}
              careUnit={careUnit}
              users={users}
              areUsersLoading={areUsersLoading}
              userSafetyScores={userSafetyScores}
              isSafetyLoading={areUsersLoading || areSurveysLoading}
              onNavigateToConfigureShifts={navigateToConfigureShifts}
            />
          </TabPanel>

          {tabs.settings.enabled !== false && (
            <TabPanel value={tabs.settings.index.toString()}>
              <CareUnitSettings
                careUnit={careUnit}
                onUpdate={updateUnitConfig}
                onDelete={deleteCareUnit}
              />
            </TabPanel>
          )}
        </PageContainer>
      </TabContext>
    </div>
  );
};
