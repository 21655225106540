import React, { useCallback } from "react";

import { ModelQuestionTypeEnum } from "../api_client";
import { QuestionWithIndices } from "../pages";
import { AdjectiveQuestion, FreeTextQuestion, RatingQuestion } from ".";

interface SurveyQuestionProps {
  questionWithIndices: QuestionWithIndices;
  questionNumber: number;
  onUpdate: (completed: QuestionWithIndices) => void;
  onAdvance: (questionNumber: number) => void;
  orgName?: string;
}

export const SurveyQuestion: React.FC<SurveyQuestionProps> = ({
  questionWithIndices,
  questionNumber,
  onUpdate,
  onAdvance,
  orgName,
}) => {
  const question = questionWithIndices.question;

  const advance = useCallback(() => onAdvance(questionNumber), [
    onAdvance,
    questionNumber,
  ]);

  const updateAdjective = useCallback(
    completed => {
      const q = { ...questionWithIndices };
      q.question.adjective = completed;
      onUpdate(q);
    },
    [onUpdate, questionWithIndices],
  );

  const updateFreeText = useCallback(
    completed => {
      const q = { ...questionWithIndices };
      q.question.freeText = completed;
      onUpdate(q);
    },
    [onUpdate, questionWithIndices],
  );

  const updateRating = useCallback(
    completed => {
      const q = { ...questionWithIndices };
      q.question.rating = completed;
      onUpdate(q);
    },
    [onUpdate, questionWithIndices],
  );

  switch (question.type) {
    case ModelQuestionTypeEnum.Adjective:
      return (
        <AdjectiveQuestion
          question={question.adjective}
          questionNumber={questionNumber}
          onUpdate={updateAdjective}
          onAdvance={advance}
        />
      );
    case ModelQuestionTypeEnum.Freetext:
      return (
        <FreeTextQuestion
          question={question.freeText}
          questionNumber={questionNumber}
          onUpdate={updateFreeText}
          onAdvance={advance}
          orgName={orgName}
        />
      );
    case ModelQuestionTypeEnum.Rating:
      return (
        <RatingQuestion
          question={question.rating}
          questionNumber={questionNumber}
          onUpdate={updateRating}
          onAdvance={advance}
          orgName={orgName}
        />
      );
    default:
      throw new TypeError(`Unrecognised question type: '${question.type}'.`);
  }
};
