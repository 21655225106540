/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EchoHTTPError,
  EchoHTTPErrorFromJSON,
  EchoHTTPErrorToJSON,
  ModelShiftPreset,
  ModelShiftPresetFromJSON,
  ModelShiftPresetToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface AddShiftPresetRequest {
  careOrganisationID: number;
  shiftPreset: ModelShiftPreset;
}

export interface DeleteShiftPresetRequest {
  id: number;
}

export interface GetAllShiftPresetsRequest {
  careOrganisationID: number;
}

export interface UpdateShiftPresetRequest {
  id: number;
  updatedShiftPreset: ModelShiftPreset;
}

/**
 *
 */
export class ShiftPresetsApi extends runtime.BaseAPI {
  /**
   * Add new shift preset for a particular care organisation. Note that you can\'t add standard presets (i.e. early, late and night) for a care organisation as these are unique and created automatically.
   * Add new custom shift preset to a care organisation.
   */
  async addShiftPresetRaw(
    requestParameters: AddShiftPresetRequest,
  ): Promise<runtime.ApiResponse<ModelShiftPreset>> {
    if (
      requestParameters.careOrganisationID === null ||
      requestParameters.careOrganisationID === undefined
    ) {
      throw new runtime.RequiredError(
        "careOrganisationID",
        "Required parameter requestParameters.careOrganisationID was null or undefined when calling addShiftPreset.",
      );
    }

    if (
      requestParameters.shiftPreset === null ||
      requestParameters.shiftPreset === undefined
    ) {
      throw new runtime.RequiredError(
        "shiftPreset",
        "Required parameter requestParameters.shiftPreset was null or undefined when calling addShiftPreset.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.careOrganisationID !== undefined) {
      queryParameters["careOrganisationID"] =
        requestParameters.careOrganisationID;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/shift-presets`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelShiftPresetToJSON(requestParameters.shiftPreset),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelShiftPresetFromJSON(jsonValue),
    );
  }

  /**
   * Add new shift preset for a particular care organisation. Note that you can\'t add standard presets (i.e. early, late and night) for a care organisation as these are unique and created automatically.
   * Add new custom shift preset to a care organisation.
   */
  async addShiftPreset(
    requestParameters: AddShiftPresetRequest,
  ): Promise<ModelShiftPreset> {
    const response = await this.addShiftPresetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Remove existing shift preset for a particular care organisation. Note that you can\'t remove the standard presets (i.e. early, late and night) for a care organisation.
   * Remove existing shift preset from a care organisation.
   */
  async deleteShiftPresetRaw(
    requestParameters: DeleteShiftPresetRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteShiftPreset.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/shift-presets/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Remove existing shift preset for a particular care organisation. Note that you can\'t remove the standard presets (i.e. early, late and night) for a care organisation.
   * Remove existing shift preset from a care organisation.
   */
  async deleteShiftPreset(
    requestParameters: DeleteShiftPresetRequest,
  ): Promise<void> {
    await this.deleteShiftPresetRaw(requestParameters);
  }

  /**
   * Retrieve all shift presets for a particular care organisation.
   * Get all shift presets for a care organisation.
   */
  async getAllShiftPresetsRaw(
    requestParameters: GetAllShiftPresetsRequest,
  ): Promise<runtime.ApiResponse<Array<ModelShiftPreset>>> {
    if (
      requestParameters.careOrganisationID === null ||
      requestParameters.careOrganisationID === undefined
    ) {
      throw new runtime.RequiredError(
        "careOrganisationID",
        "Required parameter requestParameters.careOrganisationID was null or undefined when calling getAllShiftPresets.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.careOrganisationID !== undefined) {
      queryParameters["careOrganisationID"] =
        requestParameters.careOrganisationID;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/shift-presets`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ModelShiftPresetFromJSON),
    );
  }

  /**
   * Retrieve all shift presets for a particular care organisation.
   * Get all shift presets for a care organisation.
   */
  async getAllShiftPresets(
    requestParameters: GetAllShiftPresetsRequest,
  ): Promise<Array<ModelShiftPreset>> {
    const response = await this.getAllShiftPresetsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update existing shift preset for a particular care organisation. Note that you can change the names and start times / durations for standard presets (i.e. early, late and night) however you can\'t change a standard preset to a non-standard preset or change an \'early\' preset to a \'late\' preset, etc.
   * Update existing shift preset in a care organisation.
   */
  async updateShiftPresetRaw(
    requestParameters: UpdateShiftPresetRequest,
  ): Promise<runtime.ApiResponse<ModelShiftPreset>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateShiftPreset.",
      );
    }

    if (
      requestParameters.updatedShiftPreset === null ||
      requestParameters.updatedShiftPreset === undefined
    ) {
      throw new runtime.RequiredError(
        "updatedShiftPreset",
        "Required parameter requestParameters.updatedShiftPreset was null or undefined when calling updateShiftPreset.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/shift-presets/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ModelShiftPresetToJSON(requestParameters.updatedShiftPreset),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelShiftPresetFromJSON(jsonValue),
    );
  }

  /**
   * Update existing shift preset for a particular care organisation. Note that you can change the names and start times / durations for standard presets (i.e. early, late and night) however you can\'t change a standard preset to a non-standard preset or change an \'early\' preset to a \'late\' preset, etc.
   * Update existing shift preset in a care organisation.
   */
  async updateShiftPreset(
    requestParameters: UpdateShiftPresetRequest,
  ): Promise<ModelShiftPreset> {
    const response = await this.updateShiftPresetRaw(requestParameters);
    return await response.value();
  }
}
