import { useCallback, useEffect, useState } from "react";

import { ModelShiftPreset, getShiftPresetsAPI } from "../api_client";
import { AppDatabase, HandleAPIErrorFun, useAppContext } from "../helpers";
import { useAPIUpdatePeriod } from "./useAPIUpdatePeriod";

const getShiftPresets = async (
  orgId: number,
  setShiftPresets: React.Dispatch<React.SetStateAction<ModelShiftPreset[]>>,
  handleAPIError: HandleAPIErrorFun,
) => {
  try {
    const shiftPresets = await getShiftPresetsAPI().getAllShiftPresets({
      careOrganisationID: orgId,
    });

    const db = await AppDatabase.getInstance();
    if (db !== undefined) {
      const clearRemoved = async () => {
        const itemsToRemove = await db.shiftPresets
          .filter(
            preset =>
              preset.careOrganisationID === orgId &&
              !shiftPresets.map(p => p.id).includes(preset.id),
          )
          .toArray();

        await db.shifts.bulkDelete(itemsToRemove.map(p => p.id!));
      };

      await Promise.all([
        clearRemoved(),
        db.shiftPresets.bulkPut(shiftPresets),
      ]);
    }

    setShiftPresets(shiftPresets);
  } catch (e) {
    handleAPIError(e, "retrieving shift presets");
  }
};

export const useShiftPresets = (orgId: number | undefined) => {
  const { handleAPIError } = useAppContext();

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [shiftPresets, setShiftPresets] = useState<ModelShiftPreset[]>([]);

  const [setUpdatedFromAPI, isAPIUpdateDue] = useAPIUpdatePeriod(
    `shift-presets-${orgId}`,
  );

  useEffect(() => {
    setInitialLoadComplete(false);
    setIsLoading(true);
    setShiftPresets([]);
  }, [orgId]);

  useEffect(() => {
    if (initialLoadComplete || orgId === undefined) {
      setIsLoading(false);
      return;
    }

    const getCached = async () => {
      const db = await AppDatabase.getInstance();
      if (db === undefined) {
        return;
      }

      const cachedPresets = await db.shiftPresets
        .filter(p => p.careOrganisationID === orgId)
        .toArray();

      setShiftPresets(cachedPresets);
      return cachedPresets.length > 0;
    };

    getCached().then(async cachePresent => {
      if (!cachePresent || isAPIUpdateDue()) {
        await getShiftPresets(orgId, setShiftPresets, handleAPIError);
      }

      setInitialLoadComplete(true);
      setIsLoading(false);
      setUpdatedFromAPI();
    });
  }, [
    orgId,
    initialLoadComplete,
    isAPIUpdateDue,
    setUpdatedFromAPI,
    handleAPIError,
  ]);

  const refreshData = useCallback(async () => {
    if (orgId === undefined) {
      return;
    }

    setIsLoading(true);
    await getShiftPresets(orgId, setShiftPresets, handleAPIError);
    setIsLoading(false);
    setUpdatedFromAPI();
  }, [orgId, handleAPIError, setUpdatedFromAPI]);

  return [shiftPresets, setShiftPresets, isLoading, refreshData] as const;
};
