import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { History as TimeoutIcon } from "@material-ui/icons";
import React, { useEffect } from "react";

import { AuthService } from "../services";

const useStyles = makeStyles({
  root: {},
  titleIcon: {
    verticalAlign: "text-bottom",
    fontSize: 35,
    marginRight: 5,
  },
  titleText: {
    verticalAlign: "super",
  },
  widgetBackdrop: {
    borderRadius: 20,
  },
});

export const SessionExpiredDialog: React.FC = () => {
  const classes = useStyles();

  useEffect(() => {
    AuthService.getInstance().login();
  }, []);

  return (
    <div className={classes.root}>
      <Dialog
        disablePortal
        open
        aria-labelledby="cqe-session-expired-title"
        aria-describedby="cqe-session-expired-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="cqe-session-expired-title" disableTypography={false}>
          <Typography variant="h6" component="div">
            <TimeoutIcon className={classes.titleIcon} />
            <span className={classes.titleText}>
              You&apos;ve been logged out due to inactivity
            </span>
          </Typography>
        </DialogTitle>
        <DialogContent id="cqe-session-expired-description">
          <DialogContentText>
            Your session has expired. Please log in again.
          </DialogContentText>
          <DialogContentText>Redirecting you now...</DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
