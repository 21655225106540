import {
  TableCell,
  TableRow,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import React from "react";

import { ModelShiftPresetPresetTimeEnum } from "../../api_client";
import { ShiftSafety } from "./RotaTable";
import { ShiftSafetyIcon } from "..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unclassifiedShiftIcon: {
      color: theme.palette.background.paper,
    },
    safeShiftIcon: {
      color: theme.palette.success.main,
    },
    unsafeShiftIcon: {
      color: theme.palette.error.main,
    },
    unclassifiedShiftText: {
      color: theme.palette.text.primary,
    },
    safeShiftText: {},
    unsafeShiftText: {},
    boxContainer: {
      display: "flex",
      alignItems: "end",
    },
    shiftNameCell: {
      textAlign: "right",
    },
    shiftSafetyCell: {},
    shiftName: {
      fontWeight: "bold",
    },
    shiftSafety: {
      display: "flex",
      flexDirection: "column",
    },
    shiftSafetyTop: {},
    shiftSafetyBottom: {},
    icon: {
      alignSelf: "center",
    },
  }),
);

interface ShiftSafetyRowProps {
  isNightStaff: boolean;
  shiftSafetyScores: ShiftSafety[];
  isLoading: boolean;
  classes?: { cell?: string };
}

export const ShiftSafetyRow: React.FC<ShiftSafetyRowProps> = ({
  isNightStaff,
  shiftSafetyScores,
  isLoading,
  classes: classesProps,
}) => {
  const classes = { ...useStyles(), ...classesProps };

  const getSafetyIcon = (
    shiftSafety: ShiftSafety,
    time: ModelShiftPresetPresetTimeEnum,
  ) => {
    if (isLoading) {
      return <Skeleton variant="text" />;
    }

    const safetyClasses = shiftSafety.isShiftSafe[time]
      ? { icon: classes.safeShiftIcon, text: classes.safeShiftText }
      : shiftSafety.isShiftUnsafe[time]
      ? { icon: classes.unsafeShiftIcon, text: classes.unsafeShiftText }
      : {
          icon: classes.unclassifiedShiftIcon,
          text: classes.unclassifiedShiftText,
        };

    return (
      <ShiftSafetyIcon
        numUsers={shiftSafety.numCarers[time]}
        className={classes.icon}
        classes={safetyClasses}
      />
    );
  };

  return (
    <TableRow>
      <TableCell colSpan={2} />
      <TableCell className={classes.shiftNameCell}>
        {isNightStaff ? (
          <div className={classes.shiftName}>Night:</div>
        ) : (
          <>
            <div className={classes.shiftName}>Early:</div>
            <div className={classes.shiftName}>Late:</div>
          </>
        )}
      </TableCell>
      {Array.from(Array(7).keys()).map(i => {
        const shiftSafety = shiftSafetyScores[i];
        return (
          <TableCell
            key={i}
            align="center"
            className={clsx(classes.cell, classes.shiftSafetyCell)}
          >
            <div className={classes.shiftSafety}>
              {isNightStaff ? (
                getSafetyIcon(shiftSafety, ModelShiftPresetPresetTimeEnum.Night)
              ) : (
                <>
                  {getSafetyIcon(
                    shiftSafety,
                    ModelShiftPresetPresetTimeEnum.Early,
                  )}
                  {getSafetyIcon(
                    shiftSafety,
                    ModelShiftPresetPresetTimeEnum.Late,
                  )}
                </>
              )}
            </div>
          </TableCell>
        );
      })}
      <TableCell colSpan={2} />
    </TableRow>
  );
};
