/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ModelCareOrganisation
 */
export interface ModelCareOrganisation {
  /**
   *
   * @type {number}
   * @memberof ModelCareOrganisation
   */
  defaultCarerSurveyFrequencyDays?: number;
  /**
   *
   * @type {number}
   * @memberof ModelCareOrganisation
   */
  defaultManagerSurveyFrequencyDays?: number;
  /**
   *
   * @type {string}
   * @memberof ModelCareOrganisation
   */
  defaultShiftSafetyMode?: ModelCareOrganisationDefaultShiftSafetyModeEnum;
  /**
   *
   * @type {number}
   * @memberof ModelCareOrganisation
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ModelCareOrganisation
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ModelCareOrganisation
   */
  numCarers?: number;
  /**
   *
   * @type {number}
   * @memberof ModelCareOrganisation
   */
  numManagers?: number;
  /**
   *
   * @type {number}
   * @memberof ModelCareOrganisation
   */
  numUnits?: number;
}

export function ModelCareOrganisationFromJSON(
  json: any,
): ModelCareOrganisation {
  return ModelCareOrganisationFromJSONTyped(json, false);
}

export function ModelCareOrganisationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ModelCareOrganisation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    defaultCarerSurveyFrequencyDays: !exists(
      json,
      "defaultCarerSurveyFrequencyDays",
    )
      ? undefined
      : json["defaultCarerSurveyFrequencyDays"],
    defaultManagerSurveyFrequencyDays: !exists(
      json,
      "defaultManagerSurveyFrequencyDays",
    )
      ? undefined
      : json["defaultManagerSurveyFrequencyDays"],
    defaultShiftSafetyMode: !exists(json, "defaultShiftSafetyMode")
      ? undefined
      : json["defaultShiftSafetyMode"],
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
    numCarers: !exists(json, "numCarers") ? undefined : json["numCarers"],
    numManagers: !exists(json, "numManagers") ? undefined : json["numManagers"],
    numUnits: !exists(json, "numUnits") ? undefined : json["numUnits"],
  };
}

export function ModelCareOrganisationToJSON(
  value?: ModelCareOrganisation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    defaultCarerSurveyFrequencyDays: value.defaultCarerSurveyFrequencyDays,
    defaultManagerSurveyFrequencyDays: value.defaultManagerSurveyFrequencyDays,
    defaultShiftSafetyMode: value.defaultShiftSafetyMode,
    id: value.id,
    name: value.name,
    numCarers: value.numCarers,
    numManagers: value.numManagers,
    numUnits: value.numUnits,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ModelCareOrganisationDefaultShiftSafetyModeEnum {
  Beginner = "beginner",
  Standard = "standard",
}
