import DateFnsUtils from "@date-io/date-fns";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import clsx from "clsx";
import React from "react";

import { ModelCareUnit, ModelSurvey, ModelUser } from "../api_client";
import {
  monthNames,
  nextSurveyDue,
  nth,
  toTitleCase,
  userFullName,
} from "../helpers";
import { StressGraph, UserNCASGraph } from "./Graphs";
import { UserAvatar } from ".";
import {
  EndDateIcon,
  RoleIcon,
  StartDateIcon,
  StatusIcon,
  SupernumeraryIcon,
} from ".";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    userCard: {},
    infoKey: {
      fontWeight: "bold",
    },
    infoValue: {
      marginLeft: "auto",
    },
    surveyDueText: {
      marginBottom: 16,
    },
    surveyOverdue: {
      color: theme.palette.error.main,
    },
    surveyUpcoming: {
      color: theme.palette.warning.main,
    },
    surveyFarAway: {
      color: theme.palette.info.main,
    },
    surveyDate: {
      "& .MuiPickersBasePicker-container": {
        margin: "auto",
      },
    },
    cardGraph: {
      height: 400,
    },
  }),
);

interface UserOverviewProps {
  user?: ModelUser;
  surveys: ModelSurvey[];
  careUnit?: ModelCareUnit;
}

const formatDate = (date?: Date) => {
  if (date === undefined) {
    return "N/A";
  }

  const d = new Date(date);
  return (
    <>
      {d.getDate()}
      <sup>{nth(d.getDate())}</sup> {monthNames[d.getMonth()]} {d.getFullYear()}
    </>
  );
};

export const UserOverview: React.FC<UserOverviewProps> = ({
  user,
  surveys,
  careUnit,
}) => {
  const classes = useStyles();

  const nextSurveyDate = nextSurveyDue(
    user,
    surveys,
    careUnit?.carerSurveyFrequencyDays,
    careUnit?.managerSurveyFrequencyDays,
  );

  const now = new Date();
  const oneDay = 24 * 60 * 60 * 1000;
  const daysTillSurveyDue =
    nextSurveyDate &&
    Math.round((nextSurveyDate?.getTime() - now.getTime()) / oneDay);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card className={classes.userCard}>
            <CardHeader
              avatar={<UserAvatar user={user} />}
              title={userFullName(user)}
            />

            <CardContent>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <StartDateIcon />
                  </ListItemIcon>
                  <span className={classes.infoKey}>Employment began:</span>{" "}
                  <span className={classes.infoValue}>
                    {formatDate(user?.startDate)}
                  </span>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <EndDateIcon />
                  </ListItemIcon>
                  <span className={classes.infoKey}>Employment ended:</span>{" "}
                  <span className={classes.infoValue}>
                    {formatDate(user?.endDate)}
                  </span>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <RoleIcon />
                  </ListItemIcon>
                  <span className={classes.infoKey}>Role:</span>{" "}
                  <span className={classes.infoValue}>
                    {toTitleCase(user?.role ?? "", "_")}
                  </span>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <StatusIcon />
                  </ListItemIcon>
                  <span className={classes.infoKey}>Status:</span>{" "}
                  <span className={classes.infoValue}>
                    {toTitleCase(user?.status ?? "", "_")}
                  </span>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <SupernumeraryIcon />
                  </ListItemIcon>
                  <span className={classes.infoKey}>Is supernumerary:</span>{" "}
                  <span className={classes.infoValue}>
                    {user?.isSupernumerary === true ? "Yes" : "No"}
                  </span>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Next survey" />
            <CardContent>
              <Typography className={classes.surveyDueText}>
                <span className={classes.infoKey}>Due:</span>{" "}
                {daysTillSurveyDue === undefined && "N/A"}
                {daysTillSurveyDue && (
                  <span
                    className={clsx({
                      [classes.surveyOverdue]: daysTillSurveyDue < 0,
                      [classes.surveyUpcoming]:
                        daysTillSurveyDue >= 0 && daysTillSurveyDue < 14,
                      [classes.surveyFarAway]: daysTillSurveyDue >= 14,
                    })}
                  >
                    {Math.abs(daysTillSurveyDue)} days{" "}
                    {daysTillSurveyDue < 0 ? "ago" : "time"}
                  </span>
                )}
              </Typography>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className={classes.surveyDate}>
                  <DatePicker
                    readOnly
                    orientation="landscape"
                    variant="static"
                    openTo="date"
                    value={nextSurveyDate}
                    onChange={() => {}}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title="Net Customer Advocacy Scores" />
            <CardContent className={classes.cardGraph}>
              <UserNCASGraph data={surveys} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title="User Stress" />
            <CardContent className={classes.cardGraph}>
              <StressGraph data={surveys} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
