import {
  Button,
  CircularProgress,
  TextField,
  Theme,
  Typography,
  createStyles,
  fade,
  makeStyles,
} from "@material-ui/core";
import { Delete as DeleteIcon, Save as SaveIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ModelUser, getUsersAPI } from "../api_client";
import { ConfirmationDialog, PageContainer } from "../components";
import {
  Constants,
  isEmailValid,
  useAppContext,
  userFullName,
} from "../helpers";
import { Breadcrumb, useBreadcrumbs } from "../hooks";
import { AuthService } from "../services";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //
    },
    form: {
      marginTop: theme.spacing(2),
    },
    formSection: {
      marginBottom: theme.spacing(2),

      "&:not(:first-child)": {
        borderTop: "1px solid",
        borderColor: fade(theme.palette.text.secondary, 0.2),
        paddingTop: theme.spacing(2),
      },

      "& .MuiFormControl-root": {
        marginBottom: theme.spacing(2),
      },

      "& .MuiFormControl-root:first-child": {
        marginTop: theme.spacing(2),
      },
    },
    formSectionTitle: {
      marginBottom: theme.spacing(1),
    },
    formFieldDescription: {
      marginBottom: theme.spacing(2),
    },
    textField: {
      display: "block",
      width: 200,
    },
    textFieldEmail: {
      width: 400,
    },
    dangerZone: {
      padding: theme.spacing(2),
      border: "1px solid",
      borderColor: theme.palette.error.main,
      borderRadius: 6,
    },
    dangerRow: {
      display: "flex",
      "&:not(:first-child)": {
        borderTop: "1px solid",
        borderColor: fade(theme.palette.text.secondary, 0.2),
      },
    },
    dangerInfo: {
      flexGrow: 1,
    },
    dangerDescription: {
      //
    },
    dangerButtonWrapper: {
      position: "relative",
      margin: theme.spacing(1),
    },
    dangerButton: {
      height: 38,
      position: "relative",
      top: "50%",
      transform: "translateY(-50%)",
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      transitionProperty: "background-color, color",

      "&:hover, &:focus": {
        backgroundColor: theme.palette.error.main,
        color: "white",
      },
    },
    actions: {
      display: "flex",
      marginTop: theme.spacing(4),
    },
    saveButtonWrapper: {
      marginLeft: "auto",
    },
    saveButton: {
      //
    },
    progressButtonWrapper: {
      position: "relative",
    },
    progressButtonSpinner: {
      color: theme.palette.success.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

export const UserSettings: React.FC = () => {
  const classes = useStyles();
  const { handleAPIError, showSnackbar, user, setUser } = useAppContext();
  const history = useHistory();

  const [deleting, setDeleting] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [saving, setSaving] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const currentFirstName = user?.firstName ?? "";
  const [firstName, setFirstName] = useState(currentFirstName);
  useEffect(() => setFirstName(currentFirstName), [currentFirstName]);

  const currentLastName = user?.lastName ?? "";
  const [lastName, setLastName] = useState(currentLastName);
  useEffect(() => setLastName(currentLastName), [currentLastName]);

  const currentEmail = user?.email ?? "";
  const [email, setEmail] = useState(currentEmail);
  useEffect(() => setEmail(currentEmail), [currentEmail]);

  useBreadcrumbs(Breadcrumb.UserSettings);

  const hasChanged =
    firstName !== currentFirstName ||
    lastName !== currentLastName ||
    email !== currentEmail;

  const isValid = firstName !== "" && lastName !== "" && isEmailValid(email);

  const updateUser = async () => {
    const userId = user?.id;
    if (!isValid || userId === undefined) {
      return;
    }

    setSaving(true);

    const updatedUser = { ...user, firstName, lastName, email } as ModelUser;

    try {
      await getUsersAPI().updateUser({ id: userId, user: updatedUser });
      setUser(updatedUser);
      showSnackbar("Succesfully updated user settings.", "success");
    } catch (e) {
      handleAPIError(e, "updating user settings");
    } finally {
      setSaving(false);
    }
  };

  const deleteUser = async () => {
    const userId = user?.id;
    if (!isValid || userId === undefined) {
      return;
    }

    setDeleting(true);

    try {
      await getUsersAPI().deleteUser({ id: userId });
      setDeleteSuccess(true);
      showSnackbar("Succesfully deleted user.", "success");
      AuthService.getInstance().logout();
      history.push(Constants.paths.home);
    } catch (e) {
      handleAPIError(e, "deleting user");
    } finally {
      setDeleting(false);
    }
  };

  return (
    <PageContainer className={classes.root}>
      <Typography variant="h4">User Settings</Typography>

      <div className={classes.form}>
        <div className={classes.formSection}>
          <Typography variant="h6" className={classes.formSectionTitle}>
            {user !== undefined ? "General" : <Skeleton width="20%" />}
          </Typography>

          {user !== undefined ? (
            <TextField
              label="First name"
              variant="outlined"
              className={classes.textField}
              fullWidth
              value={firstName}
              onChange={event => setFirstName(event.target.value)}
            />
          ) : (
            <Skeleton width="50%" />
          )}

          {user !== undefined ? (
            <TextField
              label="Last name"
              variant="outlined"
              className={classes.textField}
              fullWidth
              value={lastName}
              onChange={event => setLastName(event.target.value)}
            />
          ) : (
            <Skeleton width="50%" />
          )}

          {user !== undefined ? (
            <TextField
              label="Email"
              variant="outlined"
              type="email"
              error={!isEmailValid(email)}
              className={clsx(classes.textField, classes.textFieldEmail)}
              fullWidth
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
          ) : (
            <Skeleton width="50%" />
          )}
        </div>

        <div className={classes.formSection}>
          <Typography variant="h6" className={classes.formSectionTitle}>
            {user !== undefined ? "Danger Zone" : <Skeleton width="20%" />}
          </Typography>

          <div className={classes.dangerZone}>
            <div className={classes.dangerRow}>
              <div className={classes.dangerInfo}>
                <Typography variant="h6" component="p">
                  {user !== undefined ? (
                    "Delete my account"
                  ) : (
                    <Skeleton width="30%" />
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.dangerDescription}
                >
                  {user !== undefined ? (
                    <>
                      Once you delete your account, you will no longer have
                      access to the CQE systems without administrator
                      intervention. Any surveys you have completed or shifts you
                      have will also be deleted. This action cannot be undone.
                    </>
                  ) : (
                    <Skeleton width="75%" />
                  )}
                </Typography>
              </div>

              <div
                className={clsx(
                  classes.dangerButtonWrapper,
                  classes.progressButtonWrapper,
                )}
              >
                <Button
                  className={classes.dangerButton}
                  variant="outlined"
                  disabled={user === undefined || deleting || deleteSuccess}
                  onClick={() => setConfirmDialogOpen(true)}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
                {deleting && (
                  <CircularProgress
                    size={24}
                    className={classes.progressButtonSpinner}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.actions}>
        <div
          className={clsx(
            classes.saveButtonWrapper,
            classes.progressButtonWrapper,
          )}
        >
          <Button
            className={classes.saveButton}
            color="secondary"
            variant="contained"
            disabled={
              user === undefined ||
              !hasChanged ||
              !isValid ||
              saving ||
              deleting ||
              deleteSuccess
            }
            onClick={updateUser}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
          {saving && (
            <CircularProgress
              size={24}
              className={classes.progressButtonSpinner}
            />
          )}
        </div>
      </div>

      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        message={
          <>
            This action <strong>cannot</strong> be undone. This will{" "}
            <strong>permanently delete</strong> your surveys, shifts and
            anything else associated with your account.
          </>
        }
        onConfirm={deleteUser}
        confirmationText={userFullName(user)}
      />
    </PageContainer>
  );
};
