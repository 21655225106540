import {
  ModelShift,
  ModelShiftPreset,
  ModelSurvey,
  ModelUser,
  ModelUserRoleEnum,
  ModelUserStatusEnum,
} from "../api_client";
import { DayOfWeek } from "../components/RotaBuilder/RotaTable";

export class Colour {
  public r: number;
  public g: number;
  public b: number;
  public a: number;

  /**
   * Create colour from hex string value.
   * @param hex Colour as hex string, e.g. "#f12a9b"
   */
  constructor(hex: string);

  /**
   * Create colour from RGB (and optionally A) values. Note that the R, G and B should
   * be 0 - 255 as opposed to 0 - 1.
   * @param r The red component of the colour, in 0 - 255 format.
   * @param g The red component of the colour, in 0 - 255 format.
   * @param b The red component of the colour, in 0 - 255 format.
   * @param a The alpha (i.e. opacity) of the colour, in 0 - 1 format. If unspecified, assume 1 (i.e. 100% opacity).
   */
  constructor(r: number, g: number, b: number, a?: number);

  constructor(hexOrR: string | number, g?: number, b?: number, a?: number) {
    if (typeof hexOrR === "string") {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexOrR);
      if (!result) {
        throw new Error("Invalid hex colour string.");
      }

      this.r = parseInt(result[1], 16);
      this.g = parseInt(result[2], 16);
      this.b = parseInt(result[3], 16);
      this.a = 1; // Hex colour doesn't contain alpha.
      return;
    }

    if (g === undefined || b === undefined) {
      throw new Error(
        "Invalid arguments to Colour constructor - if first param is number, expecting G and B values also.",
      );
    }

    this.r = hexOrR;
    this.g = g;
    this.b = b;
    this.a = a ?? 1;
  }

  public toHex() {
    const numToHex = (num: number) => ("00" + num.toString(16)).substr(-2);
    return "#" + numToHex(this.r) + numToHex(this.g) + numToHex(this.b);
  }

  public toRGB() {
    return `rgb(${this.r}, ${this.g}, ${this.b})`;
  }

  public toRGBA() {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
  }

  /**
   * If this this the background colour, get the colour which should be used for
   * foreground text in order to be visible and compliant with accessibility contrast
   * specifications. Note that this ignores the alpha for this colour and just uses the
   * RGB values. Uses approximate W3C recommendation based on ITU-R recommendation
   * BT.709 to calculate the luminance. For more information, see:
   * http://www.w3.org/TR/WCAG20/.
   */
  // https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
  public fgColour() {
    // Gamma compress values to calculate relative luminance as per W3 WCAG
    // specification: https://www.w3.org/TR/WCAG20/#relativeluminancedef
    const gammaCompress = (c: number) => {
      const fraction = c / 0xff;
      return fraction <= 0.03928
        ? fraction / 12.92
        : Math.pow((fraction + 0.055) / 1.055, 2.4);
    };

    const relativeLuminance =
      0.2126 * gammaCompress(this.r) +
      0.7152 * gammaCompress(this.g) +
      0.0722 * gammaCompress(this.b);

    return relativeLuminance > 0.179 ? "#000" : "#fff";
  }

  /**
   * Given any string of characters, deterministically generate a colour for that
   * string. Internally, this hashes the string and uses the bytes from the hash to
   * create the colour.
   */
  // https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
  /* eslint-disable no-bitwise */
  public static generateForString(str?: string) {
    if (str === undefined) {
      return new Colour(0, 0, 0, 0);
    }

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const r = (hash >> (0 * 8)) & 0xff;
    const g = (hash >> (1 * 8)) & 0xff;
    const b = (hash >> (2 * 8)) & 0xff;

    return new Colour(r, g, b);
  }
  /* eslint-enable no-bitwise */
}

export type ModelInfo = [number | undefined, string | undefined];

export const modelToURLParams = (model: ModelInfo) =>
  encodeURIComponent(`${model[0] ?? ""}-${model[1] ?? ""}`);

export const urlParamToModel = (param?: string) => {
  if (param === undefined) {
    return [undefined, undefined] as ModelInfo;
  }

  const decoded = decodeURIComponent(param);

  const id = Number(decoded.split("-")[0]);
  const name = decoded.split("-").slice(1).join("-");

  return [id, name] as ModelInfo;
};

/* eslint-disable no-bitwise */
export const hashCode = (s: string) =>
  s.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
/* eslint-enable no-bitwise */

export const toTitleCase = (str: string, separator = " ") => {
  if (str.length === 0) {
    return str;
  }

  return str
    .split(separator)
    .map(word => word.replace(word[0], word[0].toUpperCase()))
    .join(" ");
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// See:
// https://stackoverflow.com/questions/15397372/javascript-new-date-ordinal-st-nd-rd-th
export const nth = (d: number) => {
  if (d > 3 && d < 21) return "th";

  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const defaultCarerSurveyFrequencyDays = 30;
const defaultManagerSurveyFrequencyDays = 60;

export const nextSurveyDue = (
  user: ModelUser | undefined,
  previousSurveys: ModelSurvey[],
  carerSurveyFrequencyDays: number | undefined,
  managerSurveyFrequencyDays: number | undefined,
) => {
  if (
    user === undefined ||
    user.status === ModelUserStatusEnum.Left ||
    user.status === ModelUserStatusEnum.Banked
  ) {
    // These users aren't currently actively working at this organisation, so they're
    // not needed to complete a survey. In the future, if they return, they'll become
    // overdue to complete a survey, which makes sense.
    return undefined;
  }

  // For the moment if carers or managers have never completed a survey, take it as if
  // they last completed one when they started work.
  const lastSurveyCompleted =
    previousSurveys
      .map(s => s.createdAt)
      .sort((left, right) =>
        (left?.getTime() ?? 0) < (right?.getTime() ?? 0) ? 1 : -1,
      )[0] ?? user.startDate;

  if (lastSurveyCompleted === undefined) {
    return undefined;
  }

  const dueDate = new Date(lastSurveyCompleted);

  // For the moment, assume carers do surveys every month, managers do surveys every 2
  // months and admins never do them. This is liable to change in the future.
  switch (user.role) {
    case ModelUserRoleEnum.Carer:
      dueDate.setDate(
        dueDate.getDate() +
          (carerSurveyFrequencyDays ?? defaultCarerSurveyFrequencyDays),
      );
      return dueDate;
    case ModelUserRoleEnum.Manager:
      dueDate.setDate(
        dueDate.getDate() +
          (managerSurveyFrequencyDays ?? defaultManagerSurveyFrequencyDays),
      );
      return dueDate;
    default:
      return undefined;
  }
};

export const lerp = (min: number, max: number, value: number) =>
  min * (1 - value) + max * value;

export const unlerp = (min: number, max: number, value: number) =>
  (value - min) / (max - min);

export const clamp = (min: number, max: number, value: number) =>
  Math.min(Math.max(value, min), max);

export const groupBy = <T, K = string | number>(
  arr: T[],
  key: keyof T | ((arrayItem: T) => K),
) => {
  return arr.reduce((acc, current) => {
    const keyValue = typeof key === "function" ? key(current) : current[key];
    acc[keyValue] =
      acc[keyValue] !== undefined ? [...acc[keyValue], current] : [current];
    return acc;
  }, {} as any) as { [key: string]: T[] };
};

export const avg = (arr: number[]) =>
  arr.reduce((a, b) => a + b, 0) / arr.length;

// Uses Fisher-Yates (a.k.a. Knuth) shuffle.
// See: https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export const shuffle = <T>(arr: T[]) => {
  const shuffled = [...arr];

  let currentIdx = shuffled.length;
  while (currentIdx > 0) {
    const randomIdx = Math.floor(Math.random() * currentIdx);
    currentIdx -= 1;

    const temp = shuffled[currentIdx];
    shuffled[currentIdx] = shuffled[randomIdx];
    shuffled[randomIdx] = temp;
  }

  return shuffled;
};

export const userFullName = (user: ModelUser | undefined) =>
  user !== undefined ? `${user.firstName ?? ""} ${user.lastName ?? ""}` : "";

export const countHoursForUser = (
  user: ModelUser | undefined,
  shiftsForUser: (ModelShift | undefined)[] | undefined,
) => {
  if (user?.id === undefined) {
    return 0;
  }

  if (shiftsForUser === undefined) {
    return 0;
  }

  const durationTotalMinutes = shiftsForUser
    .map(s => (s?.durationMinutes ?? 0) - (s?.breakDurationMinutes ?? 0))
    .reduce((a, b) => a + b, 0);

  const durationHours = Math.floor(durationTotalMinutes / 60);
  const durationMinutes = durationTotalMinutes % 60;

  const minutesText =
    durationMinutes < 10 ? `0${durationMinutes}` : durationMinutes;

  return (
    String(durationHours) + (durationMinutes !== 0 ? `:${minutesText}` : "")
  );
};

export const beginningOfWeek = (d: Date) => {
  const dayOfWeek = d.getDay();

  // Sunday is the zeroth day (for some reason).
  const diff = dayOfWeek - (dayOfWeek === 0 ? -6 : 1);

  return new Date(d.getFullYear(), d.getMonth(), d.getDate() - diff);
};

/**
 * Convert date from local time zone to same time but in UTC, essentially clearing the
 * timezone.
 *
 * For example:
 * ```ts
 * localToUTC(new Date("2021-03-29T00:00:00+01:00")).toISOString()
 * // "2021-03-29T00:00:00.000Z"
 * ```
 * @param local Input datetime in local time.
 * @returns The same datetime with the timezone cleared to UTC, without changing the time.
 */
export const localToUTC = (local: Date) =>
  new Date(local.getTime() - local.getTimezoneOffset() * 60 * 1000);

export const endOfWeek = (d: Date) => {
  const weekBeginning = beginningOfWeek(d);
  return new Date(
    weekBeginning.getFullYear(),
    weekBeginning.getMonth(),
    weekBeginning.getDate() + 7,
  );
};

export enum Safety {
  NotApplicable,
  Safe,
  Risky,
  Unsafe,
}

/**
 * Calculate whether the specified user is safe, unsafe or risky based on their survey
 * history.
 * @param user The user to calculate the safety for.
 * @param surveys All of the surveys for the care home units (or just the surveys for
 *    the user, either works).
 * @param riskTimeoutMonths The number in months below which an unsafe survey
 *    rating causes the user to be classified as 'risky'. If not an integer, this will be
 *    rounded down to the closest month. Either this or the number-of-surveys based
 *    timeout are sufficient on their own to re-classify a carer as "safe". Default: 18.
 * @param riskTimeoutRequiredSurveyCount The number of surveys required to be completed
 *    by a user before they can be classified as safe after an unsafe survey is present.
 *    Either this or the time-based timeout are sufficient on their own to re-classify a
 *    carer as "safe". Default: 6.
 */
export const userSafety = (
  user: ModelUser,
  surveys: ModelSurvey[],
  riskTimeoutMonths = 18,
  riskTimeoutRequiredSurveyCount = 6,
) => {
  const userSurveys = surveys.filter(s => s.carerID === user.id);
  const numSurveys = userSurveys.length;

  if (numSurveys === 0) {
    return undefined;
  }

  const numUnsafeSurveys = userSurveys.filter(s => s.isSafe === false).length;
  const numSafeSurveys = userSurveys.filter(s => s.isSafe === true).length;

  if (numUnsafeSurveys === numSurveys) {
    return 0;
  }

  if (numSafeSurveys === numSurveys) {
    return 1;
  }

  const lastUnsafeSurveyTime = userSurveys
    .map(s => s.createdAt!.getTime())
    .sort((a, b) => b - a)[0];

  const timeoutDate = new Date();
  timeoutDate.setMonth(timeoutDate.getMonth() - riskTimeoutMonths);

  // If last unsafe survey was more than a certain number (default: 6) of months ago and
  // they've got a certain number of surveys marked clear, just count them as safe
  // anyway (reasoning: people can change!).
  const surveysCompletedSinceUnsafe = userSurveys.filter(
    s => s.createdAt!.getTime() > lastUnsafeSurveyTime,
  );

  if (
    lastUnsafeSurveyTime < timeoutDate.getTime() &&
    surveysCompletedSinceUnsafe.length >= riskTimeoutRequiredSurveyCount
  ) {
    return 1;
  }

  return numSafeSurveys / numSurveys;
};

const rangeOverlap = (left: [number, number], right: [number, number]) => {
  if (left[0] > right[1] || right[0] > left[1]) {
    return 0;
  }

  const overlapStart = Math.max(left[0], right[0]);
  const overlapEnd = Math.min(left[1], right[1]);

  return overlapEnd - overlapStart;
};

export const shiftsOverlap = (
  shifts: (ModelShift | undefined)[],
  dayOfWeek: DayOfWeek,
  preset: ModelShiftPreset,
) => {
  // In order to accurately take night shifts into account, we need to look at the
  // previous shift in case it runs into the next morning.
  // A shift flagged as "non-working" shouldn't be counted as it represents e.g. leave
  // of absence.
  const prevDayShift =
    shifts[dayOfWeek - 1]?.isWorking === true ? shifts[dayOfWeek] : undefined;
  const sameDayShift =
    shifts[dayOfWeek]?.isWorking === true ? shifts[dayOfWeek] : undefined;

  const prevDayShiftStart =
    prevDayShift?.startTime !== undefined
      ? prevDayShift.startTime.getHours() +
        prevDayShift.startTime.getMinutes() / 60 -
        24
      : undefined;

  const prevDayShiftEnd =
    prevDayShiftStart !== undefined &&
    prevDayShift?.durationMinutes !== undefined
      ? prevDayShiftStart + prevDayShift.durationMinutes / 60
      : undefined;

  const sameDayShiftStart =
    sameDayShift?.startTime !== undefined
      ? sameDayShift.startTime.getHours() +
        sameDayShift.startTime.getMinutes() / 60
      : undefined;

  const sameDayShiftEnd =
    sameDayShiftStart !== undefined &&
    sameDayShift?.durationMinutes !== undefined
      ? sameDayShiftStart + sameDayShift.durationMinutes / 60
      : undefined;

  const shiftStart = (preset.startHours ?? 0) + (preset.startMinutes ?? 0) / 60;
  const shiftEnd =
    shiftStart +
    (preset.durationHours ?? 0) +
    (preset.durationMinutes ?? 0) / 60;

  const prevDayOverlap =
    prevDayShiftStart !== undefined && prevDayShiftEnd !== undefined
      ? rangeOverlap(
          [shiftStart, shiftEnd],
          [prevDayShiftStart, prevDayShiftEnd],
        )
      : 0;

  const sameDayOverlap =
    sameDayShiftStart !== undefined && sameDayShiftEnd !== undefined
      ? rangeOverlap(
          [shiftStart, shiftEnd],
          [sameDayShiftStart, sameDayShiftEnd],
        )
      : 0;

  // Requiring that shift overlap covers at least 50% of the standard shift time.
  const requiredOverlapFraction = 0.5;

  const presetOverlapThreshold =
    requiredOverlapFraction *
    ((preset.durationHours ?? 0) + (preset.durationMinutes ?? 0) / 60);

  if (
    prevDayOverlap >= presetOverlapThreshold ||
    sameDayOverlap >= presetOverlapThreshold
  ) {
    return true;
  }

  return false;
};

export const getShiftPresetTimes = (
  shiftDay: Date,
  preset: ModelShiftPreset | undefined,
) => {
  if (preset === undefined) {
    return [null, null] as const;
  }

  const shiftStart = new Date(
    shiftDay.getFullYear(),
    shiftDay.getMonth(),
    shiftDay.getDate(),
  );
  shiftStart.setHours(preset.startHours ?? 0, preset.startMinutes ?? 0);

  const shiftEnd = new Date(
    shiftDay.getFullYear(),
    shiftDay.getMonth(),
    shiftDay.getDate(),
  );
  shiftEnd.setHours(
    shiftStart.getHours() + (preset.durationHours ?? 0),
    shiftStart.getMinutes() + (preset.durationMinutes ?? 0),
  );

  return [shiftStart, shiftEnd] as const;
};

// For display purposes, use 1 month = 30 days. This is in line with human-interval
// package, which is used to convert the other way from human readable to interval.
export const msInSec = 1000;
export const msInMinute = msInSec * 60;
export const msInHour = msInMinute * 60;
export const msInDay = msInHour * 24;
export const msInMonth = msInDay * 30;

export const intervalToHuman = (ms: number | undefined) => {
  if (ms === undefined || isNaN(ms)) {
    return "N/A";
  }

  if (ms === 0) {
    return "0 seconds";
  }

  const months = Math.floor(ms / msInMonth);
  const days = Math.floor((ms % msInMonth) / msInDay);
  const hours = Math.floor(((ms % msInMonth) % msInDay) / msInHour);
  const mins = Math.floor(
    (((ms % msInMonth) % msInDay) % msInHour) / msInMinute,
  );
  const secs =
    ((((ms % msInMonth) % msInDay) % msInHour) % msInMinute) / msInSec;

  const monthsText =
    months !== 0 ? `${months} month${months > 1 ? "s" : ""} ` : "";
  const daysText = days !== 0 ? `${days} day${days > 1 ? "s" : ""} ` : "";
  const hoursText = hours !== 0 ? `${hours} hour${hours > 1 ? "s" : ""} ` : "";
  const minsText = mins !== 0 ? `${mins} minute${mins > 1 ? "s" : ""} ` : "";
  const secsText = secs !== 0 ? `${secs} second${secs > 1 ? "s" : ""}` : "";

  const humanReadable = monthsText + daysText + hoursText + minsText + secsText;
  return humanReadable.trim();
};

/**
 * Convert millisecond interval to days, rounding to the nearest whole number.
 * @param ms Interval, in milliseconds, to convert to whole days.
 */
export const intervalToDays = (ms: number | undefined) => {
  if (ms === undefined || isNaN(ms)) {
    return undefined;
  }

  return Math.round(ms / msInDay);
};

// See: https://emailregex.com/
export const isEmailValid = (email: string) => {
  // eslint-disable-next-line max-len
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(email);
};
