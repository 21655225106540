import { useCallback, useEffect, useState } from "react";

import { ModelCareUnit } from "../api_client";
import { getCareUnitsAPI } from "../api_client";
import { AppDatabase, HandleAPIErrorFun, useAppContext } from "../helpers";
import { useAPIUpdatePeriod } from ".";

const getUnitInfo = async (
  careUnitId: number,
  setCareUnit: (newCareUnit: ModelCareUnit) => void,
  handleAPIError: HandleAPIErrorFun,
) => {
  try {
    const careUnit = await getCareUnitsAPI().getCareUnit({
      id: careUnitId,
    });

    const db = await AppDatabase.getInstance();
    if (db !== undefined) {
      await db.careUnits.put(careUnit, careUnit.id);
    }

    setCareUnit(careUnit);
  } catch (e) {
    handleAPIError(e, "retrieving care home unit info");
  }
};

export const useCareUnit = (careUnitId: number | undefined) => {
  const { handleAPIError } = useAppContext();

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [careUnit, setCareUnit] = useState<ModelCareUnit | undefined>(
    undefined,
  );
  const [setUpdatedFromAPI, isAPIUpdateDue] = useAPIUpdatePeriod(
    `care-unit-${careUnitId}`,
  );

  useEffect(() => {
    if (initialLoadComplete || careUnitId === undefined) {
      return;
    }

    const getCachedUnit = async () => {
      const db = await AppDatabase.getInstance();
      if (db === undefined) {
        return false;
      }

      const cachedUnit = await db.careUnits.get(careUnitId);
      setCareUnit(cachedUnit);
      return cachedUnit !== undefined;
    };

    getCachedUnit().then(async cachePresent => {
      if (!cachePresent || isAPIUpdateDue()) {
        await getUnitInfo(careUnitId, setCareUnit, handleAPIError);
      }

      setInitialLoadComplete(true);
      setIsLoading(false);
      setUpdatedFromAPI();
    });
  }, [
    careUnitId,
    handleAPIError,
    initialLoadComplete,
    isAPIUpdateDue,
    setUpdatedFromAPI,
  ]);

  const refreshCareUnit = useCallback(async () => {
    if (careUnitId === undefined) {
      return;
    }

    setIsLoading(true);
    await getUnitInfo(careUnitId, setCareUnit, handleAPIError);
    setIsLoading(false);
    setUpdatedFromAPI();
  }, [careUnitId, handleAPIError, setUpdatedFromAPI]);

  return [careUnit, setCareUnit, isLoading, refreshCareUnit] as const;
};
