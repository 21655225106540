import {
  Card,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";

import { ModelSurvey } from "../../api_client";
import { Constants } from "../../helpers";

const discFullNames: { [short: string]: string } = {
  D: "Dominance",
  i: "Influence",
  S: "Steadiness",
  C: "Conscientiousness",
};

const discMin = 0;
const lowGreyThreshold = 17;
const highGreyThreshold = 120;
const discMax = 130;

const greyZoneColour = "#ff7300";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    graphTitle: {
      width: "100%",
      textAlign: "center",
      marginLeft: 25,
    },
    chartContainer: {
      flexGrow: 1,
      display: "flex",
    },
    minMaxContainer: {
      display: "flex",
      alignItems: "end",
      marginBottom: 40,
    },
    minMaxCircle: {
      width: 50,
      height: 50,
      overflow: "hidden",
      borderRadius: "50%",
      border: "2px solid rgba(0, 0, 0, 0.25)",
      position: "relative",

      "&::after": {
        content: "''",
        position: "absolute",
        top: "calc(50% - 2px)",
        width: "100%",
        height: 2,
        background: "rgba(0, 0, 0, 0.25)",
      },
    },
    minMaxCircleValue: {
      width: "100%",
      height: "50%",
      textAlign: "center",
      fontWeight: "bold",
    },
    minMaxCircleMax: {
      background: Constants.palette.green,
    },
    minMaxCircleMin: {
      background: Constants.palette.blue,
    },
    tooltip: {
      minWidth: 52,
    },
    tooltipContent: {
      padding: theme.spacing(2),
    },
  }),
);

const CustomTooltip: React.FC<TooltipProps> = ({ payload, label }) => {
  const classes = useStyles();

  const graphValue = payload?.find(datum => datum.dataKey === "value");

  return (
    <Card className={classes.tooltip}>
      <div className={classes.tooltipContent}>
        <Typography variant="h5">
          <div className="recharts-tooltip-label">
            {label && discFullNames[label]}:{" "}
            {graphValue && (
              <span style={{ color: graphValue.color }}>
                {graphValue.payload.original}
              </span>
            )}
          </div>
        </Typography>
      </div>
    </Card>
  );
};

interface DISCGraphProps {
  data: ModelSurvey;
  title: string;
  graphIndex: number;
}

export const DISCGraph: React.FC<DISCGraphProps> = ({
  data,
  title,
  graphIndex,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const tickStyle = {
    stroke: theme.palette.text.primary,
    fill: theme.palette.text.primary,
    fontSize: theme.typography.h6.fontSize,
  };

  const colours = [
    Constants.palette.red,
    Constants.palette.green,
    Constants.palette.blue,
  ];

  const discValues = [data.graph1DISC, data.graph2DISC, data.graph3DISC][
    graphIndex
  ];

  const discData = [
    {
      label: "D",
      original: discValues?.rawDominance,
      value: discValues?.scaledDominance!,
      discMin,
      lowGreyThreshold,
      highGreyThreshold,
      discMax,
    },
    {
      label: "i",
      original: discValues?.rawInfluence,
      value: discValues?.scaledInfluence!,
      discMin,
      lowGreyThreshold,
      highGreyThreshold,
      discMax,
    },
    {
      label: "S",
      original: discValues?.rawSteadiness,
      value: discValues?.scaledSteadiness!,
      discMin,
      lowGreyThreshold,
      highGreyThreshold,
      discMax,
    },
    {
      label: "C",
      original: discValues?.rawConscientiousness,
      value: discValues?.scaledConscientiousness!,
      discMin,
      lowGreyThreshold,
      highGreyThreshold,
      discMax,
    },
  ];

  const sortedDISCData = [...discData].sort((a, b) => a.value - b.value);

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.graphTitle}>
        {title}
      </Typography>

      <div className={classes.chartContainer}>
        <span className={classes.minMaxContainer}>
          <div className={classes.minMaxCircle}>
            <div
              className={clsx(
                classes.minMaxCircleValue,
                classes.minMaxCircleMax,
              )}
            >
              {sortedDISCData[3].label}
            </div>
            <div
              className={clsx(
                classes.minMaxCircleValue,
                classes.minMaxCircleMin,
              )}
            >
              {sortedDISCData[0].label}
            </div>
          </div>
        </span>

        <ResponsiveContainer>
          <ComposedChart
            data={discData}
            margin={{
              top: 10,
              right: 30,
              left: -30,
              bottom: 10,
            }}
          >
            <Area
              dataKey="discMax"
              fill={greyZoneColour}
              stroke="none"
              isAnimationActive={false}
              label={false}
              dot={false}
              activeDot={false}
              legendType="none"
            />
            <Area
              dataKey="highGreyThreshold"
              fill={theme.palette.background.paper}
              stroke="none"
              isAnimationActive={false}
              fillOpacity={1}
              label={false}
              dot={false}
              activeDot={false}
              legendType="none"
            />

            <Area
              dataKey="lowGreyThreshold"
              fill={greyZoneColour}
              stroke="none"
              isAnimationActive={false}
              label={false}
              dot={false}
              activeDot={false}
              legendType="none"
            />

            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" tick={tickStyle} />
            <YAxis
              type="number"
              domain={[discMin, discMax]}
              interval={0}
              ticks={[discMin, lowGreyThreshold, highGreyThreshold, discMax]}
              tickFormatter={() => ""}
            />
            <ZAxis range={[200, 200]} />
            <Tooltip
              labelStyle={{ color: "black" }}
              content={<CustomTooltip />}
            />

            <Scatter
              name={`Graph ${graphIndex + 1}`}
              dataKey="value"
              fill={colours[graphIndex % colours.length]}
              line
              strokeWidth={5}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
