import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React from "react";

import { ModelSurvey } from "../api_client";
import {
  CareUnitNCASGraph,
  CarerContinuityGraph,
  RedShiftCountGraph,
  SafetyTrendGraph,
  SurveyTriangle,
} from "./Graphs";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {},
    cardGraph: {
      height: 400,
    },
  }),
);

interface CareUnitOverviewProps {
  careUnitId?: number;
  surveys: ModelSurvey[];
}

export const CareUnitOverview: React.FC<CareUnitOverviewProps> = ({
  careUnitId: _careUnitId,
  surveys,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title="3D Carer Survey" />
            <CardContent className={classes.cardGraph}>
              <SurveyTriangle surveys={surveys} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title="Group Safety Trend" />
            <CardContent className={classes.cardGraph}>
              <SafetyTrendGraph surveys={surveys} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title="Red Shift Count" />
            <CardContent className={classes.cardGraph}>
              <RedShiftCountGraph surveys={surveys} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title="Carer Advocacy Rating" />
            <CardContent className={classes.cardGraph}>
              <CareUnitNCASGraph surveys={surveys} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title="Carer Continuity" />
            <CardContent className={classes.cardGraph}>
              <CarerContinuityGraph surveys={surveys} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
