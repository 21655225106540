/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ModelAdjectiveQuestion,
  ModelAdjectiveQuestionFromJSON,
  ModelAdjectiveQuestionFromJSONTyped,
  ModelAdjectiveQuestionToJSON,
  ModelFreeTextQuestion,
  ModelFreeTextQuestionFromJSON,
  ModelFreeTextQuestionFromJSONTyped,
  ModelFreeTextQuestionToJSON,
  ModelRatingQuestion,
  ModelRatingQuestionFromJSON,
  ModelRatingQuestionFromJSONTyped,
  ModelRatingQuestionToJSON,
} from "./";

/**
 *
 * @export
 * @interface ModelQuestion
 */
export interface ModelQuestion {
  /**
   *
   * @type {ModelAdjectiveQuestion}
   * @memberof ModelQuestion
   */
  adjective?: ModelAdjectiveQuestion;
  /**
   *
   * @type {ModelFreeTextQuestion}
   * @memberof ModelQuestion
   */
  freeText?: ModelFreeTextQuestion;
  /**
   *
   * @type {ModelRatingQuestion}
   * @memberof ModelQuestion
   */
  rating?: ModelRatingQuestion;
  /**
   *
   * @type {string}
   * @memberof ModelQuestion
   */
  type?: ModelQuestionTypeEnum;
}

export function ModelQuestionFromJSON(json: any): ModelQuestion {
  return ModelQuestionFromJSONTyped(json, false);
}

export function ModelQuestionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ModelQuestion {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    adjective: !exists(json, "adjective")
      ? undefined
      : ModelAdjectiveQuestionFromJSON(json["adjective"]),
    freeText: !exists(json, "freeText")
      ? undefined
      : ModelFreeTextQuestionFromJSON(json["freeText"]),
    rating: !exists(json, "rating")
      ? undefined
      : ModelRatingQuestionFromJSON(json["rating"]),
    type: !exists(json, "type") ? undefined : json["type"],
  };
}

export function ModelQuestionToJSON(value?: ModelQuestion | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    adjective: ModelAdjectiveQuestionToJSON(value.adjective),
    freeText: ModelFreeTextQuestionToJSON(value.freeText),
    rating: ModelRatingQuestionToJSON(value.rating),
    type: value.type,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ModelQuestionTypeEnum {
  Adjective = "adjective",
  Freetext = "freetext",
  Rating = "rating",
}
