import {
  ThemeProvider,
  createMuiTheme,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";
import { HashRouter as Router } from "react-router-dom";

import { AppContextProvider, useCreateAppContext } from "../helpers/AppContext";
import { Constants } from "../helpers/Constants";

export const AppProviders: React.FC = ({ children }) => {
  const appContext = useCreateAppContext();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            main: Constants.palette.green,
          },
          secondary: {
            main: Constants.palette.orange,
          },
        },
        mixins: {
          toolbar: {
            minHeight: 128,
          },
        },
      }),
    [prefersDarkMode],
  );

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AppContextProvider value={appContext}>{children}</AppContextProvider>
      </ThemeProvider>
    </Router>
  );
};
