import { Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

import { ModelUserRoleEnum } from "../api_client";
import { PageContainer, UsersTable } from "../components";
import { Constants, useAppContext } from "../helpers";
import { Breadcrumb, useAdmins, useBreadcrumbs } from "../hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      marginTop: theme.spacing(4),
    },
  }),
);

export const Admins: React.FC = () => {
  const { user } = useAppContext();
  const classes = useStyles();
  const history = useHistory();

  useBreadcrumbs(Breadcrumb.Admins);

  const [admins, setAdmins, isLoading, refreshData] = useAdmins();

  const hasPermission = user?.role === ModelUserRoleEnum.Administrator;
  if (!hasPermission) {
    history.push(Constants.paths.permissionDenied);
    return null;
  }

  return (
    <PageContainer>
      <Typography variant="h4" component="h4">
        Admins
      </Typography>

      <div className={classes.content}>
        <UsersTable
          title="Admins"
          isLoading={isLoading}
          users={admins}
          setUsers={setAdmins}
          refreshData={refreshData}
          userRoles="admins"
        />
      </div>
    </PageContainer>
  );
};
