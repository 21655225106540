import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Add as AddIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { ModelRota, ModelShift } from "../../api_client";
import { ModelShiftPreset } from "../../api_client/models/ModelShiftPreset";
import { Colour, getShiftPresetTimes, msInMinute } from "../../helpers";
import { DayOfWeek } from "./RotaTable";

const dateUtils = new DateFnsUtils();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    shiftTimeContainer: {
      display: "flex",
    },
    shiftTimeRadio: {
      flexGrow: 1,
    },
    manualControlsRow: {
      display: "flex",
      "& > :not(:first-child)": {
        marginLeft: theme.spacing(1),
      },
    },
    timePicker: {
      display: "block",
    },
    shiftButton: {
      fontSize: "0.7rem",
      textTransform: "none",
      width: "100%",
      height: "100%",
      transition: "opacity ease-in-out 100ms",
      opacity: 0.8,
      "&:hover, &:focus": {
        opacity: 1,
      },
    },
    shiftRadioLabel: {
      fontSize: "0.8rem",
      width: 100,
      height: 75,
    },
    emptyShift: {
      opacity: 0,
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    headerTitle: {
      verticalAlign: "sub",
    },
    actions: {
      "& > :nth-child(2)": {
        marginLeft: "auto",
      },
    },
    submitWrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    submitProgress: {
      color: theme.palette.success.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

const getPresetForShift = (
  shift: ModelShift | undefined,
  presets: ModelShiftPreset[],
) => {
  if (
    shift === undefined ||
    shift.startTime === undefined ||
    shift.durationMinutes === undefined
  ) {
    return undefined;
  }

  return presets.find(p => {
    const shiftStart = shift.startTime;
    if (shiftStart === undefined) {
      return false;
    }

    if (
      p.startHours !== shiftStart.getHours() ||
      p.startMinutes !== shiftStart.getMinutes()
    ) {
      return false;
    }

    // We can't just compare durations here because shifts can go over time zone
    // changes, in which case they will be 1 hour shorter or longer than the preset.
    // e.g. if you are working an 8 pm to 7 am shift over a GMT+0 to GMT+1 change, the
    // shift will actually last for 12 hours instead of 13 hours, but we still want it
    // to display as the 8-7 shift preset.
    const presetEnd = new Date(shiftStart);
    presetEnd.setHours(shiftStart.getHours() + (p.durationHours ?? 0));
    presetEnd.setMinutes(shiftStart.getMinutes() + (p.durationMinutes ?? 0));

    const presetDurationMinutes =
      (presetEnd.getTime() - shiftStart.getTime()) / msInMinute;

    return presetDurationMinutes === shift.durationMinutes;
  });
};

const formatShiftTime = (shift: ModelShift, presets: ModelShiftPreset[]) => {
  if (shift.startTime === undefined || shift.durationMinutes === undefined) {
    return null;
  }

  const endTime = new Date(shift.startTime);
  endTime.setMinutes(shift.startTime.getMinutes() + shift.durationMinutes);

  const startText = dateUtils.format(shift.startTime, "h:mm aaaa");
  const endText = dateUtils.format(endTime, "h:mm aaaa");

  const preset = getPresetForShift(shift, presets);

  const periodLabel = `${startText} - ${endText}`;

  if (preset !== undefined) {
    return (
      <>
        {preset.nameLabel}
        <br />({periodLabel})
      </>
    );
  }

  return periodLabel;
};

const customColour = "#ffd191";

interface ShiftButtonProps {
  shift?: ModelShift;
  rota?: ModelRota;
  dayOfWeek: DayOfWeek;
  userId: number | undefined;
  onSubmit: (newShift: ModelShift) => void;
  onDelete: (shift: ModelShift) => void;
  shiftPresets: ModelShiftPreset[];
  onNavigateToConfigureShifts: () => void;
}

export const ShiftButton: React.FC<ShiftButtonProps> = ({
  shift,
  rota,
  userId,
  dayOfWeek,
  onSubmit,
  onDelete,
  shiftPresets,
  onNavigateToConfigureShifts,
}) => {
  const classes = useStyles();

  const shiftDay = new Date(
    rota?.startDate?.getFullYear() ?? 0,
    rota?.startDate?.getMonth() ?? 0,
    (rota?.startDate?.getDate() ?? 0) + dayOfWeek,
  );

  // This is the option for the shift as it currently stands from the API, as opposed to
  // our local modification.
  const currentPreset = getPresetForShift(shift, shiftPresets);

  const [defaultStartTime, defaultEndTime] = getShiftPresetTimes(
    shiftDay,
    currentPreset ?? shiftPresets[0],
  );
  const defaultBreakDuration = shift?.breakDurationMinutes ?? 0;
  const defaultIsWorking = shift?.isWorking ?? true;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [shiftTimeRadio, setShiftTimeRadio] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startTime, setStartTime] = useState<Date | null>(defaultStartTime);
  const [endTime, setEndTime] = useState<Date | null>(defaultEndTime);
  const [breakDurationMinutes, setBreakDurationMinutes] = useState(
    defaultBreakDuration,
  );
  const [isWorking, setIsWorking] = useState(defaultIsWorking);

  const shiftStartTime = defaultStartTime?.getTime();
  const shiftEndTime = defaultEndTime?.getTime();
  const currentPresetId = currentPreset?.id;

  useEffect(() => {
    setBreakDurationMinutes(defaultBreakDuration);
    setIsWorking(defaultIsWorking);
    setStartTime(new Date(shiftStartTime ?? 0));
    setEndTime(new Date(shiftEndTime ?? 0));

    const shiftRadio =
      currentPresetId !== undefined
        ? String(currentPresetId)
        : shiftPresets[0]?.id !== undefined
        ? String(shiftPresets[0].id)
        : null;
    setShiftTimeRadio(shiftRadio);
  }, [
    shiftPresets,
    shiftStartTime,
    shiftEndTime,
    defaultBreakDuration,
    currentPresetId,
    defaultIsWorking,
  ]);

  const buttonBackgroundColour =
    shift &&
    (currentPreset !== undefined ? currentPreset.colour : customColour);
  const buttonTextColour =
    buttonBackgroundColour && new Colour(buttonBackgroundColour).fgColour();

  const submitShift = async () => {
    if (
      rota?.id === undefined ||
      userId === undefined ||
      startTime === null ||
      endTime === null
    ) {
      setDialogOpen(false);
      return;
    }

    if (endTime.getTime() < startTime.getTime()) {
      // If endTime is before startTime, that means it must refer to the next day, so
      // adjust accordingly. This is the case when specifying night shifts.
      endTime.setDate(endTime.getDate() + 1);
    }

    const durationMinutes =
      (endTime.getTime() - startTime.getTime()) / 1000 / 60;

    const newShift = {
      id: shift?.id,
      userID: userId,
      rotaID: rota.id,
      startTime,
      durationMinutes,
      breakDurationMinutes,
      isWorking,
    } as ModelShift;

    setIsSubmitting(true);
    await onSubmit(newShift);
    setIsSubmitting(false);
    setDialogOpen(false);
  };

  const deleteShift = async () => {
    if (shift?.id === undefined) {
      return;
    }

    await onDelete(shift);
    setDialogOpen(false);
  };

  const updateShiftRadio = (presetId: string) => {
    setShiftTimeRadio(presetId);

    const preset = shiftPresets.find(p => p.id === Number(presetId));

    setIsWorking(preset?.isWorking ?? true);

    const [chosenStartTime, chosenEndTime] = getShiftPresetTimes(
      shiftDay,
      preset,
    );

    setStartTime(chosenStartTime);
    setEndTime(chosenEndTime);
  };

  return (
    <>
      <Button
        onClick={() => setDialogOpen(true)}
        className={clsx(classes.shiftButton, {
          [classes.emptyShift]: shift === undefined,
        })}
        style={{
          backgroundColor: buttonBackgroundColour,
          color: buttonTextColour,
        }}
      >
        {shift !== undefined ? (
          formatShiftTime(shift, shiftPresets)
        ) : (
          <AddIcon />
        )}
      </Button>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="add-shift-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          id="add-shift-dialog-title"
          disableTypography
          className={classes.header}
        >
          <Typography variant="h6">
            <span className={classes.headerTitle}>
              {shift !== undefined ? "Modify" : "Add"} shift
            </span>
          </Typography>

          <div>
            {shift && (
              <Tooltip title="Delete shift">
                <IconButton aria-label="delete shift" onClick={deleteShift}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </DialogTitle>

        <DialogContent>
          <div className={classes.shiftTimeContainer}>
            <FormControl
              component="fieldset"
              className={classes.shiftTimeRadio}
            >
              <FormLabel component="legend">Shift times</FormLabel>
              <RadioGroup
                row
                aria-label="shift times"
                value={shiftTimeRadio}
                onChange={event => updateShiftRadio(event.target.value)}
              >
                {shiftPresets.map((preset, i) => (
                  <FormControlLabel
                    key={i}
                    value={String(preset.id)}
                    control={<Radio />}
                    label={
                      <ShiftLabel
                        preset={preset}
                        onSelect={() => updateShiftRadio(String(preset.id))}
                      />
                    }
                    labelPlacement="bottom"
                  />
                ))}
                <FormControlLabel
                  value={String(-1)}
                  control={<Radio />}
                  label={
                    <ShiftLabel onSelect={() => updateShiftRadio(String(-1))} />
                  }
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div className={classes.manualControlsRow}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TimePicker
                className={classes.timePicker}
                disabled={Number(shiftTimeRadio) !== -1}
                margin="normal"
                autoOk
                ampm={false}
                label="Shift start"
                value={startTime}
                onChange={selectedTime => {
                  if (selectedTime === null) {
                    setStartTime(null);
                    return;
                  }

                  const chosenTime = new Date(shiftDay);
                  chosenTime.setHours(
                    selectedTime.getHours(),
                    selectedTime.getMinutes(),
                  );
                  setStartTime(chosenTime);
                }}
              />

              <TimePicker
                className={classes.timePicker}
                disabled={Number(shiftTimeRadio) !== -1}
                margin="normal"
                label="Shift end"
                autoOk
                ampm={false}
                value={endTime}
                onChange={selectedTime => {
                  if (selectedTime === null) {
                    setEndTime(null);
                    return;
                  }

                  const chosenTime = new Date(shiftDay);
                  chosenTime.setHours(
                    selectedTime.getHours(),
                    selectedTime.getMinutes(),
                  );

                  setEndTime(chosenTime);
                }}
              />
            </MuiPickersUtilsProvider>

            <FormControl margin="normal">
              <TextField
                value={breakDurationMinutes}
                label="Break duration"
                type="number"
                onChange={event =>
                  setBreakDurationMinutes(Number(event.target.value))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Minutes</InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
              />
            </FormControl>
          </div>

          <div className={classes.manualControlsRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isWorking}
                  onChange={event => setIsWorking(event.target.checked)}
                />
              }
              label="Is working shift?"
            />
          </div>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button onClick={onNavigateToConfigureShifts} color="primary">
            Configure shift presets
          </Button>

          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>

          <div className={classes.submitWrapper}>
            <Button
              color="primary"
              disabled={isSubmitting}
              onClick={submitShift}
            >
              Submit
            </Button>
            {isSubmitting && (
              <CircularProgress size={24} className={classes.submitProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface ShiftLabelProps {
  preset?: ModelShiftPreset;
  onSelect: () => void;
}

const ShiftLabel: React.FC<ShiftLabelProps> = ({ preset, onSelect }) => {
  const classes = useStyles();

  const bgColour = preset?.colour !== undefined ? preset.colour : customColour;
  const fgColour = new Colour(bgColour).fgColour();

  if (preset === undefined) {
    return (
      <Button
        onClick={onSelect}
        className={clsx(classes.shiftButton, classes.shiftRadioLabel)}
        style={{
          backgroundColor: bgColour,
          color: fgColour,
        }}
      >
        Custom
      </Button>
    );
  }

  // We don't use current time because if we're sitting on border of time zone change,
  // that'll make the calculations incorrect.
  const startTime = new Date(0);
  startTime.setHours(preset.startHours ?? 0);
  startTime.setMinutes(preset.startMinutes ?? 0);

  const endTime = new Date(startTime);
  endTime.setHours(endTime.getHours() + (preset.durationHours ?? 0));
  endTime.setMinutes(endTime.getMinutes() + (preset.durationMinutes ?? 0));

  const periodLabel = `${preset.startHours}${
    preset.startMinutes !== 0 ? ":" + preset.startMinutes : ""
  } - ${endTime.getHours()}${
    endTime.getMinutes() !== 0 ? ":" + endTime.getMinutes() : ""
  }`;

  return (
    <Button
      onClick={onSelect}
      className={clsx(classes.shiftButton, classes.shiftRadioLabel)}
      style={{
        backgroundColor: bgColour,
        color: fgColour,
      }}
    >
      {preset !== undefined ? (
        <>
          {preset.nameLabel}
          <br />({periodLabel})
        </>
      ) : (
        "Custom"
      )}
    </Button>
  );
};
