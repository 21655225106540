import MaterialTable, { Action, Column } from "material-table";
import React from "react";

import {
  ModelCareUnit,
  ModelSurvey,
  ModelUser,
  ModelUserRoleEnum,
  ModelUserStatusEnum,
  ViewmodelAddAdmin,
  ViewmodelAddEmployee,
  ViewmodelAddEmployeeRoleEnum,
} from "../api_client";
import { getUsersAPI } from "../api_client";
import {
  AppDatabase,
  nextSurveyDue,
  useAppContext,
  userFullName,
} from "../helpers";
import { SurveyIcon } from ".";

// See: https://emailregex.com/
const emailRegex = new RegExp(
  /* eslint-disable-next-line max-len */
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

interface UsersTableProps {
  title?: string;
  isLoading: boolean;
  users: ModelUser[];
  setUsers: React.Dispatch<React.SetStateAction<ModelUser[]>>;
  userRoles: "employees" | "admins";
  refreshData: () => void;
  careUnitId?: number;
  careUnit?: ModelCareUnit;
  surveys?: ModelSurvey[];
  onRowClick?: (user?: ModelUser) => void;
  navigateToCompleteSurvey?: (UserId: number, userFullName: string) => void;
}

interface TableData extends ModelUser {
  nextSurveyDue?: Date;
  fullName: string;
}

export const UsersTable: React.FC<UsersTableProps> = ({
  title,
  isLoading,
  users,
  setUsers,
  userRoles,
  refreshData,
  careUnitId,
  careUnit,
  surveys,
  onRowClick,
  navigateToCompleteSurvey,
}) => {
  const appContext = useAppContext();
  const { handleAPIError, showSnackbar } = appContext;
  const [appUser, setAppUser] = [appContext.user, appContext.setUser];

  const data =
    surveys !== undefined
      ? users.map(u => ({
          ...u,
          nextSurveyDue: nextSurveyDue(
            u,
            surveys.filter(s => s.carerID === u.id),
            careUnit?.carerSurveyFrequencyDays,
            careUnit?.managerSurveyFrequencyDays,
          ),
          fullName: userFullName(u),
        }))
      : users.map(u => ({ ...u, fullName: userFullName(u) }));

  const tableActions: (
    | Action<TableData>
    | ((rowData: TableData) => Action<TableData>)
  )[] = [
    {
      icon: "refresh",
      isFreeAction: true,
      tooltip: "Refresh data",
      onClick: refreshData,
    },
  ];

  if (navigateToCompleteSurvey !== undefined) {
    tableActions.push({
      icon: SurveyIcon,
      tooltip: "Complete survey as carer",
      onClick: (_event, rowData) => {
        if (rowData instanceof Array || rowData.id === undefined) {
          return;
        }

        navigateToCompleteSurvey(rowData.id, userFullName(rowData));
      },
    });
  }

  const columns: Column<TableData>[] = [
    // We want to be able to search using a user's full name, e.g. "Daisy
    // Williamson", which doesn't work if they are two separate fields. Hence, we
    // use a custom search function to search against the full name.
    {
      title: "First name",
      field: "firstName",
      searchable: false,
      filterPlaceholder: "Filter by first name",
    },
    {
      title: "Last name",
      field: "lastName",
      customFilterAndSearch: (filter, rowData) =>
        rowData.fullName.trim() !== "" &&
        rowData.fullName.toUpperCase().includes(filter.toUpperCase()),
      filterPlaceholder: "Filter by last name",
    },
    {
      title: "Email",
      field: "email",
      validate: rowData =>
        emailRegex.test(rowData.email ?? "") || {
          isValid: false,
          helperText: "Invalid email address",
        },
      filterPlaceholder: "Filter by email",
    },
  ];

  if (userRoles === "employees") {
    columns.push(
      {
        title: "Start Date",
        field: "startDate",
        type: "date",
        initialEditValue: new Date(),
        filterPlaceholder: "Filter by start date",
      },
      {
        title: "End Date",
        field: "endDate",
        type: "date",
        emptyValue: "N/A",
        filterPlaceholder: "Filter by end date",
      },
      {
        title: "Next Survey Due",
        type: "date",
        emptyValue: "N/A",
        field: "nextSurveyDue",
        editable: "never",
        filterPlaceholder: "Filter by next survey due",
        // "undefined" means that user doesn't need to complete survey so they should
        // sort after the "most recent" (i.e. larger value).
        customSort: (leftRow, rightRow) => {
          const a = leftRow.nextSurveyDue;
          const b = rightRow.nextSurveyDue;

          if (a === undefined) {
            return 1;
          }

          if (b === undefined) {
            return -1;
          }

          return a.getTime() - b.getTime();
        },
      },
      {
        title: "Is Supernumerary?",
        field: "isSupernumerary",
        type: "boolean",
      },
      {
        title: "Role",
        field: "role",
        lookup: {
          [ModelUserRoleEnum.Carer]: "Carer",
          [ModelUserRoleEnum.Manager]: "Manager",
          // Purposefuly leave out 'administrator' - they shouldn't appear here or be
          // option for dropdown.
        },
        initialEditValue: ModelUserRoleEnum.Carer,
        filterPlaceholder: "Filter by role",
      },
      {
        title: "Status",
        field: "status",
        lookup: {
          [ModelUserStatusEnum.ActiveEmployee]: "Active",
          [ModelUserStatusEnum.Applicant]: "Applicant",
          [ModelUserStatusEnum.Banked]: "Banked",
          [ModelUserStatusEnum.Left]: "Left",
          [ModelUserStatusEnum.NotApplicable]: "N/A",
        },
        initialEditValue: ModelUserStatusEnum.ActiveEmployee,
        filterPlaceholder: "Filter by status",
      },
    );
  }

  return (
    <MaterialTable
      isLoading={isLoading}
      title={title ?? "Users"}
      columns={columns}
      data={data}
      onRowClick={
        onRowClick !== undefined
          ? (_event, rowData) => onRowClick(rowData)
          : undefined
      }
      options={{
        exportButton: true,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50],
        filtering: true,
        actionsColumnIndex: -1,
        addRowPosition: "first",
      }}
      actions={tableActions}
      editable={{
        onRowAdd: async newUser => {
          if (careUnitId === undefined) {
            newUser.role = ModelUserRoleEnum.Administrator;
          }

          const db = await AppDatabase.getInstance();
          const api = getUsersAPI();
          let addedUser: ModelUser | undefined;

          const userToAdd = {
            ...newUser,
            careUnitID: careUnitId,
          };

          try {
            switch (newUser.role) {
              case ModelUserRoleEnum.Carer: {
                const carer = {
                  ...userToAdd,
                  role: ViewmodelAddEmployeeRoleEnum.Carer,
                } as ViewmodelAddEmployee;
                addedUser = await api.addEmployee({ employee: carer });
                break;
              }
              case ModelUserRoleEnum.Manager: {
                const manager = {
                  ...userToAdd,
                  role: ViewmodelAddEmployeeRoleEnum.Manager,
                } as ViewmodelAddEmployee;
                addedUser = await api.addEmployee({ employee: manager });
                break;
              }
              case ModelUserRoleEnum.Administrator: {
                const admin = {
                  ...userToAdd,
                } as ViewmodelAddAdmin;
                addedUser = await api.addAdmin({ admin });
                break;
              }
              default:
                break;
            }

            if (addedUser !== undefined) {
              if (db !== undefined) {
                await db.users.add(addedUser, addedUser.id);
              }
              setUsers(current => {
                return addedUser !== undefined
                  ? [...current, addedUser]
                  : current;
              });
              if (db !== undefined) {
                await db.updateUnitStats(careUnitId);
              }
              showSnackbar("Successfully added user.", "success");
            }
          } catch (err) {
            handleAPIError(err, "adding new user");
            // Reject promise to cancel "Add" operation.
            throw new Error("Unable to add user");
          }
        },
        onRowUpdate: async (newUserInfo, oldUserInfo) => {
          if (newUserInfo.id === undefined) {
            const message = "Cannot update user without an ID.";
            handleAPIError(message, "error");
            throw new Error(message);
          }

          const db = await AppDatabase.getInstance();

          const updatedUser = {
            ...newUserInfo,
            careUnitID: careUnitId,
            createdAt:
              newUserInfo.createdAt !== undefined
                ? new Date(newUserInfo.createdAt)
                : undefined,
            startDate:
              newUserInfo.startDate !== undefined
                ? new Date(newUserInfo.startDate)
                : undefined,
          } as ModelUser;

          try {
            await getUsersAPI().updateUser({
              id: newUserInfo.id,
              user: updatedUser,
            });

            if (oldUserInfo === undefined) {
              return;
            }

            if (db !== undefined) {
              await db.users.put(updatedUser, newUserInfo.id);
            }
            setUsers(oldUsers => {
              const newUsers = [...oldUsers];
              const index = oldUsers.map(u => u.id).indexOf(oldUserInfo.id);

              if (index !== -1) {
                newUsers[index] = updatedUser;
              }

              return newUsers;
            });

            showSnackbar("Successfully updated user.", "success");

            if (updatedUser.id === appUser?.id) {
              setAppUser(updatedUser);
            }
          } catch (err) {
            handleAPIError(err, "updating user");
            // Throw error to reject "Update" operation.
            throw new Error("Unable to update user");
          }
        },
        onRowDelete: async userToDelete => {
          const userId = userToDelete.id;
          if (userId === undefined) {
            const message = "Cannot delete user without an ID.";
            showSnackbar(message, "error");
            throw new Error(message);
          }

          if (userId === appUser?.id) {
            const message = "Cannot delete user currently logged in.";
            showSnackbar(message, "error");
            throw new Error(message);
          }

          const db = await AppDatabase.getInstance();

          try {
            await getUsersAPI().deleteUser({ id: userId });

            setUsers(current => current.filter(u => u.id !== userId));

            if (db !== undefined) {
              await db.users.delete(userId);

              const surveysToDelete = await db.surveys
                .filter(s => s.carerID === userId)
                .toArray();
              const surveyIdsToDelete = surveysToDelete
                .map(s => s.id)
                .filter(id => id !== undefined) as number[];

              const shiftsToDelete = await db.shifts
                .filter(s => s.userID === userId)
                .toArray();
              const shiftIdsToDelete = shiftsToDelete
                .map(s => s.id)
                .filter(id => id !== undefined) as number[];

              await db.surveys.bulkDelete(surveyIdsToDelete);
              await db.shifts.bulkDelete(shiftIdsToDelete);

              await db.updateUnitStats(careUnitId);
            }

            showSnackbar(`Successfully deleted user.`, "success");
          } catch (err) {
            handleAPIError(err, "removing user");
            // Throw error to reject "Delete" operation.
            throw new Error("Unable to delete user");
          }
        },
      }}
    />
  );
};
