import { Paper, Tab, Theme, createStyles, makeStyles } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ModelSurvey, ModelUserRoleEnum } from "../api_client";
import {
  PageContainer,
  SurveyDialog,
  SurveysTable,
  UserOverview,
} from "../components";
import { Constants, urlParamToModel, useAppContext } from "../helpers";
import {
  Breadcrumb,
  useBreadcrumbs,
  useCareUnit,
  useSurveys,
  useUser,
} from "../hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    tabBar: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    table: {
      flex: "1 0 auto",
    },
    pageContainerFullscreen: {
      //
    },
  }),
);

interface UnitUserParams {
  orgInfo?: string;
  unitInfo?: string;
  userInfo?: string;
}

export const UnitUser: React.FC = () => {
  const classes = useStyles();
  const params = useParams<UnitUserParams>();
  const appUser = useAppContext().user;
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState("0");

  const [unitId] = urlParamToModel(params.unitInfo);
  const [userId] = urlParamToModel(params.userInfo);

  const [unitUser] = useUser(userId);
  const [surveys, setSurveys, areSurveysLoading, refreshSurveys] = useSurveys(
    userId,
  );
  const [surveyDialogOpen, setSurveyDialogOpen] = useState(false);
  const [activeSurvey, setActiveSurvey] = useState<ModelSurvey | undefined>(
    undefined,
  );
  const [careUnit] = useCareUnit(unitId);

  useBreadcrumbs(
    Breadcrumb.Organisations,
    Breadcrumb.Organisation,
    Breadcrumb.OrganisationUnit,
    Breadcrumb.OrganisationUnitUser,
  );

  const hasPermission =
    appUser?.role === ModelUserRoleEnum.Administrator ||
    (appUser?.role === ModelUserRoleEnum.Manager &&
      appUser.careUnitID === unitId);
  if (!hasPermission) {
    history.push(Constants.paths.permissionDenied);
    return null;
  }

  const tabs = {
    overview: {
      label: "Overview",
      wideMode: false,
      index: "0",
    },
    surveys: {
      label: "Surveys",
      wideMode: true,
      index: "1",
    },
  };

  const currentWideMode = Object.values(tabs).find(
    tab => tab.index === currentTab,
  )?.wideMode;
  const isWide = currentWideMode === true;

  return (
    <div className={classes.root}>
      <TabContext value={currentTab}>
        <Paper className={classes.tabBar}>
          <TabList
            onChange={(_event, value) => setCurrentTab(value)}
            indicatorColor="primary"
            textColor="primary"
            centered
            aria-label="main navigation tabs"
          >
            <Tab label={tabs.overview.label} value={tabs.overview.index} />
            <Tab label={tabs.surveys.label} value={tabs.surveys.index} />
          </TabList>
        </Paper>

        <PageContainer isWide={isWide}>
          <TabPanel value="0">
            <UserOverview
              user={unitUser}
              surveys={surveys}
              careUnit={careUnit}
            />
          </TabPanel>

          <TabPanel value="1">
            <SurveysTable
              data={surveys}
              setData={setSurveys}
              isLoading={areSurveysLoading}
              refreshData={refreshSurveys}
              onRowClick={rowData => {
                setActiveSurvey(rowData);
                setSurveyDialogOpen(true);
              }}
            />
          </TabPanel>
        </PageContainer>
      </TabContext>

      {activeSurvey && (
        <SurveyDialog
          open={surveyDialogOpen}
          onClose={() => setSurveyDialogOpen(false)}
          data={activeSurvey}
        />
      )}
    </div>
  );
};
