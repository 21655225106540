import React, { useCallback, useEffect, useState } from "react";

import { ModelSurvey } from "../api_client";
import { getSurveysAPI } from "../api_client";
import { AppDatabase, HandleAPIErrorFun, useAppContext } from "../helpers";
import { useAPIUpdatePeriod } from ".";

const getAllSurveys = async (
  forUserId: number | undefined,
  forCareUnitId: number | undefined,
  setSurveys: React.Dispatch<React.SetStateAction<ModelSurvey[]>>,
  handleAPIError: HandleAPIErrorFun,
) => {
  try {
    const surveysResponse = await getSurveysAPI().getAllSurveys({
      disablePagination: true,
      carerID: forUserId,
      careUnitID: forCareUnitId,
    });

    const records = surveysResponse.records ?? [];

    const db = await AppDatabase.getInstance();

    if (db !== undefined) {
      const clearRemoved = async () => {
        let surveysCollection =
          forUserId !== undefined
            ? db.surveys.where("carerID").equals(forUserId)
            : db.surveys.toCollection();

        if (forCareUnitId !== undefined) {
          surveysCollection = surveysCollection.and(
            s => s.careUnitID === forCareUnitId,
          );
        }

        const surveysToRemove = await surveysCollection
          .filter(survey => !records.map(s => s.id).includes(survey.id))
          .toArray();

        await db.surveys.bulkDelete(surveysToRemove.map(s => s.id!));
      };

      await Promise.all([clearRemoved(), db.surveys.bulkPut(records)]);
    }

    setSurveys(records);
  } catch (e) {
    handleAPIError(e, "retrieving surveys");
  }
};

export const useSurveys = (forUserId?: number, forCareUnitId?: number) => {
  const { handleAPIError } = useAppContext();

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [surveys, setSurveys] = useState<ModelSurvey[]>([]);

  const [setUpdatedFromAPI, isAPIUpdateDue] = useAPIUpdatePeriod(
    `surveys-unit-${forCareUnitId ?? "all"}-user-${forUserId ?? "all"}`,
  );

  useEffect(() => {
    if (initialLoadComplete) {
      return;
    }

    const getCachedOrgs = async () => {
      const db = await AppDatabase.getInstance();
      if (db === undefined) {
        return;
      }

      let surveysCollection =
        forUserId !== undefined
          ? db.surveys.where("carerID").equals(forUserId)
          : db.surveys.toCollection();

      if (forCareUnitId !== undefined) {
        surveysCollection = surveysCollection.and(
          s => s.careUnitID === forCareUnitId,
        );
      }

      const cachedSurveys = await surveysCollection.toArray();

      setSurveys(cachedSurveys);
      return cachedSurveys.length > 0;
    };

    getCachedOrgs().then(async cachePresent => {
      if (!cachePresent || isAPIUpdateDue()) {
        await getAllSurveys(
          forUserId,
          forCareUnitId,
          setSurveys,
          handleAPIError,
        );
      }

      setInitialLoadComplete(true);
      setIsLoading(false);
      setUpdatedFromAPI();
    });
  }, [
    initialLoadComplete,
    forUserId,
    forCareUnitId,
    handleAPIError,
    setUpdatedFromAPI,
    isAPIUpdateDue,
  ]);

  const refreshSurveys = useCallback(async () => {
    setIsLoading(true);
    await getAllSurveys(forUserId, forCareUnitId, setSurveys, handleAPIError);
    setIsLoading(false);
    setUpdatedFromAPI();
  }, [forUserId, forCareUnitId, handleAPIError, setUpdatedFromAPI]);

  return [surveys, setSurveys, isLoading, refreshSurveys] as const;
};
