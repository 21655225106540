import { useCallback, useEffect, useState } from "react";

import { ModelUser } from "../api_client";
import { getUsersAPI } from "../api_client";
import { AppDatabase, HandleAPIErrorFun, useAppContext } from "../helpers";
import { useAPIUpdatePeriod } from ".";

const getUser = async (
  userId: number,
  setUser: React.Dispatch<React.SetStateAction<ModelUser | undefined>>,
  handleAPIError: HandleAPIErrorFun,
) => {
  try {
    const userResponse = await getUsersAPI().getUser({
      id: userId,
    });

    const db = await AppDatabase.getInstance();
    if (db !== undefined) {
      await db.users.put(userResponse);
    }

    setUser(userResponse);
  } catch (e) {
    handleAPIError(e, "retrieving user info");
  }
};

export const useUser = (userId: number | undefined) => {
  const { handleAPIError } = useAppContext();

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<ModelUser | undefined>(undefined);

  const [setUpdatedFromAPI, isAPIUpdateDue] = useAPIUpdatePeriod(
    `user-${userId}`,
  );

  useEffect(() => {
    if (initialLoadComplete || userId === undefined) {
      return;
    }

    const getCachedUser = async () => {
      const db = await AppDatabase.getInstance();
      if (db === undefined) {
        return;
      }

      const cachedUser = await db.users.where("id").equals(userId).first();
      setUser(cachedUser);
      return cachedUser !== undefined;
    };

    getCachedUser().then(async cachePresent => {
      if (!cachePresent || isAPIUpdateDue()) {
        await getUser(userId, setUser, handleAPIError);
      }

      setInitialLoadComplete(true);
      setIsLoading(false);
      setUpdatedFromAPI();
    });
  }, [
    userId,
    handleAPIError,
    setUpdatedFromAPI,
    isAPIUpdateDue,
    initialLoadComplete,
  ]);

  const refreshUser = useCallback(async () => {
    if (userId === undefined) {
      return;
    }

    setIsLoading(true);
    await getUser(userId, setUser, handleAPIError);
    setIsLoading(false);
    setUpdatedFromAPI();
  }, [userId, handleAPIError, setUpdatedFromAPI]);

  return [user, isLoading, refreshUser] as const;
};
