import { Container, Theme, createStyles, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
    },
    main: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
      maxWidth: "100%",
    },
    content: {
      flexGrow: 1,
      transition: "all 0.25s ease-in-out",
    },
    wideContainer: {
      margin: "auto",
      maxWidth: "100%",
      paddingLeft: 0,
      paddingRight: 0,
    },
  }),
);

interface PageContainerProps {
  isWide?: boolean;
  className?: string;
}

export const PageContainer: React.FC<PageContainerProps> = ({
  isWide,
  children,
  className,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main className={classes.main}>
        <Container
          className={clsx(classes.content, className, {
            [classes.wideContainer]: isWide,
          })}
        >
          <>{children}</>
        </Container>
      </main>
    </div>
  );
};
