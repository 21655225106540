import {
  Button,
  Fade,
  IconButton,
  LinearProgress,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import {
  ChevronLeft as PaginateLeftIcon,
  ChevronRight as PaginateRightIcon,
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  ModelQuestion,
  ModelQuestionTypeEnum,
  ModelUserRoleEnum,
  ViewmodelAddSurvey,
  getSurveysAPI,
} from "../api_client";
import {
  AdjectiveQuestion,
  PageContainer,
  SurveyQuestion,
} from "../components";
import {
  AppDatabase,
  Constants,
  shuffle,
  urlParamToModel,
  useAppContext,
} from "../helpers";
import { Breadcrumb, useActiveSurveyTemplate, useBreadcrumbs } from "../hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    header: {
      marginBottom: theme.spacing(1),
    },
    content: {
      position: "relative",
      flexGrow: 1,
    },
    page: {
      position: "absolute",
      width: "100%",
      textAlign: "center",
    },
    pageSection: {
      marginBottom: theme.spacing(4),
    },
    textEmphasis: {
      fontStyle: "oblique",
    },
    progress: {
      //
    },
    instructions: {
      "& p": {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontSize: "1.1rem",
      },
    },
    actionButton: {
      marginTop: theme.spacing(1),
      float: "right",
    },
    submitPageInfoWrapper: {
      marginBottom: theme.spacing(1),
    },
    submitPageInfo: {
      display: "inline-block",
      width: "50%",
    },
    paginateLeftButton: {
      position: "fixed",
      left: 16,
      top: "50%",
    },
    paginateRightButton: {
      position: "fixed",
      right: 16,
      top: "50%",
    },
  }),
);

export interface QuestionWithIndices {
  groupIndex: number;
  questionIndex: number;
  question: ModelQuestion;
}

interface CompleteSurveyParams {
  orgInfo?: string;
  unitInfo?: string;
  userInfo?: string;
}

export const CompleteSurvey: React.FC = () => {
  const { handleAPIError, showSnackbar, user } = useAppContext();
  const classes = useStyles();
  const params = useParams<CompleteSurveyParams>();
  const history = useHistory();

  const [_orgId, orgName] = urlParamToModel(params.orgInfo);
  const [unitId] = urlParamToModel(params.unitInfo);
  const [userId] = urlParamToModel(params.userInfo);

  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const [surveyTemplate, isLoading] = useActiveSurveyTemplate();
  const [survey, setSurvey] = useState<ViewmodelAddSurvey>({
    userID: userId,
    surveyContents:
      surveyTemplate?.contents !== undefined
        ? [...surveyTemplate.contents]
        : undefined,
  });

  // Todo: persist survey in local storage so refresh doesn't lose progress? If it's 26
  // questions it would be very frustrating to lose that info.
  useEffect(() => {
    setSurvey(s => {
      s.surveyContents =
        surveyTemplate?.contents !== undefined
          ? [...surveyTemplate?.contents]
          : undefined;
      return s;
    });
  }, [surveyTemplate]);

  const [questions, setQuestions] = useState<QuestionWithIndices[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [maxCompletedQuestion, setMaxCompletedQuestion] = useState(-1);

  const numQuestions = questions.length;

  const showPageLeft = currentPage > 1 && currentPage <= numQuestions;
  const showPageRight =
    currentPage > 0 &&
    currentPage <= maxCompletedQuestion &&
    currentPage < numQuestions;

  useBreadcrumbs(
    Breadcrumb.Organisations,
    Breadcrumb.Organisation,
    Breadcrumb.OrganisationUnit,
    Breadcrumb.OrganisationUnitUser,
    Breadcrumb.CompleteSurvey,
  );

  // We want to randomise the order of the questions when asked to do so, but we don't
  // want the order to change on every render.
  useEffect(() => {
    if (surveyTemplate === undefined || surveyTemplate.contents === undefined) {
      setQuestions([]);
      return;
    }

    const newQuestions: QuestionWithIndices[] = [];

    for (
      let groupIndex = 0;
      groupIndex < surveyTemplate.contents?.length ?? 0;
      groupIndex++
    ) {
      const group = surveyTemplate.contents[groupIndex];

      if (group.questions === undefined) {
        continue;
      }

      const questionsWithIndices = group.questions.map(
        (q, i) =>
          ({
            question: q,
            groupIndex,
            questionIndex: i,
          } as QuestionWithIndices),
      );

      const shuffledQuestions = group.randomise
        ? shuffle(questionsWithIndices)
        : questionsWithIndices;

      newQuestions.push(...shuffledQuestions);
    }

    setQuestions(newQuestions);
  }, [surveyTemplate]);

  // Currently, carers cannot complete surveys using their own account - they need to
  // get the managers to get the complete survey screen up, then they fill it out while
  // supervised. Note that we don't check `carer.careUnitID === unitId` here but the
  // backend does do that check.
  const hasPermission =
    user?.role === ModelUserRoleEnum.Administrator ||
    (user?.role === ModelUserRoleEnum.Manager && user.careUnitID === unitId);
  if (!hasPermission) {
    history.push(Constants.paths.permissionDenied);
  }

  const submitSurvey = useCallback(async () => {
    const areAllQuestionsComplete = () => {
      // Double-check that all questions that require answers have answers.
      for (const group of survey.surveyContents ?? []) {
        for (const question of group.questions ?? []) {
          switch (question.type) {
            case ModelQuestionTypeEnum.Adjective: {
              const adj = question.adjective;
              if (
                adj === undefined ||
                adj.userChoiceLeast === undefined ||
                adj.userChoiceMost === undefined
              ) {
                return false;
              }
              break;
            }
            case ModelQuestionTypeEnum.Freetext: {
              const text = question.freeText;
              if (text === undefined || text.userResponse === undefined) {
                return false;
              }
              break;
            }
            case ModelQuestionTypeEnum.Rating: {
              const rating = question.rating;
              if (rating?.userChoice === undefined) {
                return false;
              }
              break;
            }
            default:
              continue;
          }
        }

        return true;
      }
    };

    if (areAllQuestionsComplete() === false) {
      setCurrentPage(numQuestions);
      showSnackbar(
        "Unable to submit survey - one or more questions are incomplete.",
        "error",
      );
      return;
    }

    setSubmitting(true);

    try {
      const addedSurvey = await getSurveysAPI().addSurvey({ survey });

      const db = await AppDatabase.getInstance();
      if (db !== undefined) {
        await db.surveys.add(addedSurvey, addedSurvey.id);
        await db.updateUnitStats(unitId);
      }

      setSubmitSuccess(true);
    } catch (e) {
      setSubmitError(true);
      handleAPIError(e, "submitting survey");
    } finally {
      setSubmitting(false);
    }
  }, [survey, handleAPIError, showSnackbar, unitId, numQuestions]);

  const updateQuestion = useCallback(async (completed: QuestionWithIndices) => {
    setSurvey(s => {
      const newS = { ...s };
      if (
        newS.surveyContents === undefined ||
        newS.surveyContents.length === 0
      ) {
        return newS;
      }

      newS.surveyContents[completed.groupIndex].questions![
        completed.questionIndex
      ] = completed.question;

      return newS;
    });
  }, []);

  useEffect(() => {
    if (
      submitting ||
      submitError ||
      submitSuccess ||
      numQuestions === 0 ||
      maxCompletedQuestion < numQuestions
    ) {
      return;
    }

    submitSurvey();
  }, [
    numQuestions,
    maxCompletedQuestion,
    submitting,
    submitError,
    submitSuccess,
    submitSurvey,
  ]);

  const advanceToNextQuestion = useCallback(
    (questionIdx: number) => {
      setMaxCompletedQuestion(current => Math.max(current, questionIdx));
      setCurrentPage(current => Math.min(current + 1, numQuestions + 1));
    },
    [numQuestions],
  );

  return (
    <PageContainer>
      <div className={classes.root}>
        <Typography className={classes.header} variant="h4">
          Complete Survey
        </Typography>

        <div className={classes.content}>
          <Fade in={currentPage === 0}>
            <div className={clsx(classes.page, classes.instructions)}>
              <div className={classes.pageSection}>
                <Typography variant="h5">Instructions</Typography>

                <Typography>
                  When completing this exercise,{" "}
                  <span className={classes.textEmphasis}>
                    think of yourself at work.
                  </span>
                </Typography>

                <Typography>
                  If you are not presently in work, think of yourself in your
                  last job.
                </Typography>

                <Typography>
                  If you have not worked before, think of yourself at home.
                </Typography>

                <Typography>
                  Each question contains four descriptive words.
                </Typography>

                <Typography>
                  Your first spontaneous answer is the best one for this
                  exercise.
                </Typography>
              </div>

              <div className={classes.pageSection}>
                <Typography variant="h5">Please note</Typography>

                <Typography>
                  The best profile is the one that best reflects how you would
                  describe{" "}
                  <span className={classes.textEmphasis}>yourself</span>.
                </Typography>

                <Typography>
                  Please ensure you will be able to complete the exercise
                  without interruption. As a guide: allow yourself 10 to 15
                  minutes.
                </Typography>
              </div>

              {process.env.NODE_ENV === "development" && (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.actionButton}
                  style={{ marginLeft: 8 }}
                  onClick={() => {
                    questions.forEach((q, index) => {
                      const question = q.question;
                      switch (question.type) {
                        case "adjective":
                          question.adjective!.userChoiceMost = 0;
                          question.adjective!.userChoiceLeast = 1;
                          break;
                        case "freetext":
                          question.freeText!.userResponse = "Autofilled answer";
                          break;
                        case "rating":
                          question.rating!.userChoice = 7;
                          break;
                        default:
                          break;
                      }

                      updateQuestion(q);

                      if (index !== numQuestions - 1) {
                        advanceToNextQuestion(index);
                      }
                    });
                  }}
                >
                  Autofill
                </Button>
              )}

              <Button
                variant="contained"
                color="secondary"
                className={classes.actionButton}
                onClick={() => setCurrentPage(1)}
              >
                OK
              </Button>
            </div>
          </Fade>

          <Fade in={isLoading && currentPage === 1}>
            <div className={classes.page}>
              <AdjectiveQuestion question={undefined} />
            </div>
          </Fade>

          {questions.map((question, questionIdx) => (
            <Fade
              key={questionIdx}
              in={currentPage === questionIdx + 1}
              timeout={400}
              style={{ transitionDelay: "200ms" }}
            >
              <div className={classes.page}>
                <SurveyQuestion
                  questionWithIndices={question}
                  questionNumber={questionIdx + 1}
                  onUpdate={updateQuestion}
                  onAdvance={advanceToNextQuestion}
                  orgName={orgName}
                />
              </div>
            </Fade>
          ))}

          <Fade in={currentPage !== 0 && currentPage === numQuestions + 1}>
            <div className={classes.page}>
              <div className={classes.submitPageInfoWrapper}>
                <div className={classes.submitPageInfo}>
                  <Typography variant="h6">
                    {submitting ? (
                      <Skeleton
                        width="50%"
                        style={{ display: "inline-block" }}
                      />
                    ) : submitSuccess ? (
                      "Thank you"
                    ) : submitError ? (
                      "Oops, an error occurred"
                    ) : (
                      ""
                    )}
                  </Typography>
                  <Typography>
                    {submitting ? (
                      <Skeleton width="100%" />
                    ) : submitSuccess ? (
                      "Your survey has successfully been submitted."
                    ) : submitError ? (
                      "Our team has been notified, so please do try again later."
                    ) : (
                      ""
                    )}
                  </Typography>
                </div>
              </div>

              <Button
                variant="contained"
                color="secondary"
                className={classes.actionButton}
                onClick={() => history.push(Constants.paths.home)}
              >
                Back to home
              </Button>
            </div>
          </Fade>
        </div>

        <Fade in={showPageLeft}>
          <div className={classes.paginateLeftButton}>
            <IconButton
              aria-label="paginate left"
              onClick={() => setCurrentPage(p => Math.max(p - 1, 1))}
            >
              <PaginateLeftIcon fontSize="large" />
            </IconButton>
          </div>
        </Fade>

        <Fade in={showPageRight}>
          <div className={classes.paginateRightButton}>
            <IconButton
              aria-label="paginate right"
              onClick={() =>
                setCurrentPage(p => Math.min(p + 1, maxCompletedQuestion + 2))
              }
            >
              <PaginateRightIcon fontSize="large" />
            </IconButton>
          </div>
        </Fade>

        <Fade in={currentPage > 0}>
          <div className={classes.progress}>
            <LinearProgress
              variant="determinate"
              value={(100 * (currentPage - 1)) / numQuestions}
            />
          </div>
        </Fade>
      </div>
    </PageContainer>
  );
};
