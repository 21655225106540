import { useCallback, useEffect, useState } from "react";

import { ModelSurveyTemplate, getSurveyTemplatesAPI } from "../api_client";
import { AppDatabase, HandleAPIErrorFun, useAppContext } from "../helpers";
import { useAPIUpdatePeriod } from ".";

const getActiveSurveyTemplate = async (
  setActiveSurveyTemplate: React.Dispatch<
    React.SetStateAction<ModelSurveyTemplate | undefined>
  >,
  handleAPIError: HandleAPIErrorFun,
) => {
  try {
    const response = await getSurveyTemplatesAPI().getAciveSurveyTemplate();

    // At the moment there's only one active survey template for the whole CQE system
    // but in the future we may introduce separate templates for each care organisation
    // in which case storing them using Dexie will make more sense.
    const db = await AppDatabase.getInstance();
    if (db !== undefined) {
      await db.surveyTemplates.clear();
      await db.surveyTemplates.put(response);
    }

    setActiveSurveyTemplate(response);
  } catch (e) {
    handleAPIError(e, "retrieving active survey template.");
  }
};

export const useActiveSurveyTemplate = () => {
  const { handleAPIError } = useAppContext();

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeSurveyTemplate, setActiveSurveyTemplate] = useState<
    ModelSurveyTemplate | undefined
  >(undefined);

  // The survey template will basically never change.
  const [setUpdatedFromAPI, isAPIUpdateDue] = useAPIUpdatePeriod(
    "active-survey-template",
    60 * 60 * 1000, // Currently 1 hour, but could be longer.
  );

  useEffect(() => {
    if (initialLoadComplete) {
      return;
    }

    const getCachedTemplate = async () => {
      const db = await AppDatabase.getInstance();
      if (db === undefined) {
        return false;
      }

      const cachedTemplate = await db.surveyTemplates
        .filter(t => t.isActive === true)
        .first();
      setActiveSurveyTemplate(cachedTemplate);
      return cachedTemplate !== undefined;
    };

    getCachedTemplate().then(async cachePresent => {
      if (!cachePresent || isAPIUpdateDue()) {
        await getActiveSurveyTemplate(setActiveSurveyTemplate, handleAPIError);
      }

      setInitialLoadComplete(true);
      setIsLoading(false);
      setUpdatedFromAPI();
    });
  }, [handleAPIError, setUpdatedFromAPI, isAPIUpdateDue, initialLoadComplete]);

  const refreshActiveTemplate = useCallback(async () => {
    setIsLoading(true);
    await getActiveSurveyTemplate(setActiveSurveyTemplate, handleAPIError);
    setIsLoading(false);
    setUpdatedFromAPI();
  }, [setActiveSurveyTemplate, handleAPIError, setUpdatedFromAPI]);

  return [activeSurveyTemplate, isLoading, refreshActiveTemplate] as const;
};
