import {
  Avatar,
  IconButton,
  TableCell,
  TableRow,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import {
  ExpandMore as DownIcon,
  ExpandLess as UpIcon,
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import React, { useState } from "react";

import { ModelRota, ModelShift, ModelUser } from "../../api_client";
import { ModelShiftPreset } from "../../api_client/models/ModelShiftPreset";
import { countHoursForUser } from "../../helpers";
import { UserSafetyScores } from "../../pages";
import { RemoveUserButton } from "./RemoveUserButton";
import { DayOfWeek } from "./RotaTable";
import { ShiftButton } from "./ShiftButton";
import { UserSafetyTooltipIcon } from "./UserSafetyTooltipIcon";
import { UserAvatar } from "..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userInfoCell: {
      minWidth: 225,
      paddingLeft: 0,
    },
    userInfo: {
      display: "flex",
      alignItems: "center",
    },
    userAvatar: {
      display: "inline-flex",
    },
    userName: {
      display: "inline-flex",
      marginLeft: 8,
      flexGrow: 1,
      fontSize: theme.typography.pxToRem(18),
    },
    shiftCell: {
      height: "100%",
      width: 100,
      padding: 8,
    },
    actionsCell: {
      width: 80,
    },
    totalHoursCell: {
      minWidth: 48,
    },
    tableContentsRow: {
      height: "100%",
    },
    shiftSkeleton: {
      width: "100%",
      height: "100%",
      borderRadius: 4,
    },
    safetyCell: {
      fontSize: theme.typography.pxToRem(32),
    },
    safetyTooltip: {
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(12),
    },
    reorderButtonsCell: {
      width: 50,
      padding: 2,
    },
    reorderButton: {
      //
    },
  }),
);

interface UserRow {
  user?: ModelUser;
  rota?: ModelRota;
  shiftsForUser?: (ModelShift | undefined)[];
  addOrUpdateShift?: (shift: ModelShift, i: DayOfWeek) => void;
  removeShift?: (shift: ModelShift, i: DayOfWeek) => void;
  removeUserFromRota?: (userId: number | undefined) => void;
  userCanMoveUp?: boolean;
  userCanMoveDown?: boolean;
  moveUserUp?: () => void;
  moveUserDown?: () => void;
  isLoading?: boolean;
  userSafetyScores?: UserSafetyScores;
  isSafetyLoading?: boolean;
  shiftPresets?: ModelShiftPreset[];
  onNavigateToConfigureShifts?: () => void;
}

export const UserRow: React.FC<UserRow> = ({
  user,
  rota,
  addOrUpdateShift,
  removeShift,
  shiftsForUser,
  removeUserFromRota,
  userCanMoveUp,
  userCanMoveDown,
  moveUserUp,
  moveUserDown,
  isLoading,
  userSafetyScores,
  isSafetyLoading,
  shiftPresets,
  onNavigateToConfigureShifts,
}) => {
  const classes = useStyles();

  const [removingUser, setRemovingUser] = useState(false);

  const userSafetyScore =
    user?.id !== undefined ? userSafetyScores?.[user.id] : undefined;

  return (
    <TableRow className={classes.tableContentsRow}>
      <TableCell className={classes.reorderButtonsCell}>
        <IconButton
          className={classes.reorderButton}
          disabled={user === undefined || isLoading || userCanMoveUp !== true}
          onClick={moveUserUp}
        >
          <UpIcon />
        </IconButton>
        <IconButton
          className={classes.reorderButton}
          disabled={user === undefined || isLoading || userCanMoveDown !== true}
          onClick={moveUserDown}
        >
          <DownIcon />
        </IconButton>
      </TableCell>
      <TableCell component="th" scope="row" className={classes.userInfoCell}>
        <div className={classes.userInfo}>
          {!isLoading && user !== undefined ? (
            <UserAvatar user={user} className={classes.userAvatar} />
          ) : (
            <Skeleton variant="circle" className={classes.userAvatar}>
              <Avatar />
            </Skeleton>
          )}

          <Typography variant="h6" className={classes.userName}>
            {!isLoading && user !== undefined ? (
              `${user.firstName} ${user.lastName}`
            ) : (
              <Skeleton variant="text" className={classes.userName} />
            )}
          </Typography>
        </div>
      </TableCell>
      <TableCell align="center" className={classes.safetyCell}>
        <UserSafetyTooltipIcon
          isLoading={isLoading || isSafetyLoading || user === undefined}
          userSafetyScore={userSafetyScore}
        />
      </TableCell>
      {Array.from(Array(7).keys()).map(i => (
        <TableCell key={i} align="center" className={classes.shiftCell}>
          {!isLoading && user !== undefined ? (
            <ShiftButton
              rota={rota}
              userId={user.id}
              dayOfWeek={i as DayOfWeek}
              shift={shiftsForUser?.[i]}
              onSubmit={async shift => {
                if (addOrUpdateShift !== undefined) {
                  await addOrUpdateShift(shift, i as DayOfWeek);
                }
              }}
              onDelete={async shift => {
                if (removeShift !== undefined) {
                  await removeShift(shift, i as DayOfWeek);
                }
              }}
              shiftPresets={shiftPresets ?? []}
              onNavigateToConfigureShifts={
                onNavigateToConfigureShifts ?? (() => {})
              }
            />
          ) : (
            <Skeleton variant="rect" className={classes.shiftSkeleton} />
          )}
        </TableCell>
      ))}
      <TableCell className={classes.totalHoursCell} align="right">
        {!isLoading && user !== undefined ? (
          countHoursForUser(user, shiftsForUser)
        ) : (
          <Skeleton variant="text" />
        )}
      </TableCell>
      <TableCell className={classes.actionsCell}>
        {!isLoading && user !== undefined && (
          <RemoveUserButton
            user={user}
            shiftsForUser={shiftsForUser ?? []}
            removeUserFromRota={async (userId: number) => {
              if (removeUserFromRota === undefined) {
                return;
              }

              setRemovingUser(true);
              await removeUserFromRota(userId);
              setRemovingUser(false);
            }}
            removingUser={removingUser}
          />
        )}
      </TableCell>
    </TableRow>
  );
};
