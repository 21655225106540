import { useState } from "react";

export const usePagination = (defaultPageSize = 100) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPageNumber, setNextPageNumber] = useState<number | undefined>(
    undefined,
  );
  const [prevPageNumber, setPrevPageNumber] = useState<number | undefined>(
    undefined,
  );
  const [pageCount, setPageCount] = useState<number | undefined>(undefined);

  const offset = (pageNumber - 1) * pageSize;

  return {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    nextPageNumber,
    setNextPageNumber,
    prevPageNumber,
    setPrevPageNumber,
    pageCount,
    setPageCount,
    offset,
  };
};
