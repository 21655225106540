import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  darken,
  lighten,
  makeStyles,
} from "@material-ui/core";
import {
  PersonAdd as AddUserIcon,
  WbSunny as DayShiftIcon,
  ChevronRight as NextIcon,
  NightsStay as NightShiftIcon,
  ChevronLeft as PreviousIcon,
  Refresh as RefreshIcon,
  TripOrigin as TodayIcon,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";

import {
  ModelCareUnit,
  ModelRota,
  ModelRotaUser,
  ModelUser,
  ModelUserRoleEnum,
  ModelUserStatusEnum,
  getRotasAPI,
} from "../../api_client";
import {
  AppDatabase,
  beginningOfWeek,
  localToUTC,
  useAppContext,
} from "../../helpers";
import { useRotas, useShifts } from "../../hooks";
import { UserSafetyScores } from "../../pages";
import { AddUsersDialog } from "./AddUsersDialog";
import { RotaMenu } from "./RotaMenu";
import { RotaTable } from "./RotaTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      height: `calc(100vh - ${theme.spacing(4)}px)`,
    },
    header: {
      display: "flex",
      marginBottom: theme.spacing(2),
    },
    timeButton: {
      minWidth: 32,
      marginRight: 8,
      verticalAlign: "text-bottom",
    },
    headerText: {
      flexGrow: 1,
      display: "inline-block",
      marginLeft: 8,
    },
    shiftTimeFormControl: {
      minWidth: 160,
      margin: `0 ${theme.spacing(1)}px`,
    },
    shiftTimeIcon: {
      verticalAlign: "text-bottom",
      marginRight: 2,
    },
    addCarersButton: {
      height: "100%",
    },
    refreshButton: {
      height: "100%",
    },
    publishSwitch: {
      margin: `0 ${theme.spacing(1)}px 0 0`,
    },
    rotas: {
      overflowY: "auto",
    },
    rotaContainer: {
      marginTop: theme.spacing(3),
      paddingTop: 8,
      backgroundColor:
        theme.palette.type === "light"
          ? darken(theme.palette.background.paper, 0.05)
          : lighten(theme.palette.background.paper, 0.1),
    },
  }),
);

interface RotaBuilderProps {
  orgId: number | undefined;
  careUnitId: number | undefined;
  careUnit: ModelCareUnit | undefined;
  areUsersLoading: boolean;
  users: ModelUser[];
  userSafetyScores: UserSafetyScores;
  isSafetyLoading: boolean;
  onNavigateToConfigureShifts: () => void;
}

const dateUtils = new DateFnsUtils();

export const RotaBuilder: React.FC<RotaBuilderProps> = ({
  orgId,
  careUnitId,
  careUnit,
  users,
  areUsersLoading,
  userSafetyScores,
  isSafetyLoading,
  onNavigateToConfigureShifts,
}) => {
  const classes = useStyles();
  const { handleAPIError } = useAppContext();

  const [isNightStaff, setIsNightStaff] = useState(false);

  const [rotaDateLocal, setRotaDateLocal] = useState(
    beginningOfWeek(new Date()),
  );
  const [
    daysRota,
    setDaysRota,
    nightsRota,
    setNightsRota,
    isRotaLoading,
    refreshRotaData,
  ] = useRotas(careUnitId, rotaDateLocal);

  const previousRotaDate = dateUtils.addDays(rotaDateLocal, -7);
  const [
    previousDaysRota,
    _setPreviousDaysRota,
    previousNightsRota,
    _setPreviousNightsRota,
    _isPreviousRotaLoading,
    refreshPreviousRotaData,
  ] = useRotas(careUnitId, previousRotaDate);

  const previousRota = isNightStaff ? previousNightsRota : previousDaysRota;
  const previousRotaUsers = previousRota?.users;

  const [rota, setRota] = isNightStaff
    ? [nightsRota, setNightsRota]
    : [daysRota, setDaysRota];

  const [
    userShifts,
    setUserShifts,
    areShiftsLoading,
    refreshShiftData,
  ] = useShifts(rota?.id);

  const refreshData = () => {
    refreshRotaData();
    refreshPreviousRotaData();
    refreshShiftData();
  };

  const shiftSafetyMode = careUnit?.shiftSafetyMode;

  // The UI should update immediately, even if the backend API call takes a while.
  const [rotaPublishToggle, setRotaPublishToggle] = useState(
    rota?.isPublished ?? false,
  );
  const [isPublishDisabled, setIsPublishDisabled] = useState(false);

  const isRotaPublished = rota?.isPublished;
  useEffect(() => {
    if (!isRotaLoading) {
      setRotaPublishToggle(isRotaPublished ?? false);
    }
  }, [isRotaLoading, isRotaPublished]);

  const [addUsersDialogOpen, setAddUsersDialogOpen] = useState(false);

  const availableUsers = users.filter(
    u =>
      u.status === ModelUserStatusEnum.ActiveEmployee &&
      !rota?.users?.map(rotaUser => rotaUser.userID).includes(u.id),
  );

  const updateRotaPublished = async (isPublished: boolean) => {
    if (isRotaLoading || rota?.id === undefined) {
      return;
    }

    setIsPublishDisabled(true);
    const updatedRota: ModelRota = { ...rota, isPublished };

    try {
      await getRotasAPI().updateRota({ id: rota.id, rota: updatedRota });

      const db = await AppDatabase.getInstance();
      if (db !== undefined) {
        await db.rotas.put(updatedRota, updatedRota.id);
      }
      setRota(updatedRota);
    } catch (e) {
      handleAPIError(e, "updating publish status of rota");
    }

    setIsPublishDisabled(false);
  };

  const addUsersToRota = async (usersToAdd: ModelUser[]) => {
    if (careUnitId === undefined || isRotaLoading) {
      return;
    }

    const startIndex = rota?.users?.length ?? 0;

    // By default, arrange alphabetically by surname, but managers go before carers.
    const orderedUsers = usersToAdd
      .sort((left, right) => {
        const leftSurname = left.lastName;
        const rightSurname = right.lastName;

        if (leftSurname === undefined && rightSurname === undefined) {
          return 0;
        }

        if (leftSurname === undefined) {
          return -1;
        }

        if (rightSurname === undefined) {
          return 1;
        }

        return leftSurname.localeCompare(rightSurname);
      })
      .sort((left, right) => {
        // Most important goes first.
        const roleOrder = {
          unknown: 3,
          [ModelUserRoleEnum.Carer]: 2,
          [ModelUserRoleEnum.Manager]: 1,
          [ModelUserRoleEnum.Administrator]: 0,
        };
        return (
          roleOrder[left.role ?? "unknown"] - roleOrder[right.role ?? "unknown"]
        );
      })
      .map(
        (user, i) =>
          ({
            userID: user.id,
            index: startIndex + i,
          } as ModelRotaUser),
      );

    if (rota?.id === undefined) {
      const rotaDateUTC = localToUTC(rotaDateLocal);

      try {
        const createdRota = await getRotasAPI().addRota({
          rota: {
            careUnitID: careUnitId,
            isPublished: false,
            startDate: rotaDateUTC,
            isNightStaff,
            users: orderedUsers,
          },
        });

        const db = await AppDatabase.getInstance();
        if (db !== undefined) {
          await db.rotas.put(createdRota, createdRota.id);
        }
        setRota(createdRota);
      } catch (e) {
        if (e instanceof Response && e.status === 409) {
          // If we're trying to create a rota that already exists, that means we need to
          // refresh our local copy of the data because it's clearly stale. We still
          // show an error because we weren't able to add the users that we wanted to
          // the rota.
          refreshData();
        }

        handleAPIError(e, "creating rota");
      }

      return;
    }

    const newRota = {
      ...rota,
      users: [...(rota.users ?? []), ...orderedUsers],
    } as ModelRota;

    try {
      const updatedRota = await getRotasAPI().updateRota({
        id: rota.id,
        rota: newRota,
      });

      const db = await AppDatabase.getInstance();
      if (db !== undefined) {
        await db.rotas.put(updatedRota, updatedRota.id);
      }
      setRota(updatedRota);
    } catch (e) {
      handleAPIError(e, "adding users to rota");
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Button
          className={classes.timeButton}
          variant="outlined"
          aria-label="previous week"
          onClick={() => {
            const newRotaDate = dateUtils.addDays(rotaDateLocal, -7);
            setRotaDateLocal(newRotaDate);
          }}
        >
          <PreviousIcon />
        </Button>
        <Button
          className={classes.timeButton}
          variant="outlined"
          aria-label="this week"
          startIcon={<TodayIcon />}
          onClick={() => {
            const newRotaDate = beginningOfWeek(new Date());
            setRotaDateLocal(newRotaDate);
          }}
        >
          Today
        </Button>
        <Button
          className={classes.timeButton}
          variant="outlined"
          aria-label="next week"
          onClick={() => {
            const newRotaDate = dateUtils.addDays(rotaDateLocal, 7);
            setRotaDateLocal(newRotaDate);
          }}
        >
          <NextIcon />
        </Button>

        <Typography className={classes.headerText} variant="h4">
          Week starting {dateUtils.format(rotaDateLocal, "do MMMM yyyy")} (Week{" "}
          {dateUtils.format(rotaDateLocal, "w")})
        </Typography>

        <FormControl className={classes.shiftTimeFormControl}>
          <Select
            labelId="shift-time-select-label"
            id="shift-time-select"
            value={isNightStaff ? "night" : "day"}
            onChange={event => {
              const value = event.target.value;
              setIsNightStaff(value === "night");
            }}
          >
            <MenuItem value="day">
              <DayShiftIcon className={classes.shiftTimeIcon} /> Day Staff
            </MenuItem>
            <MenuItem value="night">
              <NightShiftIcon className={classes.shiftTimeIcon} /> Night Staff
            </MenuItem>
          </Select>
        </FormControl>

        <FormControlLabel
          className={classes.publishSwitch}
          disabled={isRotaLoading || rota === undefined || isPublishDisabled}
          control={
            <Switch
              checked={rotaPublishToggle}
              onChange={event => {
                const newValue = event.target.checked;
                setRotaPublishToggle(newValue);
                updateRotaPublished(newValue);
              }}
              name="isPublished"
            />
          }
          label="Published"
        />

        <Tooltip title="Add carers to rota">
          <div>
            <Button
              className={classes.addCarersButton}
              disabled={isRotaLoading}
              onClick={() => setAddUsersDialogOpen(true)}
            >
              <AddUserIcon />
            </Button>
          </div>
        </Tooltip>

        <Tooltip title="Refresh data">
          <div>
            <Button
              className={classes.refreshButton}
              disabled={isRotaLoading}
              onClick={() => refreshData()}
            >
              <RefreshIcon />
            </Button>
          </div>
        </Tooltip>

        <RotaMenu
          rota={rota}
          rotaDate={rotaDateLocal}
          isLoading={isRotaLoading}
          isNightStaff={isNightStaff}
          userShifts={userShifts}
          onNavigateToConfigureShifts={onNavigateToConfigureShifts}
        />
      </div>

      <Paper className={classes.rotas}>
        <RotaTable
          orgId={orgId}
          rota={rota}
          setRota={setRota}
          isRotaLoading={isRotaLoading}
          rotaDate={rotaDateLocal}
          isNightStaff={isNightStaff}
          users={users}
          areUsersLoading={areUsersLoading}
          userShifts={userShifts}
          setUserShifts={setUserShifts}
          areShiftsLoading={areShiftsLoading}
          userSafetyScores={userSafetyScores}
          shiftSafetyMode={shiftSafetyMode}
          isSafetyLoading={isSafetyLoading}
          onNavigateToConfigureShifts={onNavigateToConfigureShifts}
        />
      </Paper>

      <AddUsersDialog
        open={addUsersDialogOpen}
        onClose={() => setAddUsersDialogOpen(false)}
        // We don't want to give app user option to add users who are already added.
        options={availableUsers}
        previousUserIds={previousRotaUsers?.map(u => u.userID!)}
        onSubmit={addUsersToRota}
      />
    </div>
  );
};
