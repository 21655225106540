import {
  Button,
  ButtonProps,
  CircularProgress,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submitWrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    submitProgress: {
      color: theme.palette.success.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

interface SubmitButtonProps extends ButtonProps {}

export const SubmitButton: React.FC<SubmitButtonProps> = props => {
  const classes = useStyles();
  const { onClick, disabled, children } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <div className={classes.submitWrapper}>
      <Button
        {...props}
        disabled={isSubmitting || disabled}
        onClick={async event => {
          setIsSubmitting(true);
          if (onClick !== undefined) {
            await onClick(event);
          }
          setIsSubmitting(false);
        }}
      >
        <>{children}</>
      </Button>
      {isSubmitting && (
        <CircularProgress size={24} className={classes.submitProgress} />
      )}
    </div>
  );
};
