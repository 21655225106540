/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ModelCareUnit,
  ModelCareUnitFromJSON,
  ModelCareUnitFromJSONTyped,
  ModelCareUnitToJSON,
} from "./";

/**
 *
 * @export
 * @interface ModelUser
 */
export interface ModelUser {
  /**
   *
   * @type {number}
   * @memberof ModelUser
   */
  careOrganisationID?: number;
  /**
   *
   * @type {string}
   * @memberof ModelUser
   */
  careOrganisationName?: string;
  /**
   *
   * @type {ModelCareUnit}
   * @memberof ModelUser
   */
  careUnit?: ModelCareUnit;
  /**
   *
   * @type {number}
   * @memberof ModelUser
   */
  careUnitID?: number;
  /**
   *
   * @type {string}
   * @memberof ModelUser
   */
  careUnitName?: string;
  /**
   *
   * @type {Date}
   * @memberof ModelUser
   */
  createdAt?: Date;
  /**
   *
   * @type {string}
   * @memberof ModelUser
   */
  email?: string;
  /**
   *
   * @type {Date}
   * @memberof ModelUser
   */
  endDate?: Date;
  /**
   *
   * @type {string}
   * @memberof ModelUser
   */
  firstName?: string;
  /**
   * POST updates shouldn't change this.
   * @type {string}
   * @memberof ModelUser
   */
  iamID?: string;
  /**
   *
   * @type {number}
   * @memberof ModelUser
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof ModelUser
   */
  isSupernumerary?: boolean;
  /**
   *
   * @type {string}
   * @memberof ModelUser
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ModelUser
   */
  role?: ModelUserRoleEnum;
  /**
   *
   * @type {Date}
   * @memberof ModelUser
   */
  startDate?: Date;
  /**
   *
   * @type {string}
   * @memberof ModelUser
   */
  status?: ModelUserStatusEnum;
}

export function ModelUserFromJSON(json: any): ModelUser {
  return ModelUserFromJSONTyped(json, false);
}

export function ModelUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ModelUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    careOrganisationID: !exists(json, "careOrganisationID")
      ? undefined
      : json["careOrganisationID"],
    careOrganisationName: !exists(json, "careOrganisationName")
      ? undefined
      : json["careOrganisationName"],
    careUnit: !exists(json, "careUnit")
      ? undefined
      : ModelCareUnitFromJSON(json["careUnit"]),
    careUnitID: !exists(json, "careUnitID") ? undefined : json["careUnitID"],
    careUnitName: !exists(json, "careUnitName")
      ? undefined
      : json["careUnitName"],
    createdAt: !exists(json, "createdAt")
      ? undefined
      : new Date(json["createdAt"]),
    email: !exists(json, "email") ? undefined : json["email"],
    endDate: !exists(json, "endDate") ? undefined : new Date(json["endDate"]),
    firstName: !exists(json, "firstName") ? undefined : json["firstName"],
    iamID: !exists(json, "iamID") ? undefined : json["iamID"],
    id: !exists(json, "id") ? undefined : json["id"],
    isSupernumerary: !exists(json, "isSupernumerary")
      ? undefined
      : json["isSupernumerary"],
    lastName: !exists(json, "lastName") ? undefined : json["lastName"],
    role: !exists(json, "role") ? undefined : json["role"],
    startDate: !exists(json, "startDate")
      ? undefined
      : new Date(json["startDate"]),
    status: !exists(json, "status") ? undefined : json["status"],
  };
}

export function ModelUserToJSON(value?: ModelUser | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    careOrganisationID: value.careOrganisationID,
    careOrganisationName: value.careOrganisationName,
    careUnit: ModelCareUnitToJSON(value.careUnit),
    careUnitID: value.careUnitID,
    careUnitName: value.careUnitName,
    createdAt:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    email: value.email,
    endDate:
      value.endDate === undefined ? undefined : value.endDate.toISOString(),
    firstName: value.firstName,
    iamID: value.iamID,
    id: value.id,
    isSupernumerary: value.isSupernumerary,
    lastName: value.lastName,
    role: value.role,
    startDate:
      value.startDate === undefined ? undefined : value.startDate.toISOString(),
    status: value.status,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ModelUserRoleEnum {
  Carer = "carer",
  Manager = "manager",
  Administrator = "administrator",
}
/**
 * @export
 * @enum {string}
 */
export enum ModelUserStatusEnum {
  NotApplicable = "not_applicable",
  Applicant = "applicant",
  ActiveEmployee = "active_employee",
  Banked = "banked",
  Left = "left",
}
