import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Slider,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { ModelRatingQuestion } from "../api_client";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
    },
    questionNumber: {
      fontWeight: "bold",
    },
    card: {
      textAlign: "left",
      marginTop: theme.spacing(2),
      display: "inline-block",
      maxWidth: 650,
      width: "100%",
    },
    content: {
      textAlign: "center",
      padding: theme.spacing(3),
    },
    labelLeft: {
      float: "left",
      display: "inline-block",
    },
    labelRight: {
      float: "right",
      display: "inline-block",
    },
    okButton: {
      marginLeft: "auto",
    },
  }),
);

interface RatingQuestionProps {
  question: ModelRatingQuestion | undefined;
  questionNumber: number;
  onUpdate: (completed: ModelRatingQuestion) => void;
  onAdvance: () => void;
  orgName?: string;
}

export const RatingQuestion: React.FC<RatingQuestionProps> = ({
  question,
  questionNumber,
  orgName,
  onUpdate,
  onAdvance,
}) => {
  const classes = useStyles();

  const start = question?.rangeStart ?? 0;
  const stop = question?.rangeStop ?? 10;
  const step = question?.rangeStep ?? 1;

  // Default value is mid-point between start and stop (rounded to fit with discrete
  // steps).
  const midPoint = start + step * Math.floor((stop - start) / (2 * step));

  const currentChoice = question?.userChoice;
  const [rating, setRating] = useState(currentChoice ?? midPoint);

  useEffect(() => setRating(currentChoice ?? midPoint), [
    currentChoice,
    midPoint,
  ]);

  useEffect(() => {
    if (question === undefined) {
      return;
    }

    question.userChoice = rating;
    onUpdate(question);
  }, [question, rating, onUpdate]);

  const totalSteps = Math.ceil((stop - start) / step);
  const marks = Array.from(Array(totalSteps + 1).keys()).map(i => {
    const value = start + i * step;
    return {
      value,
      label: String(value),
    };
  });

  const text = question?.text?.replace(
    "{{ care_organisation_name }}",
    orgName ?? "",
  );

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          title={
            <>
              <span className={classes.questionNumber}>{questionNumber})</span>{" "}
              {text}
            </>
          }
        />
        <CardContent className={classes.content}>
          <Typography className={classes.labelLeft} variant="body2">
            {question?.startLabel}
          </Typography>
          <Typography className={classes.labelRight} variant="body2">
            {question?.stopLabel}
          </Typography>

          <Slider
            value={rating}
            onChange={(_event, value) => setRating(Number(value))}
            valueLabelDisplay="auto"
            step={step}
            marks={marks}
            min={start}
            max={stop}
          />
        </CardContent>

        <CardActions>
          <Button
            className={classes.okButton}
            onClick={() => {
              if (question === undefined) {
                return;
              }

              question.userChoice = rating;
              onUpdate(question);
              onAdvance();
            }}
          >
            OK
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};
