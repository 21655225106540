import { Theme, createStyles, makeStyles } from "@material-ui/core";
import React from "react";

import { ModelSurvey } from "../../api_client";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {},
  }),
);

interface RedShiftCountGraphProps {
  surveys: ModelSurvey[];
}

export const RedShiftCountGraph: React.FC<RedShiftCountGraphProps> = ({
  surveys: _surveys,
}) => {
  const classes = useStyles();

  return <div className={classes.root}>Todo</div>;
};
