import {
  Breadcrumbs,
  Link,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {},
    crumb: {
      fontSize: "inherit",
    },
  }),
);

export interface BreadcrumbInfo {
  name: string;
  link: string;
  linkEnabled: boolean;
}

interface NavBreadcrumbsProps {
  crumbs: BreadcrumbInfo[];
  className?: string;
}

export const NavBreadcrumbs: React.FC<NavBreadcrumbsProps> = ({
  crumbs,
  className,
}) => {
  const classes = useStyles();

  return (
    <Breadcrumbs
      className={clsx(classes.root, className)}
      separator="›"
      aria-label="breadcrumb"
    >
      {crumbs.map((crumbInfo, index) => {
        // We're on the page for the final crumb already so it shouldn't be a link.
        const linkEnabled =
          crumbInfo.linkEnabled && index !== crumbs.length - 1;

        return linkEnabled ? (
          <Link
            key={index}
            component={RouterLink}
            color="inherit"
            to={crumbInfo.link}
            className={classes.crumb}
          >
            {crumbInfo.name}
          </Link>
        ) : (
          <Typography key={index} color="inherit" className={classes.crumb}>
            {crumbInfo.name}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};
