/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ModelRota,
  ModelRotaFromJSON,
  ModelRotaFromJSONTyped,
  ModelRotaToJSON,
  ModelUser,
  ModelUserFromJSON,
  ModelUserFromJSONTyped,
  ModelUserToJSON,
} from "./";

/**
 *
 * @export
 * @interface ModelRotaUser
 */
export interface ModelRotaUser {
  /**
   *
   * @type {number}
   * @memberof ModelRotaUser
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ModelRotaUser
   */
  index?: number;
  /**
   *
   * @type {ModelRota}
   * @memberof ModelRotaUser
   */
  rota?: ModelRota;
  /**
   *
   * @type {number}
   * @memberof ModelRotaUser
   */
  rotaID?: number;
  /**
   *
   * @type {ModelUser}
   * @memberof ModelRotaUser
   */
  user?: ModelUser;
  /**
   *
   * @type {number}
   * @memberof ModelRotaUser
   */
  userID?: number;
}

export function ModelRotaUserFromJSON(json: any): ModelRotaUser {
  return ModelRotaUserFromJSONTyped(json, false);
}

export function ModelRotaUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ModelRotaUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    index: !exists(json, "index") ? undefined : json["index"],
    rota: !exists(json, "rota") ? undefined : ModelRotaFromJSON(json["rota"]),
    rotaID: !exists(json, "rotaID") ? undefined : json["rotaID"],
    user: !exists(json, "user") ? undefined : ModelUserFromJSON(json["user"]),
    userID: !exists(json, "userID") ? undefined : json["userID"],
  };
}

export function ModelRotaUserToJSON(value?: ModelRotaUser | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    index: value.index,
    rota: ModelRotaToJSON(value.rota),
    rotaID: value.rotaID,
    user: ModelUserToJSON(value.user),
    userID: value.userID,
  };
}
