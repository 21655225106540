/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ModelRota,
  ModelRotaFromJSON,
  ModelRotaFromJSONTyped,
  ModelRotaToJSON,
  ModelUser,
  ModelUserFromJSON,
  ModelUserFromJSONTyped,
  ModelUserToJSON,
} from "./";

/**
 *
 * @export
 * @interface ModelShift
 */
export interface ModelShift {
  /**
   *
   * @type {number}
   * @memberof ModelShift
   */
  breakDurationMinutes?: number;
  /**
   *
   * @type {number}
   * @memberof ModelShift
   */
  durationMinutes?: number;
  /**
   *
   * @type {number}
   * @memberof ModelShift
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof ModelShift
   */
  isWorking?: boolean;
  /**
   *
   * @type {ModelRota}
   * @memberof ModelShift
   */
  rota?: ModelRota;
  /**
   *
   * @type {number}
   * @memberof ModelShift
   */
  rotaID?: number;
  /**
   *
   * @type {Date}
   * @memberof ModelShift
   */
  startTime?: Date;
  /**
   *
   * @type {ModelUser}
   * @memberof ModelShift
   */
  user?: ModelUser;
  /**
   *
   * @type {number}
   * @memberof ModelShift
   */
  userID?: number;
}

export function ModelShiftFromJSON(json: any): ModelShift {
  return ModelShiftFromJSONTyped(json, false);
}

export function ModelShiftFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ModelShift {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    breakDurationMinutes: !exists(json, "breakDurationMinutes")
      ? undefined
      : json["breakDurationMinutes"],
    durationMinutes: !exists(json, "durationMinutes")
      ? undefined
      : json["durationMinutes"],
    id: !exists(json, "id") ? undefined : json["id"],
    isWorking: !exists(json, "isWorking") ? undefined : json["isWorking"],
    rota: !exists(json, "rota") ? undefined : ModelRotaFromJSON(json["rota"]),
    rotaID: !exists(json, "rotaID") ? undefined : json["rotaID"],
    startTime: !exists(json, "startTime")
      ? undefined
      : new Date(json["startTime"]),
    user: !exists(json, "user") ? undefined : ModelUserFromJSON(json["user"]),
    userID: !exists(json, "userID") ? undefined : json["userID"],
  };
}

export function ModelShiftToJSON(value?: ModelShift | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    breakDurationMinutes: value.breakDurationMinutes,
    durationMinutes: value.durationMinutes,
    id: value.id,
    isWorking: value.isWorking,
    rota: ModelRotaToJSON(value.rota),
    rotaID: value.rotaID,
    startTime:
      value.startTime === undefined ? undefined : value.startTime.toISOString(),
    user: ModelUserToJSON(value.user),
    userID: value.userID,
  };
}
