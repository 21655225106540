import Dexie from "dexie";

import {
  ModelCareOrganisation,
  ModelCareUnit,
  ModelRota,
  ModelShift,
  ModelShiftPreset,
  ModelSurvey,
  ModelSurveyTemplate,
  ModelUser,
  ModelUserRoleEnum,
} from "../api_client";

// According to Dexie docs, class is designed to be used as singleton for performance
// reasons. For instance, each new instance needs to connect to the IndexedDB database
// which can take 20-200ms. See: https://dexie.org/docs/Tutorial/Building-Addons
export class AppDatabase extends Dexie {
  public organisations: Dexie.Table<ModelCareOrganisation, number>;
  public careUnits: Dexie.Table<ModelCareUnit, number>;
  public users: Dexie.Table<ModelUser, number>;
  public surveys: Dexie.Table<ModelSurvey, number>;
  public surveyTemplates: Dexie.Table<ModelSurveyTemplate, number>;
  public rotas: Dexie.Table<ModelRota, number>;
  public shifts: Dexie.Table<ModelShift, number>;
  public shiftPresets: Dexie.Table<ModelShiftPreset, number>;

  private static instance?: AppDatabase;

  private constructor() {
    super("CQEDatabase", { autoOpen: false });

    const db = this;

    db.version(8).stores({
      organisations:
        "++id, name, defaultShiftSafetyMode, numUnits, numManagers, numCarers",
      careUnits:
        "++id, name, careOrganisationID, shiftSafetyMode, numManagers, numCarers, numSurveys",
      users:
        "++id, email, careUnitID, iamID, firstName, lastName, createdAt, startDate, endDate," +
        "role, status",
      surveys:
        "++id, createdAt, carerID, graph1Disc, graph2Disc, graph3Disc, graph1HasOvershift, " +
        "isSafe, stress, netCustomerAdvocacyScore, hasVolatileLowDominance, dominanceShift, " +
        "graph1OverallPattern, surveyInput",
      surveyTemplates: "++id, createdAt, isActive, contents",
      rotas: "++id, careUnitID, isPublished, startDate, duration",
      shifts: "++id, rotaID, userID, startTime, endTime, breakDuration",
      shiftPresets:
        "++id, careOrganisationID, presetTime, nameLabel, startHours, startMinutes, " +
        "durationHours, durationMinutes, colour",
    });

    // rotas.users is now an array of a completely different type. Clear the cached
    // rotas so that we re-retrieve them all from the API fresh, with all the required
    // info.
    db.version(9).upgrade(tx => tx.table("rotas").clear());

    db.version(11)
      .stores({
        organisations:
          "++id, name, defaultShiftSafetyMode, numUnits, numManagers, numCarers",
        careUnits:
          "++id, name, careOrganisationID, shiftSafetyMode, numManagers, numCarers, numSurveys",
        users:
          "++id, email, careUnitID, iamID, firstName, lastName, createdAt, startDate, endDate," +
          "role, status, isSupernumerary",
        surveys:
          "++id, createdAt, carerID, graph1Disc, graph2Disc, graph3Disc, graph1HasOvershift, " +
          "isSafe, stress, netCustomerAdvocacyScore, hasVolatileLowDominance, dominanceShift, " +
          "graph1OverallPattern, surveyInput",
        surveyTemplates: "++id, createdAt, isActive, contents",
        rotas: "++id, careUnitID, isPublished, startDate, duration",
        shifts:
          "++id, rotaID, userID, startTime, endTime, breakDuration, isWorking",
        shiftPresets:
          "++id, careOrganisationID, presetTime, nameLabel, startHours, startMinutes, " +
          "durationHours, durationMinutes, colour, isWorking",
      })
      .upgrade(tx => {
        tx.table("users").clear();
        tx.table("rotas").clear();
        tx.table("shifts").clear();
        tx.table("shiftPresets").clear();
      });

    this.organisations = db.table("organisations");
    this.careUnits = db.table("careUnits");
    this.users = db.table("users");
    this.surveys = db.table("surveys");
    this.surveyTemplates = db.table("surveyTemplates");
    this.rotas = db.table("rotas");
    this.shifts = db.table("shifts");
    this.shiftPresets = db.table("shiftPresets");
  }

  /**
   * App should gracefully degrade when IndexedDB is not available, for instance when
   * in Safari / Firefox private browsing mode. As such, we prevent direct constructor
   * access and require going through this function which either returns your new
   * instance or fails and returns undefined, This means we don't have to handle the
   * error in every calling function.
   * @returns New app database instance.
   */
  public static async getInstance() {
    try {
      if (this.instance === undefined) {
        this.instance = new AppDatabase();
      }

      const db = this.instance;
      if (!db.isOpen()) {
        await db.open();
      }
      return db;
    } catch (e) {
      if (
        e instanceof Dexie.OpenFailedError ||
        e instanceof Dexie.InvalidStateError
      ) {
        return undefined;
      }

      throw e;
    }
  }

  public async updateOrgStats(orgId: number | undefined) {
    if (orgId === undefined) {
      return;
    }

    const org = await this.organisations.get(orgId);
    if (org === undefined) {
      return;
    }

    const careUnits = await this.careUnits
      .where("careOrganisationID")
      .equals(orgId)
      .toArray();
    const careUnitIds = careUnits
      .map(c => c.id)
      .filter(id => id !== undefined) as number[];
    const numUnits = careUnits.length;

    const usersQuery = this.users.where("careUnitID").anyOf(careUnitIds);

    const numCarers = await usersQuery
      .and(u => u.role === ModelUserRoleEnum.Carer)
      .count();
    const numManagers = await usersQuery
      .and(u => u.role === ModelUserRoleEnum.Manager)
      .count();

    await this.organisations.update(orgId, {
      numUnits,
      numCarers,
      numManagers,
    });
  }

  public async updateUnitStats(unitId: number | undefined) {
    if (unitId === undefined) {
      return;
    }

    const careUnit = await this.careUnits.get(unitId);
    if (careUnit === undefined) {
      return;
    }

    const users = await this.users.where("careUnitID").equals(unitId).toArray();
    const userIds = users
      .map(c => c.id)
      .filter(id => id !== undefined) as number[];

    const numCarers = users.filter(u => u.role === ModelUserRoleEnum.Carer)
      .length;
    const numManagers = users.filter(u => u.role === ModelUserRoleEnum.Manager)
      .length;

    const numSurveys = await this.surveys
      .where("carerID")
      .anyOf(userIds)
      .count();

    await this.careUnits.update(unitId, {
      numCarers,
      numManagers,
      numSurveys,
    });

    if (careUnit.careOrganisationID !== undefined) {
      await this.updateOrgStats(careUnit.careOrganisationID);
    }
  }
}
