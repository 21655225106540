import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ModelUser, ModelUserRoleEnum } from "../api_client";
import { BreadcrumbInfo } from "../components";
import { Constants, urlParamToModel, useAppContext } from "../helpers";

interface ParamsType {
  orgInfo?: string;
  unitInfo?: string;
  userInfo?: string;
  [key: string]: string | undefined;
}

export enum Breadcrumb {
  UserSettings,
  Organisations,
  NewOrganisation,
  Organisation,
  NewCareUnit,
  OrganisationUnit,
  OrganisationUnitUser,
  CompleteSurvey,
  ConfigureShifts,
  Admins,
  PageNotFound,
  PermissionDenied,
}

export const useBreadcrumbs = (...breadcrumbPages: Breadcrumb[]) => {
  const { setBreadcrumbs, user } = useAppContext();
  const params = useParams<ParamsType>();
  const [paramsState, setParamsState] = useState(params);
  const [breadcrumbsState, setBreadcrumbsState] = useState(breadcrumbPages);

  // Params object changes on every render, so we maintain state and do deep equal
  // comparison to only update state when the values actually change. Inspired by:
  // https://gist.github.com/gragland/8322804ba43392d5a1e96d37d1a38218#gistcomment-3447257
  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(paramsState)) {
      console.log("Updating params state due to change.");
      setParamsState(params);
    }
  }, [params, paramsState]);

  useEffect(() => {
    if (JSON.stringify(breadcrumbPages) !== JSON.stringify(breadcrumbsState)) {
      setBreadcrumbsState(breadcrumbPages);
    }
  }, [breadcrumbPages, breadcrumbsState]);

  useEffect(() => {
    const infos = breadcrumbsState
      .map(b => {
        const infoFunction = breadcrumbPageInfos[b];

        if (infoFunction === undefined) {
          console.error("No breadcrumb info function found for:", b);
          return undefined;
        }

        return infoFunction(user, paramsState);
      })
      .filter(b => b !== undefined) as BreadcrumbInfo[];

    setBreadcrumbs(...infos);
  }, [setBreadcrumbs, user, paramsState, breadcrumbsState]);
};

const breadcrumbPageInfos: {
  [key: string]: (
    User: ModelUser | undefined,
    params: ParamsType,
  ) => BreadcrumbInfo;
} = {
  [Breadcrumb.UserSettings]: (
    _user: ModelUser | undefined,
    _params: ParamsType,
  ) =>
    ({
      name: "User Settings",
      link: Constants.paths.userSettings,
      linkEnabled: true,
    } as BreadcrumbInfo),

  [Breadcrumb.Organisations]: (
    user: ModelUser | undefined,
    _params: ParamsType,
  ) =>
    ({
      name: "Organisations",
      link: Constants.paths.organisations,
      linkEnabled: user?.role === ModelUserRoleEnum.Administrator,
    } as BreadcrumbInfo),

  [Breadcrumb.NewOrganisation]: (
    user: ModelUser | undefined,
    _params: ParamsType,
  ) =>
    ({
      name: "New Organisation",
      link: Constants.paths.newOrganisation,
      linkEnabled: user?.role === ModelUserRoleEnum.Administrator,
    } as BreadcrumbInfo),

  [Breadcrumb.Organisation]: (
    user: ModelUser | undefined,
    params: ParamsType,
  ) => {
    const [_orgId, orgName] = urlParamToModel(params.orgInfo);

    return {
      name: orgName,
      link: Constants.paths.organisation.replace(
        ":orgInfo",
        params.orgInfo ?? "",
      ),
      linkEnabled: user?.role === ModelUserRoleEnum.Administrator,
    } as BreadcrumbInfo;
  },

  [Breadcrumb.NewCareUnit]: (user: ModelUser | undefined, params: ParamsType) =>
    ({
      name: "New Care Unit",
      link: Constants.paths.newOrganisationCareUnit.replace(
        ":orgInfo",
        params.orgInfo ?? "",
      ),
      linkEnabled: user?.role === ModelUserRoleEnum.Administrator,
    } as BreadcrumbInfo),

  [Breadcrumb.OrganisationUnit]: (
    user: ModelUser | undefined,
    params: ParamsType,
  ) => {
    const [unitId, unitName] = urlParamToModel(params.unitInfo);

    const hasPermission =
      user?.role === ModelUserRoleEnum.Administrator ||
      (user?.role === ModelUserRoleEnum.Manager && user.careUnitID === unitId);

    return {
      name: unitName,
      link: Constants.paths.organisationUnit
        .replace(":orgInfo", params.orgInfo ?? "")
        .replace(":unitInfo", params.unitInfo ?? ""),
      linkEnabled: hasPermission,
    } as BreadcrumbInfo;
  },

  [Breadcrumb.OrganisationUnitUser]: (
    user: ModelUser | undefined,
    params: ParamsType,
  ) => {
    const [unitId] = urlParamToModel(params.unitInfo);
    const [_userId, userName] = urlParamToModel(params.userInfo);

    const hasPermission =
      user?.role === ModelUserRoleEnum.Administrator ||
      (user?.role === ModelUserRoleEnum.Manager && user.careUnitID === unitId);

    return {
      name: userName,
      link: Constants.paths.organisationUnitUser
        .replace(":orgInfo", params.orgInfo ?? "")
        .replace(":unitInfo", params.unitInfo ?? "")
        .replace(":userInfo", params.userInfo ?? ""),
      linkEnabled: hasPermission,
    } as BreadcrumbInfo;
  },

  [Breadcrumb.CompleteSurvey]: (
    user: ModelUser | undefined,
    params: ParamsType,
  ) => {
    const [unitId] = urlParamToModel(params.unitInfo);

    const hasPermission =
      user?.role === ModelUserRoleEnum.Administrator ||
      (user?.role === ModelUserRoleEnum.Manager && user.careUnitID === unitId);

    return {
      name: "Complete Survey",
      link: Constants.paths.completeSurvey
        .replace(":orgInfo", params.orgInfo ?? "")
        .replace(":unitInfo", params.unitInfo ?? "")
        .replace(":userInfo", params.userInfo ?? ""),
      linkEnabled: hasPermission,
    } as BreadcrumbInfo;
  },

  [Breadcrumb.ConfigureShifts]: (
    user: ModelUser | undefined,
    params: ParamsType,
  ) => {
    const [unitId] = urlParamToModel(params.unitInfo);

    const hasPermission =
      user?.role === ModelUserRoleEnum.Administrator ||
      (user?.role === ModelUserRoleEnum.Manager && user.careUnitID === unitId);

    return {
      name: "Configure Shift Presets",
      link: Constants.paths.configureShifts
        .replace(":orgInfo", params.orgInfo ?? "")
        .replace(":unitInfo", params.unitInfo ?? ""),
      linkEnabled: hasPermission,
    } as BreadcrumbInfo;
  },

  [Breadcrumb.Admins]: (_user: ModelUser | undefined, _params: ParamsType) =>
    ({
      name: "Admins",
      link: Constants.paths.manageAdmins,
    } as BreadcrumbInfo),

  [Breadcrumb.PageNotFound]: (
    _user: ModelUser | undefined,
    _params: ParamsType,
  ) =>
    ({
      name: "Page Not Found",
      link: Constants.paths.notFound,
    } as BreadcrumbInfo),

  [Breadcrumb.PermissionDenied]: (
    _user: ModelUser | undefined,
    _params: ParamsType,
  ) =>
    ({
      name: "Permission Denied",
      link: Constants.paths.permissionDenied,
    } as BreadcrumbInfo),
};
