import { Color } from "@material-ui/lab";
import { User } from "oidc-client";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { ModelUser } from "../api_client";
import { BreadcrumbInfo } from "../components";
import { AuthService } from "../services";
import { Session } from ".";

export type ShowSnackbarFun = (message: string, severity: Color) => void;
export type HandleAPIErrorFun = (err: any, description: string) => void;

export interface AppContext {
  user?: ModelUser;
  setUser: (user?: ModelUser) => void;

  jwt: User | null;
  isLoggedIn: boolean;

  showSnackbar: ShowSnackbarFun;
  handleAPIError: HandleAPIErrorFun;

  breadcrumbs: BreadcrumbInfo[];
  setBreadcrumbs: (...crumbs: BreadcrumbInfo[]) => void;
}

const defaultAppContext: AppContext = {
  user: undefined,
  setUser: () => {},

  jwt: null,
  isLoggedIn: false,

  showSnackbar: () => {},
  handleAPIError: () => {},

  breadcrumbs: [],
  setBreadcrumbs: () => {},
};

const appContextInstance = React.createContext<AppContext>(defaultAppContext);

export const AppContextProvider = appContextInstance.Provider;
export const useAppContext = () => useContext(appContextInstance);

export const useCreateAppContext = () => {
  // We need to use `useState()` in here for any values that change and also affect the
  // rendering.

  // Load user info in from session (i.e. localStorage) if it's there.
  const [user, setUserState] = useState<ModelUser | undefined>(
    Session.getInstance().user,
  );
  const setUser = useCallback((u?: ModelUser) => {
    Session.getInstance().user = u;
    setUserState(u);
  }, []);

  const [jwt, setJwt] = useState<User | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    AuthService.getInstance()
      .getUser()
      .then(storedJwt => {
        setJwt(storedJwt);
        setIsLoggedIn(storedJwt !== null);
      });
  }, []);

  const [breadcrumbs, setBreadcrumbsState] = useState<BreadcrumbInfo[]>([]);
  const setBreadcrumbs = useCallback(
    (...crumbs: BreadcrumbInfo[]) => setBreadcrumbsState(crumbs),
    [],
  );

  return {
    user,
    setUser,

    jwt,
    isLoggedIn,

    showSnackbar: () => {},
    handleAPIError: () => {},

    breadcrumbs,
    setBreadcrumbs,
  } as AppContext;
};
