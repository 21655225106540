import { Log, User, UserManager } from "oidc-client";

import { Constants } from "../helpers/Constants";

export class AuthService {
  private static instance: AuthService | undefined;

  public userManager: UserManager;

  // If we hammer the auth server every time we send a request, we slow down the auth
  // server and the client web page to a halt. Instead, cache the promises so that we're
  // only ever sending one request of each type to the auth server at any time. We
  // should consider the possibility of a promise getting stuck and never completing. If
  // this does happen, we can implement a setTimeout() to clear the promises after ten
  // seconds of not being completed (or similar) but that doesn't need to be done if the
  // situation never occurs.
  private promises: {
    getUser?: Promise<User | null>;
    login?: Promise<void>;
    renewToken?: Promise<User>;
    logout?: Promise<void>;
  } = {};

  private constructor() {
    this.userManager = new UserManager({
      /* eslint-disable camelcase */
      authority: Constants.auth.stsAuthority,
      client_id: Constants.auth.clientId,
      redirect_uri: `${Constants.auth.clientRoot}/signin-callback.html`,
      silent_redirect_uri: `${Constants.auth.clientRoot}/silent-renew.html`,
      post_logout_redirect_uri: Constants.auth.clientRoot,
      response_type: "code",
      response_mode: "query",
      scope: Constants.auth.clientScope,
      automaticSilentRenew: false,
      /* eslint-enable camelcase */
    });

    Log.logger = console;
    Log.level = process.env.NODE_ENV === "production" ? Log.ERROR : Log.INFO;
  }

  public static getInstance() {
    if (this.instance === undefined) {
      this.instance = new AuthService();
    }

    return this.instance;
  }

  public async getUser() {
    if (this.promises.getUser === undefined) {
      this.promises.getUser = this.userManager.getUser().then(user => {
        this.promises.getUser = undefined;
        return user;
      });
    }

    return await this.promises.getUser;
  }

  public async login() {
    if (this.promises.login === undefined) {
      this.promises.login = this.userManager.signinRedirect().then(() => {
        this.promises.login = undefined;
      });
    }

    return await this.promises.login;
  }

  public async renewToken() {
    if (this.promises.renewToken === undefined) {
      this.promises.renewToken = this.userManager.signinSilent().then(user => {
        this.promises.renewToken = undefined;
        return user;
      });
    }

    return await this.promises.renewToken;
  }

  public async logout() {
    if (this.promises.logout === undefined) {
      this.promises.logout = this.userManager.signoutRedirect().then(() => {
        this.promises.logout = undefined;
      });
    }

    return await this.promises.logout;
  }
}
