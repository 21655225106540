import {
  CircularProgress,
  Fab,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  ModelShiftPreset,
  ModelShiftPresetPresetTimeEnum,
  ModelUserRoleEnum,
  getShiftPresetsAPI,
} from "../api_client";
import { PageContainer, ShiftPresetCard } from "../components";
import {
  AppDatabase,
  Constants,
  urlParamToModel,
  useAppContext,
} from "../helpers";
import {
  Breadcrumb,
  useBreadcrumbs,
  usePagination,
  useShiftPresets,
} from "../hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    header: {},
    grid: {
      marginTop: theme.spacing(1),
      flex: "1 0 auto",
    },
    paginationContainer: {
      paddingTop: theme.spacing(2),
      textAlign: "center",
      flexShrink: 0,
    },
    pagination: {
      display: "inline-block",
    },
    fabWrapper: {
      position: "fixed",
      right: theme.spacing(2),
      bottom: theme.spacing(2),
    },
    fab: {},
    fabProgress: {
      color: theme.palette.primary.main,
      position: "absolute",
      left: "50%",
      top: "50%",
      marginTop: -34,
      marginLeft: -34,
    },
  }),
);

interface ConfigureShiftPresetsParams {
  orgInfo: string;
  unitInfo: string;
}

const numSkeletonCards = 3;

export const ConfigureShifts: React.FC = () => {
  const { handleAPIError, user } = useAppContext();
  const classes = useStyles();
  const params = useParams<ConfigureShiftPresetsParams>();
  const history = useHistory();

  const [orgId] = urlParamToModel(params.orgInfo);
  const [unitId] = urlParamToModel(params.unitInfo);

  const [shiftPresets, setShiftPresets, isLoading] = useShiftPresets(orgId);

  const [addingPreset, setAddingPreset] = useState(false);

  useBreadcrumbs(
    Breadcrumb.Organisations,
    Breadcrumb.Organisation,
    Breadcrumb.OrganisationUnit,
    Breadcrumb.ConfigureShifts,
  );

  const {
    pageNumber,
    setPageNumber,
    offset,
    pageSize,
    pageCount,
    setPageCount,
  } = usePagination(8);

  const presetsPage = shiftPresets.slice(offset, offset + pageSize);

  const numPresets = shiftPresets.length;
  useEffect(() => setPageCount(Math.ceil(numPresets / pageSize)), [
    setPageCount,
    numPresets,
    pageSize,
  ]);

  // On database, shift presets are global to organisation instead of specific to care
  // unit. For purposes of UI, though, we simply require users to be managers to be able
  // to change the shift presets for their organisation.
  const hasPermission =
    user?.role === ModelUserRoleEnum.Administrator ||
    (user?.role === ModelUserRoleEnum.Manager && user.careUnitID === unitId);
  if (!hasPermission) {
    history.push(Constants.paths.permissionDenied);
    return null;
  }

  const addPreset = async () => {
    if (orgId === undefined) {
      return;
    }

    const newPreset = {
      nameLabel: "New Preset",
      startHours: 9,
      startMinutes: 0,
      durationHours: 8,
      durationMinutes: 0,
      colour: "#69FFC0",
      isWorking: true,
      presetTime: ModelShiftPresetPresetTimeEnum.Custom,
      careOrganisationID: orgId,
    } as ModelShiftPreset;

    try {
      const addedPreset = await getShiftPresetsAPI().addShiftPreset({
        careOrganisationID: orgId,
        shiftPreset: newPreset,
      });

      const db = await AppDatabase.getInstance();
      if (db !== undefined) {
        await db.shiftPresets.add(addedPreset, addedPreset.id);
      }

      setShiftPresets(current => [...current, addedPreset]);
    } catch (e) {
      handleAPIError(e, "adding new shift preset");
    }
  };

  const updatePreset = async (updated: ModelShiftPreset) => {
    try {
      await getShiftPresetsAPI().updateShiftPreset({
        id: updated.id!,
        updatedShiftPreset: updated,
      });

      const db = await AppDatabase.getInstance();
      if (db !== undefined) {
        await db.shiftPresets.update(updated.id!, updated);
      }

      setShiftPresets(current => {
        const clone = [...current];
        const presetIndex = clone.map(p => p.id).indexOf(updated.id);

        if (presetIndex !== -1) {
          clone[presetIndex] = updated;
        }

        return clone;
      });
    } catch (e) {
      handleAPIError(e, "updating shift preset");
    }
  };

  const deletePreset = async (presetId: number) => {
    try {
      await getShiftPresetsAPI().deleteShiftPreset({ id: presetId });

      const db = await AppDatabase.getInstance();
      if (db !== undefined) {
        await db.shiftPresets.delete(presetId);
      }

      setShiftPresets(current => current.filter(p => p.id !== presetId));
    } catch (e) {
      handleAPIError(e, "deleting shift preset");
    }
  };

  return (
    <PageContainer>
      <div className={classes.root}>
        <Typography className={classes.header} variant="h4">
          Configure Shift Presets
        </Typography>

        {!isLoading && presetsPage.length === 0 && (
          <Typography variant="h6">No shift presets to display.</Typography>
        )}

        <Grid className={classes.grid} container spacing={3}>
          {isLoading &&
            Array.from(Array(numSkeletonCards).keys()).map(i => (
              <Grid key={i} item xs={3}>
                <ShiftPresetCard />
              </Grid>
            ))}

          {!isLoading &&
            presetsPage.map((preset, i) => (
              <Grid key={i} item xs={3}>
                <ShiftPresetCard
                  preset={preset}
                  onSubmit={updatePreset}
                  onDelete={deletePreset}
                />
              </Grid>
            ))}
        </Grid>

        <div className={classes.paginationContainer}>
          <Pagination
            disabled={isLoading}
            className={classes.pagination}
            page={pageNumber}
            count={pageCount}
            onChange={(_event, value) => setPageNumber(value)}
          />
        </div>

        <div className={classes.fabWrapper}>
          <Fab
            disabled={addingPreset}
            className={classes.fab}
            color="secondary"
            aria-label="Add"
            onClick={async () => {
              setAddingPreset(true);
              await addPreset();
              setAddingPreset(false);
            }}
          >
            <AddIcon />
          </Fab>
          {addingPreset && (
            <CircularProgress size={68} className={classes.fabProgress} />
          )}
        </div>
      </div>
    </PageContainer>
  );
};
