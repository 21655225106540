/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EchoHTTPError,
  EchoHTTPErrorFromJSON,
  EchoHTTPErrorToJSON,
  HandlersRecordsPageModelRota,
  HandlersRecordsPageModelRotaFromJSON,
  HandlersRecordsPageModelRotaToJSON,
  ModelRota,
  ModelRotaFromJSON,
  ModelRotaToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface AddRotaRequest {
  rota: ModelRota;
}

export interface DeleteRotaRequest {
  id: number;
}

export interface GetAllRotasRequest {
  careUnitID: number;
  pageNumber?: number;
  pageSize?: number;
  order?: string;
  disablePagination?: boolean;
  startDate?: Date;
}

export interface GetRotaRequest {
  id: number;
}

export interface UpdateRotaRequest {
  id: number;
  rota: ModelRota;
}

/**
 *
 */
export class RotasApi extends runtime.BaseAPI {
  /**
   * Add rota to CQE systems.
   * Add rota.
   */
  async addRotaRaw(
    requestParameters: AddRotaRequest,
  ): Promise<runtime.ApiResponse<ModelRota>> {
    if (
      requestParameters.rota === null ||
      requestParameters.rota === undefined
    ) {
      throw new runtime.RequiredError(
        "rota",
        "Required parameter requestParameters.rota was null or undefined when calling addRota.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/rotas`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelRotaToJSON(requestParameters.rota),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelRotaFromJSON(jsonValue),
    );
  }

  /**
   * Add rota to CQE systems.
   * Add rota.
   */
  async addRota(requestParameters: AddRotaRequest): Promise<ModelRota> {
    const response = await this.addRotaRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete rota from CQE systems.
   * Delete rota.
   */
  async deleteRotaRaw(
    requestParameters: DeleteRotaRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteRota.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/rotas/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete rota from CQE systems.
   * Delete rota.
   */
  async deleteRota(requestParameters: DeleteRotaRequest): Promise<void> {
    await this.deleteRotaRaw(requestParameters);
  }

  /**
   * Retrieve all rotas for the specified care home unit. The results are paginated by default. See arguments for pagination options.
   * Get all rotas for the specified care home unit.
   */
  async getAllRotasRaw(
    requestParameters: GetAllRotasRequest,
  ): Promise<runtime.ApiResponse<HandlersRecordsPageModelRota>> {
    if (
      requestParameters.careUnitID === null ||
      requestParameters.careUnitID === undefined
    ) {
      throw new runtime.RequiredError(
        "careUnitID",
        "Required parameter requestParameters.careUnitID was null or undefined when calling getAllRotas.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["pageNumber"] = requestParameters.pageNumber;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["pageSize"] = requestParameters.pageSize;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.disablePagination !== undefined) {
      queryParameters["disablePagination"] =
        requestParameters.disablePagination;
    }

    if (requestParameters.careUnitID !== undefined) {
      queryParameters["careUnitID"] = requestParameters.careUnitID;
    }

    if (requestParameters.startDate !== undefined) {
      queryParameters[
        "startDate"
      ] = (requestParameters.startDate as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/rotas`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      HandlersRecordsPageModelRotaFromJSON(jsonValue),
    );
  }

  /**
   * Retrieve all rotas for the specified care home unit. The results are paginated by default. See arguments for pagination options.
   * Get all rotas for the specified care home unit.
   */
  async getAllRotas(
    requestParameters: GetAllRotasRequest,
  ): Promise<HandlersRecordsPageModelRota> {
    const response = await this.getAllRotasRaw(requestParameters);
    return await response.value();
  }

  /**
   * Retrieve rota from within the CQE systems with specified ID and for specified care home unit.
   * Get rota with specified ID.
   */
  async getRotaRaw(
    requestParameters: GetRotaRequest,
  ): Promise<runtime.ApiResponse<ModelRota>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getRota.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/rotas/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelRotaFromJSON(jsonValue),
    );
  }

  /**
   * Retrieve rota from within the CQE systems with specified ID and for specified care home unit.
   * Get rota with specified ID.
   */
  async getRota(requestParameters: GetRotaRequest): Promise<ModelRota> {
    const response = await this.getRotaRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update rotas within CQE systems.
   * Update rota.
   */
  async updateRotaRaw(
    requestParameters: UpdateRotaRequest,
  ): Promise<runtime.ApiResponse<ModelRota>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateRota.",
      );
    }

    if (
      requestParameters.rota === null ||
      requestParameters.rota === undefined
    ) {
      throw new runtime.RequiredError(
        "rota",
        "Required parameter requestParameters.rota was null or undefined when calling updateRota.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/rotas/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ModelRotaToJSON(requestParameters.rota),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelRotaFromJSON(jsonValue),
    );
  }

  /**
   * Update rotas within CQE systems.
   * Update rota.
   */
  async updateRota(requestParameters: UpdateRotaRequest): Promise<ModelRota> {
    const response = await this.updateRotaRaw(requestParameters);
    return await response.value();
  }
}
