import { useCallback, useEffect, useState } from "react";

import { ModelShift, getShiftsAPI } from "../api_client";
import {
  AppDatabase,
  HandleAPIErrorFun,
  groupBy,
  useAppContext,
} from "../helpers";
import { InfoRef, useInfoRef } from "./useInfoRef";
import { useAPIUpdatePeriod } from ".";

export interface UserShiftMap {
  [userId: number]: [
    ModelShift | undefined,
    ModelShift | undefined,
    ModelShift | undefined,
    ModelShift | undefined,
    ModelShift | undefined,
    ModelShift | undefined,
    ModelShift | undefined,
  ];
}

const shiftArrayToMap = (shifts: ModelShift[]) => {
  const groupedShifts = groupBy(shifts, "userID");

  const userIds = Object.keys(groupedShifts);

  const shiftsMap: UserShiftMap = {};

  for (const userId of userIds) {
    const userShifts = groupedShifts[userId];

    shiftsMap[Number(userId)] = [
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ];

    for (const shift of userShifts) {
      // We need to find out which day this shift is for so that we can put it into the
      // right slot in the array.
      const dayOfWeek =
        shift.startTime !== undefined
          ? // JS has Sunday = 0 whereas we are normal so have Monday = 0 and Sunday = 6
            shift.startTime.getDay() === 0
            ? 6
            : shift.startTime.getDay() - 1
          : undefined;

      if (dayOfWeek !== undefined) {
        shiftsMap[Number(userId)][dayOfWeek] = shift;
      }
    }
  }

  return shiftsMap;
};

const getShifts = async (
  rotaId: number,
  setShifts: React.Dispatch<React.SetStateAction<UserShiftMap>>,
  infoRef: InfoRef<[number | undefined]>,
  handleAPIError: HandleAPIErrorFun,
) => {
  try {
    const response = await getShiftsAPI().getAllShifts({
      rotaID: rotaId,
      disablePagination: true,
    });

    const shifts = response.records ?? [];

    const db = await AppDatabase.getInstance();

    if (db !== undefined) {
      const clearRemoved = async () => {
        const shiftsToRemove = await db.shifts
          .filter(
            shift =>
              shift.rotaID === rotaId &&
              !shifts.map(s => s.id).includes(shift.id),
          )
          .toArray();

        await db.shifts.bulkDelete(shiftsToRemove.map(s => s.id!));
      };

      await Promise.all([clearRemoved(), db.shifts.bulkPut(shifts)]);
    }

    if (infoRef.current.hasChanged(rotaId)) {
      return;
    }

    setShifts(shiftArrayToMap(shifts));
  } catch (e) {
    handleAPIError(e, "retrieving shifts for rota");
  }
};

export const useShifts = (rotaId: number | undefined) => {
  const { handleAPIError } = useAppContext();

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [shifts, setShifts] = useState<UserShiftMap>({});

  const [setUpdatedFromAPI, isAPIUpdateDue] = useAPIUpdatePeriod(
    `shifts-${rotaId}`,
  );

  const infoRef = useInfoRef(rotaId);

  useEffect(() => {
    setInitialLoadComplete(false);
    setIsLoading(true);
    setShifts({});
  }, [rotaId]);

  useEffect(() => {
    if (initialLoadComplete || rotaId === undefined) {
      setIsLoading(false);
      return;
    }

    const getCached = async () => {
      const db = await AppDatabase.getInstance();
      if (db === undefined) {
        return;
      }

      const cachedShifts = await db.shifts
        .filter(s => s.rotaID === rotaId)
        .toArray();

      if (infoRef.current.hasChanged(rotaId)) {
        return false;
      }

      setShifts(shiftArrayToMap(cachedShifts));

      return cachedShifts.length > 0;
    };

    getCached().then(async cachePresent => {
      if (infoRef.current.hasChanged(rotaId)) {
        return;
      }

      if (!cachePresent || isAPIUpdateDue()) {
        await getShifts(rotaId, setShifts, infoRef, handleAPIError);
      }

      setInitialLoadComplete(true);
      setIsLoading(false);
      setUpdatedFromAPI();
    });
  }, [
    rotaId,
    initialLoadComplete,
    infoRef,
    isAPIUpdateDue,
    setUpdatedFromAPI,
    handleAPIError,
  ]);

  const refreshData = useCallback(async () => {
    if (rotaId === undefined) {
      return;
    }

    setIsLoading(true);
    await getShifts(rotaId, setShifts, infoRef, handleAPIError);
    setIsLoading(false);
    setUpdatedFromAPI();
  }, [infoRef, rotaId, handleAPIError, setUpdatedFromAPI]);

  return [shifts, setShifts, isLoading, refreshData] as const;
};
