/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ModelCareOrganisation,
  ModelCareOrganisationFromJSON,
  ModelCareOrganisationFromJSONTyped,
  ModelCareOrganisationToJSON,
} from "./";

/**
 *
 * @export
 * @interface ModelShiftPreset
 */
export interface ModelShiftPreset {
  /**
   *
   * @type {ModelCareOrganisation}
   * @memberof ModelShiftPreset
   */
  careOrganisation?: ModelCareOrganisation;
  /**
   *
   * @type {number}
   * @memberof ModelShiftPreset
   */
  careOrganisationID?: number;
  /**
   *
   * @type {string}
   * @memberof ModelShiftPreset
   */
  colour?: string;
  /**
   *
   * @type {number}
   * @memberof ModelShiftPreset
   */
  durationHours?: number;
  /**
   *
   * @type {number}
   * @memberof ModelShiftPreset
   */
  durationMinutes?: number;
  /**
   *
   * @type {number}
   * @memberof ModelShiftPreset
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof ModelShiftPreset
   */
  isWorking?: boolean;
  /**
   *
   * @type {string}
   * @memberof ModelShiftPreset
   */
  nameLabel?: string;
  /**
   *
   * @type {string}
   * @memberof ModelShiftPreset
   */
  presetTime?: ModelShiftPresetPresetTimeEnum;
  /**
   *
   * @type {number}
   * @memberof ModelShiftPreset
   */
  startHours?: number;
  /**
   *
   * @type {number}
   * @memberof ModelShiftPreset
   */
  startMinutes?: number;
}

export function ModelShiftPresetFromJSON(json: any): ModelShiftPreset {
  return ModelShiftPresetFromJSONTyped(json, false);
}

export function ModelShiftPresetFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ModelShiftPreset {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    careOrganisation: !exists(json, "careOrganisation")
      ? undefined
      : ModelCareOrganisationFromJSON(json["careOrganisation"]),
    careOrganisationID: !exists(json, "careOrganisationID")
      ? undefined
      : json["careOrganisationID"],
    colour: !exists(json, "colour") ? undefined : json["colour"],
    durationHours: !exists(json, "durationHours")
      ? undefined
      : json["durationHours"],
    durationMinutes: !exists(json, "durationMinutes")
      ? undefined
      : json["durationMinutes"],
    id: !exists(json, "id") ? undefined : json["id"],
    isWorking: !exists(json, "isWorking") ? undefined : json["isWorking"],
    nameLabel: !exists(json, "nameLabel") ? undefined : json["nameLabel"],
    presetTime: !exists(json, "presetTime") ? undefined : json["presetTime"],
    startHours: !exists(json, "startHours") ? undefined : json["startHours"],
    startMinutes: !exists(json, "startMinutes")
      ? undefined
      : json["startMinutes"],
  };
}

export function ModelShiftPresetToJSON(value?: ModelShiftPreset | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    careOrganisation: ModelCareOrganisationToJSON(value.careOrganisation),
    careOrganisationID: value.careOrganisationID,
    colour: value.colour,
    durationHours: value.durationHours,
    durationMinutes: value.durationMinutes,
    id: value.id,
    isWorking: value.isWorking,
    nameLabel: value.nameLabel,
    presetTime: value.presetTime,
    startHours: value.startHours,
    startMinutes: value.startMinutes,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ModelShiftPresetPresetTimeEnum {
  Early = "early",
  Late = "late",
  Night = "night",
  Custom = "custom",
}
