import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { useWindowWidth } from "@react-hook/window-size";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { ModelSurvey } from "../../api_client";
import { Constants } from "../../helpers";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      position: "relative",
      fontSize: "xxx-large",
      textAlign: "center",
    },
    triangleContainer: {
      position: "absolute",
      width: "100%",
      left: "50%",
      marginLeft: "-50%",
    },
    safeTriangleContainer: {
      height: "100%",
      fontSize: "4rem",
    },
    riskyTriangleContainer: {
      fontSize: "3rem",
    },
    unsafeTriangleContainer: {
      fontSize: "2rem",
    },
    triangle: {
      borderLeft: "solid transparent",
      borderRight: "solid transparent",
      borderBottom: "solid",
      position: "absolute",
      left: 0,
    },
    safeTriangle: {
      borderBottomColor: Constants.palette.green,
    },
    riskyTriangle: {
      borderBottomColor: Constants.palette.orange,
    },
    unsafeTriangle: {
      borderBottomColor: Constants.palette.red,
    },
    percent: {
      position: "relative",
      top: "50%",
      zIndex: 10,
    },
    percentSafe: {
      //
    },
    percentRisky: {},
    percentUnsafe: {},
  }),
);

interface SurveyTriangleProps {
  surveys: ModelSurvey[];
}

export const SurveyTriangle: React.FC<SurveyTriangleProps> = ({
  surveys: _surveys,
}) => {
  const classes = useStyles();

  // Todo: Calculate how many carers are safe, risky and unsafe.

  const fractionSafe = 0.92;
  const fractionRisky = 0.06;
  const fractionUnsafe = 0.02;

  const percentSafe = `${Math.round(fractionSafe * 100)}%`;
  const percentRisky = `${Math.round(fractionRisky * 100)}%`;
  const percentUnsafe = `${Math.round(fractionUnsafe * 100)}%`;

  const percentRiskyOrUnsafe = `${Math.round(
    (fractionRisky + fractionUnsafe) * 100,
  )}%`;

  const windowWidth = useWindowWidth();
  const rootRef = React.useRef<HTMLDivElement>(null);
  const [rootWidth, setRootWidth] = useState(0);
  const [rootHeight, setRootHeight] = useState(0);
  const [rootAspect, setRootAspect] = useState(0);

  useEffect(() => {
    if (rootRef.current !== null) {
      const width = rootRef.current.offsetWidth;
      const height = rootRef.current.offsetHeight;
      setRootWidth(width);
      setRootHeight(height);
      setRootAspect(width / height);
    }
  }, [rootRef, windowWidth]);

  // You just can't see the triangles unless they're a certain height so use a minimum
  // triangle height of 20%.

  const riskyTriangleHeight =
    rootHeight * Math.max(fractionUnsafe + fractionRisky, 0.35);
  const riskyTriangleWidth = riskyTriangleHeight * rootAspect;

  const unsafeTriangleHeight = rootHeight * Math.max(fractionUnsafe, 0.2);
  const unsafeTriangleWidth = unsafeTriangleHeight * rootAspect;

  return (
    <div className={classes.root} ref={rootRef}>
      <div
        className={clsx(
          classes.triangleContainer,
          classes.safeTriangleContainer,
        )}
      >
        <span
          className={clsx(classes.triangle, classes.safeTriangle)}
          style={{
            left: `calc(50% - ${rootWidth / 2}px)`,
            borderLeftWidth: rootWidth / 2,
            borderRightWidth: rootWidth / 2,
            borderBottomWidth: rootHeight,
          }}
        />
        <span
          className={clsx(classes.percent, classes.percentSafe)}
          style={{ top: rootHeight / 2 }}
        >
          {percentSafe}
        </span>
      </div>
      <div
        className={clsx(
          classes.triangleContainer,
          classes.riskyTriangleContainer,
        )}
        style={{ height: percentRiskyOrUnsafe }}
      >
        <span
          className={clsx(classes.triangle, classes.riskyTriangle)}
          style={{
            left: `calc(50% - ${riskyTriangleWidth / 2}px)`,
            borderLeftWidth: riskyTriangleWidth / 2,
            borderRightWidth: riskyTriangleWidth / 2,
            borderBottomWidth: riskyTriangleHeight,
          }}
        />
        <span
          className={clsx(classes.percent, classes.percentRisky)}
          style={{ top: riskyTriangleHeight / 2 }}
        >
          {percentRisky}
        </span>
      </div>
      <div
        className={clsx(
          classes.triangleContainer,
          classes.unsafeTriangleContainer,
        )}
        style={{ height: percentUnsafe }}
      >
        <span
          className={clsx(classes.triangle, classes.unsafeTriangle)}
          style={{
            left: `calc(50% - ${unsafeTriangleWidth / 2}px)`,
            borderLeftWidth: unsafeTriangleWidth / 2,
            borderRightWidth: unsafeTriangleWidth / 2,
            borderBottomWidth: unsafeTriangleHeight,
          }}
        />
        <span
          className={clsx(classes.percent, classes.percentUnsafe)}
          style={{ top: unsafeTriangleHeight / 2 - 10 }}
        >
          {percentUnsafe}
        </span>
      </div>
    </div>
  );
};
