import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popper,
  TextField,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Done as TickIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";

import { ModelUser } from "../../api_client";
import { userFullName } from "../../helpers";
import { UserAvatar } from "../UserAvatar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 512,
    },
    optionUserName: {
      marginLeft: 8,
    },
    optionSelectedAvatar: {
      backgroundColor: theme.palette.info.main,
      color: "white",
    },
    submitWrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    submitProgress: {
      color: theme.palette.success.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    leftActionButton: {
      marginRight: "auto",
      marginLeft: theme.spacing(1),
    },
  }),
);

interface AddUsersDialogProps {
  options: ModelUser[];
  open: boolean;
  onClose: () => void;
  onSubmit: (usersToAdd: ModelUser[]) => void;
  previousUserIds: number[] | undefined;
}

export const AddUsersDialog: React.FC<AddUsersDialogProps> = ({
  options,
  open,
  onClose,
  onSubmit,
  previousUserIds,
}) => {
  const classes = useStyles();
  const [selectedUsers, setSelectedUsers] = useState<ModelUser[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getValidPrevUsersNotAdded = (current: ModelUser[]) => {
    if (previousUserIds === undefined) {
      return [];
    }

    const idToUser = options.reduce((map, option) => {
      map[option.id!] = option;
      return map;
    }, {} as { [userId: number]: ModelUser });

    const validUserIds = previousUserIds.filter(previousUserId => {
      const optionsIds = options.map(u => u.id);
      const currentSelectionIds = current.map(u => u.id);

      return (
        optionsIds.includes(previousUserId) &&
        !currentSelectionIds.includes(previousUserId)
      );
    });

    return validUserIds.map(userId => idToUser[userId]);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="add-users-dialog-title"
      className={classes.root}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="add-users-dialog-title">Add carers to rota</DialogTitle>

      <DialogContent>
        <Autocomplete
          multiple
          PopperComponent={props => <Popper placement="top" {...props} />}
          disableCloseOnSelect
          options={options}
          getOptionLabel={userFullName}
          defaultValue={[]}
          value={selectedUsers}
          getOptionSelected={(option, value) => option.id === value.id}
          noOptionsText="No users found"
          onChange={(_event, newValue) => setSelectedUsers(newValue)}
          renderOption={(option, { selected }) => (
            <>
              {selected ? (
                <Avatar
                  aria-label="user selected avatar"
                  className={classes.optionSelectedAvatar}
                >
                  <TickIcon />
                </Avatar>
              ) : (
                <UserAvatar user={option} />
              )}
              <span className={classes.optionUserName}>
                {userFullName(option)}
              </span>
            </>
          )}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label="Select users"
              placeholder="Users"
            />
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          className={classes.leftActionButton}
          onClick={() => {
            setSelectedUsers(current => {
              const toAdd = getValidPrevUsersNotAdded(current);
              return current.concat(toAdd);
            });
          }}
          disabled={
            previousUserIds === undefined ||
            previousUserIds.length === 0 ||
            getValidPrevUsersNotAdded(selectedUsers).length === 0
          }
        >
          Select users from last rota
        </Button>

        <Button onClick={onClose} color="primary">
          Cancel
        </Button>

        <div className={classes.submitWrapper}>
          <Button
            color="primary"
            disabled={isSubmitting}
            onClick={async () => {
              if (selectedUsers.length === 0) {
                onClose();
                return;
              }

              setIsSubmitting(true);
              await onSubmit(selectedUsers);
              setIsSubmitting(false);
              setSelectedUsers([]);
              onClose();
            }}
          >
            Submit
          </Button>
          {isSubmitting && (
            <CircularProgress size={24} className={classes.submitProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};
