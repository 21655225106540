import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { Lens } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    innerNumber: {
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      margin: "auto",
      color: "white",
    },
  }),
);

interface ShiftSafetyIconProps {
  numUsers: number;
  className?: string;
  classes?: { icon?: string; text?: string };
}

export const ShiftSafetyIcon: React.FC<ShiftSafetyIconProps> = ({
  numUsers,
  className,
  classes: classesProps,
}) => {
  const classes = { ...useStyles(), ...classesProps };
  return (
    <div className={clsx(classes.root, className)}>
      <Lens className={classes.icon} />
      <span className={clsx(classes.innerNumber, classes.text)}>
        {numUsers}
      </span>
    </div>
  );
};

export {
  EventBusy as EndDateIcon,
  AssignmentInd as RoleIcon,
  Event as StartDateIcon,
  WorkOutline as StatusIcon,
  HourglassEmpty as SupernumeraryIcon,
  Lens as UserSafetyIcon,
  Info as NotEnoughDataIcon,
  ListAlt as SurveyIcon,
} from "@material-ui/icons";
