/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ViewmodelAddEmployee
 */
export interface ViewmodelAddEmployee {
  /**
   *
   * @type {number}
   * @memberof ViewmodelAddEmployee
   */
  careUnitID?: number;
  /**
   *
   * @type {string}
   * @memberof ViewmodelAddEmployee
   */
  email?: string;
  /**
   *
   * @type {Date}
   * @memberof ViewmodelAddEmployee
   */
  endDate?: Date;
  /**
   *
   * @type {string}
   * @memberof ViewmodelAddEmployee
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ViewmodelAddEmployee
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ViewmodelAddEmployee
   */
  role?: ViewmodelAddEmployeeRoleEnum;
  /**
   *
   * @type {Date}
   * @memberof ViewmodelAddEmployee
   */
  startDate?: Date;
  /**
   *
   * @type {string}
   * @memberof ViewmodelAddEmployee
   */
  status?: ViewmodelAddEmployeeStatusEnum;
}

export function ViewmodelAddEmployeeFromJSON(json: any): ViewmodelAddEmployee {
  return ViewmodelAddEmployeeFromJSONTyped(json, false);
}

export function ViewmodelAddEmployeeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ViewmodelAddEmployee {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    careUnitID: !exists(json, "careUnitID") ? undefined : json["careUnitID"],
    email: !exists(json, "email") ? undefined : json["email"],
    endDate: !exists(json, "endDate") ? undefined : new Date(json["endDate"]),
    firstName: !exists(json, "firstName") ? undefined : json["firstName"],
    lastName: !exists(json, "lastName") ? undefined : json["lastName"],
    role: !exists(json, "role") ? undefined : json["role"],
    startDate: !exists(json, "startDate")
      ? undefined
      : new Date(json["startDate"]),
    status: !exists(json, "status") ? undefined : json["status"],
  };
}

export function ViewmodelAddEmployeeToJSON(
  value?: ViewmodelAddEmployee | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    careUnitID: value.careUnitID,
    email: value.email,
    endDate:
      value.endDate === undefined ? undefined : value.endDate.toISOString(),
    firstName: value.firstName,
    lastName: value.lastName,
    role: value.role,
    startDate:
      value.startDate === undefined ? undefined : value.startDate.toISOString(),
    status: value.status,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ViewmodelAddEmployeeRoleEnum {
  Carer = "carer",
  Manager = "manager",
}
/**
 * @export
 * @enum {string}
 */
export enum ViewmodelAddEmployeeStatusEnum {
  NotApplicable = "not_applicable",
  Applicant = "applicant",
  ActiveEmployee = "active_employee",
  Banked = "banked",
  Left = "left",
}
