import { useCallback, useEffect, useState } from "react";

import {
  GetAllUsersWithRoleEnum,
  ModelUser,
  ModelUserRoleEnum,
} from "../api_client";
import { getUsersAPI } from "../api_client";
import { AppDatabase, HandleAPIErrorFun, useAppContext } from "../helpers";
import { useAPIUpdatePeriod } from ".";

const getAdmins = async (
  setUsers: React.Dispatch<React.SetStateAction<ModelUser[]>>,
  handleAPIError: HandleAPIErrorFun,
) => {
  try {
    const newUsers = await getUsersAPI().getAllUsers({
      withRole: GetAllUsersWithRoleEnum.Administrator,
      disablePagination: true,
    });

    const records = newUsers?.records;
    if (records === undefined) {
      return;
    }

    const db = await AppDatabase.getInstance();

    if (db !== undefined) {
      const clearRemoved = async () => {
        // If there are users in the cache that aren't in the live API results, they need to
        // be removed.
        const usersToRemove = await db.users
          .where("role")
          .equals(ModelUserRoleEnum.Administrator)
          .toArray();
        await db.users.bulkDelete(usersToRemove.map(user => user.id!));
      };

      await Promise.all([clearRemoved(), db.users.bulkPut(records)]);
    }

    setUsers(records);
  } catch (e) {
    handleAPIError(e, "retrieving users for care home unit");
  }
};

export const useAdmins = () => {
  const { handleAPIError } = useAppContext();

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [admins, setAdmins] = useState<ModelUser[]>([]);

  const [setUpdatedFromAPI, isAPIUpdateDue] = useAPIUpdatePeriod("admins");

  useEffect(() => {
    if (initialLoadComplete) {
      return;
    }

    const getCachedUsers = async () => {
      const db = await AppDatabase.getInstance();
      if (db === undefined) {
        return;
      }

      const cachedUsers = await db.users
        .where("role")
        .equals(ModelUserRoleEnum.Administrator)
        .toArray();
      setAdmins(cachedUsers);
      return cachedUsers.length > 0;
    };

    getCachedUsers().then(async cachePresent => {
      if (!cachePresent || isAPIUpdateDue()) {
        await getAdmins(setAdmins, handleAPIError);
      }

      setInitialLoadComplete(true);
      setIsLoading(false);
      setUpdatedFromAPI();
    });
  }, [handleAPIError, setUpdatedFromAPI, isAPIUpdateDue, initialLoadComplete]);

  const refreshUsers = useCallback(async () => {
    setIsLoading(true);
    await getAdmins(setAdmins, handleAPIError);
    setIsLoading(false);
    setUpdatedFromAPI();
  }, [handleAPIError, setUpdatedFromAPI]);

  return [admins, setAdmins, isLoading, refreshUsers] as const;
};
