import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  TextField,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { ColorPicker } from "material-ui-color";
import React, { useEffect, useState } from "react";

import {
  ModelShiftPreset,
  ModelShiftPresetPresetTimeEnum,
} from "../api_client";
import { getShiftPresetTimes } from "../helpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    header: {
      "& .MuiCardHeader-action": {
        marginTop: 0,
      },
      "& .MuiInputBase-root": {
        fontSize: theme.typography.h5.fontSize,
      },
    },
    nameLabel: {
      "&:not(:hover):not(:focus) .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(255, 255, 255, 0)",
      },
    },
    cardContent: {
      paddingTop: 0,
    },
    actions: {
      "& > :not(:first-child)": {
        marginLeft: "auto",
      },
    },
    checkbox: {
      marginTop: theme.spacing(1),
    },
    colourLabel: {
      position: "initial",
    },
    updateWrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    updateProgress: {
      color: theme.palette.success.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    skeletonFormControl: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      height: 62,
    },
  }),
);

interface ShiftPresetCardProps {
  preset?: ModelShiftPreset;
  onSubmit?: (updated: ModelShiftPreset) => void;
  onDelete?: (presetId: number) => void;
}

export const ShiftPresetCard: React.FC<ShiftPresetCardProps> = ({
  preset,
  onSubmit,
  onDelete,
}) => {
  const classes = useStyles();

  const [initialStartTime, initialEndTime] = getShiftPresetTimes(
    new Date(),
    preset,
  );

  const initialNameLabel = preset?.nameLabel;
  const initialStartMs = initialStartTime?.getTime();
  const initialEndMs = initialEndTime?.getTime();
  const initialColour = preset?.colour;
  const initialIsWorking = preset?.isWorking ?? true;

  const [nameLabel, setNameLabel] = useState(initialNameLabel);
  const [startTime, setStartTime] = useState<Date | null>(initialStartTime);
  const [endTime, setEndTime] = useState<Date | null>(initialEndTime);
  const [colour, setColour] = useState(initialColour);
  const [isWorking, setIsWorking] = useState(initialIsWorking);

  // Using Date object as dependency causes continuous updates.
  useEffect(() => {
    setNameLabel(initialNameLabel);
    setStartTime(
      initialStartMs !== undefined ? new Date(initialStartMs) : null,
    );
    setEndTime(initialEndMs !== undefined ? new Date(initialEndMs) : null);
    setColour(initialColour);
    setIsWorking(initialIsWorking);
  }, [
    initialNameLabel,
    initialStartMs,
    initialEndMs,
    initialColour,
    initialIsWorking,
  ]);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const startHours = startTime?.getHours() ?? 0;
  const startMinutes = startTime?.getMinutes() ?? 0;

  let durationTotalMinutes =
    ((endTime?.getTime() ?? 0) - (startTime?.getTime() ?? 0)) / 1000 / 60;

  // If end time is earlier than start time, assume it's referring to the next day (as
  // is the case for specifying night shifts).
  if (
    startTime !== null &&
    endTime !== null &&
    startTime.getTime() > endTime.getTime()
  ) {
    durationTotalMinutes += 60 * 24;
  }

  const durationHours = Math.floor(durationTotalMinutes / 60);
  const durationMinutes = Math.round(durationTotalMinutes % 60);

  const hasChanged =
    nameLabel !== preset?.nameLabel ||
    startHours !== preset?.startHours ||
    startMinutes !== preset?.startMinutes ||
    durationHours !== preset?.durationHours ||
    durationMinutes !== preset?.durationMinutes ||
    colour !== preset?.colour ||
    isWorking !== preset?.isWorking;

  const resetValues = () => {
    setNameLabel(initialNameLabel);
    setStartTime(initialStartTime);
    setEndTime(initialEndTime);
    setColour(initialColour);
    setIsWorking(initialIsWorking);
  };

  const saveChanges = async () => {
    if (
      onSubmit === undefined ||
      preset === undefined ||
      startTime === null ||
      endTime === null ||
      !hasChanged
    ) {
      return;
    }

    const updated = {
      ...preset,
      nameLabel: nameLabel ?? "",
      startHours,
      startMinutes,
      durationHours,
      durationMinutes,
      colour: colour ?? "",
      isWorking,
    } as ModelShiftPreset;

    await onSubmit(updated);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        title={
          preset !== undefined ? (
            <TextField
              className={classes.nameLabel}
              value={nameLabel}
              onChange={event => setNameLabel(event.target.value)}
              size="medium"
              variant="outlined"
            />
          ) : (
            <Skeleton width="80%" height={56} />
          )
        }
        action={
          preset?.presetTime === ModelShiftPresetPresetTimeEnum.Custom && (
            <Button
              disabled={
                preset?.id === undefined || onDelete === undefined || isDeleting
              }
              onClick={async () => {
                if (preset?.id === undefined) {
                  return;
                }

                setIsDeleting(true);
                if (onDelete !== undefined) {
                  await onDelete(preset.id);
                }
                setIsDeleting(false);
              }}
            >
              <DeleteIcon />
            </Button>
          )
        }
      />

      <CardContent className={classes.cardContent}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {preset !== undefined ? (
            <TimePicker
              margin="normal"
              autoOk
              ampm={false}
              label="Start time"
              value={startTime}
              onChange={setStartTime}
            />
          ) : (
            <Skeleton variant="text" className={classes.skeletonFormControl} />
          )}

          {preset !== undefined ? (
            <TimePicker
              margin="normal"
              autoOk
              ampm={false}
              label="End time"
              value={endTime}
              onChange={setEndTime}
            />
          ) : (
            <Skeleton variant="text" className={classes.skeletonFormControl} />
          )}
        </MuiPickersUtilsProvider>

        {preset !== undefined ? (
          <FormControl margin="normal">
            <InputLabel shrink className={classes.colourLabel}>
              Colour
            </InputLabel>
            <ColorPicker
              disableAlpha
              value={colour}
              onChange={value => setColour("#" + value.hex)}
            />
          </FormControl>
        ) : (
          <Skeleton variant="text" className={classes.skeletonFormControl} />
        )}

        {preset !== undefined ? (
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                checked={isWorking}
                onChange={event => setIsWorking(event.target.checked)}
              />
            }
            label="Is working shift?"
          />
        ) : (
          <Skeleton variant="text" className={classes.skeletonFormControl} />
        )}
      </CardContent>

      <CardActions className={classes.actions}>
        <Button
          disabled={
            preset === undefined || !hasChanged || isDeleting || isUpdating
          }
          variant="contained"
          onClick={resetValues}
        >
          Cancel
        </Button>

        <div className={classes.updateWrapper}>
          <Button
            disabled={
              preset === undefined || !hasChanged || isDeleting || isUpdating
            }
            variant="contained"
            color="secondary"
            onClick={async () => {
              setIsUpdating(true);
              await saveChanges();
              setIsUpdating(false);
            }}
          >
            Save
          </Button>
          {isUpdating && (
            <CircularProgress size={24} className={classes.updateProgress} />
          )}
        </div>
      </CardActions>
    </Card>
  );
};
