import {
  Button,
  Link,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { PageContainer } from "../components";
import { Constants } from "../helpers";
import { Breadcrumb, useBreadcrumbs } from "../hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paragraph: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    actions: {
      marginTop: theme.spacing(4),
    },
  }),
);

export const PermissionDenied: React.FC = () => {
  const classes = useStyles();

  useBreadcrumbs(Breadcrumb.PermissionDenied);

  return (
    <PageContainer>
      <Typography variant="h4">Permission Denied</Typography>

      <Typography variant="body1" className={classes.paragraph}>
        You don&apos;t have permission to access this page or resource.
      </Typography>

      <Typography variant="body1" className={classes.paragraph}>
        If you think this is in error, please contact us on{" "}
        <Link
          to={`mailto:${Constants.contactDetails.adminEmail}`}
          component={RouterLink}
        >
          {Constants.contactDetails.adminEmail}
        </Link>
        .
      </Typography>

      <div className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          component={RouterLink}
          to={Constants.paths.home}
        >
          Back to home
        </Button>
      </div>
    </PageContainer>
  );
};
