import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
} from "@material-ui/core";
import {
  Person as CarerIcon,
  SupervisedUserCircle as ManagerIcon,
  Assignment as SurveyIcon,
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { ModelCareUnit } from "../api_client";
import { Colour, Constants, modelToURLParams } from "../helpers";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  avatar: {},
  actionButton: {
    marginLeft: "auto",
  },
  infoKey: {
    fontWeight: "bold",
  },
  infoValue: {
    marginLeft: "auto",
  },
});

interface CareUnitCardProps {
  careUnit?: ModelCareUnit;
  orgId?: number;
  orgName?: string;
}

export const CareUnitCard: React.FC<CareUnitCardProps> = ({
  careUnit,
  orgId,
  orgName,
}) => {
  const classes = useStyles();

  const avatarColour = Colour.generateForString(careUnit?.name);

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          careUnit !== undefined ? (
            <Avatar
              aria-label="care-home-unit"
              className={classes.avatar}
              style={{
                backgroundColor: avatarColour.toHex(),
                color: avatarColour.fgColour(),
              }}
            >
              {careUnit?.name && careUnit.name[0]}
            </Avatar>
          ) : (
            <Skeleton variant="circle" width={40} height={40} />
          )
        }
        title={
          careUnit !== undefined ? (
            careUnit.name
          ) : (
            <Skeleton width="80%" height={56} />
          )
        }
      />

      <CardContent>
        <List>
          <ListItem>
            {careUnit !== undefined ? (
              <>
                <ListItemIcon>
                  <ManagerIcon />
                </ListItemIcon>
                <span className={classes.infoKey}>N# managers:</span>{" "}
                <span className={classes.infoValue}>
                  {careUnit.numManagers}
                </span>
              </>
            ) : (
              <Skeleton width="100%" />
            )}
          </ListItem>

          <ListItem>
            {careUnit !== undefined ? (
              <>
                <ListItemIcon>
                  <CarerIcon />
                </ListItemIcon>
                <span className={classes.infoKey}>N# carers:</span>{" "}
                <span className={classes.infoValue}>{careUnit.numCarers}</span>
              </>
            ) : (
              <Skeleton width="100%" />
            )}
          </ListItem>
        </List>

        <ListItem>
          {careUnit !== undefined ? (
            <>
              <ListItemIcon>
                <SurveyIcon />
              </ListItemIcon>
              <span className={classes.infoKey}>N# surveys completed:</span>{" "}
              <span className={classes.infoValue}>{careUnit.numSurveys}</span>
            </>
          ) : (
            <Skeleton width="100%" />
          )}
        </ListItem>
      </CardContent>

      <CardActions disableSpacing>
        <Button
          className={classes.actionButton}
          component={RouterLink}
          to={Constants.paths.organisationUnit
            .replace(":orgInfo", modelToURLParams([orgId, orgName]))
            .replace(
              ":unitInfo",
              modelToURLParams([careUnit?.id, careUnit?.name]),
            )}
          variant="contained"
          color="secondary"
          size="large"
          disabled={careUnit === undefined}
        >
          View
        </Button>
      </CardActions>
    </Card>
  );
};
