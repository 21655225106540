/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EchoHTTPError,
  EchoHTTPErrorFromJSON,
  EchoHTTPErrorToJSON,
  HandlersRecordsPageModelShift,
  HandlersRecordsPageModelShiftFromJSON,
  HandlersRecordsPageModelShiftToJSON,
  ModelShift,
  ModelShiftFromJSON,
  ModelShiftToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface AddShiftRequest {
  shift: ModelShift;
}

export interface DeleteShiftRequest {
  id: number;
}

export interface GetAllShiftsRequest {
  rotaID: number;
  pageNumber?: number;
  pageSize?: number;
  order?: string;
  disablePagination?: boolean;
}

export interface GetShiftRequest {
  id: number;
}

export interface UpdateShiftRequest {
  id: number;
  shift: ModelShift;
}

/**
 *
 */
export class ShiftsApi extends runtime.BaseAPI {
  /**
   * Add shift to CQE systems.
   * Add shift.
   */
  async addShiftRaw(
    requestParameters: AddShiftRequest,
  ): Promise<runtime.ApiResponse<ModelShift>> {
    if (
      requestParameters.shift === null ||
      requestParameters.shift === undefined
    ) {
      throw new runtime.RequiredError(
        "shift",
        "Required parameter requestParameters.shift was null or undefined when calling addShift.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/shifts`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelShiftToJSON(requestParameters.shift),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelShiftFromJSON(jsonValue),
    );
  }

  /**
   * Add shift to CQE systems.
   * Add shift.
   */
  async addShift(requestParameters: AddShiftRequest): Promise<ModelShift> {
    const response = await this.addShiftRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete shift from CQE systems.
   * Delete shift.
   */
  async deleteShiftRaw(
    requestParameters: DeleteShiftRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteShift.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/shifts/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete shift from CQE systems.
   * Delete shift.
   */
  async deleteShift(requestParameters: DeleteShiftRequest): Promise<void> {
    await this.deleteShiftRaw(requestParameters);
  }

  /**
   * Retrieve all shift results that exist within the CQE systems. The results are paginated by default. See arguments for pagination options.
   * Get all shifts in the CQE system.
   */
  async getAllShiftsRaw(
    requestParameters: GetAllShiftsRequest,
  ): Promise<runtime.ApiResponse<HandlersRecordsPageModelShift>> {
    if (
      requestParameters.rotaID === null ||
      requestParameters.rotaID === undefined
    ) {
      throw new runtime.RequiredError(
        "rotaID",
        "Required parameter requestParameters.rotaID was null or undefined when calling getAllShifts.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["pageNumber"] = requestParameters.pageNumber;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["pageSize"] = requestParameters.pageSize;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.disablePagination !== undefined) {
      queryParameters["disablePagination"] =
        requestParameters.disablePagination;
    }

    if (requestParameters.rotaID !== undefined) {
      queryParameters["rotaID"] = requestParameters.rotaID;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/shifts`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      HandlersRecordsPageModelShiftFromJSON(jsonValue),
    );
  }

  /**
   * Retrieve all shift results that exist within the CQE systems. The results are paginated by default. See arguments for pagination options.
   * Get all shifts in the CQE system.
   */
  async getAllShifts(
    requestParameters: GetAllShiftsRequest,
  ): Promise<HandlersRecordsPageModelShift> {
    const response = await this.getAllShiftsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Retrieve shift from within the CQE systems with specified ID.
   * Get shift with specified ID.
   */
  async getShiftRaw(
    requestParameters: GetShiftRequest,
  ): Promise<runtime.ApiResponse<ModelShift>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getShift.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/shifts/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelShiftFromJSON(jsonValue),
    );
  }

  /**
   * Retrieve shift from within the CQE systems with specified ID.
   * Get shift with specified ID.
   */
  async getShift(requestParameters: GetShiftRequest): Promise<ModelShift> {
    const response = await this.getShiftRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update shifts within CQE systems.
   * Update shift.
   */
  async updateShiftRaw(
    requestParameters: UpdateShiftRequest,
  ): Promise<runtime.ApiResponse<ModelShift>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateShift.",
      );
    }

    if (
      requestParameters.shift === null ||
      requestParameters.shift === undefined
    ) {
      throw new runtime.RequiredError(
        "shift",
        "Required parameter requestParameters.shift was null or undefined when calling updateShift.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/shifts/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ModelShiftToJSON(requestParameters.shift),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelShiftFromJSON(jsonValue),
    );
  }

  /**
   * Update shifts within CQE systems.
   * Update shift.
   */
  async updateShift(
    requestParameters: UpdateShiftRequest,
  ): Promise<ModelShift> {
    const response = await this.updateShiftRaw(requestParameters);
    return await response.value();
  }
}
