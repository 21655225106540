/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ModelRatingQuestion
 */
export interface ModelRatingQuestion {
  /**
   *
   * @type {string}
   * @memberof ModelRatingQuestion
   */
  name?: ModelRatingQuestionNameEnum;
  /**
   *
   * @type {number}
   * @memberof ModelRatingQuestion
   */
  rangeStart?: number;
  /**
   *
   * @type {number}
   * @memberof ModelRatingQuestion
   */
  rangeStep?: number;
  /**
   *
   * @type {number}
   * @memberof ModelRatingQuestion
   */
  rangeStop?: number;
  /**
   *
   * @type {string}
   * @memberof ModelRatingQuestion
   */
  startLabel?: string;
  /**
   *
   * @type {string}
   * @memberof ModelRatingQuestion
   */
  stopLabel?: string;
  /**
   *
   * @type {string}
   * @memberof ModelRatingQuestion
   */
  text?: string;
  /**
   *
   * @type {number}
   * @memberof ModelRatingQuestion
   */
  userChoice?: number;
}

export function ModelRatingQuestionFromJSON(json: any): ModelRatingQuestion {
  return ModelRatingQuestionFromJSONTyped(json, false);
}

export function ModelRatingQuestionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ModelRatingQuestion {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    rangeStart: !exists(json, "rangeStart") ? undefined : json["rangeStart"],
    rangeStep: !exists(json, "rangeStep") ? undefined : json["rangeStep"],
    rangeStop: !exists(json, "rangeStop") ? undefined : json["rangeStop"],
    startLabel: !exists(json, "startLabel") ? undefined : json["startLabel"],
    stopLabel: !exists(json, "stopLabel") ? undefined : json["stopLabel"],
    text: !exists(json, "text") ? undefined : json["text"],
    userChoice: !exists(json, "userChoice") ? undefined : json["userChoice"],
  };
}

export function ModelRatingQuestionToJSON(
  value?: ModelRatingQuestion | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    rangeStart: value.rangeStart,
    rangeStep: value.rangeStep,
    rangeStop: value.rangeStop,
    startLabel: value.startLabel,
    stopLabel: value.stopLabel,
    text: value.text,
    userChoice: value.userChoice,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ModelRatingQuestionNameEnum {
  Ncas = "ncas",
  Happiness = "happiness",
}
