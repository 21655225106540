import { ModelUser } from "../api_client";

const SESSION_STORAGE_KEY = "@CQE_FRONTEND:session";
const CURRENT_SESSION_VERSION = 2;

export interface SessionStorage {
  version: number; // When version increases, we invalid all previous sessions.
  user?: ModelUser;

  /**
   * We keep track on when we've updated our local values from the API so that our
   * local values don't get too stale.
   */
  apiRefreshTimestamps: {
    [key: string]: number;
  };
}

export class Session {
  private static instance: Session | undefined;

  private storedSession: SessionStorage = {
    version: CURRENT_SESSION_VERSION,
    user: undefined,
    apiRefreshTimestamps: {},
  };

  private constructor() {
    const storedSession = this.loadSession();
    if (storedSession !== undefined) {
      this.storedSession = storedSession;
    }
    this.storeSession();
  }

  public static getInstance() {
    if (this.instance === undefined) {
      this.instance = new Session();
    }

    return this.instance;
  }

  public get user() {
    return this.storedSession.user;
  }

  public set user(user: ModelUser | undefined) {
    this.storedSession.user = user;
    this.storeSession();
  }

  public apiRefreshTimestamp(key: string) {
    const timestamp = this.storedSession.apiRefreshTimestamps[key];
    return timestamp !== undefined ? new Date(timestamp) : undefined;
  }

  public setAPIRefreshTimestamp(key: string, value: Date) {
    this.storedSession.apiRefreshTimestamps[key] = value.getTime();
    this.storeSession();
  }

  private loadSession() {
    const storedSessionJson = localStorage.getItem(SESSION_STORAGE_KEY);
    if (storedSessionJson === null) {
      // We don't have any stored session, so persist this new one.
      return undefined;
    }

    // If version doesn't match, we ignore the stored session and stomp over it.
    const storedSession = JSON.parse(storedSessionJson);
    if (storedSession.version !== this.storedSession.version) {
      return undefined;
    }

    return storedSession as SessionStorage;
  }

  private storeSession() {
    localStorage.setItem(
      SESSION_STORAGE_KEY,
      JSON.stringify(this.storedSession),
    );
  }
}
