/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EchoHTTPError,
  EchoHTTPErrorFromJSON,
  EchoHTTPErrorToJSON,
  HandlersRecordsPageModelUser,
  HandlersRecordsPageModelUserFromJSON,
  HandlersRecordsPageModelUserToJSON,
  ModelUser,
  ModelUserFromJSON,
  ModelUserToJSON,
  ViewmodelAddAdmin,
  ViewmodelAddAdminFromJSON,
  ViewmodelAddAdminToJSON,
  ViewmodelAddEmployee,
  ViewmodelAddEmployeeFromJSON,
  ViewmodelAddEmployeeToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface AddAdminRequest {
  admin: ViewmodelAddAdmin;
}

export interface AddEmployeeRequest {
  employee: ViewmodelAddEmployee;
}

export interface DeleteUserRequest {
  id: number;
}

export interface GetAllUsersRequest {
  careUnitID?: number;
  withRole?: GetAllUsersWithRoleEnum;
  pageNumber?: number;
  pageSize?: number;
  order?: string;
  disablePagination?: boolean;
}

export interface GetUserRequest {
  id: number;
}

export interface UpdateUserRequest {
  id: number;
  user: ModelUser;
}

/**
 *
 */
export class UsersApi extends runtime.BaseAPI {
  /**
   * Add CQE administrator to CQE systems.
   * Add CQE administrator.
   */
  async addAdminRaw(
    requestParameters: AddAdminRequest,
  ): Promise<runtime.ApiResponse<ModelUser>> {
    if (
      requestParameters.admin === null ||
      requestParameters.admin === undefined
    ) {
      throw new runtime.RequiredError(
        "admin",
        "Required parameter requestParameters.admin was null or undefined when calling addAdmin.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/users/admins`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ViewmodelAddAdminToJSON(requestParameters.admin),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelUserFromJSON(jsonValue),
    );
  }

  /**
   * Add CQE administrator to CQE systems.
   * Add CQE administrator.
   */
  async addAdmin(requestParameters: AddAdminRequest): Promise<ModelUser> {
    const response = await this.addAdminRaw(requestParameters);
    return await response.value();
  }

  /**
   * Add care home manager or employee to care home unit.
   * Add care home manager or carer.
   */
  async addEmployeeRaw(
    requestParameters: AddEmployeeRequest,
  ): Promise<runtime.ApiResponse<ModelUser>> {
    if (
      requestParameters.employee === null ||
      requestParameters.employee === undefined
    ) {
      throw new runtime.RequiredError(
        "employee",
        "Required parameter requestParameters.employee was null or undefined when calling addEmployee.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/users/employees`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ViewmodelAddEmployeeToJSON(requestParameters.employee),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelUserFromJSON(jsonValue),
    );
  }

  /**
   * Add care home manager or employee to care home unit.
   * Add care home manager or carer.
   */
  async addEmployee(requestParameters: AddEmployeeRequest): Promise<ModelUser> {
    const response = await this.addEmployeeRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete user from CQE systems.
   * Delete user.
   */
  async deleteUserRaw(
    requestParameters: DeleteUserRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteUser.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/users/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete user from CQE systems.
   * Delete user.
   */
  async deleteUser(requestParameters: DeleteUserRequest): Promise<void> {
    await this.deleteUserRaw(requestParameters);
  }

  /**
   * Retrieve all users that exist within the CQE systems. This includes care home carers, care home managers and CQE administrators. The results are paginated by default. See arguments for pagination options.
   * Get all users in the CQE system.
   */
  async getAllUsersRaw(
    requestParameters: GetAllUsersRequest,
  ): Promise<runtime.ApiResponse<HandlersRecordsPageModelUser>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.careUnitID !== undefined) {
      queryParameters["careUnitID"] = requestParameters.careUnitID;
    }

    if (requestParameters.withRole !== undefined) {
      queryParameters["withRole"] = requestParameters.withRole;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["pageNumber"] = requestParameters.pageNumber;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["pageSize"] = requestParameters.pageSize;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.disablePagination !== undefined) {
      queryParameters["disablePagination"] =
        requestParameters.disablePagination;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/users`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      HandlersRecordsPageModelUserFromJSON(jsonValue),
    );
  }

  /**
   * Retrieve all users that exist within the CQE systems. This includes care home carers, care home managers and CQE administrators. The results are paginated by default. See arguments for pagination options.
   * Get all users in the CQE system.
   */
  async getAllUsers(
    requestParameters: GetAllUsersRequest,
  ): Promise<HandlersRecordsPageModelUser> {
    const response = await this.getAllUsersRaw(requestParameters);
    return await response.value();
  }

  /**
   * Retrieve user info from DB for user making request.
   * Get info for user making API request.
   */
  async getCurrentUserRaw(): Promise<runtime.ApiResponse<ModelUser>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/users/me`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelUserFromJSON(jsonValue),
    );
  }

  /**
   * Retrieve user info from DB for user making request.
   * Get info for user making API request.
   */
  async getCurrentUser(): Promise<ModelUser> {
    const response = await this.getCurrentUserRaw();
    return await response.value();
  }

  /**
   * Retrieve user from within the CQE systems with specified ID.
   * Get user with specified ID.
   */
  async getUserRaw(
    requestParameters: GetUserRequest,
  ): Promise<runtime.ApiResponse<ModelUser>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getUser.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/users/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelUserFromJSON(jsonValue),
    );
  }

  /**
   * Retrieve user from within the CQE systems with specified ID.
   * Get user with specified ID.
   */
  async getUser(requestParameters: GetUserRequest): Promise<ModelUser> {
    const response = await this.getUserRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update user within CQE systems.
   * Update user.
   */
  async updateUserRaw(
    requestParameters: UpdateUserRequest,
  ): Promise<runtime.ApiResponse<ModelUser>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateUser.",
      );
    }

    if (
      requestParameters.user === null ||
      requestParameters.user === undefined
    ) {
      throw new runtime.RequiredError(
        "user",
        "Required parameter requestParameters.user was null or undefined when calling updateUser.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/users/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ModelUserToJSON(requestParameters.user),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelUserFromJSON(jsonValue),
    );
  }

  /**
   * Update user within CQE systems.
   * Update user.
   */
  async updateUser(requestParameters: UpdateUserRequest): Promise<ModelUser> {
    const response = await this.updateUserRaw(requestParameters);
    return await response.value();
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetAllUsersWithRoleEnum {
  Carer = "carer",
  Manager = "manager",
  Administrator = "administrator",
}
