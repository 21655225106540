import DateFnsUtils from "@date-io/date-fns";
import {
  Card,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React from "react";
import {
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";

import { ModelSurvey } from "../../api_client";
import { Constants, avg, groupBy } from "../../helpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    noDataLabel: {
      width: "100%",
      textAlign: "center",
      display: "block",
      position: "relative",
      top: "-59%",
    },
    tooltip: {
      minWidth: 52,
    },
    tooltipContent: {
      padding: theme.spacing(2),
    },
  }),
);

const CustomTooltip: React.FC<TooltipProps> = ({ payload, label }) => {
  const classes = useStyles();

  const datum = payload?.find(d => d.dataKey === "value");

  return (
    <Card className={classes.tooltip}>
      <div className={classes.tooltipContent}>
        <Typography variant="h5">
          <div className="recharts-tooltip-label">
            {label && dateUtils.format(new Date(Number(label)), "MMM yyyy")}:{" "}
            {datum && (
              <span style={{ color: datum.color }}>
                {Math.round(Number(datum.value) * 10)}%
              </span>
            )}
          </div>
        </Typography>
      </div>
    </Card>
  );
};

interface CareUnitNCASGraphProps {
  surveys: ModelSurvey[];
}

const dateUtils = new DateFnsUtils();

export const CareUnitNCASGraph: React.FC<CareUnitNCASGraphProps> = ({
  surveys,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const tickStyle = {
    stroke: theme.palette.text.primary,
    fill: theme.palette.text.primary,
  };

  const colour = Constants.palette.blue;

  const groupedSurveys = groupBy(surveys, s => {
    const surveyDate = new Date(s.createdAt ?? 0);
    return new Date(surveyDate.getFullYear(), surveyDate.getMonth()).getTime();
  });

  const data = Object.keys(groupedSurveys).map(key => {
    const surveysGroup = groupedSurveys[key];
    const ncasGroup = surveysGroup.map(s => s.netCustomerAdvocacyScore!);

    return {
      date: key,
      value: avg(ncasGroup),
    };
  });

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={200}
          height={200}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            name="Date"
            domain={["auto", "auto"]}
            dataKey="date"
            interval={0}
            tick={{ ...tickStyle, fontSize: theme.typography.body2.fontSize }}
            tickFormatter={date => {
              if (!dateUtils.isValid(date)) {
                return "";
              }

              return dateUtils.format(date, "MM-yyyy");
            }}
            type="number"
          />
          <YAxis
            domain={[0, 10]}
            interval={0}
            ticks={[0, 2, 4, 6, 8, 10]}
            tick={{ ...tickStyle, fontSize: theme.typography.h6.fontSize }}
          />
          <ZAxis range={[200, 200]} />
          <Tooltip content={<CustomTooltip />} />

          <Scatter
            name="Net Customer Advocacy Score"
            dataKey="value"
            fill={colour}
            line
            strokeWidth={5}
          />
        </ComposedChart>
      </ResponsiveContainer>

      {data.length === 0 && (
        <Typography variant="h6" component="p" className={classes.noDataLabel}>
          No data to display
        </Typography>
      )}
    </>
  );
};
