import { useCallback, useEffect, useState } from "react";

import { ModelCareUnit } from "../api_client";
import { getCareUnitsAPI } from "../api_client";
import { AppDatabase, HandleAPIErrorFun, useAppContext } from "../helpers";
import { useAPIUpdatePeriod } from "./useAPIUpdatePeriod";

const getOrgUnits = async (
  orgId: number,
  setCareUnits: (newOrgUnits: ModelCareUnit[]) => void,
  handleAPIError: HandleAPIErrorFun,
) => {
  try {
    const units = await getCareUnitsAPI().getAllCareUnits({
      forOrganisation: orgId,
      includeStats: true,
      disablePagination: true,
    });

    const records = units.records;
    if (records === undefined) {
      return;
    }

    const db = await AppDatabase.getInstance();
    if (db !== undefined) {
      const clearRemoved = async () => {
        const careUnitsToRemove = await db.careUnits
          .where("careOrganisationID")
          .equals(orgId)
          .and(careUnit => !records.map(u => u.id).includes(careUnit.id))
          .toArray();
        await db.careUnits.bulkDelete(careUnitsToRemove.map(u => u.id!));
      };

      await Promise.all([clearRemoved(), db.careUnits.bulkPut(records)]);
    }

    setCareUnits(records ?? []);
  } catch (e) {
    handleAPIError(e, "retrieving care home units");
  }
};

export const useOrgUnits = (orgId: number | undefined) => {
  const { handleAPIError } = useAppContext();

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [orgUnits, setOrgUnits] = useState<ModelCareUnit[]>([]);

  const [setUpdatedFromAPI, isAPIUpdateDue] = useAPIUpdatePeriod(
    `org-${orgId}-units`,
  );

  useEffect(() => {
    if (initialLoadComplete || orgId === undefined) {
      return;
    }

    const getCachedOrgUnits = async () => {
      const db = await AppDatabase.getInstance();
      if (db === undefined) {
        return;
      }

      const cachedUnits = await db.careUnits
        .where("careOrganisationID")
        .equals(orgId)
        .toArray();

      setOrgUnits(cachedUnits);
      return cachedUnits.length > 0;
    };

    getCachedOrgUnits().then(async cachePresent => {
      if (!cachePresent || isAPIUpdateDue()) {
        await getOrgUnits(orgId, setOrgUnits, handleAPIError);
      }

      setInitialLoadComplete(true);
      setIsLoading(false);
      setUpdatedFromAPI();
    });
  }, [
    orgId,
    handleAPIError,
    isAPIUpdateDue,
    setUpdatedFromAPI,
    initialLoadComplete,
  ]);

  const refreshOrgUnits = useCallback(async () => {
    if (orgId === undefined) {
      return;
    }

    setIsLoading(true);
    setOrgUnits([]);
    await getOrgUnits(orgId, setOrgUnits, handleAPIError);
    setIsLoading(false);
    setUpdatedFromAPI();
  }, [orgId, handleAPIError, setUpdatedFromAPI]);

  return [orgUnits, isLoading, refreshOrgUnits] as const;
};
