/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EchoHTTPError,
  EchoHTTPErrorFromJSON,
  EchoHTTPErrorToJSON,
  HandlersRecordsPageModelSurvey,
  HandlersRecordsPageModelSurveyFromJSON,
  HandlersRecordsPageModelSurveyToJSON,
  ModelSurvey,
  ModelSurveyFromJSON,
  ModelSurveyToJSON,
  ViewmodelAddSurvey,
  ViewmodelAddSurveyFromJSON,
  ViewmodelAddSurveyToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface AddSurveyRequest {
  survey: ViewmodelAddSurvey;
}

export interface DeleteSurveyRequest {
  id: number;
}

export interface GetAllSurveysRequest {
  careUnitID?: number;
  carerID?: number;
  pageNumber?: number;
  pageSize?: number;
  order?: string;
  disablePagination?: boolean;
}

export interface GetSurveyRequest {
  id: number;
}

export interface UpdateSurveyRequest {
  id: number;
  survey: ModelSurvey;
}

/**
 *
 */
export class SurveysApi extends runtime.BaseAPI {
  /**
   * Add survey to CQE systems.
   * Add survey.
   */
  async addSurveyRaw(
    requestParameters: AddSurveyRequest,
  ): Promise<runtime.ApiResponse<ModelSurvey>> {
    if (
      requestParameters.survey === null ||
      requestParameters.survey === undefined
    ) {
      throw new runtime.RequiredError(
        "survey",
        "Required parameter requestParameters.survey was null or undefined when calling addSurvey.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/surveys`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ViewmodelAddSurveyToJSON(requestParameters.survey),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelSurveyFromJSON(jsonValue),
    );
  }

  /**
   * Add survey to CQE systems.
   * Add survey.
   */
  async addSurvey(requestParameters: AddSurveyRequest): Promise<ModelSurvey> {
    const response = await this.addSurveyRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete survey from CQE systems.
   * Delete survey.
   */
  async deleteSurveyRaw(
    requestParameters: DeleteSurveyRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteSurvey.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/surveys/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete survey from CQE systems.
   * Delete survey.
   */
  async deleteSurvey(requestParameters: DeleteSurveyRequest): Promise<void> {
    await this.deleteSurveyRaw(requestParameters);
  }

  /**
   * Retrieve all survey results that exist within the CQE systems. The results are paginated by default. See arguments for pagination options.
   * Get all surveys in the CQE system.
   */
  async getAllSurveysRaw(
    requestParameters: GetAllSurveysRequest,
  ): Promise<runtime.ApiResponse<HandlersRecordsPageModelSurvey>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.careUnitID !== undefined) {
      queryParameters["careUnitID"] = requestParameters.careUnitID;
    }

    if (requestParameters.carerID !== undefined) {
      queryParameters["carerID"] = requestParameters.carerID;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["pageNumber"] = requestParameters.pageNumber;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["pageSize"] = requestParameters.pageSize;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.disablePagination !== undefined) {
      queryParameters["disablePagination"] =
        requestParameters.disablePagination;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/surveys`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      HandlersRecordsPageModelSurveyFromJSON(jsonValue),
    );
  }

  /**
   * Retrieve all survey results that exist within the CQE systems. The results are paginated by default. See arguments for pagination options.
   * Get all surveys in the CQE system.
   */
  async getAllSurveys(
    requestParameters: GetAllSurveysRequest,
  ): Promise<HandlersRecordsPageModelSurvey> {
    const response = await this.getAllSurveysRaw(requestParameters);
    return await response.value();
  }

  /**
   * Retrieve survey from within the CQE systems with specified ID.
   * Get survey with specified ID.
   */
  async getSurveyRaw(
    requestParameters: GetSurveyRequest,
  ): Promise<runtime.ApiResponse<ModelSurvey>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getSurvey.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/surveys/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelSurveyFromJSON(jsonValue),
    );
  }

  /**
   * Retrieve survey from within the CQE systems with specified ID.
   * Get survey with specified ID.
   */
  async getSurvey(requestParameters: GetSurveyRequest): Promise<ModelSurvey> {
    const response = await this.getSurveyRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update surveys within CQE systems.
   * Update survey.
   */
  async updateSurveyRaw(
    requestParameters: UpdateSurveyRequest,
  ): Promise<runtime.ApiResponse<ModelSurvey>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateSurvey.",
      );
    }

    if (
      requestParameters.survey === null ||
      requestParameters.survey === undefined
    ) {
      throw new runtime.RequiredError(
        "survey",
        "Required parameter requestParameters.survey was null or undefined when calling updateSurvey.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/surveys/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ModelSurveyToJSON(requestParameters.survey),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelSurveyFromJSON(jsonValue),
    );
  }

  /**
   * Update surveys within CQE systems.
   * Update survey.
   */
  async updateSurvey(
    requestParameters: UpdateSurveyRequest,
  ): Promise<ModelSurvey> {
    const response = await this.updateSurveyRaw(requestParameters);
    return await response.value();
  }
}
