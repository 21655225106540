/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ModelFreeTextQuestion
 */
export interface ModelFreeTextQuestion {
  /**
   *
   * @type {string}
   * @memberof ModelFreeTextQuestion
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof ModelFreeTextQuestion
   */
  userResponse?: string;
}

export function ModelFreeTextQuestionFromJSON(
  json: any,
): ModelFreeTextQuestion {
  return ModelFreeTextQuestionFromJSONTyped(json, false);
}

export function ModelFreeTextQuestionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ModelFreeTextQuestion {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    text: !exists(json, "text") ? undefined : json["text"],
    userResponse: !exists(json, "userResponse")
      ? undefined
      : json["userResponse"],
  };
}

export function ModelFreeTextQuestionToJSON(
  value?: ModelFreeTextQuestion | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    text: value.text,
    userResponse: value.userResponse,
  };
}
