import {
  AppBar,
  Button,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  fade,
} from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Help as AboutIcon,
  AccountCircle as AccountCircleIcon,
  Settings as SettingsIcon,
  ExitToApp as SignOutIcon,
} from "@material-ui/icons";
import React, { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import { ModelUserRoleEnum } from "../api_client";
import { Constants, useAppContext } from "../helpers";
import { AuthService } from "../services";
import { AboutDialog, NavBreadcrumbs } from ".";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      alignItems: "flex-start",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      ...theme.mixins.toolbar,
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    menuItem: {
      "& .MuiListItemIcon-root": {
        minWidth: theme.spacing(5),
      },
    },
    title: {
      fontSize: "1.5rem",
    },
    breadcrumbs: {
      flexGrow: 1,
      alignSelf: "flex-end",
      color: "inherit",
      fontSize: "1.5rem",
    },
    manageAdminsButton: {
      marginRight: 4,
      color: "black",
      borderColor: fade("#000", 0.3),
    },
    aboutButton: {
      padding: "6px 8px",
    },
  }),
);

export const AppTopBar: React.FC = () => {
  const { isLoggedIn, user, setUser, breadcrumbs } = useAppContext();
  const classes = useStyles();
  const history = useHistory();

  const [aboutDialogOpen, setAboutDialogOpen] = useState(false);

  const [
    profileMenuAnchor,
    setProfileMenuAnchor,
  ] = useState<HTMLElement | null>(null);

  const isProfileMenuOpen = profileMenuAnchor !== null;
  const profileMenuId = "top-bar-profil-menu";
  const handleMenuClose = () => setProfileMenuAnchor(null);

  const profileMenu = (
    <Menu
      anchorEl={profileMenuAnchor}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isProfileMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        className={classes.menuItem}
        onClick={() => {
          handleMenuClose();
          history.push(Constants.paths.userSettings);
        }}
      >
        <ListItemIcon>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>
        User Settings
      </MenuItem>
      <MenuItem
        className={classes.menuItem}
        onClick={() => {
          handleMenuClose();
          setUser(undefined);
          AuthService.getInstance().logout();
        }}
      >
        <ListItemIcon>
          <SignOutIcon fontSize="small" />
        </ListItemIcon>
        Sign Out
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" className={classes.title} noWrap>
            <Link
              component={RouterLink}
              color="inherit"
              to="/"
              underline="none"
            >
              Care Quality EcoSystems
            </Link>
          </Typography>

          <NavBreadcrumbs
            crumbs={breadcrumbs}
            className={classes.breadcrumbs}
          />

          {user?.role === ModelUserRoleEnum.Administrator && (
            <Button
              className={classes.manageAdminsButton}
              component={RouterLink}
              to={Constants.paths.manageAdmins}
              variant="outlined"
            >
              Manage admins
            </Button>
          )}

          <IconButton
            aria-label="about"
            onClick={() => setAboutDialogOpen(true)}
            color="inherit"
            className={classes.aboutButton}
            size="small"
          >
            <AboutIcon />
          </IconButton>

          {isLoggedIn && user !== undefined && (
            <Button
              aria-label="account of current user"
              aria-controls={profileMenuId}
              aria-haspopup="true"
              onClick={event => setProfileMenuAnchor(event.currentTarget)}
              color="inherit"
              startIcon={<AccountCircleIcon />}
            >
              {user.firstName} {user.lastName}
            </Button>
          )}
        </Toolbar>
      </AppBar>

      {profileMenu}

      <AboutDialog
        open={aboutDialogOpen}
        onClose={() => setAboutDialogOpen(false)}
      />
    </>
  );
};
