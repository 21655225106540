/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ModelAdjective,
  ModelAdjectiveFromJSON,
  ModelAdjectiveFromJSONTyped,
  ModelAdjectiveToJSON,
} from "./";

/**
 *
 * @export
 * @interface ModelAdjectiveQuestion
 */
export interface ModelAdjectiveQuestion {
  /**
   *
   * @type {Array<ModelAdjective>}
   * @memberof ModelAdjectiveQuestion
   */
  adjectives?: Array<ModelAdjective>;
  /**
   *
   * @type {number}
   * @memberof ModelAdjectiveQuestion
   */
  userChoiceLeast?: number;
  /**
   * Indexes into `adjectives` list.
   * @type {number}
   * @memberof ModelAdjectiveQuestion
   */
  userChoiceMost?: number;
}

export function ModelAdjectiveQuestionFromJSON(
  json: any,
): ModelAdjectiveQuestion {
  return ModelAdjectiveQuestionFromJSONTyped(json, false);
}

export function ModelAdjectiveQuestionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ModelAdjectiveQuestion {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    adjectives: !exists(json, "adjectives")
      ? undefined
      : (json["adjectives"] as Array<any>).map(ModelAdjectiveFromJSON),
    userChoiceLeast: !exists(json, "userChoiceLeast")
      ? undefined
      : json["userChoiceLeast"],
    userChoiceMost: !exists(json, "userChoiceMost")
      ? undefined
      : json["userChoiceMost"],
  };
}

export function ModelAdjectiveQuestionToJSON(
  value?: ModelAdjectiveQuestion | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    adjectives:
      value.adjectives === undefined
        ? undefined
        : (value.adjectives as Array<any>).map(ModelAdjectiveToJSON),
    userChoiceLeast: value.userChoiceLeast,
    userChoiceMost: value.userChoiceMost,
  };
}
