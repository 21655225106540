/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EchoHTTPError,
  EchoHTTPErrorFromJSON,
  EchoHTTPErrorToJSON,
  ModelSurveyTemplate,
  ModelSurveyTemplateFromJSON,
  ModelSurveyTemplateToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface AddSurveyTemplateRequest {
  surveyTemplate: ModelSurveyTemplate;
}

export interface DeleteSurveyTemplateRequest {
  id: number;
}

export interface UpdateSurveyTemplateRequest {
  id: number;
  updatedSurveyTemplate: ModelSurveyTemplate;
}

/**
 *
 */
export class SurveyTemplatesApi extends runtime.BaseAPI {
  /**
   * Add new survey template.
   * Add new survey template.
   */
  async addSurveyTemplateRaw(
    requestParameters: AddSurveyTemplateRequest,
  ): Promise<runtime.ApiResponse<ModelSurveyTemplate>> {
    if (
      requestParameters.surveyTemplate === null ||
      requestParameters.surveyTemplate === undefined
    ) {
      throw new runtime.RequiredError(
        "surveyTemplate",
        "Required parameter requestParameters.surveyTemplate was null or undefined when calling addSurveyTemplate.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/survey-templates`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelSurveyTemplateToJSON(requestParameters.surveyTemplate),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelSurveyTemplateFromJSON(jsonValue),
    );
  }

  /**
   * Add new survey template.
   * Add new survey template.
   */
  async addSurveyTemplate(
    requestParameters: AddSurveyTemplateRequest,
  ): Promise<ModelSurveyTemplate> {
    const response = await this.addSurveyTemplateRaw(requestParameters);
    return await response.value();
  }

  /**
   * Remove existing survey template.
   * Remove existing survey template.
   */
  async deleteSurveyTemplateRaw(
    requestParameters: DeleteSurveyTemplateRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteSurveyTemplate.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/survey-templates/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Remove existing survey template.
   * Remove existing survey template.
   */
  async deleteSurveyTemplate(
    requestParameters: DeleteSurveyTemplateRequest,
  ): Promise<void> {
    await this.deleteSurveyTemplateRaw(requestParameters);
  }

  /**
   * Retrieve currently active survey template.
   * Get currently active survey template.
   */
  async getAciveSurveyTemplateRaw(): Promise<
    runtime.ApiResponse<ModelSurveyTemplate>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/survey-templates/active`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelSurveyTemplateFromJSON(jsonValue),
    );
  }

  /**
   * Retrieve currently active survey template.
   * Get currently active survey template.
   */
  async getAciveSurveyTemplate(): Promise<ModelSurveyTemplate> {
    const response = await this.getAciveSurveyTemplateRaw();
    return await response.value();
  }

  /**
   * Retrieve all survey templates.
   * Get all survey templates for a care organisation.
   */
  async getAllSurveyTemplatesRaw(): Promise<
    runtime.ApiResponse<Array<ModelSurveyTemplate>>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/survey-templates`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ModelSurveyTemplateFromJSON),
    );
  }

  /**
   * Retrieve all survey templates.
   * Get all survey templates for a care organisation.
   */
  async getAllSurveyTemplates(): Promise<Array<ModelSurveyTemplate>> {
    const response = await this.getAllSurveyTemplatesRaw();
    return await response.value();
  }

  /**
   * Update existing survey template.
   * Update existing survey template.
   */
  async updateSurveyTemplateRaw(
    requestParameters: UpdateSurveyTemplateRequest,
  ): Promise<runtime.ApiResponse<ModelSurveyTemplate>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateSurveyTemplate.",
      );
    }

    if (
      requestParameters.updatedSurveyTemplate === null ||
      requestParameters.updatedSurveyTemplate === undefined
    ) {
      throw new runtime.RequiredError(
        "updatedSurveyTemplate",
        "Required parameter requestParameters.updatedSurveyTemplate was null or undefined when calling updateSurveyTemplate.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "CQEOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Dev",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken(
          "HCOAuth2Prod",
          [],
        );
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/survey-templates/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ModelSurveyTemplateToJSON(requestParameters.updatedSurveyTemplate),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ModelSurveyTemplateFromJSON(jsonValue),
    );
  }

  /**
   * Update existing survey template.
   * Update existing survey template.
   */
  async updateSurveyTemplate(
    requestParameters: UpdateSurveyTemplateRequest,
  ): Promise<ModelSurveyTemplate> {
    const response = await this.updateSurveyTemplateRaw(requestParameters);
    return await response.value();
  }
}
