import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import * as Sentry from "@sentry/react";
import React from "react";

import { Constants, useAppContext, userFullName } from "../helpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    aboutDatum: {
      display: "flex",
    },
    aboutKey: {
      fontWeight: "bold",
    },
    aboutValue: {
      flexGrow: 1,
      textAlign: "right",
    },
    aboutParagraph: {
      marginBottom: theme.spacing(2),
    },
  }),
);

interface AboutDialogProps {
  open: boolean;
  onClose: () => void;
}

export const AboutDialog: React.FC<AboutDialogProps> = ({ open, onClose }) => {
  const { user } = useAppContext();
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>About</DialogTitle>

      <DialogContent>
        <Typography className={classes.aboutParagraph} component="p">
          Copyright © 2021 Care Quality EcoSystems.
        </Typography>

        <Typography className={classes.aboutParagraph} component="h6">
          Debug info:
        </Typography>

        <div className={classes.aboutDatum}>
          <span className={classes.aboutKey}>App version:</span>{" "}
          <span className={classes.aboutValue}>{Constants.app.version}</span>
        </div>

        <div className={classes.aboutDatum}>
          <span className={classes.aboutKey}>App built:</span>{" "}
          <span className={classes.aboutValue}>{Constants.app.buildTime}</span>
        </div>

        <div className={classes.aboutDatum}>
          <span className={classes.aboutKey}>User agent:</span>{" "}
          <span className={classes.aboutValue}>
            {window.navigator.userAgent}
          </span>
        </div>

        <div className={classes.aboutDatum}>
          <span className={classes.aboutKey}>Origin:</span>{" "}
          <span className={classes.aboutValue}>{window.location.origin}</span>
        </div>

        <div className={classes.aboutDatum}>
          <span className={classes.aboutKey}>Path:</span>{" "}
          <span className={classes.aboutValue}>{window.location.pathname}</span>
        </div>

        <div className={classes.aboutDatum}>
          <span className={classes.aboutKey}>Fragment:</span>{" "}
          <span className={classes.aboutValue}>{window.location.hash}</span>
        </div>

        <div className={classes.aboutDatum}>
          <span className={classes.aboutKey}>API URL:</span>{" "}
          <span className={classes.aboutValue}>{Constants.api.baseUrl}</span>
        </div>

        <div className={classes.aboutDatum}>
          <span className={classes.aboutKey}>Authentication URL:</span>{" "}
          <span className={classes.aboutValue}>
            {Constants.auth.stsAuthority}
          </span>
        </div>

        <div className={classes.aboutDatum}>
          <span className={classes.aboutKey}>Authentication client ID:</span>{" "}
          <span className={classes.aboutValue}>{Constants.auth.clientId}</span>
        </div>

        <div className={classes.aboutDatum}>
          <span className={classes.aboutKey}>Authentication client root:</span>{" "}
          <span className={classes.aboutValue}>
            {Constants.auth.clientRoot}
          </span>
        </div>

        <div className={classes.aboutDatum}>
          <span className={classes.aboutKey}>Authentication client scope:</span>{" "}
          <span className={classes.aboutValue}>
            {Constants.auth.clientScope}
          </span>
        </div>
      </DialogContent>

      {Constants.logging.sentryEnabled && (
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
              Sentry.showReportDialog({
                eventId: Sentry.captureMessage(`User bug report ${new Date()}`),
                user: { email: user?.email, name: userFullName(user) },
                title: "Tell us what went wrong.",
                subtitle:
                  "The more information you can provide, the faster we can resolve any issues.",
              });
            }}
          >
            Report issue
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
