import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Tab,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { useState } from "react";

import { ModelSurvey } from "../api_client";
import { monthNames, nth } from "../helpers";
import { DISCGraph } from "./Graphs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      height: "75%",
    },
    title: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogContent: {
      height: "100%",
    },
    tabContent: {
      height: "100%",
    },
    dialogActions: {
      padding: 0,
    },
    tabBar: {
      width: "100%",
    },
  }),
);

interface SurveyDialogProps {
  open: boolean;
  onClose: () => void;
  data: ModelSurvey;
}

export const SurveyDialog: React.FC<SurveyDialogProps> = ({
  open,
  onClose,
  data,
}) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState("0");

  const d = new Date(data.createdAt ?? 0);

  const graphTitles = [
    "I - How others see you",
    "II - Behaviour under pressure",
    "III - How you see yourself",
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">
          {data.userFullName !== undefined
            ? `${data.userFullName}'s survey `
            : "Survey "}
          from {d.getDate()}
          <sup>{nth(d.getDate())}</sup> {monthNames[d.getMonth()]}{" "}
          {d.getFullYear()} at {d.getHours()}:
          {(d.getMinutes() < 10 ? "0" : "") + d.getMinutes()}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <TabContext value={currentTab}>
        <DialogContent className={classes.dialogContent}>
          {graphTitles.map((title, index) => (
            <TabPanel
              key={index}
              value={String(index)}
              className={classes.tabContent}
            >
              <DISCGraph data={data} graphIndex={index} title={title} />
            </TabPanel>
          ))}
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Paper className={classes.tabBar}>
            <TabList
              onChange={(_event, value) => setCurrentTab(value)}
              indicatorColor="primary"
              textColor="primary"
              centered
              aria-label="main navigation tabs"
            >
              <Tab label="Graph I" value="0" />
              <Tab label="Graph II" value="1" />
              <Tab label="Graph III" value="2" />
            </TabList>
          </Paper>
        </DialogActions>
      </TabContext>
    </Dialog>
  );
};
