import { Snackbar, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";

import { HandlersUploadError } from "../../api_client";

interface CSVValidationAlertProps {
  response?: HandlersUploadError;
  onClose: () => void;
}

export const CSVValidationAlert: React.FC<CSVValidationAlertProps> = ({
  response,
  onClose,
}) => {
  const maxErrorsToShow = 25;

  let errors = response?.errors;
  if (errors !== undefined && errors.length > maxErrorsToShow) {
    const numExtra = errors.length - maxErrorsToShow;
    errors = errors
      .slice(0, maxErrorsToShow)
      .concat(`... (${numExtra} additional errors)`);
  }

  return (
    <Snackbar
      open={response !== undefined}
      onClose={onClose}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
    >
      <Alert severity="error" onClose={onClose}>
        <AlertTitle>CSV validation failed</AlertTitle>
        <Typography variant="body1">
          Please fix the errors and try again. The following validation errors
          were reported:
        </Typography>

        {errors ? (
          <ul>
            {errors.map((validationError, i) => (
              <li key={i}>
                <Typography variant="body1">{validationError}</Typography>
              </li>
            ))}
          </ul>
        ) : (
          "None"
        )}
      </Alert>
    </Snackbar>
  );
};
