import { useCallback, useEffect, useState } from "react";

import {
  GetAllUsersWithRoleEnum,
  ModelUser,
  ModelUserRoleEnum,
} from "../api_client";
import { getUsersAPI } from "../api_client";
import { AppDatabase, HandleAPIErrorFun, useAppContext } from "../helpers";
import { useAPIUpdatePeriod } from ".";

const getCareUnitEmployees = async (
  careUnitId: number,
  role: ModelUserRoleEnum | undefined,
  setUsers: React.Dispatch<React.SetStateAction<ModelUser[]>>,
  handleAPIError: HandleAPIErrorFun,
) => {
  try {
    const newUsers = await getUsersAPI().getAllUsers({
      careUnitID: careUnitId,
      withRole: role && ((role as string) as GetAllUsersWithRoleEnum),
      disablePagination: true,
    });

    const records = newUsers?.records;
    if (records === undefined) {
      return;
    }

    const db = await AppDatabase.getInstance();

    if (db !== undefined) {
      const clearRemoved = async () => {
        // If there are users in the cache that aren't in the live API results, they need to
        // be removed.
        const usersToRemove = await db.users
          .where("careUnitID")
          .equals(careUnitId)
          .and(
            user =>
              (role === undefined || user.role === role) &&
              !records.map(record => record.id).includes(user.id),
          )
          .toArray();
        await db.users.bulkDelete(usersToRemove.map(user => user.id!));
      };

      await Promise.all([clearRemoved(), db.users.bulkPut(records)]);
    }

    setUsers(records);
  } catch (e) {
    handleAPIError(e, "retrieving users for care home unit");
  }
};

export const useEmployees = (
  careUnitId: number | undefined,
  role?: ModelUserRoleEnum,
) => {
  const { handleAPIError } = useAppContext();

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<ModelUser[]>([]);

  const [setUpdatedFromAPI, isAPIUpdateDue] = useAPIUpdatePeriod(
    `users-${careUnitId}-${role ?? "all"}`,
  );

  useEffect(() => {
    if (initialLoadComplete || careUnitId === undefined) {
      return;
    }

    const getCachedUsers = async () => {
      const db = await AppDatabase.getInstance();
      if (db === undefined) {
        return;
      }

      const cachedUsers = await db.users
        .where("careUnitID")
        .equals(careUnitId)
        .and(u => role === undefined || u.role === role)
        .toArray();
      setUsers(cachedUsers);
      return cachedUsers.length > 0;
    };

    getCachedUsers().then(async cachePresent => {
      if (!cachePresent || isAPIUpdateDue()) {
        await getCareUnitEmployees(careUnitId, role, setUsers, handleAPIError);
      }

      setInitialLoadComplete(true);
      setIsLoading(false);
      setUpdatedFromAPI();
    });
  }, [
    careUnitId,
    role,
    handleAPIError,
    setUpdatedFromAPI,
    isAPIUpdateDue,
    initialLoadComplete,
  ]);

  const refreshUsers = useCallback(async () => {
    if (careUnitId === undefined) {
      return;
    }

    setIsLoading(true);
    await getCareUnitEmployees(careUnitId, role, setUsers, handleAPIError);
    setIsLoading(false);
    setUpdatedFromAPI();
  }, [careUnitId, role, handleAPIError, setUpdatedFromAPI]);

  return [users, setUsers, isLoading, refreshUsers] as const;
};
