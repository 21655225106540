import { Avatar } from "@material-ui/core";
import React from "react";

import { ModelUser } from "../api_client";

interface UserAvatarProps {
  user?: ModelUser;
  className?: string;
}

// In the future this could use custom pictures or gravatars.
export const UserAvatar: React.FC<UserAvatarProps> = ({ className }) => {
  return <Avatar aria-label="user avatar" className={className} />;
};
