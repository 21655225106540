/* tslint:disable */
/* eslint-disable */
/**
 * CQE API
 * This is the backend API for CQE systems, providing RESTful access to care homes, carers and their survey results.
 *
 * The version of the OpenAPI document: v0.12.2
 * Contact: drew.silcock@stfc.ac.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ModelDISCScore
 */
export interface ModelDISCScore {
  /**
   *
   * @type {string}
   * @memberof ModelDISCScore
   */
  coarsePattern?: string;
  /**
   *
   * @type {string}
   * @memberof ModelDISCScore
   */
  finePattern?: string;
  /**
   *
   * @type {boolean}
   * @memberof ModelDISCScore
   */
  hasOverShift?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ModelDISCScore
   */
  hasUnderShift?: boolean;
  /**
   *
   * @type {number}
   * @memberof ModelDISCScore
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof ModelDISCScore
   */
  isTight?: boolean;
  /**
   *
   * @type {number}
   * @memberof ModelDISCScore
   */
  rawConscientiousness?: number;
  /**
   *
   * @type {number}
   * @memberof ModelDISCScore
   */
  rawDominance?: number;
  /**
   *
   * @type {number}
   * @memberof ModelDISCScore
   */
  rawInfluence?: number;
  /**
   *
   * @type {number}
   * @memberof ModelDISCScore
   */
  rawSteadiness?: number;
  /**
   *
   * @type {number}
   * @memberof ModelDISCScore
   */
  scaledConscientiousness?: number;
  /**
   *
   * @type {number}
   * @memberof ModelDISCScore
   */
  scaledDominance?: number;
  /**
   *
   * @type {number}
   * @memberof ModelDISCScore
   */
  scaledInfluence?: number;
  /**
   *
   * @type {number}
   * @memberof ModelDISCScore
   */
  scaledSteadiness?: number;
}

export function ModelDISCScoreFromJSON(json: any): ModelDISCScore {
  return ModelDISCScoreFromJSONTyped(json, false);
}

export function ModelDISCScoreFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ModelDISCScore {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    coarsePattern: !exists(json, "coarsePattern")
      ? undefined
      : json["coarsePattern"],
    finePattern: !exists(json, "finePattern") ? undefined : json["finePattern"],
    hasOverShift: !exists(json, "hasOverShift")
      ? undefined
      : json["hasOverShift"],
    hasUnderShift: !exists(json, "hasUnderShift")
      ? undefined
      : json["hasUnderShift"],
    id: !exists(json, "id") ? undefined : json["id"],
    isTight: !exists(json, "isTight") ? undefined : json["isTight"],
    rawConscientiousness: !exists(json, "rawConscientiousness")
      ? undefined
      : json["rawConscientiousness"],
    rawDominance: !exists(json, "rawDominance")
      ? undefined
      : json["rawDominance"],
    rawInfluence: !exists(json, "rawInfluence")
      ? undefined
      : json["rawInfluence"],
    rawSteadiness: !exists(json, "rawSteadiness")
      ? undefined
      : json["rawSteadiness"],
    scaledConscientiousness: !exists(json, "scaledConscientiousness")
      ? undefined
      : json["scaledConscientiousness"],
    scaledDominance: !exists(json, "scaledDominance")
      ? undefined
      : json["scaledDominance"],
    scaledInfluence: !exists(json, "scaledInfluence")
      ? undefined
      : json["scaledInfluence"],
    scaledSteadiness: !exists(json, "scaledSteadiness")
      ? undefined
      : json["scaledSteadiness"],
  };
}

export function ModelDISCScoreToJSON(value?: ModelDISCScore | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    coarsePattern: value.coarsePattern,
    finePattern: value.finePattern,
    hasOverShift: value.hasOverShift,
    hasUnderShift: value.hasUnderShift,
    id: value.id,
    isTight: value.isTight,
    rawConscientiousness: value.rawConscientiousness,
    rawDominance: value.rawDominance,
    rawInfluence: value.rawInfluence,
    rawSteadiness: value.rawSteadiness,
    scaledConscientiousness: value.scaledConscientiousness,
    scaledDominance: value.scaledDominance,
    scaledInfluence: value.scaledInfluence,
    scaledSteadiness: value.scaledSteadiness,
  };
}
