import {
  Grid,
  Paper,
  Tab,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Add as AddIcon, Refresh as RefreshIcon } from "@material-ui/icons";
import {
  Pagination,
  Skeleton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  TabContext,
  TabList,
  TabPanel,
} from "@material-ui/lab";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  ModelCareOrganisation,
  ModelCareOrganisationDefaultShiftSafetyModeEnum,
  ModelUserRoleEnum,
  getCareOrganisationsAPI,
} from "../api_client";
import {
  CareUnitCard,
  OrganisationSettings,
  PageContainer,
} from "../components";
import {
  AppDatabase,
  Constants,
  modelToURLParams,
  urlParamToModel,
  useAppContext,
} from "../helpers";
import {
  Breadcrumb,
  useBreadcrumbs,
  useOrgInfo,
  useOrgUnits,
  usePagination,
} from "../hooks";
import { useQueryParams } from "../hooks/useQueryParams";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      "& .MuiTabPanel-root": {
        padding: 0,
      },
    },
    tabBar: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    pageContainer: {
      height: "100%",
    },
    careUnits: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    activeTab: {
      height: "100%",
    },
    grid: {
      flex: "1 0 auto",
    },
    paginationContainer: {
      paddingTop: theme.spacing(2),
      textAlign: "center",
      flexShrink: 0,
    },
    pagination: { display: "inline-block" },
    speedDialWrapper: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    speedDial: {},
  }),
);

export interface OrgConfig {
  name: string;
  defaultCarerSurveyFrequencyDays: number;
  defaultManagerSurveyFrequencyDays: number;
  defaultShiftSafetyMode: ModelCareOrganisationDefaultShiftSafetyModeEnum;
}

interface OrganisationParams {
  orgInfo: string;
}

export const Organisation: React.FC = () => {
  const classes = useStyles();
  const params = useParams<OrganisationParams>();
  const query = useQueryParams();
  const location = useLocation();
  const history = useHistory();
  const { handleAPIError, showSnackbar, user } = useAppContext();

  const [orgId, orgName] = urlParamToModel(params.orgInfo);

  const tabs = {
    units: {
      label: "Care units",
      index: 0,
      enabled: true,
    },
    settings: {
      label: "Settings",
      index: 1,
      enabled: user?.role === ModelUserRoleEnum.Administrator,
    },
  };

  const tabIndices = Object.values(tabs).map(t => t.index.toString());
  const queryTab = query.get("tab") ?? "0";
  const defaultTab = tabIndices.includes(queryTab)
    ? queryTab
    : tabs.units.index.toString();
  const [currentTab, setCurrentTab] = useState(defaultTab);
  useEffect(() => setCurrentTab(defaultTab), [defaultTab]);

  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [org, setOrg, isOrgLoading, refreshOrg] = useOrgInfo(orgId);
  const [orgUnits, areUnitsLoading, refreshOrgUnits] = useOrgUnits(orgId);

  const isLoading = areUnitsLoading || isOrgLoading;

  const {
    pageNumber,
    pageSize,
    pageCount,
    setPageCount,
    offset,
    setPageNumber,
  } = usePagination(8);

  const careUnitsPage = orgUnits.slice(offset, offset + pageSize);

  useEffect(() => {
    setPageCount(Math.ceil(orgUnits.length / pageSize));
  }, [setPageCount, orgUnits, pageSize]);

  useBreadcrumbs(Breadcrumb.Organisations, Breadcrumb.Organisation);

  if (orgId === undefined || orgName === undefined) {
    throw new Error(
      `Invalid params specified for organisation page: ${JSON.stringify(
        params,
      )}`,
    );
  }

  if (user?.role !== ModelUserRoleEnum.Administrator) {
    history.push(Constants.paths.permissionDenied);
    return null;
  }

  const updateOrgConfig = async (config: OrgConfig) => {
    if (orgId === undefined) {
      return;
    }

    const oldName = org?.name;

    const updatedOrg: ModelCareOrganisation = {
      ...org,
      name: config.name,
      defaultCarerSurveyFrequencyDays: config.defaultCarerSurveyFrequencyDays,
      defaultManagerSurveyFrequencyDays:
        config.defaultManagerSurveyFrequencyDays,
      defaultShiftSafetyMode: config.defaultShiftSafetyMode,
    };

    try {
      await getCareOrganisationsAPI().updateCareOrganisation({
        id: orgId,
        careOrganisation: updatedOrg,
      });

      const db = await AppDatabase.getInstance();
      if (db !== undefined) {
        await db.organisations.update(orgId, updatedOrg);
      }

      setOrg(current => {
        if (current === undefined) {
          return;
        }

        return {
          ...current,
          name: config.name,
          defaultCarerSurveyFrequencyDays:
            config.defaultCarerSurveyFrequencyDays,
          defaultManagerSurveyFrequencyDays:
            config.defaultManagerSurveyFrequencyDays,
          defaultShiftSafetyMode: config.defaultShiftSafetyMode,
        };
      });

      showSnackbar(
        "Successfully updated care organisation settings.",
        "success",
      );

      if (oldName !== config.name) {
        history.push({
          pathname: Constants.paths.organisation.replace(
            ":orgInfo",
            modelToURLParams([orgId, config.name]),
          ),
          search: `?${query.toString()}`,
        });
      }
    } catch (e) {
      handleAPIError(e, "updating organisation settings");
    }
  };

  const deleteOrg = async () => {
    if (orgId === undefined) {
      return false;
    }

    try {
      await getCareOrganisationsAPI().deleteCareOrganisation({ id: orgId });

      const db = await AppDatabase.getInstance();
      if (db !== undefined) {
        const careUnits = await db.careUnits
          .filter(c => c.careOrganisationID === orgId)
          .toArray();
        const careUnitIds = careUnits
          .map(c => c.id)
          .filter(id => id !== undefined) as number[];

        const rotas = await db.rotas
          .filter(
            r =>
              r.careUnitID !== undefined && careUnitIds.includes(r.careUnitID),
          )
          .toArray();
        const rotaIds = rotas
          .map(r => r.id)
          .filter(id => id !== undefined) as number[];

        const users = await db.users
          .filter(
            u =>
              u.careUnitID !== undefined && careUnitIds.includes(u.careUnitID),
          )
          .toArray();
        const userIds = users
          .map(u => u.id)
          .filter(id => id !== undefined) as number[];

        const shifts = await db.shifts
          .filter(
            s =>
              (s.rotaID !== undefined && rotaIds.includes(s.rotaID)) ||
              (s.userID !== undefined && userIds.includes(s.userID)),
          )
          .toArray();
        const shiftIds = shifts
          .map(s => s.id)
          .filter(id => id !== undefined) as number[];

        const surveys = await db.surveys
          .filter(s => s.carerID !== undefined && userIds.includes(s.carerID))
          .toArray();
        const surveyIds = surveys
          .map(s => s.id)
          .filter(id => id !== undefined) as number[];

        const shiftPresets = await db.shiftPresets
          .filter(p => p.careOrganisationID === orgId)
          .toArray();
        const shiftPresetIds = shiftPresets
          .map(p => p.id)
          .filter(id => id !== undefined) as number[];

        await db.organisations.delete(orgId);
        await db.careUnits.bulkDelete(careUnitIds);
        await db.rotas.bulkDelete(rotaIds);
        await db.users.bulkDelete(userIds);
        await db.shifts.bulkDelete(shiftIds);
        await db.surveys.bulkDelete(surveyIds);
        await db.shiftPresets.bulkDelete(shiftPresetIds);
      }

      showSnackbar("Successfully deleted care organisation.", "success");

      window.setTimeout(() => history.push(Constants.paths.home), 500);

      return true;
    } catch (e) {
      handleAPIError(e, "deleting organisation");
    }

    return false;
  };

  return (
    <div className={classes.root}>
      <TabContext value={currentTab}>
        <Paper className={classes.tabBar}>
          <TabList
            onChange={(_event, value) => {
              setCurrentTab(value);
              query.set("tab", value);
              location.search = query.toString();
              history.push(location);
            }}
            indicatorColor="primary"
            textColor="primary"
            centered
            aria-label="main navigation tabs"
          >
            {Object.values(tabs)
              .filter(tab => tab.enabled !== false)
              .sort((left, right) => left.index - right.index)
              .map((tab, i) => (
                <Tab key={i} label={tab.label} value={tab.index.toString()} />
              ))}
          </TabList>
        </Paper>

        <PageContainer className={classes.pageContainer}>
          <TabPanel
            value={tabs.units.index.toString()}
            className={clsx(classes.careUnits, {
              [classes.activeTab]: currentTab === tabs.units.index.toString(),
            })}
          >
            {careUnitsPage.length === 0 && !isLoading && (
              <Typography variant="h4" component="h4">
                No care units found in this organisations.
              </Typography>
            )}

            <Grid className={classes.grid} container spacing={3}>
              {isLoading &&
                Array.from(Array(3).keys()).map(i => (
                  <Grid key={i} item xs={3}>
                    <CareUnitCard />
                  </Grid>
                ))}

              {!isLoading &&
                careUnitsPage.map((careUnit, index) => (
                  <Grid key={index} item xs={3}>
                    <CareUnitCard
                      careUnit={careUnit}
                      orgId={orgId}
                      orgName={orgName}
                    />
                  </Grid>
                ))}
            </Grid>

            <div className={classes.paginationContainer}>
              {isLoading ? (
                <Skeleton
                  width={72}
                  height={38}
                  className={classes.pagination}
                />
              ) : (
                <Pagination
                  disabled={isLoading}
                  className={classes.pagination}
                  page={pageNumber}
                  count={pageCount}
                  onChange={(_event, value) => setPageNumber(value)}
                />
              )}
            </div>

            <div className={classes.speedDialWrapper}>
              <SpeedDial
                ariaLabel="Further actions"
                className={classes.speedDial}
                icon={<SpeedDialIcon />}
                onOpen={() => setSpeedDialOpen(true)}
                onClose={() => setSpeedDialOpen(false)}
                open={speedDialOpen}
                direction="up"
                FabProps={{ size: "large" }}
              >
                <SpeedDialAction
                  icon={<AddIcon />}
                  tooltipTitle="Add care unit"
                  onClick={() =>
                    history.push(
                      Constants.paths.newOrganisationCareUnit.replace(
                        ":orgInfo",
                        params.orgInfo ?? "",
                      ),
                    )
                  }
                />
                <SpeedDialAction
                  icon={<RefreshIcon />}
                  tooltipTitle="Refresh"
                  onClick={async () => {
                    refreshOrg();
                    refreshOrgUnits();
                  }}
                />
              </SpeedDial>
            </div>
          </TabPanel>

          {tabs.settings.enabled && (
            <TabPanel value={tabs.settings.index.toString()}>
              <OrganisationSettings
                org={org}
                onUpdate={updateOrgConfig}
                onDelete={deleteOrg}
              />
            </TabPanel>
          )}
        </PageContainer>
      </TabContext>
    </div>
  );
};
