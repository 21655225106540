import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import React, { useState } from "react";

import { ModelShift, ModelUser } from "../../api_client";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      position: "relative",
    },
    spinner: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -14,
      marginLeft: -14,
    },
  }),
);

interface RemoveUserButtonProps {
  user: ModelUser;
  shiftsForUser: (ModelShift | undefined)[];
  removeUserFromRota: (userId: number) => void;
  removingUser: boolean;
}

export const RemoveUserButton: React.FC<RemoveUserButtonProps> = ({
  user,
  shiftsForUser,
  removeUserFromRota,
  removingUser,
}) => {
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);

  const numShifts = shiftsForUser.filter(s => s !== undefined).length;

  return (
    <div className={classes.root}>
      <IconButton
        disabled={removingUser}
        onClick={async () => {
          if (user.id === undefined) {
            return;
          }

          if (numShifts > 0) {
            setDialogOpen(true);
            return;
          }

          await removeUserFromRota(user.id);
          setDialogOpen(false);
        }}
      >
        <DeleteIcon />
      </IconButton>

      {removingUser && (
        <CircularProgress size={28} className={classes.spinner} />
      )}

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Remove user from rota?</DialogTitle>

        <DialogContent>
          <DialogContentText>
            This will remove {numShifts} shifts from the rota.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>

          <Button
            onClick={async () => {
              setDialogOpen(false);

              if (user.id === undefined) {
                return;
              }

              await removeUserFromRota(user.id);
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
