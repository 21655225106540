import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Container,
  FormControlLabel,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import {
  GetApp as DownloadIcon,
  CloudUpload as UploadIcon,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import * as Sentry from "@sentry/react";
import { DropzoneDialog } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";

import {
  HandlersUploadError,
  ModelSurvey,
  ModelUserRoleEnum,
  getCareUnitsAPI,
} from "../../api_client";
import {
  APIError,
  beginningOfWeek,
  endOfWeek,
  useAppContext,
} from "../../helpers";
import { CSVValidationAlert } from "./CSVValidationAlert";
import { SurveyDialog, SurveysTable } from "..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    surveysControlPanel: {
      padding: 16,
      marginBottom: 16,
    },
    datePickers: {
      flexGrow: 1,
    },
    surveyDatePicker: {
      margin: "0 15px",
    },
    timeFilterRadioGroup: {
      flexDirection: "row",
    },
    controlBottom: {
      display: "flex",
    },
    controlCsvActions: {
      display: "flex",
      flexDirection: "column",
      "& .MuiButton-root:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
    },
    controlCsvActionsInner: {
      marginTop: "auto",
    },
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
    },
    uploadProgress: {
      position: "absolute",
      bottom: 0,
      width: "100%",
    },
    uploadProgressBar: {
      // Animation jitters when updating quickly, so just disable it.
      transition: "none",
    },
    uploadProgressText: {
      display: "inline-block",
      marginLeft: 8,
    },
    uploadAlert: {
      marginBottom: 4,
    },
  }),
);

enum DateRange {
  ThisWeek = "this-week",
  LastWeek = "last-week",
  ThisMonth = "this-month",
  LastMonth = "last-month",
  ThisYear = "this-year",
  LastYear = "last-year",
  Custom = "custom",
}

interface UploadProgress {
  progress: number;
}

interface UploadError {
  errors: string[];
}

interface CareUnitSurveysProps {
  careUnitId: number | undefined;
  surveys: ModelSurvey[];
  setSurveys: React.Dispatch<React.SetStateAction<ModelSurvey[]>>;
  isLoading: boolean;
  refreshData: () => void;
}

export const CareUnitSurveys: React.FC<CareUnitSurveysProps> = ({
  careUnitId,
  surveys,
  setSurveys,
  isLoading,
  refreshData,
}) => {
  const classes = useStyles();
  const { showSnackbar, handleAPIError, user } = useAppContext();
  const isAdmin = user?.role === ModelUserRoleEnum.Administrator;

  const [surveyDialogOpen, setSurveyDialogOpen] = useState(false);
  const [activeSurvey, setActiveSurvey] = useState<ModelSurvey | undefined>(
    undefined,
  );

  // Note: Currently, filtering of data happens entirely on the client-side. If there
  // are cases where a care unit has many thousands of surveys, it may be beneficial to
  // take advantage of server-side pagination and filtering by time using that. This'll
  // be more complicated to implement but more efficient for larger numbers of surveys.
  const [dateRangeRadio, setDateRangeRadio] = useState<string | null>(null);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [uploadingSnackbarOpen, setUploadingSnackbarOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [validationResponse, setValidationResponse] = useState<
    HandlersUploadError | undefined
  >(undefined);

  // Ask user to confirm leaving page while upload is in progress.
  useEffect(() => {
    // This doesn't actually prevent exit, browsers interpret it as "ask for
    // confirmation before leaving".
    // See: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    const preventExit = (e: BeforeUnloadEvent) => {
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set - browsers don't support custom messages anymore.
      e.returnValue = "";
    };

    if (uploadingSnackbarOpen) {
      window.addEventListener("beforeunload", preventExit);
    } else {
      window.removeEventListener("beforeunload", preventExit);
    }

    return () => window.removeEventListener("beforeunload", preventExit);
  }, [uploadingSnackbarOpen]);

  const downloadCsv = async () => {
    if (careUnitId === undefined) {
      return;
    }

    try {
      const surveyCsv = await getCareUnitsAPI().downloadCareHomeSurveyCsv({
        id: careUnitId,
        fromDate: fromDate ?? undefined,
        toDate: toDate ?? undefined,
      });

      const csvUrl = URL.createObjectURL(
        new Blob([surveyCsv], { type: "text/csv" }),
      );

      // The 'download' attribute isn't supported on IE. More testing
      // needed to verify what IE behaviour is here and how to work around
      // this.
      const a = document.createElement("a");
      a.href = csvUrl;
      a.download = "care-unit-surveys.csv";
      a.click();
    } catch (e) {
      handleAPIError(e, "downloading care unit survey CSV");
    }
  };

  const uploadCsv = async (files: File[]) => {
    if (careUnitId === undefined) {
      return;
    }

    setIsUploadOpen(false);
    setUploadingSnackbarOpen(true);

    let success = true;

    try {
      const response = await getCareUnitsAPI().uploadCareHomeSurveyCsvRaw({
        id: careUnitId,
        csvFile: files[0],
      });

      const decoder = new TextDecoder("utf-8");
      const progressReader = response.raw.body?.getReader();
      while (true) {
        if (progressReader === undefined) {
          break;
        }

        const { done, value } = await progressReader.read();

        if (value !== undefined) {
          const progressJson = decoder.decode(value);

          // Sometimes we get multiple chunks at a time - in this case
          // we can just split by the newline and take the lattermost.
          // This isn't the cleanest way of doing this but I can't think
          // of a better way.
          const split = progressJson.split("\n");
          const lastMessage = JSON.parse(split[split.length - 2]) as
            | UploadProgress
            | UploadError;

          if (Object.prototype.hasOwnProperty.call(lastMessage, "progress")) {
            const { progress } = lastMessage as UploadProgress;
            setUploadProgress(currentProgress =>
              Math.max(progress, currentProgress ?? 0),
            );
          } else if (Object.hasOwnProperty.call(lastMessage, "errors")) {
            const { errors } = lastMessage as UploadError;
            setValidationResponse({ errors });
            success = false;
            break;
          } else {
            console.error(
              "Unknown message from CSV upload endpoint:",
              lastMessage,
            );
            Sentry.captureException(
              new APIError("Unknown message from CSV upload endpoint."),
              scope => scope.setExtra("csvUploadLastMessage", lastMessage),
            );
            setValidationResponse({ errors: ["An unknown error occurred."] });
            success = false;
            break;
          }
        }

        if (done) {
          break;
        }
      }

      if (success) {
        showSnackbar(
          "Successfully uploaded care unit survey CSV data.",
          "success",
        );
        await refreshData();
      }
    } catch (e) {
      if (e instanceof Response && e.status === 400) {
        setValidationResponse((await e.json()) as HandlersUploadError);
      } else {
        handleAPIError(e, "uploading care unit surveys CSV");
      }
    } finally {
      setUploadProgress(100);
      setUploadingSnackbarOpen(false);
      setUploadProgress(0);
    }
  };

  const setDateFilterFromRange = (range: DateRange) => {
    const now = new Date();

    switch (range) {
      case DateRange.ThisWeek:
        setFromDate(beginningOfWeek(now));
        setToDate(endOfWeek(now));
        break;

      case DateRange.LastWeek: {
        const lastWeek = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 7,
        );
        setFromDate(beginningOfWeek(lastWeek));
        setToDate(endOfWeek(lastWeek));
        break;
      }

      case DateRange.ThisMonth:
        setFromDate(new Date(now.getFullYear(), now.getMonth(), 1));
        setToDate(new Date(now.getFullYear(), now.getMonth() + 1, 1));
        break;

      case DateRange.LastMonth:
        setFromDate(new Date(now.getFullYear(), now.getMonth() - 1, 1));
        setToDate(new Date(now.getFullYear(), now.getMonth(), 1));
        break;

      case DateRange.ThisYear:
        setFromDate(new Date(now.getFullYear(), 0, 1));
        setToDate(new Date(now.getFullYear() + 1, 0, 1));
        break;

      case DateRange.LastYear:
        setFromDate(new Date(now.getFullYear() - 1, 0, 1));
        setToDate(new Date(now.getFullYear(), 0, 1));
        break;

      case DateRange.Custom:
      default:
        setFromDate(null);
        setToDate(null);
        break;
    }
  };

  return (
    <>
      <Container>
        <Paper className={classes.surveysControlPanel}>
          <Typography variant="h6">Filter by date</Typography>

          <RadioGroup
            className={classes.timeFilterRadioGroup}
            value={dateRangeRadio}
            onChange={event => {
              const value = event.target.value;
              setDateRangeRadio(value);

              setDateFilterFromRange(value as DateRange);
            }}
          >
            <FormControlLabel
              value={DateRange.ThisWeek}
              control={<Radio />}
              label="This Week"
            />
            <FormControlLabel
              value={DateRange.LastWeek}
              control={<Radio />}
              label="Last Week"
            />
            <FormControlLabel
              value={DateRange.ThisMonth}
              control={<Radio />}
              label="This Month"
            />
            <FormControlLabel
              value={DateRange.LastMonth}
              control={<Radio />}
              label="Last Month"
            />
            <FormControlLabel
              value={DateRange.ThisYear}
              control={<Radio />}
              label="This Year"
            />
            <FormControlLabel
              value={DateRange.LastYear}
              control={<Radio />}
              label="Last Year"
            />
            <FormControlLabel
              value={DateRange.Custom}
              control={<Radio />}
              label="Custom"
            />
          </RadioGroup>

          <div className={classes.controlBottom}>
            <div className={classes.datePickers}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.surveyDatePicker}
                  disabled={dateRangeRadio !== DateRange.Custom}
                  margin="normal"
                  label="From date"
                  format="dd/MM/yyyy"
                  value={fromDate}
                  onChange={setFromDate}
                />

                <KeyboardDatePicker
                  className={classes.surveyDatePicker}
                  disabled={dateRangeRadio !== DateRange.Custom}
                  margin="normal"
                  label="To date"
                  format="dd/MM/yyyy"
                  value={toDate}
                  onChange={setToDate}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div className={classes.controlCsvActions}>
              <div className={classes.controlCsvActionsInner}>
                {isAdmin && (
                  <Button startIcon={<DownloadIcon />} onClick={downloadCsv}>
                    Download CSV
                  </Button>
                )}

                {isAdmin && (
                  <Button
                    startIcon={<UploadIcon />}
                    onClick={() => setIsUploadOpen(true)}
                    disabled={uploadingSnackbarOpen}
                  >
                    Upload CSV
                  </Button>
                )}

                <DropzoneDialog
                  open={isUploadOpen}
                  onSave={uploadCsv}
                  onClose={() => setIsUploadOpen(false)}
                  dialogTitle="Upload CSV Survey Data"
                  acceptedFiles={["text/csv"]}
                  maxFileSize={32 * 1024 * 1024}
                  showPreviews
                  useChipsForPreview
                  filesLimit={1}
                  previewGridProps={{
                    container: { spacing: 1, direction: "row" },
                  }}
                  previewChipProps={{ classes: { root: classes.previewChip } }}
                  previewText="Selected files"
                />

                <Snackbar
                  open={uploadingSnackbarOpen}
                  autoHideDuration={null}
                  onClose={(_event, reason) => {
                    if (reason === "clickaway") {
                      return;
                    }

                    setUploadingSnackbarOpen(false);
                  }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <>
                    <LinearProgress
                      className={classes.uploadProgress}
                      color="secondary"
                      variant="determinate"
                      value={uploadProgress}
                      classes={{ bar1Determinate: classes.uploadProgressBar }}
                    />
                    <Alert className={classes.uploadAlert} severity="info">
                      Processing survey CSV data...
                      <Typography
                        className={classes.uploadProgressText}
                        variant="body2"
                        color="textSecondary"
                      >{`${Math.round(uploadProgress)}%`}</Typography>
                    </Alert>
                  </>
                </Snackbar>
              </div>
            </div>
          </div>
        </Paper>
      </Container>

      <SurveysTable
        data={surveys.filter(s => {
          if (s.createdAt === undefined) {
            return true;
          }

          const createdAtTime = new Date(s.createdAt).getTime();

          if (fromDate !== null && createdAtTime < fromDate.getTime()) {
            return false;
          }

          if (toDate !== null && createdAtTime > toDate.getTime()) {
            return false;
          }

          return true;
        })}
        setData={setSurveys}
        isLoading={isLoading}
        refreshData={refreshData}
        onRowClick={rowData => {
          setActiveSurvey(rowData);
          setSurveyDialogOpen(true);
        }}
      />

      {activeSurvey && (
        <SurveyDialog
          open={surveyDialogOpen}
          onClose={() => setSurveyDialogOpen(false)}
          data={activeSurvey}
        />
      )}

      <CSVValidationAlert
        response={validationResponse}
        onClose={() => setValidationResponse(undefined)}
      />
    </>
  );
};
